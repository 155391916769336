"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientGeoAreaToJSON = exports.ClientGeoAreaFromJSONTyped = exports.ClientGeoAreaFromJSON = void 0;
function ClientGeoAreaFromJSON(json) {
    return ClientGeoAreaFromJSONTyped(json, false);
}
exports.ClientGeoAreaFromJSON = ClientGeoAreaFromJSON;
function ClientGeoAreaFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'name': json['name'],
    };
}
exports.ClientGeoAreaFromJSONTyped = ClientGeoAreaFromJSONTyped;
function ClientGeoAreaToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
    };
}
exports.ClientGeoAreaToJSON = ClientGeoAreaToJSON;
