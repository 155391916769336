import { FC, useEffect, useRef, useState } from 'react';
import * as L from 'leaflet';
import 'leaflet/dist/leaflet.css';

export type LeafletPoint = L.LatLngTuple;

export type LeafletMarker = {
  title: string;
  description: string;
  url: string;
  location: LeafletPoint;
};

export type LeafletMapProps = {
  center: LeafletPoint;
  zoom: number;
  markerUrl?: string;
  markers?: Array<LeafletMarker>;
};

const LeafletMap: FC<LeafletMapProps> = (props: LeafletMapProps) => {
  const { center, zoom, markerUrl, markers } = props;
  const mapElRef = useRef<HTMLDivElement>(null);
  const [leafletMap, setLeafletMap] = useState<L.Map>();

  useEffect(() => {
    if (!mapElRef.current) {
      return;
    }

    if (leafletMap) {
      const currentCenter = leafletMap.getCenter();
      const currentZoom = leafletMap.getZoom();
      if (!currentCenter.equals(center) || currentZoom !== zoom) {
        leafletMap.setView(center, zoom);
      }
    } else {
      const tileLayer = L.tileLayer(
        'https://proxy.infra.prod.landkreise.digital/maps/{z}/{x}/{y}.png',
        {
          attribution:
            '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        }
      );

      const map = L.map(mapElRef.current, {
        center,
        zoom,
        layers: [tileLayer]
      });

      setLeafletMap(map);
    }
  }, [center, leafletMap, zoom]);

  useEffect(() => {
    if (!leafletMap || !markers || markers.length === 0) {
      return;
    }
    const markerIcon = L.icon({
      iconUrl: markerUrl ?? '/markers/marker-default.png',
      iconSize: [32, 32]
    });
    markers.forEach((m) => {
      const { title, description, url, location: point } = m;
      const marker = L.marker(point, { icon: markerIcon, title });
      marker
        .addTo(leafletMap)
        .bindPopup(
          `<h2>${title}</h2><p>${description}</p><a href=${url}>Mehr erfahren</a>`
        );
    });
    const bounds = markers.map((m) => m.location);
    leafletMap.fitBounds(bounds);
  }, [center, leafletMap, markerUrl, markers]);

  return (
    <div style={{ height: '384px', border: '2px solid black' }}>
      <div id="map" ref={mapElRef} style={{ height: '100%' }}></div>
    </div>
  );
};

export default LeafletMap;
