import {
  Alert,
  Breadcrumbs,
  Link,
  Paper,
  Stack,
  Typography
} from '@mui/material';
import { FC, useMemo } from 'react';
import {
  useOutletContext,
  useParams,
  useLocation,
  Link as ReactRouterLink
} from 'react-router-dom';
import { getImageUrl } from '../api/InformationAPI';
import { TPost } from '../types/information';
import { findById } from '../utils/common';
import HTMLContent from './HTMLContent';
import { TPostOutletContext } from '../views/Posts/PostView';
import { CATEGORY_FIT, HOME_TILE_INFOMATION } from '../constants/strings';

type TParams = {
  postId: string;
};

type PostDetailProps = {
  posts: Array<TPost>;
};

const PostDetail: FC<PostDetailProps> = (props) => {
  const { posts } = props;
  const { postId } = useParams<TParams>();
  const location = useLocation();
  const { categories, subCategories } = useOutletContext<TPostOutletContext>();

  const currentLocationPathname = useMemo(() => {
    const { pathname } = location;
    return pathname.split('/');
  }, [location]);

  const currentPost = useMemo(() => {
    if (!postId) {
      return undefined;
    }
    return findById(posts, postId);
  }, [postId, posts]);

  const tabName = useMemo(() => {
    const pathTabNameMap: Record<string, string> = {
      information: HOME_TILE_INFOMATION,
      'fit-gesund': CATEGORY_FIT
    };
    return pathTabNameMap[currentLocationPathname[1]];
  }, [currentLocationPathname]);

  const selectedCategory = useMemo(() => {
    if (!currentPost?.category || !categories) {
      return;
    }

    return findById(categories, currentPost.category);
  }, [categories, currentPost?.category]);

  const selectedSubCategory = useMemo(() => {
    if (!currentPost?.subcategory || !subCategories) {
      return;
    }
    return subCategories.find(
      (subCategoriesforReturn) =>
        subCategoriesforReturn.id == currentPost.subcategory
    );
  }, [subCategories, currentPost?.subcategory]);

  const selectedCategoryURL =
    '/information' + '/category/' + currentPost?.category;
  const selectedSubCategoryURL =
    '/information' +
    '/category/' +
    currentPost?.category +
    '/sub-category/' +
    currentPost?.subcategory;

  if (!currentPost) {
    return <Alert severity="error">Could not load post!</Alert>;
  }

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          color="text.primary"
          variant="h3"
          to="/"
          component={ReactRouterLink}
        >
          {tabName}
        </Link>
        {selectedCategory && (
          <Link
            underline="hover"
            color="text.primary"
            variant="h3"
            to={selectedCategoryURL}
            component={ReactRouterLink}
          >
            {selectedCategory.name}
          </Link>
        )}
        {selectedSubCategory && (
          <Link
            underline="hover"
            color="text.primary"
            variant="h3"
            to={selectedSubCategoryURL}
            component={ReactRouterLink}
          >
            {selectedSubCategory.name}
          </Link>
        )}
        <Typography color="text.primary" variant="h3">
          {currentPost.title}
        </Typography>
      </Breadcrumbs>
      <Stack spacing={2}>
        <Paper variant="outlined" sx={{ padding: '1rem' }}>
          <Typography variant="h3" gutterBottom={true}>
            {currentPost.title}
          </Typography>
          {currentPost.subtitle && (
            <Typography variant="h4" gutterBottom={true}>
              {currentPost.subtitle}
            </Typography>
          )}
          {currentPost.featuredImage && (
            <div style={{ textAlign: 'center' }}>
              <img
                src={getImageUrl(currentPost.featuredImage)}
                style={{ maxWidth: '100%' }}
              />
            </div>
          )}
          <Typography component="div">
            <HTMLContent content={currentPost.text} />
          </Typography>
        </Paper>
      </Stack>
    </>
  );
};

export default PostDetail;
