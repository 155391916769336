import { Typography } from '@mui/material';
import {
  ClientHappeningExtended,
  ClientHappeningExtendedCategoryEnum
} from 'digital-vital-backend-api';
import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import { ReactComponent as Balloon } from '../../../assets/svgs/balloon.svg';
import GroupsIcon from '@mui/icons-material/Groups';
import Event from '../../../assets/svgs/event.svg';
import HappeningDetails from '../../../components/happening-detail/HappeningDetailsContainer';
import {
  CONTACT_INTERESTED_BTN_TEXT,
  INTERESTED_BADGE_TEXT,
  VERANSTALTUNGEN_JOIN_SUCCESS_MESSAGE,
  VERANSTALTUNGEN_JOIN_SUCCESS_TITLE,
  VERANSTALTUNGEN_JOIN_TEXT,
  VERANSTALTUNGEN_LEAVE_TEXT,
  VERANSTALTUNGEN_TYPE_TEXT
} from '../../../constants/strings';
import useHappeningApi from '../../../hooks/useHappeningApi';
import { EEventCategory } from '../../../types/happening';
import { hasLength } from '../../../utils/common';

const EventHappeningDetails: FC = () => {
  const happeningApi = useHappeningApi();
  const navigate = useNavigate();
  const params = useParams();
  const happeningId = useMemo(() => params.eventId, [params]);
  const [happening, setHappening] = useState<ClientHappeningExtended>();

  const navigateToEdit = useCallback(() => {
    navigate(`/veranstaltungen/createevents?eventId=${happeningId}`);
  }, [navigate, happeningId]);

  const categoryText = useMemo(() => {
    switch (happening?.category) {
      case ClientHappeningExtendedCategoryEnum.General:
        return EEventCategory.GENERAL;
      case ClientHappeningExtendedCategoryEnum.Culture:
        return EEventCategory.CULTURE;
      case ClientHappeningExtendedCategoryEnum.ExchangeEncounter:
        return EEventCategory.EXCHANGE_ENCOUNTER;
      case ClientHappeningExtendedCategoryEnum.HealthSport:
        return EEventCategory.HEALTH_SPORT;
      default:
        return EEventCategory.GENERAL;
    }
  }, [happening]);

  const hasOrganizerEmail = useMemo(() => {
    if (!happening) {
      return false;
    }
    const { organizerContact } = happening;
    return hasLength(organizerContact);
  }, [happening]);

  useEffect(() => {
    const loadEventDetailsAsync = async () => {
      if (!happeningId) {
        return;
      }
      const loadedHappening = await happeningApi.getHappeningById(happeningId);
      setHappening(loadedHappening);
    };
    loadEventDetailsAsync();
  }, [happeningApi, happeningId]);

  return (
    <HappeningDetails
      happening={happening}
      categoryText={categoryText}
      defaultHeaderImage={Event}
      hasOrganizerEmail={hasOrganizerEmail}
      isPublic={true}
      joinButtonText={VERANSTALTUNGEN_JOIN_TEXT}
      notAuthenticatedContent={
        <Fragment>
          <Typography align="center">
            {'Sie müssen sich anmelden um'}
          </Typography>
          <Typography align="center">
            <strong>
              <q>{happening?.title}</q>
            </strong>
          </Typography>
          <Typography align="center">
            {'zu ihrem Kalender hinzuzufügen!'}
          </Typography>
        </Fragment>
      }
      participantBadgeText={INTERESTED_BADGE_TEXT}
      participantButtonText={CONTACT_INTERESTED_BTN_TEXT}
      rejectionButtonText={VERANSTALTUNGEN_LEAVE_TEXT}
      requestSuccessDialogTitle={VERANSTALTUNGEN_JOIN_SUCCESS_TITLE}
      requestSuccessDialogMessage={VERANSTALTUNGEN_JOIN_SUCCESS_MESSAGE}
      typeIcon={<GroupsIcon sx={{ fontSize: '3em' }} />}
      typeText={VERANSTALTUNGEN_TYPE_TEXT}
      onEditHappening={navigateToEdit}
    />
  );
};

export default EventHappeningDetails;
