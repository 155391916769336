import {
  ClientHappeningExtended,
  ClientHappeningParticipation,
  ClientHappeningParticipationStatusEnum,
  ClientUser
} from 'digital-vital-backend-api';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { BackendApiError } from '../../../api/BackendAPI';
import { MAX_NUMBER_HAPPENING_PARTICIPANTS } from '../../../constants/constants';
import {
  DEFAULT_ERROR,
  MAX_PARTICIPANT_REACHED_ERROR,
  PENDING_DIALOG_TITLE_TEXT
} from '../../../constants/strings';
import useHappeningApi from '../../../hooks/useHappeningApi';
import useUserApi from '../../../hooks/useUserApi';
import ImageService, { ImageSizes } from '../../../services/ImageService';
import { isInRange } from '../../../utils/common';
import AbstractDialog from '../../dialogs/AbstractDialog';
import ErrorDialog from '../../ErrorDialog';
import AboutMeView from './AboutMeView';
import AlreadyDoneView from './AlreadyDoneView';
import ConfirmationAcceptView from './ConfirmationAcceptView';
import ConfirmationRejectView from './ConfirmationRejectView';
import PendingContentView from './PendingContentView';
import SuccessView from './SuccessView';

enum EDialogStatus {
  PENDING = 'pending',
  ABOUT_ME = 'aboutMe',
  REJECT = 'reject',
  ACCEPT = 'accept',
  REJECTED = 'rejected',
  ACCEPTED = 'accepted',
  ALREADY_DONE = 'alreadyDone'
}

type ParticipationRequestDialogProps = {
  happening: ClientHappeningExtended;
  participationId: string | null;
  open: boolean;
  onClose: () => void;
};

const ParticipationRequestDialog: FC<ParticipationRequestDialogProps> = (
  props: ParticipationRequestDialogProps
) => {
  const { happening, participationId, open, onClose } = props;
  const happeningApi = useHappeningApi();
  const userApi = useUserApi();
  const [participation, setParticipation] =
    useState<ClientHappeningParticipation>();
  const [participant, setParticipant] = useState<ClientUser>();
  const [participantProfilePicture, setparticipantProfilePicture] =
    useState<string>();
  const [participantRejectMessage, setParticipantRejectMessage] =
    useState<string>('');
  const [dialogStatus, setDialogStatus] = useState<EDialogStatus>(
    EDialogStatus.PENDING
  );
  const [isErrorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');

  const handleErrorDialogClose = useCallback(() => {
    setErrorDialogOpen(false);
    setErrorMsg('');
  }, []);

  const isPendingStatus = useMemo(
    () => dialogStatus === EDialogStatus.PENDING,
    [dialogStatus]
  );

  useEffect(() => {
    const loadHappeningParticipation = async () => {
      if (!participationId) {
        return;
      }
      const happeningParticipation =
        await happeningApi.getHappeningParticipation(
          happening.id,
          participationId
        );
      setParticipation(happeningParticipation);
    };
    loadHappeningParticipation();
  }, [happening.id, happeningApi, participationId]);

  useEffect(() => {
    if (!participation) {
      return;
    }
    const status = participation.status;
    if (status !== ClientHappeningParticipationStatusEnum.Pending) {
      setDialogStatus(EDialogStatus.ALREADY_DONE);
    }
  }, [participation]);

  useEffect(() => {
    const loadParticipant = async () => {
      const participantId = participation?.participantId;
      if (!participantId) {
        return;
      }
      const user = await userApi.getUserById(participantId);
      setParticipant(user);
      if (user.profilePicture) {
        setparticipantProfilePicture(
          ImageService.getImageUrl(ImageSizes.THUMBNAIL, user.profilePicture)
        );
      }
    };
    loadParticipant();
  }, [participation, userApi]);

  const rejectHappeningParticipation = useCallback(() => {
    const rejectHappeningParticipationAsync = async () => {
      if (!participationId) {
        return;
      }
      await happeningApi.rejectHappeningParticipation(
        happening.id,
        participationId,
        { message: participantRejectMessage }
      );
      setDialogStatus(EDialogStatus.REJECTED);
    };
    rejectHappeningParticipationAsync();
  }, [happening.id, happeningApi, participantRejectMessage, participationId]);

  const acceptHappeningParticipation = useCallback(() => {
    const acceptHappeningParticipationAsync = async () => {
      if (!participationId) {
        return;
      }
      try {
        await happeningApi.acceptHappeningParticipation(
          happening.id,
          participationId
        );
        setDialogStatus(EDialogStatus.ACCEPTED);
      } catch (error: BackendApiError | unknown) {
        onClose();
        if (error instanceof BackendApiError) {
          switch (error.details.type) {
            case MAX_NUMBER_HAPPENING_PARTICIPANTS:
              setErrorMsg(MAX_PARTICIPANT_REACHED_ERROR);
              break;
            // TODO: Handle other error types
            default:
              setErrorMsg(DEFAULT_ERROR);
          }
        } else {
          setErrorMsg(DEFAULT_ERROR);
        }

        setErrorDialogOpen(true);
      }
    };
    acceptHappeningParticipationAsync();
  }, [participationId, happeningApi, happening.id, onClose]);

  const handleParticipantClick = useCallback(() => {
    setDialogStatus(EDialogStatus.ABOUT_ME);
  }, []);

  const handleRejectMessageChanged = useCallback((message: string) => {
    setParticipantRejectMessage(message);
  }, []);

  const handleClose = useCallback(() => {
    if (isPendingStatus) {
      setDialogStatus(EDialogStatus.REJECT);
    } else {
      setDialogStatus(EDialogStatus.PENDING);
    }
  }, [isPendingStatus]);

  const handleAction = useCallback(() => {
    switch (dialogStatus) {
      case EDialogStatus.ACCEPT:
        acceptHappeningParticipation();
        break;
      case EDialogStatus.REJECT:
        rejectHappeningParticipation();
        break;
      case EDialogStatus.PENDING:
        setDialogStatus(EDialogStatus.ACCEPT);
        break;
      default:
        onClose();
        break;
    }
  }, [
    acceptHappeningParticipation,
    dialogStatus,
    onClose,
    rejectHappeningParticipation
  ]);

  const isFinishedStatus =
    dialogStatus === EDialogStatus.REJECTED ||
    dialogStatus === EDialogStatus.ACCEPTED ||
    dialogStatus === EDialogStatus.ALREADY_DONE;
  const btnCancelText = isPendingStatus ? 'Ablehnen' : 'Zurück';
  const btnActionText = isPendingStatus
    ? 'Akzeptieren'
    : isFinishedStatus
    ? 'Alles klar'
    : 'Ja';

  return (
    <>
      <AbstractDialog
        open={open}
        title={
          dialogStatus === EDialogStatus.ABOUT_ME
            ? participant?.displayName
            : PENDING_DIALOG_TITLE_TEXT
        }
        btnCancelText={btnCancelText}
        btnActionText={btnActionText}
        hideCancelBtn={isFinishedStatus}
        hideActionBtn={dialogStatus === EDialogStatus.ABOUT_ME}
        isActionDisabled={
          dialogStatus === EDialogStatus.REJECT &&
          !isInRange(10, 500, participantRejectMessage)
        }
        onClose={handleClose}
        onAction={handleAction}
      >
        {
          {
            pending: (
              <PendingContentView
                happeningTitle={happening.title}
                participantDisplayName={participant?.displayName}
                participantProfilePicture={participantProfilePicture}
                onParticipantClick={handleParticipantClick}
              />
            ),
            aboutMe: (
              <AboutMeView
                user={participant}
                userProfilePicture={participantProfilePicture}
              />
            ),
            reject: (
              <ConfirmationRejectView
                participantDisplayName={participant?.displayName}
                participantProfilePicture={participantProfilePicture}
                rejectMessage={participantRejectMessage}
                onParticipantClick={handleParticipantClick}
                onRejectMessageChanged={handleRejectMessageChanged}
              />
            ),
            accept: (
              <ConfirmationAcceptView
                participantDisplayName={participant?.displayName}
                participantProfilePicture={participantProfilePicture}
                onParticipantClick={handleParticipantClick}
              />
            ),
            rejected: (
              <SuccessView
                happeningTitle={happening.title}
                participantDisplayName={participant?.displayName}
                result="abgelehnt"
              />
            ),
            accepted: (
              <SuccessView
                happeningTitle={happening.title}
                participantDisplayName={participant?.displayName}
                result="bestätigt"
              />
            ),
            alreadyDone: (
              <AlreadyDoneView
                happeningTitle={happening.title}
                participantDisplayName={participant?.displayName}
              />
            )
          }[dialogStatus]
        }
      </AbstractDialog>
      <ErrorDialog
        open={isErrorDialogOpen}
        msg={errorMsg}
        onClose={handleErrorDialogClose}
      />
    </>
  );
};

export default ParticipationRequestDialog;
