import Keycloak, { type KeycloakInitOptions } from 'keycloak-js';
import { FC, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import KeycloakService from '../../services/KeycloakService';

type AuthContextProviderProps = {
  initOptions: KeycloakInitOptions;
  keycloakInstance: Keycloak;
  LoadingComponent: JSX.Element;
};

const AuthContextProvider: FC<AuthContextProviderProps> = (props) => {
  const { children, initOptions, keycloakInstance, LoadingComponent } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    const initKeycloak = async () => {
      setLoading(true);
      try {
        await keycloakInstance.init(initOptions);
        setInitialized(true);
      } finally {
        setLoading(false);
      }
    };
    initKeycloak();
  }, [initOptions, keycloakInstance]);

  useEffect(() => {
    if (!initialized || !keycloakInstance.authenticated) {
      return;
    }
    const interval = setInterval(() => {
      KeycloakService.updateToken(10);
    }, 10 * 1000);
    return () => clearInterval(interval);
  }, [initialized, keycloakInstance.authenticated]);

  if (loading) {
    return LoadingComponent;
  }

  return (
    <AuthContext.Provider value={{ initialized, keycloak: keycloakInstance }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
