import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import { FC, useCallback } from 'react';
import { HAPPENING_TYPE_TEXT, ADD_URL } from '../../../constants/strings';
import { urlIsValid } from '../../../utils/common';
import { EPresentType } from '../store/types';
import CustomTextField from './CustomTextField';

type TypeSelectionProps = {
  presentType: EPresentType;
  url: string | null;
  onTypeChange: (presentType: EPresentType) => void;
  onUrlChange: (url: string) => void;
};

const PresentTypeSelection: FC<TypeSelectionProps> = (props) => {
  const { presentType, url, onTypeChange, onUrlChange } = props;

  const handleTypeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onTypeChange(event.target.value as EPresentType);
    },
    [onTypeChange]
  );

  const handleUrlChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onUrlChange(event.target.value);
    },
    [onUrlChange]
  );

  return (
    <Box sx={{ mt: '1rem' }}>
      <Typography variant="h4">{HAPPENING_TYPE_TEXT}</Typography>
      <RadioGroup row value={presentType} onChange={handleTypeChange}>
        {Object.entries(EPresentType).map(([key, value]) => {
          return (
            <FormControlLabel
              key={key}
              value={value}
              control={<Radio />}
              label={value}
              labelPlacement="start"
            />
          );
        })}
      </RadioGroup>
      {presentType !== EPresentType.ON_SITE && (
        <CustomTextField
          fullWidth
          placeholder={ADD_URL}
          value={url}
          error={!urlIsValid(url)}
          onChange={handleUrlChange}
        />
      )}
    </Box>
  );
};

export default PresentTypeSelection;
