import EditIcon from '@mui/icons-material/Edit';
import { Badge, IconButton } from '@mui/material';
import { ClientMediaItem } from 'digital-vital-backend-api';
import {
  CSSProperties,
  FC,
  Fragment,
  useCallback,
  useMemo,
  useState
} from 'react';
import ImageService, { ImageSizes } from '../services/ImageService';
import UploadImageDialog from './dialogs/UploadImageDialog';

type EditableImageProps = {
  clientMediaItem?: ClientMediaItem;
  defaultImage: React.ReactElement;
  rounded?: boolean;
  onChange: (temporaryMediaItemId: string) => void;
  onDeleteImage?: (deleteImage: boolean) => void;
};

const useStyles = (): Record<string, CSSProperties> => ({
  rounded: {
    border: '1px solid currentColor',
    borderRadius: '50%'
  }
});

const EditableImage: FC<EditableImageProps> = ({
  clientMediaItem,
  defaultImage,
  rounded,
  onChange,
  onDeleteImage
}) => {
  const styles = useStyles();
  const [isUploadImageDialogOpen, setUploadImageDialogOpen] =
    useState<boolean>(false);

  const handleUploadImageDialogClose = useCallback(() => {
    setUploadImageDialogOpen(false);
  }, []);

  const handleUploadImageFinished = useCallback(
    (temporaryMediaItemId: string) => {
      onChange(temporaryMediaItemId);
      setUploadImageDialogOpen(false);
    },
    [onChange]
  );

  const handleDeleteImage = useCallback(
    (deleteImage: boolean) => {
      if (onDeleteImage) {
        onDeleteImage(deleteImage);
        setUploadImageDialogOpen(false);
      }
    },
    [onDeleteImage]
  );
  const showUploadImageDialog = useCallback(() => {
    setUploadImageDialogOpen(true);
  }, []);

  const imageUrl = useMemo(() => {
    return ImageService.getImageUrl(ImageSizes.THUMBNAIL, clientMediaItem);
  }, [clientMediaItem]);

  const imageStyle = useMemo(() => {
    return rounded ? { ...styles.rounded } : {};
  }, [rounded, styles]);

  return (
    <Fragment>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        badgeContent={
          <IconButton
            size="small"
            aria-label="edit-image"
            onClick={showUploadImageDialog}
            sx={{
              cursor: 'pointer',
              padding: '0.2rem',
              border: '1px solid currentColor',
              backgroundColor: 'white',
              borderRadius: '50%',
              '&:hover': {
                backgroundColor: 'white'
              },
              top: '30%',
              right: '10%'
            }}
          >
            <EditIcon sx={{ fontSize: '1rem' }} />
          </IconButton>
        }
      >
        {imageUrl ? (
          <img
            src={imageUrl}
            loading="lazy"
            height="100rem"
            style={imageStyle}
          />
        ) : (
          defaultImage
        )}
      </Badge>
      <UploadImageDialog
        imageUrl={imageUrl ? imageUrl : undefined}
        open={isUploadImageDialogOpen}
        onClose={handleUploadImageDialogClose}
        onUploadFinished={handleUploadImageFinished}
        onDeleteImage={handleDeleteImage}
      />
    </Fragment>
  );
};

export default EditableImage;
