import { FC, PropsWithChildren, useCallback } from 'react';
import AbstractDialog from './AbstractDialog';
import KeycloakService from '../../services/KeycloakService';
import useAuthContext from '../../hooks/useAuthContext';

type NotAuthenticatedDialogProps = {
  open: boolean;
  onClose: () => void;
};

const NotAuthenticatedDialog: FC<NotAuthenticatedDialogProps> = (
  props: PropsWithChildren<NotAuthenticatedDialogProps>
) => {
  const { keycloak } = useAuthContext();
  const { open, onClose, children } = props;

  const handleAction = useCallback(() => {
    if (!keycloak.authenticated) {
      KeycloakService.doLogin();
    }
  }, [keycloak]);

  return (
    <AbstractDialog
      open={open}
      title={'Bitte Anmelden'}
      btnActionText={'Anmelden'}
      onClose={onClose}
      onAction={handleAction}
    >
      {children}
    </AbstractDialog>
  );
};

export default NotAuthenticatedDialog;
