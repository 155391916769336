import { createTheme } from '@mui/material/styles';
import baseTheme from './baseTheme';
import { MuiTabComponent } from './baseThemeComponents';
import {
  NAVIGATION_ACTIVE,
  NAVIGATION_ACTIVE_BG,
  NAVIGATION_TEXT
} from './colors/globalColors';

const myAreaTheme = createTheme({
  ...baseTheme,
  palette: {
    primary: {
      main: NAVIGATION_ACTIVE
    },
    secondary: {
      main: NAVIGATION_ACTIVE
    },
    text: { secondary: NAVIGATION_TEXT }
  },
  components: {
    ...baseTheme.components,
    MuiTab: MuiTabComponent(
      NAVIGATION_TEXT,
      NAVIGATION_ACTIVE,
      NAVIGATION_ACTIVE_BG
    )
  }
});

export default myAreaTheme;
