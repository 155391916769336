"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientUserUpdateRequestToJSON = exports.ClientUserUpdateRequestFromJSONTyped = exports.ClientUserUpdateRequestFromJSON = void 0;
const runtime_1 = require("../runtime");
function ClientUserUpdateRequestFromJSON(json) {
    return ClientUserUpdateRequestFromJSONTyped(json, false);
}
exports.ClientUserUpdateRequestFromJSON = ClientUserUpdateRequestFromJSON;
function ClientUserUpdateRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'displayName': json['displayName'],
        'homeGeoAreaId': json['homeGeoAreaId'],
        'street': !runtime_1.exists(json, 'street') ? undefined : json['street'],
        'zipCode': !runtime_1.exists(json, 'zipCode') ? undefined : json['zipCode'],
        'telephone': !runtime_1.exists(json, 'telephone') ? undefined : json['telephone'],
        'aboutMe': !runtime_1.exists(json, 'aboutMe') ? undefined : json['aboutMe'],
        'temporaryMediaItemId': !runtime_1.exists(json, 'temporaryMediaItemId') ? undefined : json['temporaryMediaItemId'],
    };
}
exports.ClientUserUpdateRequestFromJSONTyped = ClientUserUpdateRequestFromJSONTyped;
function ClientUserUpdateRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'firstName': value.firstName,
        'lastName': value.lastName,
        'displayName': value.displayName,
        'homeGeoAreaId': value.homeGeoAreaId,
        'street': value.street,
        'zipCode': value.zipCode,
        'telephone': value.telephone,
        'aboutMe': value.aboutMe,
        'temporaryMediaItemId': value.temporaryMediaItemId,
    };
}
exports.ClientUserUpdateRequestToJSON = ClientUserUpdateRequestToJSON;
