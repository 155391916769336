import { ThemeProvider, Typography } from '@mui/material';
import { ClientHappeningGeoAreaMapping } from 'digital-vital-backend-api';
import { FC, Fragment, useEffect, useState } from 'react';
// import EventBalloon from '../../../assets/svgs/eventballoon.svg';
import Table from '../../../assets/svgs/table.svg';
import {
  HEADER_TITLE_NACHBARSCHAFTSTISCHE // ,
  // HEADER_TITLE_VERANSTALTUNGEN
} from '../../../constants/strings';
import useAuthContext from '../../../hooks/useAuthContext';
import useHappeningApi from '../../../hooks/useHappeningApi';
import neighborhoodTheme from '../../../themes/neighborhoodTheme';
import { HappeningType } from '../../../types/happening';
// import eventsTheme from '../../../themes/eventsTheme';
import MyHappeningList from './MyHappeningList';

const MyRegisteredHappenings: FC = () => {
  const { keycloak } = useAuthContext();
  const happeningApi = useHappeningApi();
  const [myRegisteredTables, setMyRegisteredTables] = useState<
    Array<ClientHappeningGeoAreaMapping>
  >([]);
  // const [myRegisteredEvents, setMyRegisteredEvents] = useState<
  //   Array<ClientHappeningGeoAreaMapping>
  // >([]);

  useEffect(() => {
    const loadOwnHappeningsAsync = async () => {
      if (!keycloak.authenticated) {
        return;
      }
      const ownTables = await happeningApi.getRegisteredHappenings(
        HappeningType.NeighborhoodTable
      );
      setMyRegisteredTables(ownTables);
      // const ownEvents = await happeningApi.getOwnHappenings(
      //   HappeningType.Event
      // );
      // setMyRegisteredEvents(ownEvents);
    };
    loadOwnHappeningsAsync();
  }, [happeningApi, keycloak.authenticated]);

  return (
    <Fragment>
      <ThemeProvider theme={neighborhoodTheme}>
        <Typography gutterBottom={true} variant="h2">
          {HEADER_TITLE_NACHBARSCHAFTSTISCHE}
        </Typography>
        <MyHappeningList
          happeningGeoAreaMappings={myRegisteredTables}
          defaultImage={Table}
          routePrefix={'table/'}
        />
      </ThemeProvider>
      {/* <ThemeProvider theme={eventsTheme}>
        <Typography gutterBottom={true} variant="h4">
          {HEADER_TITLE_VERANSTALTUNGEN}
        </Typography>
        <MyHappeningList
          happeningGeoAreaMappings={myRegisteredEvents}
          defaultImage={EventBalloon}
          routePrefix={'event/'}
        />
      </ThemeProvider> */}
    </Fragment>
  );
};

export default MyRegisteredHappenings;
