import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import { FC } from 'react';
import { LocationTagProps } from '../interfaces/props';

const LocationContainer = styled('span')(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  display: 'inline-block',
  borderRadius: '5px 5px 0 0',
  padding: '0 5px'
}));

const LocationTag: FC<LocationTagProps> = ({ locationName }) => {
  return (
    <LocationContainer>
      <LocationOnIcon sx={{ fontSize: '12px', marginRight: '5px' }} />
      <Typography variant="subtitle2" component="span">
        {locationName}
      </Typography>
    </LocationContainer>
  );
};

export default LocationTag;
