import { DialogContentText } from '@mui/material';
import { FC } from 'react';
import {
  ADDRESS_MISSING_HEADING,
  NAVIGATE_PROFILE_BTN_TEXT,
  REJECT_BTN_TEXT
} from '../../../constants/strings';
import { MissingAddressDialogProps } from '../../../interfaces/props';
import AbstractDialog from '../../dialogs/AbstractDialog';

const MissingAddressDialog: FC<MissingAddressDialogProps> = (props) => {
  const { message, open, onClose, onAction } = props;
  return (
    <AbstractDialog
      open={open}
      title={ADDRESS_MISSING_HEADING}
      btnActionText={NAVIGATE_PROFILE_BTN_TEXT}
      btnCancelText={REJECT_BTN_TEXT}
      hideCancelBtn={false}
      onClose={onClose}
      onAction={onAction}
    >
      <DialogContentText>{message}</DialogContentText>
    </AbstractDialog>
  );
};

export default MissingAddressDialog;
