import { Typography } from '@mui/material';
import { FC } from 'react';
import {
  CANCEL_BTN_TEXT,
  DELETE_CONFIRM_BTN_TEXT,
  VERANSTALTUNGEN_DELETE_CONFIRMATION
} from '../../constants/strings';
import AbstractDialog from '../dialogs/AbstractDialog';

type DeleteHappeningDialogProps = {
  open: boolean;
  onClose: () => void;
  onAction: () => void;
};

const DeleteHappeningDialog: FC<DeleteHappeningDialogProps> = (props) => {
  const { open, onClose, onAction } = props;
  return (
    <AbstractDialog
      open={open}
      btnCancelText={CANCEL_BTN_TEXT}
      btnActionText={DELETE_CONFIRM_BTN_TEXT}
      onClose={onClose}
      onAction={onAction}
    >
      <Typography align="center">
        {VERANSTALTUNGEN_DELETE_CONFIRMATION}
      </Typography>
    </AbstractDialog>
  );
};

export default DeleteHappeningDialog;
