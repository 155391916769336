import RestaurantIcon from '@mui/icons-material/Restaurant';
import { Typography } from '@mui/material';
import {
  ClientHappeningExtended,
  ClientHappeningExtendedMealTypeEnum
} from 'digital-vital-backend-api';
import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Table from '../../../assets/svgs/table.svg';
import HappeningDetails from '../../../components/happening-detail/HappeningDetailsContainer';
import {
  CONTACT_PARTIICPATNS_BTN_TEXT,
  MEAL_TYPE_TEXT,
  MEAL_TYPE_TEXT_CLASSIC,
  MEAL_TYPE_TEXT_VEGAN,
  MEAL_TYPE_TEXT_VEGETARIAN,
  PARTICIPANT_BADGE_TEXT,
  REJECTION_BTN_TEXT,
  REQUEST_BTN_TEXT,
  REQUEST_SUCCESS_DIALOG_MESSAGE,
  REQUEST_SUCCESS_DIALOG_TITLE
} from '../../../constants/strings';
import useHappeningApi from '../../../hooks/useHappeningApi';

const TableHappeningDetails: FC = () => {
  const happeningApi = useHappeningApi();
  const navigate = useNavigate();
  const params = useParams();
  const happeningId = useMemo(() => params.eventId, [params]);
  const [happening, setHappening] = useState<ClientHappeningExtended>();

  const navigateToEdit = useCallback(() => {
    navigate(`/nachbarschaftstische/tischanbieten?eventId=${happeningId}`);
  }, [navigate, happeningId]);

  const categoryText = useMemo(() => {
    switch (happening?.mealType) {
      case ClientHappeningExtendedMealTypeEnum.Classic:
        return MEAL_TYPE_TEXT_CLASSIC;
      case ClientHappeningExtendedMealTypeEnum.Vegan:
        return MEAL_TYPE_TEXT_VEGAN;
      case ClientHappeningExtendedMealTypeEnum.Vegetarian:
        return MEAL_TYPE_TEXT_VEGETARIAN;
      default:
        return MEAL_TYPE_TEXT_CLASSIC;
    }
  }, [happening]);

  useEffect(() => {
    const loadEventDetailsAsync = async () => {
      if (!happeningId) {
        return;
      }
      const loadedHappening = await happeningApi.getHappeningById(happeningId);
      setHappening(loadedHappening);
    };
    loadEventDetailsAsync();
  }, [happeningApi, happeningId]);

  return (
    <HappeningDetails
      happening={happening}
      categoryText={categoryText}
      defaultHeaderImage={Table}
      isPublic={false}
      joinButtonText={REQUEST_BTN_TEXT}
      notAuthenticatedContent={
        <Fragment>
          <Typography align="center">
            {'Sie müssen sich anmelden um an'}
          </Typography>
          <Typography align="center">
            <strong>
              <q>{happening?.title}</q>
            </strong>
          </Typography>
          <Typography align="center">{'teilnehmen zu können!'}</Typography>
        </Fragment>
      }
      participantBadgeText={PARTICIPANT_BADGE_TEXT}
      participantButtonText={CONTACT_PARTIICPATNS_BTN_TEXT}
      hasOrganizerEmail={true}
      rejectionButtonText={REJECTION_BTN_TEXT}
      requestSuccessDialogTitle={REQUEST_SUCCESS_DIALOG_TITLE}
      requestSuccessDialogMessage={REQUEST_SUCCESS_DIALOG_MESSAGE}
      typeIcon={<RestaurantIcon sx={{ fontSize: '48px' }} />}
      typeText={MEAL_TYPE_TEXT}
      onEditHappening={navigateToEdit}
    />
  );
};

export default TableHappeningDetails;
