import { Breadcrumbs, Link, Stack, Typography } from '@mui/material';
import { ClientPostGeoAreaMapping } from 'digital-vital-backend-api';
import { FC, useCallback, useEffect, useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import Notice from '../../../assets/svgs/notice.svg';
import NoticeList from '../../../components/notice/NoticeList';
import {
  HOME_TILE_PINNWAND,
  NOTICE_TAB_6_TEXT
} from '../../../constants/strings';
import usePostApi from '../../../hooks/usePostApi';

const Mynotices: FC = () => {
  const postApi = usePostApi();
  const [myNotices, setMyNotices] = useState<Array<ClientPostGeoAreaMapping>>(
    []
  );

  const loadMyNotices = useCallback(() => {
    const loadMyNoticesAsync = async () => {
      const allPosts = await postApi.getOwnPosts();
      setMyNotices(allPosts);
    };
    loadMyNoticesAsync();
  }, [postApi]);

  useEffect(() => {
    loadMyNotices();
    return () => {
      setMyNotices([]);
    };
  }, [loadMyNotices]);
  return (
    <Stack spacing={2}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          color="text.primary"
          variant="h3"
          to="/"
          component={ReactRouterLink}
        >
          {HOME_TILE_PINNWAND}
        </Link>
        <Typography color="text.primary" variant="h3">
          {NOTICE_TAB_6_TEXT}
        </Typography>
      </Breadcrumbs>
      <NoticeList postGeoAreaMappings={myNotices} defaultImage={Notice} />
    </Stack>
  );
};

export default Mynotices;
