import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import HelpIcon from '@mui/icons-material/Help';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/system';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import {
  LINK_HELP,
  LINK_HOME,
  LINK_IMPRINT,
  LINK_PROFILE,
  LINK_SETTING
} from '../constants/strings';
import {
  NAVIGATION_ACTIVE,
  NAVIGATION_TEXT
} from '../themes/colors/globalColors';
import navigatorTheme from '../themes/navigatorTheme';

const FixedFooter = styled('footer')(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  zIndex: 1,
  width: '100%',
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText
}));

const iconStyle = {
  height: { md: '1.75em', sm: '1.5em', xs: '1em' },
  width: 'auto'
};

const AppFooter: FC = () => {
  const footerLinks = [
    {
      id: 1,
      label: LINK_PROFILE,
      IconComponent: <AssignmentIndIcon sx={iconStyle} />,
      route: '/my-area',
      exact: false
    },
    {
      id: 2,
      label: LINK_HELP,
      IconComponent: <HelpIcon sx={iconStyle} />,
      route: '/help/overview',
      exact: false
    },
    {
      id: 3,
      label: LINK_HOME,
      IconComponent: <HomeIcon sx={iconStyle} />,
      route: '/',
      exact: true
    },
    {
      id: 4,
      label: LINK_IMPRINT,
      IconComponent: <InfoIcon sx={iconStyle} />,
      route: '/imprint/impressum',
      exact: false
    },
    {
      id: 5,
      label: LINK_SETTING,
      IconComponent: <SettingsIcon sx={iconStyle} />,
      route: '/einstellungen',
      exact: false
    }
  ];

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <ThemeProvider theme={navigatorTheme}>
      <Box sx={{ height: { sm: '6.5rem', xs: '5rem' } }} />
      <FixedFooter>
        <Container disableGutters={false}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              textAlign: 'center',
              alignItems: 'center',
              flexWrap: { xs: 'wrap', sm: 'nowrap' }
            }}
          >
            {footerLinks.map((link) => {
              return (
                <Box
                  key={link.id}
                  sx={{ flexGrow: 1, padding: { xs: '.25rem', sm: '1rem' } }}
                >
                  <NavLink
                    to={link.route}
                    end={link.exact}
                    style={({ isActive }) => ({
                      color: isActive ? NAVIGATION_ACTIVE : NAVIGATION_TEXT,
                      textDecoration: 'none'
                    })}
                  >
                    <Box
                      sx={{
                        display: 'inline-flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      {link.IconComponent}
                      {smallScreen && link.label}
                    </Box>
                  </NavLink>
                </Box>
              );
            })}
          </Box>
        </Container>
      </FixedFooter>
    </ThemeProvider>
  );
};

export default AppFooter;
