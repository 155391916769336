import { ClientPost } from 'digital-vital-backend-api';
import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthContext from '../../hooks/useAuthContext';
import useKeycloakUtils from '../../hooks/useKeycloakUtils';
import usePostApi from '../../hooks/usePostApi';
import { EPortalRoles } from '../../types/roles';
import ReportingDialog from '../ReportingDialog';
import DeleteNoticeConfirmationDialog from './DeleteNoticeConfirmationDialog';
import NoticeDetailsView from './NoticeDetailsView';

interface NoticeDetailsContainerProps {
  notice: ClientPost;
  defaultHeaderImage: string;
}

const NoticeDetailsContainer: FC<NoticeDetailsContainerProps> = ({
  notice,
  defaultHeaderImage
}) => {
  const { keycloak } = useAuthContext();
  const { getResourceRoles } = useKeycloakUtils();
  const postApi = usePostApi();
  const navigate = useNavigate();
  const { id } = notice;

  const [isDeleteNoticeDialogOpen, setDeleteNoticeDialogOpen] =
    useState<boolean>(false);
  const [
    isReportingConfirmationDialogOpen,
    setReportingConfirmationDialogOpen
  ] = useState<boolean>(false);
  const [isPortalAdmin, setIsPortalAdmin] = useState<boolean>(false);

  const handleReportingConfirmationDialogOpen = useCallback(() => {
    setReportingConfirmationDialogOpen(true);
  }, [setReportingConfirmationDialogOpen]);

  const handleReportingConfirmationDialogClose = useCallback(() => {
    setReportingConfirmationDialogOpen(false);
  }, [setReportingConfirmationDialogOpen]);

  const handleDeleteNotice = useCallback(() => {
    setDeleteNoticeDialogOpen(true);
  }, [setDeleteNoticeDialogOpen]);

  const handleDeleteNoticeDialogClose = useCallback(() => {
    setDeleteNoticeDialogOpen(false);
  }, [setDeleteNoticeDialogOpen]);

  const navigateToNearByNotices = useCallback(() => {
    navigate('/pinnwand/nearbynotices');
  }, [navigate]);

  const navigateToEdit = useCallback(() => {
    if (!notice) {
      return;
    }
    navigate(`/pinnwand/createnotice?noticeId=${id}`);
  }, [navigate, notice, id]);

  const deleteNotice = useCallback(() => {
    const deleteNoticeAsync = async () => {
      if (!notice) {
        return;
      }
      await postApi.deletePost(id);
      setDeleteNoticeDialogOpen(false);
      navigateToNearByNotices();
    };
    deleteNoticeAsync();
  }, [navigateToNearByNotices, notice, postApi, id]);

  useEffect(() => {
    if (!keycloak.authenticated) {
      return;
    }
    const roles = getResourceRoles('digital-vital-portal');
    if (roles && roles.some((r) => r === EPortalRoles.PORTAL_ADMIN)) {
      setIsPortalAdmin(true);
    }
  }, [getResourceRoles, keycloak]);

  return (
    <>
      <NoticeDetailsView
        notice={notice}
        isPortalAdmin={isPortalAdmin}
        onDeleteNotice={handleDeleteNotice}
        onEditNotice={navigateToEdit}
        onReporting={handleReportingConfirmationDialogOpen}
        defaultHeaderImage={defaultHeaderImage}
      />
      <DeleteNoticeConfirmationDialog
        open={isDeleteNoticeDialogOpen}
        onClose={handleDeleteNoticeDialogClose}
        onAction={deleteNotice}
      />
      <ReportingDialog
        open={isReportingConfirmationDialogOpen}
        onClose={handleReportingConfirmationDialogClose}
        eventId={id}
        eventType="Pinnwand"
      />
    </>
  );
};

export default NoticeDetailsContainer;
