import { Button, Menu, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC, FormEvent, useCallback, useEffect } from 'react';

import { CITY, SAVE, STREET, ZIPCODE } from '../../../constants/strings';
import { isIntValid } from '../../../utils/common';
import { TAddress } from '../store/types';
interface UpdateAddressMenuProps {
  address?: TAddress;
  anchorEl: null | HTMLElement;
  open: boolean;
  onClose: () => void;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

const UpdateAddressMenu: FC<UpdateAddressMenuProps> = (
  props: UpdateAddressMenuProps
) => {
  const { address, anchorEl, open, onClose, onSubmit } = props;
  const [zipCode, setZipCode] = React.useState<string>(address?.zipCode ?? '');
  /*
   * Know Issue with Textfield inside menu
   * https://github.com/mui/material-ui/issues/19116
   */
  const handleKeyDownEvent = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const handleZipCodeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      if (isIntValid(event.target.value)) {
        if (event.target.value === '') {
          // Since empty string is getting converted to 0
          setZipCode('');
        } else {
          // converting to number to avoid "NaN" input error
          const convertionTonumber = Number(event.target.value);
          setZipCode(convertionTonumber.toString());
        }
      }
    },
    []
  );

  useEffect(() => {
    setZipCode(address?.zipCode ?? '');
  }, [address?.zipCode]);

  return (
    <Menu
      id="address-update"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      MenuListProps={{
        'aria-labelledby': 'basic-button'
      }}
    >
      <Box
        sx={{ p: 1, width: '300px', textAlign: 'center' }}
        component="form"
        autoComplete="off"
        onSubmit={onSubmit}
      >
        <TextField
          defaultValue={address?.street}
          required
          label={STREET}
          variant="outlined"
          name="street"
          onKeyDown={handleKeyDownEvent}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          value={zipCode}
          required
          label={ZIPCODE}
          variant="outlined"
          name="zipCode"
          onKeyDown={handleKeyDownEvent}
          onChange={handleZipCodeChange}
          fullWidth
          inputProps={{ maxLength: 5 }}
          sx={{ mb: 2 }}
        ></TextField>
        <TextField
          defaultValue={address?.city}
          required
          label={CITY}
          variant="outlined"
          name="city"
          onKeyDown={handleKeyDownEvent}
          fullWidth
          sx={{ mb: 2 }}
        />
        <Button variant="contained" type="submit">
          {SAVE}
        </Button>
      </Box>
    </Menu>
  );
};

export default UpdateAddressMenu;
