import { FC, Fragment } from 'react';
import { Typography } from '@mui/material';

type AlreadyDoneViewProps = {
  happeningTitle: string;
  participantDisplayName?: string;
};

const AlreadyDoneView: FC<AlreadyDoneViewProps> = (
  props: AlreadyDoneViewProps
) => {
  const { happeningTitle, participantDisplayName } = props;
  return (
    <Fragment>
      <Typography align="center" gutterBottom={true}>
        {'Die Anfrage von '}
        <b>{participantDisplayName}</b>
        {' für den Nachbarschaftstisch '}
        <b>{happeningTitle}</b>
        {' wurde bereits bearbeitet'}
      </Typography>
    </Fragment>
  );
};

export default AlreadyDoneView;
