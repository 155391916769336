import React, { FC, useCallback, useMemo, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { SEARCH_EXPLANATION, SEARCH_HEADING } from '../../constants/strings';
import { TPost } from '../../types/information';
import { searchPosts } from '../../api/InformationAPI';
import { TPostOutletContext } from './PostView';
import PrimaryStyledLink from '../../components/PrimaryStyledLink';
import { findById } from '../../utils/common';
import { APP_VARIANT_IDENTIFIER } from '../../config';

const PUBLISHED_STATUS = 'published';

const Search: FC = () => {
  const { categories } = useOutletContext<TPostOutletContext>();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [posts, setPosts] = useState<Record<string, Array<TPost>>>({});

  const categoryIdSet = useMemo(() => {
    return new Set(categories.map((c) => c.id));
  }, [categories]);

  const findCategoryById = useCallback(
    (id: string) => {
      return findById(categories, id);
    },
    [categories]
  );

  const findPosts = useCallback(() => {
    const searchPostsAsync = async () => {
      const result = await searchPosts(searchTerm);
      const publishedPosts = result
        .filter((p) => p.portalVariantIdentifier === APP_VARIANT_IDENTIFIER)
        .filter((p) => p.status === PUBLISHED_STATUS)
        .filter((p) => categoryIdSet.has(p.category));
      const groupedPosts = publishedPosts.reduce<Record<string, Array<TPost>>>(
        (group, post) => {
          const { category } = post;
          group[category] = group[category] ?? [];
          group[category].push(post);
          return group;
        },
        {}
      );
      setPosts(groupedPosts);
    };
    searchPostsAsync();
  }, [categoryIdSet, searchTerm]);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      event.stopPropagation();
      findPosts();
    },
    [findPosts]
  );

  const handleSearchTermChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setSearchTerm(event.target.value);
    },
    []
  );

  return (
    <Box sx={{ marginTop: '1rem' }}>
      <Typography gutterBottom={true} variant="body1">
        {SEARCH_EXPLANATION}
      </Typography>
      <Box sx={{ height: '0.5rem' }} />
      <Typography gutterBottom={true} variant="h2">
        {SEARCH_HEADING}
      </Typography>
      <Box sx={{ height: '1rem' }} />
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: 'flex',
          justifyContent: 'stretch',
          alignItems: 'stretch'
        }}
      >
        <TextField
          autoFocus={true}
          value={searchTerm}
          onChange={handleSearchTermChange}
          sx={{ flex: '1' }}
        />
        <Box sx={{ width: '1rem' }} />
        <Button type="submit" variant="contained">
          Suchen
        </Button>
      </Box>
      <Box sx={{ height: '1rem' }} />
      <Stack spacing={2}>
        {Object.entries(posts).map(([categoryId, categoryPosts]) => (
          <Paper
            key={categoryId}
            square={true}
            variant="outlined"
            sx={{ padding: '1em 1em 0em' }}
          >
            <Typography gutterBottom={true} variant="h6">
              {findCategoryById(categoryId)?.name}
            </Typography>
            <Divider light />
            <List disablePadding>
              {categoryPosts.map((p) => (
                <ListItem key={p.id} disablePadding>
                  <ListItemIcon>
                    <ChevronRightIcon />
                  </ListItemIcon>
                  <PrimaryStyledLink
                    to={{ pathname: '/information/post/' + p.id }}
                  >
                    <ListItemText>{p.title}</ListItemText>
                  </PrimaryStyledLink>
                </ListItem>
              ))}
            </List>
          </Paper>
        ))}
      </Stack>
    </Box>
  );
};

export default Search;
