import EmailIcon from '@mui/icons-material/Email';
import { TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
  ClientHappeningExtended,
  ClientHappeningMessageRequest
} from 'digital-vital-backend-api';
import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CANCEL_TISCH_BTN_TEXT } from '../../../constants/strings';
import useHappeningApi from '../../../hooks/useHappeningApi';
import { isInRange } from '../../../utils/common';
import AbstractDialog from '../../dialogs/AbstractDialog';
import HappeningCancellationSuccessDialog from '../success-dialogs/HappeningCancellationSuccessDialog';

type CancelHappeningDialogProps = {
  open: boolean;
  onClose: () => void;
  happening: ClientHappeningExtended;
  isEvent?: boolean;
};

const CancelHappeningDialog: FC<CancelHappeningDialogProps> = ({
  open,
  onClose,
  happening,
  isEvent
}) => {
  const happeningApi = useHappeningApi();
  const navigate = useNavigate();

  const [message, setMessage] = useState<string>('');
  const [isCancellationSuccessDialogOpen, setCancellationSuccessDialogOpen] =
    useState<boolean>(false);

  const enableSend = useMemo(() => isInRange(10, 500, message), [message]);

  const navigateToNearByHappening = useCallback(() => {
    const navigateRoute = isEvent
      ? '/veranstaltungen/nearbyevents'
      : '/nachbarschaftstische/nearbytisch';
    navigate(navigateRoute);
  }, [isEvent, navigate]);

  const handleCancellationSuccessDialogOpen = useCallback(() => {
    setCancellationSuccessDialogOpen(true);
  }, []);

  const handleCancellationSuccessDialogClose = useCallback(() => {
    setCancellationSuccessDialogOpen(false);

    // Navigate nearby happenings after closing dialog
    navigateToNearByHappening();
  }, [navigateToNearByHappening]);

  const handleClose = useCallback(() => {
    onClose();
    setMessage('');
  }, [onClose]);

  const handleMessageChange = useCallback(
    (e) => setMessage(e.target.value),
    [setMessage]
  );

  const handleCancelHappening = useCallback(() => {
    const cancelHappeningAsync = async () => {
      if (!happening || message === '') {
        return;
      }
      const cancellationMsg: ClientHappeningMessageRequest = {
        message
      };
      await happeningApi.cancelHappening(happening.id, cancellationMsg);
      handleClose();
      handleCancellationSuccessDialogOpen();
    };
    cancelHappeningAsync();
  }, [
    message,
    handleClose,
    happening,
    happeningApi,
    handleCancellationSuccessDialogOpen
  ]);

  return (
    <>
      <AbstractDialog
        icon={<EmailIcon color="primary" sx={{ fontSize: '64px' }} />}
        open={open}
        btnActionText={CANCEL_TISCH_BTN_TEXT}
        hideCancelBtn={false}
        onClose={handleClose}
        onAction={handleCancelHappening}
        maxWidth="600px"
        isActionDisabled={!enableSend}
      >
        <Box sx={{ mb: 2 }}>
          <Typography gutterBottom={true}>
            {'Sind Sie sicher, dass Sie '}
            <span>
              {isEvent ? 'Ihre Veranstaltung' : 'Ihren Nachbarschaftstisch'}
            </span>
            <b>{` ${happening?.title} `}</b>
            absagen möchten? Geben Sie einen Grund für die zu informierenden
            Teilnehmer an:
          </Typography>
          <TextField
            multiline
            rows={4}
            variant="outlined"
            placeholder="Hier tippen…"
            label="Nachricht"
            fullWidth
            value={message}
            onChange={handleMessageChange}
            sx={{ mt: 2 }}
          />
        </Box>
      </AbstractDialog>
      <HappeningCancellationSuccessDialog
        open={isCancellationSuccessDialogOpen}
        onClose={handleCancellationSuccessDialogClose}
        isEvent={isEvent}
      />
    </>
  );
};

export default CancelHappeningDialog;
