import { createTheme } from '@mui/material/styles';
import baseTheme from './baseTheme';
import { MuiTabComponent } from './baseThemeComponents';
import {
  INFORMATIONEN_SERVICE_PRIMARY,
  INFORMATIONEN_SERVICE_TEXT,
  INFORMATIONEN_SERVICE_ACTIVE,
  INFORMATIONEN_SERVICE_ACTIVE_BG,
  INFORMATIONEN_SERVICE_INACTIVE
} from './colors/globalColors';

const informationTheme = createTheme({
  ...baseTheme,
  palette: {
    primary: {
      main: INFORMATIONEN_SERVICE_PRIMARY,
      contrastText: INFORMATIONEN_SERVICE_TEXT
    },
    secondary: {
      main: INFORMATIONEN_SERVICE_TEXT,
      contrastText: INFORMATIONEN_SERVICE_PRIMARY
    }
  },
  components: {
    ...baseTheme.components,
    MuiList: {
      styleOverrides: {
        root: {
          marginTop: '0.5rem',
          marginBottom: '0.5rem'
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '2rem'
        }
      }
    },
    MuiTab: MuiTabComponent(
      INFORMATIONEN_SERVICE_INACTIVE,
      INFORMATIONEN_SERVICE_ACTIVE,
      INFORMATIONEN_SERVICE_ACTIVE_BG
    )
  }
});

export default informationTheme;
