import { useEffect, useState } from 'react';
import BackendCommentApi from '../api/CommentAPI';
import useAuthContext from './useAuthContext';

const useCommentApi = (): BackendCommentApi => {
  const { keycloak } = useAuthContext();

  const [commentApi, setCommentApi] = useState<BackendCommentApi>(
    () => new BackendCommentApi(keycloak.token)
  );

  useEffect(() => {
    if (commentApi.accessToken !== keycloak.token) {
      setCommentApi(new BackendCommentApi(keycloak.token));
    }
  }, [commentApi.accessToken, keycloak.token]);

  return commentApi;
};

export default useCommentApi;
