import { Typography } from '@mui/material';
import { FC } from 'react';
import { REQUEST_SUCCESS_DIALOG_BTN_TEXT } from '../../../constants/strings';
import AbstractDialog from '../../dialogs/AbstractDialog';

type TerminePublishSuccessDialogProps = {
  message: string;
  open: boolean;
  onClose: () => void;
};

const TerminePublishSuccessDialog: FC<TerminePublishSuccessDialogProps> = ({
  message,
  open,
  onClose
}) => {
  return (
    <AbstractDialog
      open={open}
      btnActionText={REQUEST_SUCCESS_DIALOG_BTN_TEXT}
      hideCancelBtn={true}
      onClose={onClose}
      onAction={onClose}
    >
      <Typography align="center" gutterBottom={true}>
        {message}
      </Typography>
    </AbstractDialog>
  );
};

export default TerminePublishSuccessDialog;
