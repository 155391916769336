import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DateRangeIcon from '@mui/icons-material/DateRange';
import EuroIcon from '@mui/icons-material/Euro';
import HomeIcon from '@mui/icons-material/Home';
import IosShareIcon from '@mui/icons-material/IosShare';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Link,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { Theme } from '@mui/system/createTheme/createTheme';
import { ClientHappeningExtended, ClientUser } from 'digital-vital-backend-api';
import { CSSProperties, FC, useCallback, useMemo } from 'react';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';
import { TERMINE_REGIONAL_INTEGRATION } from '../../config';
import {
  ADDRESS_TEXT,
  CANCEL_TISCH_BTN_TEXT,
  COST_TEXT,
  DAY_TEXT,
  DELETE_BTN_TEXT,
  EDIT_BTN_TEXT,
  HEADER_TITLE_NACHBARSCHAFTSTISCHE,
  HEADER_TITLE_VERANSTALTUNGEN,
  LINK_PROFILE,
  NACHBARSCHAFTSTISCHE_TAB_1_TEXT,
  NACHBARSCHAFTSTISCHE_TAB_2_TEXT,
  NACHBARSCHAFTSTISCHE_TAB_3_TEXT,
  NACHBARSCHAFTSTISCHE_TAB_4_TEXT,
  NACHBARSCHAFTSTISCHE_TAB_5_TEXT,
  PARTICIPANTS_TEXT,
  REPORT_BTN_TXT,
  SHARE_BTN_TEXT,
  TIME_TEXT,
  VERANSTALTUNGEN_TAB_1_TEXT,
  VERANSTALTUNGEN_TAB_2_TEXT,
  VERANSTALTUNGEN_TAB_3_TEXT,
  VERANSTALTUNGEN_TAB_4_TEXT,
  VERANSTALTUNGEN_TAB_5_TEXT,
  VERANSTALTUNGEN_TERMINE_PUBLISH_BTN_TEXT
} from '../../constants/strings';
import useCurrentUser from '../../hooks/useCurrentUser';
import ImageService, { ImageSizes } from '../../services/ImageService';
import { currencyFormat, formatDate, formatTime } from '../../utils/common';
import DetailsContactButton from '../DetailsContactButton';
import RenderOnAuthenticated from '../RenderOnAuthenticated';
import HappeningAccessibilityView from './HappeningAccessibilityView';
import HappeningDetailsBadge from './HappeningDetailsBadge';
import HappeningDetailsParticipantsList from './HappeningDetailsParticipantsList';
import HappeningUrlView from './HappeningUrlView';

const useStyles = (theme: Theme): Record<string, CSSProperties> => ({
  centerText: {
    textAlign: 'center'
  },
  iconStyle: {
    fontSize: '48px',
    color: theme.palette.primary.main
  },
  titleStyle: {
    background: theme.palette.primary.main,
    height: '3rem',
    padding: '0.5rem 1rem'
  }
});

type HappeningDetailsViewProps = {
  happening: ClientHappeningExtended;
  categoryText: string;
  participants: Array<ClientUser>;
  isParticipant: boolean;
  isPortalAdmin: boolean;
  externalId?: string;
  isPublic: boolean;
  hasOrganizerEmail: boolean;
  hasOwnParticipation: boolean;
  defaultHeaderImage: string;
  joinButtonText: string;
  participantBadgeText: string;
  participantButtonText: string;
  rejectionButtonText: string;
  typeIcon: JSX.Element;
  typeText: string;
  onRequestButtonClick: () => void;
  onContactOrganizer: () => void;
  onContactParticipants: (all: boolean, participant?: ClientUser) => void;
  onCancelHappening: () => void;
  onDeleteHappening: () => void;
  onEditHappening: () => void;
  onShareHappening: () => void;
  onReportHappening: () => void;
  onTerminePublish: () => void;
  isEvent: boolean;
};

const HappeningDetailsView: FC<HappeningDetailsViewProps> = ({
  happening,
  categoryText,
  participants,
  isParticipant,
  isPortalAdmin,
  isPublic,
  isEvent,
  externalId,
  hasOrganizerEmail,
  hasOwnParticipation,
  defaultHeaderImage,
  joinButtonText,
  rejectionButtonText,
  participantBadgeText,
  participantButtonText,
  typeIcon,
  typeText,
  onRequestButtonClick,
  onContactOrganizer,
  onContactParticipants,
  onCancelHappening,
  onDeleteHappening,
  onEditHappening,
  onShareHappening,
  onReportHappening,
  onTerminePublish
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const { palette } = theme;
  const { image, creatorId, organizerName } = happening;
  const { currentUser } = useCurrentUser();
  const location = useLocation();

  const currentLocationPathname = useMemo(() => {
    const { pathname } = location;
    return pathname.split('/');
  }, [location]);

  const isOrganizer = useMemo(
    () => creatorId === currentUser?.id,
    [creatorId, currentUser]
  );

  const eventCost = useMemo(() => {
    const cost = happening.cost ?? 0;
    return `${currencyFormat(cost)} p.P.`;
  }, [happening]);

  const eventDate = useMemo(() => {
    const startTime = happening.startTime;
    return formatDate(new Date(startTime));
  }, [happening]);

  const eventDuration = useMemo(() => {
    const { startTime, endTime } = happening;
    return (
      formatTime(new Date(startTime)) + ' - ' + formatTime(new Date(endTime))
    );
  }, [happening]);

  const isNotFull = useMemo(() => {
    const { maxParticipants, participantsCount } = happening;
    return maxParticipants - participantsCount > 0;
  }, [happening]);

  const isArchivedHappening = useMemo(() => {
    const { startTime } = happening;
    return new Date(startTime) < new Date();
  }, [happening]);

  const isTerminePublishable = useMemo(
    () =>
      TERMINE_REGIONAL_INTEGRATION &&
      isEvent &&
      isOrganizer &&
      !isArchivedHappening,
    [isArchivedHappening, isEvent, isOrganizer]
  );

  const handleDeleteClicked = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      onDeleteHappening();
    },
    [onDeleteHappening]
  );

  const moduleName = useMemo(() => {
    const pathToModuleNameMap: Record<string, string> = {
      nachbarschaftstische: HEADER_TITLE_NACHBARSCHAFTSTISCHE,
      veranstaltungen: HEADER_TITLE_VERANSTALTUNGEN,
      'my-area': LINK_PROFILE
    };
    return pathToModuleNameMap[currentLocationPathname[1]];
  }, [currentLocationPathname]);

  const tabName = useMemo(() => {
    const pathToTabNameMap: Record<string, Record<string, string>> = {
      nachbarschaftstische: {
        nearbytisch: NACHBARSCHAFTSTISCHE_TAB_1_TEXT,
        myregistrations: NACHBARSCHAFTSTISCHE_TAB_2_TEXT,
        mytisch: NACHBARSCHAFTSTISCHE_TAB_3_TEXT,
        tischanbieten: NACHBARSCHAFTSTISCHE_TAB_4_TEXT,
        myoldtisch: NACHBARSCHAFTSTISCHE_TAB_5_TEXT
      },
      veranstaltungen: {
        nearbyevents: VERANSTALTUNGEN_TAB_1_TEXT,
        myregistrations: VERANSTALTUNGEN_TAB_2_TEXT,
        myevents: VERANSTALTUNGEN_TAB_3_TEXT,
        createevents: VERANSTALTUNGEN_TAB_4_TEXT,
        myoldevents: VERANSTALTUNGEN_TAB_5_TEXT
      },
      'my-area': {
        'my-registrations': NACHBARSCHAFTSTISCHE_TAB_2_TEXT
      }
    };

    return pathToTabNameMap[currentLocationPathname[1]][
      currentLocationPathname[2]
    ];
  }, [currentLocationPathname]);

  const handleShareButtonClicked = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      onShareHappening();
    },
    [onShareHappening]
  );

  const handleEditClicked = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      onEditHappening();
    },
    [onEditHappening]
  );

  const handleRequestClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      onRequestButtonClick();
    },
    [onRequestButtonClick]
  );

  return (
    <Stack spacing={2}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          color="text.primary"
          variant="h3"
          to="/"
          component={ReactRouterLink}
        >
          {moduleName}
        </Link>
        <Link
          underline="hover"
          color="text.primary"
          variant="h3"
          to={
            '/' + currentLocationPathname[1] + '/' + currentLocationPathname[2]
          }
          component={ReactRouterLink}
        >
          {tabName}
        </Link>
        <Typography color="text.primary" variant="h3">
          {happening.title}
        </Typography>
      </Breadcrumbs>
      <Card>
        <Box>
          <Box position="relative">
            <CardMedia
              component="img"
              height="140"
              image={
                ImageService.getImageUrl(ImageSizes.MEDIUM, image) ??
                defaultHeaderImage
              }
            />
            {!isOrganizer && hasOrganizerEmail && (
              <DetailsContactButton onContactClick={onContactOrganizer} />
            )}
            {(isOrganizer || (!isPublic && isParticipant)) && (
              <HappeningDetailsParticipantsList
                buttonText={participantButtonText}
                participants={participants}
                onContactParticipants={onContactParticipants}
                isOrganizer={isOrganizer}
              />
            )}
            {(isOrganizer || isParticipant) && (
              <HappeningDetailsBadge
                isOrganizer={isOrganizer}
                isParticipant={isParticipant}
                participantText={participantBadgeText}
              />
            )}
          </Box>
          <Box sx={styles.titleStyle}>
            <Typography variant="h2" color={palette.primary.contrastText}>
              {happening.title}
            </Typography>
          </Box>
          <CardContent>
            <Typography variant="body2" sx={{ mb: '1.5rem' }}>
              {'veranstaltet von '}
              <b>{organizerName}</b>
            </Typography>
            <Typography variant="body2">{happening.description}</Typography>
            {happening.registrationNotice && (
              <Typography variant="body2" sx={{ mt: '1.5rem' }}>
                <b>Hinweis zur Anmeldung: </b>
                {happening.registrationNotice}
              </Typography>
            )}
          </CardContent>
          <Box
            sx={{
              borderBottom: 'solid 1px #707070',
              borderTop: 'solid 1px #707070',
              p: '16px'
            }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                sm={4}
                sx={{
                  textAlign: 'center',
                  borderRight: { sm: 'solid 1px #707070' },
                  borderBottom: 'solid 1px #707070'
                }}
              >
                <Box sx={{ p: 3 }}>
                  <Box sx={{ mb: '1rem' }}>
                    <DateRangeIcon sx={styles.iconStyle} />
                    <Typography variant="h4">{DAY_TEXT}</Typography>
                  </Box>
                  <Typography variant="body1">{eventDate}</Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                sx={{
                  textAlign: 'center',
                  borderRight: { sm: 'solid 1px #707070' },
                  borderBottom: 'solid 1px #707070'
                }}
              >
                <Box sx={{ p: 3 }}>
                  <Box sx={{ mb: '1rem' }}>
                    <AccessTimeIcon sx={styles.iconStyle} />
                    <Typography variant="h4">{TIME_TEXT}</Typography>
                  </Box>
                  <Typography variant="body1">{eventDuration}</Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                sx={{
                  textAlign: 'center',
                  borderBottom: 'solid 1px #707070'
                }}
              >
                <Box sx={{ p: 3 }}>
                  <Box sx={{ mb: '1rem' }}>
                    <HomeIcon sx={styles.iconStyle} />
                    <Typography variant="h4">{ADDRESS_TEXT}</Typography>
                  </Box>
                  <Typography variant="body1">
                    {happening.location?.address}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                sx={{
                  textAlign: 'center',
                  borderRight: { sm: 'solid 1px #707070' },
                  borderBottom: { sm: 'none', xs: 'solid 1px #707070' }
                }}
              >
                <Box sx={{ p: 3 }}>
                  <Box sx={{ mb: '1rem' }}>
                    <Box sx={{ color: theme.palette.primary.main }}>
                      {typeIcon}
                    </Box>
                    <Typography variant="h4">{typeText}</Typography>
                  </Box>
                  <Typography variant="body1">{categoryText}</Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                sx={{
                  textAlign: 'center',
                  borderRight: { sm: 'solid 1px #707070' },
                  borderBottom: { sm: 'none', xs: 'solid 1px #707070' }
                }}
              >
                <Box sx={{ p: 3 }}>
                  <Box sx={{ mb: '1rem' }}>
                    <PeopleAltIcon sx={styles.iconStyle} />
                    <Typography variant="h4">{PARTICIPANTS_TEXT}</Typography>
                  </Box>
                  <Typography variant="body1">
                    {(isEvent ? '' : `${happening.participantsCount}/`) +
                      `${happening.maxParticipants} Teilnehmer`}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} sx={{ textAlign: 'center' }}>
                <Box sx={{ p: 3 }}>
                  <Box sx={{ mb: '1rem' }}>
                    <EuroIcon sx={styles.iconStyle} />
                    <Typography variant="h4">{COST_TEXT}</Typography>
                  </Box>
                  <Typography variant="body1">{eventCost}</Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box>
          <Box>
            <Grid container>
              <Grid item xs={12} sm={isOrganizer && isPortalAdmin ? 6 : 8}>
                {happening.online === true ? (
                  <HappeningUrlView happening={happening} />
                ) : (
                  <HappeningAccessibilityView happening={happening} />
                )}
              </Grid>
              <Grid item xs={12} sm={isOrganizer && isPortalAdmin ? 6 : 4}>
                <Box
                  sx={{
                    p: 3,
                    textAlign: 'center',
                    display: 'inline-flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    width: '100%'
                  }}
                >
                  {(isOrganizer ||
                    isPortalAdmin ||
                    isNotFull ||
                    hasOwnParticipation) &&
                    !isArchivedHappening && (
                      <>
                        {!isOrganizer && (isNotFull || hasOwnParticipation) && (
                          <Button
                            variant="contained"
                            onClick={handleRequestClick}
                            sx={{ mr: '0.5rem', mt: '0.5rem' }}
                          >
                            {hasOwnParticipation
                              ? rejectionButtonText
                              : joinButtonText}
                          </Button>
                        )}
                        {isOrganizer && (
                          <Button
                            variant="outlined"
                            onClick={onCancelHappening}
                            sx={{ mr: '0.5rem', mt: '0.5rem', boxShadow: 2 }}
                          >
                            {CANCEL_TISCH_BTN_TEXT}
                          </Button>
                        )}
                        {(isOrganizer || isPortalAdmin) &&
                          !isArchivedHappening && (
                            <Button
                              variant="contained"
                              onClick={handleEditClicked}
                              sx={{ mr: '0.5rem', mt: '0.5rem' }}
                            >
                              {EDIT_BTN_TEXT}
                            </Button>
                          )}
                        {isPortalAdmin && !isOrganizer && (
                          <Button
                            variant="contained"
                            onClick={handleDeleteClicked}
                            sx={{ mr: '0.5rem', mt: '0.5rem' }}
                          >
                            {DELETE_BTN_TEXT}
                          </Button>
                        )}
                        {isTerminePublishable && (
                          <Button
                            variant="contained"
                            sx={{ mr: '0.5rem', mt: '0.5rem' }}
                            onClick={onTerminePublish}
                            disabled={externalId !== undefined}
                          >
                            {VERANSTALTUNGEN_TERMINE_PUBLISH_BTN_TEXT}
                          </Button>
                        )}
                      </>
                    )}
                  <Button
                    variant="outlined"
                    onClick={handleShareButtonClicked}
                    sx={{ mr: '0.5rem', mt: '0.5rem', boxShadow: 2 }}
                    endIcon={<IosShareIcon />}
                  >
                    {SHARE_BTN_TEXT}
                  </Button>
                  {!isOrganizer && !isArchivedHappening && (
                    <RenderOnAuthenticated>
                      <Button
                        variant="contained"
                        onClick={onReportHappening}
                        sx={{ mr: '0.5rem', mt: '0.5rem' }}
                      >
                        {REPORT_BTN_TXT}
                      </Button>
                    </RenderOnAuthenticated>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Card>
    </Stack>
  );
};

export default HappeningDetailsView;
