import { createTheme } from '@mui/material/styles';
import baseTheme from './baseTheme';
import {
  NAVIGATION_PRIMARY,
  SETTINGS_LABEL,
  NAVIGATION_ACTIVE,
  SETTINGS_INPUT_BACKGROUND
} from './colors/globalColors';

const profileTheme = createTheme({
  ...baseTheme,
  palette: {
    primary: {
      main: NAVIGATION_PRIMARY,
      contrastText: NAVIGATION_ACTIVE
    },
    secondary: {
      main: NAVIGATION_ACTIVE,
      contrastText: NAVIGATION_PRIMARY
    },
    text: { secondary: SETTINGS_LABEL }
  },
  components: {
    ...baseTheme.components,
    MuiFormControl: {
      defaultProps: {
        fullWidth: true
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          marginLeft: '4px',
          marginRight: '4px',
          textAlign: 'end'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: SETTINGS_LABEL,
          fontSize: '0.75rem',
          position: 'relative',
          marginLeft: '4px',
          transform: 'none'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: '0px'
        },
        input: {
          backgroundColor: SETTINGS_INPUT_BACKGROUND,
          borderRadius: 5,
          border: '2px solid ' + NAVIGATION_PRIMARY,
          padding: '0.5rem',
          width: 'calc(100% - 4px)'
        }
      }
    },
    MuiSelect: {
      defaultProps: {
        fullWidth: true
      }
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true
      }
    }
  }
});

export default profileTheme;
