import React from 'react';
import { ClientUserExtended } from 'digital-vital-backend-api';

export type TUser = ClientUserExtended | null;

export type TCurrentUserContext = {
  currentUser: TUser;
  setCurrentUser: (user: TUser) => void;
};

const CurrentUserContext = React.createContext<TCurrentUserContext>({
  currentUser: null,
  setCurrentUser: () => {}
});

export { CurrentUserContext };
