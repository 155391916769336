import { NULL_UUID } from '../../constants/constants';
import { NO_SUBCATEGORY_FITHEALTH } from '../../constants/strings';
import { TCategory, TPost, TSubCategory } from '../../types/information';

const allPostsCategory = {
  id: NULL_UUID,
  name: NO_SUBCATEGORY_FITHEALTH,
  categoryId: ''
} as const;

export type TState = {
  selectedSubCategoryId: string;
  currentPosts: Array<TPost>;
  category: TCategory | undefined;
  subCategories: Array<TSubCategory>;
  posts: Array<TPost>;
};

export const initialState: TState = {
  selectedSubCategoryId: allPostsCategory.id,
  currentPosts: [],
  category: undefined,
  subCategories: [],
  posts: []
};

export type TSetSelectedSubCategoryAction = {
  name: 'setSelectedSubCategory';
  subCategoryId: string;
};

export type TSetCategoryAction = {
  name: 'setCategoryAction';
  category: TCategory;
};

export type TSetSubCategoriesAction = {
  name: 'setSubCategoriesAction';
  subCategories: Array<TSubCategory>;
};

export type TSetPostsAction = {
  name: 'setPostsAction';
  posts: Array<TPost>;
};

export type TAction =
  | TSetSelectedSubCategoryAction
  | TSetCategoryAction
  | TSetSubCategoriesAction
  | TSetPostsAction;

export const setSelectedSubCategory = (subCategoryId: string): TAction => {
  return { name: 'setSelectedSubCategory', subCategoryId };
};

export const setCategory = (category: TCategory): TAction => {
  return { name: 'setCategoryAction', category };
};

export const setSubCategories = (
  subCategories: Array<TSubCategory>
): TAction => {
  return { name: 'setSubCategoriesAction', subCategories };
};

export const setPosts = (posts: Array<TPost>): TAction => {
  return { name: 'setPostsAction', posts };
};

export const reducer = (state: TState, action: TAction): TState => {
  const name = action.name;
  switch (name) {
    case 'setSelectedSubCategory':
      return {
        ...state,
        selectedSubCategoryId: action.subCategoryId,
        currentPosts:
          action.subCategoryId !== allPostsCategory.id
            ? state.posts.filter(
                (p) => p.subcategory === action.subCategoryId.toString()
              )
            : state.posts.filter((p) => p.subcategory === null)
      };
    case 'setCategoryAction':
      return {
        ...state,
        category: action.category
      };
    case 'setSubCategoriesAction':
      return {
        ...state,
        subCategories: [allPostsCategory, ...action.subCategories]
      };
    case 'setPostsAction':
      return {
        ...state,
        currentPosts:
          state.selectedSubCategoryId !== allPostsCategory.id
            ? state.posts.filter(
                (p) => p.subcategory === state.selectedSubCategoryId.toString()
              )
            : action.posts.filter((p) => p.subcategory === null),
        posts: action.posts
      };
    default:
      break;
  }
  return state;
};
