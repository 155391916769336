import {
  ClientContactAuthorRequest,
  ClientPost,
  ClientPostCreateConfirmation,
  ClientPostCreateRequest,
  ClientPostDeleteConfirmation,
  ClientPostGeoAreaMapping,
  ClientPostUpdateConfirmation,
  ClientPostUpdateRequest,
  GetOwnPostsPostTypeEnum,
  GetPostsIncludedCategoriesEnum,
  GetPostsPostTypeEnum,
  PostsApi,
  PostsApiInterface
} from 'digital-vital-backend-api';
import { APP_VARIANT_IDENTIFIER } from '../config';
import BackendApi from './BackendAPI';

export default class BackendPostApi extends BackendApi {
  private readonly _postsApi: PostsApiInterface;

  constructor(accessToken?: string) {
    super(accessToken);
    this._postsApi = new PostsApi(this.createConfiguration());
  }

  public createPost(
    clientPostCreateRequest: ClientPostCreateRequest
  ): Promise<ClientPostCreateConfirmation> {
    return this.callApi(() =>
      this._postsApi.createPost(clientPostCreateRequest, APP_VARIANT_IDENTIFIER)
    );
  }

  public updatePost(
    postId: string,
    clientPostUpdateRequest: ClientPostUpdateRequest
  ): Promise<ClientPostUpdateConfirmation> {
    return this.callApi(() =>
      this._postsApi.updatePost(
        postId,
        clientPostUpdateRequest,
        APP_VARIANT_IDENTIFIER
      )
    );
  }

  public deletePost(postId: string): Promise<ClientPostDeleteConfirmation> {
    return this.callApi(() =>
      this._postsApi.deletePost(postId, APP_VARIANT_IDENTIFIER)
    );
  }

  public getPostById(postId: string): Promise<ClientPost> {
    return this.callApi(() =>
      this._postsApi.getPostById(postId, APP_VARIANT_IDENTIFIER)
    );
  }

  public getPosts(
    postType?: GetPostsPostTypeEnum,
    includedCategories?: Set<GetPostsIncludedCategoriesEnum>
  ): Promise<Array<ClientPostGeoAreaMapping>> {
    return this.callApi(() =>
      this._postsApi.getPosts(
        postType,
        includedCategories,
        APP_VARIANT_IDENTIFIER
      )
    );
  }

  public getOwnPosts(
    postType?: GetOwnPostsPostTypeEnum
  ): Promise<Array<ClientPostGeoAreaMapping>> {
    return this.callApi(() =>
      this._postsApi.getOwnPosts(postType, APP_VARIANT_IDENTIFIER)
    );
  }

  public contactAuthor(
    postId: string,
    clientContactAuthorRequest: ClientContactAuthorRequest
  ): Promise<void> {
    return this.callApi(() =>
      this._postsApi.contactAuthor(
        postId,
        clientContactAuthorRequest,
        APP_VARIANT_IDENTIFIER
      )
    );
  }
}
