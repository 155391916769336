import { useEffect, useState } from 'react';
import BackendUserAPI from '../api/UserAPI';
import useAuthContext from './useAuthContext';

const useUserApi = (): BackendUserAPI => {
  const { keycloak } = useAuthContext();

  const [userApi, setUserApi] = useState<BackendUserAPI>(
    () => new BackendUserAPI(keycloak.token)
  );

  useEffect(() => {
    if (userApi.accessToken !== keycloak.token) {
      setUserApi(new BackendUserAPI(keycloak.token));
    }
  }, [userApi.accessToken, keycloak.token]);

  return userApi;
};

export default useUserApi;
