import {
  ClientDataPrivacyRequest,
  DataprivacyApi,
  DataprivacyApiInterface
} from 'digital-vital-backend-api';
import { APP_VARIANT_IDENTIFIER } from '../config';
import BackendApi from './BackendAPI';

export default class BackendDataPrivacyApi extends BackendApi {
  private readonly _dataPrivacyApi: DataprivacyApiInterface;

  constructor(accessToken?: string) {
    super(accessToken);
    this._dataPrivacyApi = new DataprivacyApi(this.createConfiguration());
  }

  public deleteAccount(
    clientDataPrivacyRequest: ClientDataPrivacyRequest
  ): Promise<void> {
    return this.callApi(() =>
      this._dataPrivacyApi.deleteAccount(
        clientDataPrivacyRequest,
        APP_VARIANT_IDENTIFIER
      )
    );
  }

  public requestReport(): Promise<void> {
    return this.callApi(() =>
      this._dataPrivacyApi.requestReport(APP_VARIANT_IDENTIFIER)
    );
  }
}
