"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoleApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class RoleApi extends runtime.BaseAPI {
    /**
     * Assigns role to user
     * Assigns role to user
     */
    assignRoleToUserRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.userId === null || requestParameters.userId === undefined) {
                throw new runtime.RequiredError('userId', 'Required parameter requestParameters.userId was null or undefined when calling assignRoleToUser.');
            }
            if (requestParameters.clientAssignRoleToUserRequest === null || requestParameters.clientAssignRoleToUserRequest === undefined) {
                throw new runtime.RequiredError('clientAssignRoleToUserRequest', 'Required parameter requestParameters.clientAssignRoleToUserRequest was null or undefined when calling assignRoleToUser.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/users/{userId}/roles/assign`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ClientAssignRoleToUserRequestToJSON(requestParameters.clientAssignRoleToUserRequest),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Assigns role to user
     * Assigns role to user
     */
    assignRoleToUser(userId, clientAssignRoleToUserRequest, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.assignRoleToUserRaw({ userId: userId, clientAssignRoleToUserRequest: clientAssignRoleToUserRequest, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
        });
    }
    /**
     * Gets all available roles of a public client in Keycloak
     * Gets all available roles of a client
     */
    getAvailableRolesRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling getAvailableRoles.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/clients/{clientId}/roles`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     * Gets all available roles of a public client in Keycloak
     * Gets all available roles of a client
     */
    getAvailableRoles(clientId, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getAvailableRolesRaw({ clientId: clientId, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Gets all roles a user obtained in all used public client. The roles are grouped by client id
     * Gets all roles a user obtained
     */
    getRolesOfUserRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.userId === null || requestParameters.userId === undefined) {
                throw new runtime.RequiredError('userId', 'Required parameter requestParameters.userId was null or undefined when calling getRolesOfUser.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/users/{userId}/roles`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     * Gets all roles a user obtained in all used public client. The roles are grouped by client id
     * Gets all roles a user obtained
     */
    getRolesOfUser(userId, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getRolesOfUserRaw({ userId: userId, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Removes role from user
     * Removes role from user
     */
    removeRoleFromUserRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.userId === null || requestParameters.userId === undefined) {
                throw new runtime.RequiredError('userId', 'Required parameter requestParameters.userId was null or undefined when calling removeRoleFromUser.');
            }
            if (requestParameters.clientRemoveRoleFromUserRequest === null || requestParameters.clientRemoveRoleFromUserRequest === undefined) {
                throw new runtime.RequiredError('clientRemoveRoleFromUserRequest', 'Required parameter requestParameters.clientRemoveRoleFromUserRequest was null or undefined when calling removeRoleFromUser.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/users/{userId}/roles/remove`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ClientRemoveRoleFromUserRequestToJSON(requestParameters.clientRemoveRoleFromUserRequest),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Removes role from user
     * Removes role from user
     */
    removeRoleFromUser(userId, clientRemoveRoleFromUserRequest, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.removeRoleFromUserRaw({ userId: userId, clientRemoveRoleFromUserRequest: clientRemoveRoleFromUserRequest, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
        });
    }
}
exports.RoleApi = RoleApi;
