"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageClientUserAccountDetailsToJSON = exports.PageClientUserAccountDetailsFromJSONTyped = exports.PageClientUserAccountDetailsFromJSON = void 0;
const runtime_1 = require("../runtime");
const ClientUserAccountDetails_1 = require("./ClientUserAccountDetails");
const PageableObject_1 = require("./PageableObject");
const Sort_1 = require("./Sort");
function PageClientUserAccountDetailsFromJSON(json) {
    return PageClientUserAccountDetailsFromJSONTyped(json, false);
}
exports.PageClientUserAccountDetailsFromJSON = PageClientUserAccountDetailsFromJSON;
function PageClientUserAccountDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'totalPages': !runtime_1.exists(json, 'totalPages') ? undefined : json['totalPages'],
        'totalElements': !runtime_1.exists(json, 'totalElements') ? undefined : json['totalElements'],
        'size': !runtime_1.exists(json, 'size') ? undefined : json['size'],
        'content': !runtime_1.exists(json, 'content') ? undefined : (json['content'].map(ClientUserAccountDetails_1.ClientUserAccountDetailsFromJSON)),
        'number': !runtime_1.exists(json, 'number') ? undefined : json['number'],
        'sort': !runtime_1.exists(json, 'sort') ? undefined : Sort_1.SortFromJSON(json['sort']),
        'numberOfElements': !runtime_1.exists(json, 'numberOfElements') ? undefined : json['numberOfElements'],
        'pageable': !runtime_1.exists(json, 'pageable') ? undefined : PageableObject_1.PageableObjectFromJSON(json['pageable']),
        'first': !runtime_1.exists(json, 'first') ? undefined : json['first'],
        'last': !runtime_1.exists(json, 'last') ? undefined : json['last'],
        'empty': !runtime_1.exists(json, 'empty') ? undefined : json['empty'],
    };
}
exports.PageClientUserAccountDetailsFromJSONTyped = PageClientUserAccountDetailsFromJSONTyped;
function PageClientUserAccountDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'totalPages': value.totalPages,
        'totalElements': value.totalElements,
        'size': value.size,
        'content': value.content === undefined ? undefined : (value.content.map(ClientUserAccountDetails_1.ClientUserAccountDetailsToJSON)),
        'number': value.number,
        'sort': Sort_1.SortToJSON(value.sort),
        'numberOfElements': value.numberOfElements,
        'pageable': PageableObject_1.PageableObjectToJSON(value.pageable),
        'first': value.first,
        'last': value.last,
        'empty': value.empty,
    };
}
exports.PageClientUserAccountDetailsToJSON = PageClientUserAccountDetailsToJSON;
