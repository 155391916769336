import { CSSProperties, FC, useCallback, useMemo } from 'react';
import {
  Link,
  useNavigate,
  useOutletContext,
  Link as ReactRouterLink
} from 'react-router-dom';
import {
  Box,
  Breadcrumbs,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  styled,
  Typography,
  useTheme,
  Link as MUILink
} from '@mui/material';
import { Theme } from '@mui/system/createTheme/createTheme';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SearchIcon from '@mui/icons-material/Search';
import {
  INFO_HOME_EXPLANATION,
  INFO_HOME_HEADING,
  HOME_TILE_INFOMATION
} from '../../constants/strings';
import { TCategory } from '../../types/information';
import { TPostOutletContext } from './PostView';
import PrimaryStyledLink from '../../components/PrimaryStyledLink';
import { NINE_UUID } from '../../constants/constants';

const TextStyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  textDecoration: 'none'
}));

const useStyles = (theme: Theme): Record<string, CSSProperties> => ({
  headingBoxStyle: {
    backgroundColor: theme.palette.primary.main,
    padding: '0.2rem 0.5rem'
  },
  contentBoxStyle: {
    padding: '0rem 0.5rem'
  }
});

const PostWelcomeView: FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const styles = useStyles(theme);
  const { categories, subCategories } = useOutletContext<TPostOutletContext>();

  const findSubCategories = useCallback(
    (category: TCategory) => {
      const { id } = category;
      return subCategories.filter((s) => s.categoryId == id);
    },
    [subCategories]
  );

  const categoriesWithSubCategories = useMemo(() => {
    return categories
      .filter((c) => c.id !== NINE_UUID)
      .map((c) => {
        const subCategoriesForCategory = findSubCategories(c);
        return {
          category: c,
          subCategories: subCategoriesForCategory
        };
      });
  }, [categories, findSubCategories]);

  const goToSearch = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      navigate('/information/search');
    },
    [navigate]
  );

  return (
    <Box>
      <Box />
      <Breadcrumbs aria-label="breadcrumb">
        <MUILink
          underline="hover"
          color="text.primary"
          variant="h3"
          to="/information"
          component={ReactRouterLink}
        >
          {HOME_TILE_INFOMATION}
        </MUILink>
      </Breadcrumbs>
      <Typography gutterBottom={true} variant="h2">
        {INFO_HOME_HEADING}
      </Typography>
      <Typography gutterBottom={true} variant="body1">
        {INFO_HOME_EXPLANATION}
      </Typography>
      <Typography gutterBottom={true} variant="body1">
        {
          'Bitte klicken Sie auf eine der Rubriken oder nutzen Sie die Suchfunktion'
        }
        <IconButton
          aria-label="search"
          size="small"
          color="inherit"
          disableRipple={true}
          onClick={goToSearch}
        >
          <SearchIcon />
        </IconButton>
        {' um die für Sie passende Information zu finden.'}
      </Typography>
      <Box sx={{ height: '1rem' }} />
      <Stack spacing={2}>
        {categoriesWithSubCategories.map((c) => (
          <Paper key={c.category.id} square={true} variant="outlined">
            <Box sx={styles.headingBoxStyle}>
              <Typography variant="h3">
                <TextStyledLink
                  to={{ pathname: `/information/category/${c.category.id}` }}
                >
                  {c.category.name}
                </TextStyledLink>
              </Typography>
            </Box>
            {c.subCategories.length > 0 && (
              <Box sx={styles.contentBoxStyle}>
                <List>
                  {c.subCategories.map((s) => (
                    <ListItem key={s.id} disablePadding>
                      <ListItemIcon>
                        <ChevronRightIcon />
                      </ListItemIcon>
                      <PrimaryStyledLink
                        to={{
                          pathname: `/information/category/${c.category.id}/sub-category/${s.id}`
                        }}
                      >
                        <ListItemText>{s.name}</ListItemText>
                      </PrimaryStyledLink>
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
          </Paper>
        ))}
      </Stack>
    </Box>
  );
};

export default PostWelcomeView;
