"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetOwnHappeningsHappeningTypeEnum = exports.GetOldHappeningsHappeningTypeEnum = exports.GetAllHappeningsByOwnParticipationHappeningTypeEnum = exports.GetAllHappeningsHappeningTypeEnum = exports.HappeningApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class HappeningApi extends runtime.BaseAPI {
    /**
     * Accepts participation request for a happening and informs the participant by email
     * Accepts participation in a happening
     */
    acceptHappeningParticipationRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.happeningId === null || requestParameters.happeningId === undefined) {
                throw new runtime.RequiredError('happeningId', 'Required parameter requestParameters.happeningId was null or undefined when calling acceptHappeningParticipation.');
            }
            if (requestParameters.happeningParticipationId === null || requestParameters.happeningParticipationId === undefined) {
                throw new runtime.RequiredError('happeningParticipationId', 'Required parameter requestParameters.happeningParticipationId was null or undefined when calling acceptHappeningParticipation.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/happenings/{happeningId}/participations/{happeningParticipationId}/accept`.replace(`{${"happeningId"}}`, encodeURIComponent(String(requestParameters.happeningId))).replace(`{${"happeningParticipationId"}}`, encodeURIComponent(String(requestParameters.happeningParticipationId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Accepts participation request for a happening and informs the participant by email
     * Accepts participation in a happening
     */
    acceptHappeningParticipation(happeningId, happeningParticipationId, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.acceptHappeningParticipationRaw({ happeningId: happeningId, happeningParticipationId: happeningParticipationId, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
        });
    }
    /**
     * Cancels a happening and informs the participants
     * Cancels a happening
     */
    cancelHappeningRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.happeningId === null || requestParameters.happeningId === undefined) {
                throw new runtime.RequiredError('happeningId', 'Required parameter requestParameters.happeningId was null or undefined when calling cancelHappening.');
            }
            if (requestParameters.clientHappeningMessageRequest === null || requestParameters.clientHappeningMessageRequest === undefined) {
                throw new runtime.RequiredError('clientHappeningMessageRequest', 'Required parameter requestParameters.clientHappeningMessageRequest was null or undefined when calling cancelHappening.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/happenings/{happeningId}/cancel`.replace(`{${"happeningId"}}`, encodeURIComponent(String(requestParameters.happeningId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ClientHappeningMessageRequestToJSON(requestParameters.clientHappeningMessageRequest),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Cancels a happening and informs the participants
     * Cancels a happening
     */
    cancelHappening(happeningId, clientHappeningMessageRequest, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.cancelHappeningRaw({ happeningId: happeningId, clientHappeningMessageRequest: clientHappeningMessageRequest, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
        });
    }
    /**
     * Cancels own participation in a happening and an email is sent to the happening organizer
     * Cancels own participation in a happening
     */
    cancelHappeningParticipationRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.happeningId === null || requestParameters.happeningId === undefined) {
                throw new runtime.RequiredError('happeningId', 'Required parameter requestParameters.happeningId was null or undefined when calling cancelHappeningParticipation.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/happenings/{happeningId}/cancel-participation`.replace(`{${"happeningId"}}`, encodeURIComponent(String(requestParameters.happeningId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Cancels own participation in a happening and an email is sent to the happening organizer
     * Cancels own participation in a happening
     */
    cancelHappeningParticipation(happeningId, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.cancelHappeningParticipationRaw({ happeningId: happeningId, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
        });
    }
    /**
     * Logged user can contact organizer of a happening via email. The logged user does not need to have a participation in the happening
     * Contact organizer of a happening via email
     */
    contactOrganizerRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.happeningId === null || requestParameters.happeningId === undefined) {
                throw new runtime.RequiredError('happeningId', 'Required parameter requestParameters.happeningId was null or undefined when calling contactOrganizer.');
            }
            if (requestParameters.clientContactOrganizerRequest === null || requestParameters.clientContactOrganizerRequest === undefined) {
                throw new runtime.RequiredError('clientContactOrganizerRequest', 'Required parameter requestParameters.clientContactOrganizerRequest was null or undefined when calling contactOrganizer.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/happenings/{happeningId}/contact-organizer`.replace(`{${"happeningId"}}`, encodeURIComponent(String(requestParameters.happeningId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ClientContactOrganizerRequestToJSON(requestParameters.clientContactOrganizerRequest),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Logged user can contact organizer of a happening via email. The logged user does not need to have a participation in the happening
     * Contact organizer of a happening via email
     */
    contactOrganizer(happeningId, clientContactOrganizerRequest, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.contactOrganizerRaw({ happeningId: happeningId, clientContactOrganizerRequest: clientContactOrganizerRequest, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
        });
    }
    /**
     * Contacts one participants of a happening via email.
     * Contacts one participants of a happening via email
     */
    contactParticipantRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.happeningId === null || requestParameters.happeningId === undefined) {
                throw new runtime.RequiredError('happeningId', 'Required parameter requestParameters.happeningId was null or undefined when calling contactParticipant.');
            }
            if (requestParameters.participantId === null || requestParameters.participantId === undefined) {
                throw new runtime.RequiredError('participantId', 'Required parameter requestParameters.participantId was null or undefined when calling contactParticipant.');
            }
            if (requestParameters.clientHappeningMessageRequest === null || requestParameters.clientHappeningMessageRequest === undefined) {
                throw new runtime.RequiredError('clientHappeningMessageRequest', 'Required parameter requestParameters.clientHappeningMessageRequest was null or undefined when calling contactParticipant.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/happenings/{happeningId}/contact-participants/{participantId}`.replace(`{${"happeningId"}}`, encodeURIComponent(String(requestParameters.happeningId))).replace(`{${"participantId"}}`, encodeURIComponent(String(requestParameters.participantId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ClientHappeningMessageRequestToJSON(requestParameters.clientHappeningMessageRequest),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Contacts one participants of a happening via email.
     * Contacts one participants of a happening via email
     */
    contactParticipant(happeningId, participantId, clientHappeningMessageRequest, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.contactParticipantRaw({ happeningId: happeningId, participantId: participantId, clientHappeningMessageRequest: clientHappeningMessageRequest, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
        });
    }
    /**
     * Contacts all participants of a happening via email.
     * Contacts all participants of a happening via email
     */
    contactParticipantsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.happeningId === null || requestParameters.happeningId === undefined) {
                throw new runtime.RequiredError('happeningId', 'Required parameter requestParameters.happeningId was null or undefined when calling contactParticipants.');
            }
            if (requestParameters.clientHappeningMessageRequest === null || requestParameters.clientHappeningMessageRequest === undefined) {
                throw new runtime.RequiredError('clientHappeningMessageRequest', 'Required parameter requestParameters.clientHappeningMessageRequest was null or undefined when calling contactParticipants.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/happenings/{happeningId}/contact-participants`.replace(`{${"happeningId"}}`, encodeURIComponent(String(requestParameters.happeningId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ClientHappeningMessageRequestToJSON(requestParameters.clientHappeningMessageRequest),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ClientHappeningContactParticipantsConfirmationFromJSON(jsonValue));
        });
    }
    /**
     * Contacts all participants of a happening via email.
     * Contacts all participants of a happening via email
     */
    contactParticipants(happeningId, clientHappeningMessageRequest, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.contactParticipantsRaw({ happeningId: happeningId, clientHappeningMessageRequest: clientHappeningMessageRequest, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Creates a new CalendarEvent(Veranstaltung), if the person is allowed
     * Creates a new CalendarEvent (Veranstaltung)
     */
    createCalendarEventRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientCalendarEventCreateRequest === null || requestParameters.clientCalendarEventCreateRequest === undefined) {
                throw new runtime.RequiredError('clientCalendarEventCreateRequest', 'Required parameter requestParameters.clientCalendarEventCreateRequest was null or undefined when calling createCalendarEvent.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/happenings/calendar-events`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ClientCalendarEventCreateRequestToJSON(requestParameters.clientCalendarEventCreateRequest),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ClientHappeningCreateConfirmationFromJSON(jsonValue));
        });
    }
    /**
     * Creates a new CalendarEvent(Veranstaltung), if the person is allowed
     * Creates a new CalendarEvent (Veranstaltung)
     */
    createCalendarEvent(clientCalendarEventCreateRequest, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.createCalendarEventRaw({ clientCalendarEventCreateRequest: clientCalendarEventCreateRequest, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Creates a new NeighborhoodTable, if the person is allowed
     * Creates a new NeighborhoodTable
     */
    createNeighborhoodTableRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientNeighborhoodTableCreateRequest === null || requestParameters.clientNeighborhoodTableCreateRequest === undefined) {
                throw new runtime.RequiredError('clientNeighborhoodTableCreateRequest', 'Required parameter requestParameters.clientNeighborhoodTableCreateRequest was null or undefined when calling createNeighborhoodTable.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/happenings/neighborhood-tables`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ClientNeighborhoodTableCreateRequestToJSON(requestParameters.clientNeighborhoodTableCreateRequest),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ClientHappeningCreateConfirmationFromJSON(jsonValue));
        });
    }
    /**
     * Creates a new NeighborhoodTable, if the person is allowed
     * Creates a new NeighborhoodTable
     */
    createNeighborhoodTable(clientNeighborhoodTableCreateRequest, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.createNeighborhoodTableRaw({ clientNeighborhoodTableCreateRequest: clientNeighborhoodTableCreateRequest, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Deletes a happening
     * Deletes a specific happening
     */
    deleteHappeningRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.happeningId === null || requestParameters.happeningId === undefined) {
                throw new runtime.RequiredError('happeningId', 'Required parameter requestParameters.happeningId was null or undefined when calling deleteHappening.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/happenings/{happeningId}`.replace(`{${"happeningId"}}`, encodeURIComponent(String(requestParameters.happeningId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ClientHappeningDeleteConfirmationFromJSON(jsonValue));
        });
    }
    /**
     * Deletes a happening
     * Deletes a specific happening
     */
    deleteHappening(happeningId, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.deleteHappeningRaw({ happeningId: happeningId, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Returns a map of happenings specified by selected geo areas of user or default geo area of portal variant.Only upcoming happenings are returned, i.e. start time of happening >= start of current day
     * Returns a map of happenings
     */
    getAllHappeningsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.happeningType !== undefined) {
                queryParameters['happeningType'] = requestParameters.happeningType;
            }
            const headerParameters = {};
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/happenings`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.ClientHappeningGeoAreaMappingFromJSON));
        });
    }
    /**
     * Returns a map of happenings specified by selected geo areas of user or default geo area of portal variant.Only upcoming happenings are returned, i.e. start time of happening >= start of current day
     * Returns a map of happenings
     */
    getAllHappenings(happeningType, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getAllHappeningsRaw({ happeningType: happeningType, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Returns all happenings the current user participates
     * Returns all happenings the current user participates
     */
    getAllHappeningsByOwnParticipationRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.happeningType !== undefined) {
                queryParameters['happeningType'] = requestParameters.happeningType;
            }
            const headerParameters = {};
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/happenings/participations/own`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.ClientHappeningGeoAreaMappingFromJSON));
        });
    }
    /**
     * Returns all happenings the current user participates
     * Returns all happenings the current user participates
     */
    getAllHappeningsByOwnParticipation(happeningType, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getAllHappeningsByOwnParticipationRaw({ happeningType: happeningType, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Returns a happening specified by ID
     * Returns a specific happening
     */
    getHappeningByIdRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.happeningId === null || requestParameters.happeningId === undefined) {
                throw new runtime.RequiredError('happeningId', 'Required parameter requestParameters.happeningId was null or undefined when calling getHappeningById.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/happenings/{happeningId}`.replace(`{${"happeningId"}}`, encodeURIComponent(String(requestParameters.happeningId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ClientHappeningExtendedFromJSON(jsonValue));
        });
    }
    /**
     * Returns a happening specified by ID
     * Returns a specific happening
     */
    getHappeningById(happeningId, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getHappeningByIdRaw({ happeningId: happeningId, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Lists all participants of the happening
     * Lists all participants of the happening
     */
    getHappeningParticipantsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.happeningId === null || requestParameters.happeningId === undefined) {
                throw new runtime.RequiredError('happeningId', 'Required parameter requestParameters.happeningId was null or undefined when calling getHappeningParticipants.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/happenings/{happeningId}/participants`.replace(`{${"happeningId"}}`, encodeURIComponent(String(requestParameters.happeningId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.ClientUserFromJSON));
        });
    }
    /**
     * Lists all participants of the happening
     * Lists all participants of the happening
     */
    getHappeningParticipants(happeningId, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getHappeningParticipantsRaw({ happeningId: happeningId, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Returns a happening participation specified by ID
     * Returns a specific happening participation
     */
    getHappeningParticipationByIdRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.happeningId === null || requestParameters.happeningId === undefined) {
                throw new runtime.RequiredError('happeningId', 'Required parameter requestParameters.happeningId was null or undefined when calling getHappeningParticipationById.');
            }
            if (requestParameters.happeningParticipationId === null || requestParameters.happeningParticipationId === undefined) {
                throw new runtime.RequiredError('happeningParticipationId', 'Required parameter requestParameters.happeningParticipationId was null or undefined when calling getHappeningParticipationById.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/happenings/{happeningId}/participations/{happeningParticipationId}`.replace(`{${"happeningId"}}`, encodeURIComponent(String(requestParameters.happeningId))).replace(`{${"happeningParticipationId"}}`, encodeURIComponent(String(requestParameters.happeningParticipationId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ClientHappeningParticipationFromJSON(jsonValue));
        });
    }
    /**
     * Returns a happening participation specified by ID
     * Returns a specific happening participation
     */
    getHappeningParticipationById(happeningId, happeningParticipationId, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getHappeningParticipationByIdRaw({ happeningId: happeningId, happeningParticipationId: happeningParticipationId, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Returns past happenings created by the logged user.Only past happenings are returned, i.e. start time of happening < start of current day
     * Returns past happenings
     */
    getOldHappeningsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.happeningType !== undefined) {
                queryParameters['happeningType'] = requestParameters.happeningType;
            }
            const headerParameters = {};
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/happenings/archive`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.ClientHappeningGeoAreaMappingFromJSON));
        });
    }
    /**
     * Returns past happenings created by the logged user.Only past happenings are returned, i.e. start time of happening < start of current day
     * Returns past happenings
     */
    getOldHappenings(happeningType, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getOldHappeningsRaw({ happeningType: happeningType, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Returns own happenings created by the logged user.Only upcoming happenings are returned, i.e. start time of happening >= start of current day
     * Returns own happenings
     */
    getOwnHappeningsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.happeningType !== undefined) {
                queryParameters['happeningType'] = requestParameters.happeningType;
            }
            const headerParameters = {};
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/happenings/own`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.ClientHappeningGeoAreaMappingFromJSON));
        });
    }
    /**
     * Returns own happenings created by the logged user.Only upcoming happenings are returned, i.e. start time of happening >= start of current day
     * Returns own happenings
     */
    getOwnHappenings(happeningType, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getOwnHappeningsRaw({ happeningType: happeningType, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Creates participation request for a happening and an email is sent to the happening organizer
     * Creates participation request for a happening
     */
    joinHappeningRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.happeningId === null || requestParameters.happeningId === undefined) {
                throw new runtime.RequiredError('happeningId', 'Required parameter requestParameters.happeningId was null or undefined when calling joinHappening.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/happenings/{happeningId}/join`.replace(`{${"happeningId"}}`, encodeURIComponent(String(requestParameters.happeningId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Creates participation request for a happening and an email is sent to the happening organizer
     * Creates participation request for a happening
     */
    joinHappening(happeningId, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.joinHappeningRaw({ happeningId: happeningId, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
        });
    }
    /**
     * Publishes a CalendarEvent(Veranstaltung), if the person is allowed
     * Publishes a CalendarEvent (Veranstaltung) to TermineRegional
     */
    publishToTermineRegionalRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.happeningId === null || requestParameters.happeningId === undefined) {
                throw new runtime.RequiredError('happeningId', 'Required parameter requestParameters.happeningId was null or undefined when calling publishToTermineRegional.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/happenings/calendar-events/{happeningId}/termine-regional`.replace(`{${"happeningId"}}`, encodeURIComponent(String(requestParameters.happeningId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.PublishToTermineRegionalConfirmationFromJSON(jsonValue));
        });
    }
    /**
     * Publishes a CalendarEvent(Veranstaltung), if the person is allowed
     * Publishes a CalendarEvent (Veranstaltung) to TermineRegional
     */
    publishToTermineRegional(happeningId, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.publishToTermineRegionalRaw({ happeningId: happeningId, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Rejects participation request for a happening and informs the participant by email
     * Rejects participation in a happening
     */
    rejectHappeningParticipationRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.happeningId === null || requestParameters.happeningId === undefined) {
                throw new runtime.RequiredError('happeningId', 'Required parameter requestParameters.happeningId was null or undefined when calling rejectHappeningParticipation.');
            }
            if (requestParameters.happeningParticipationId === null || requestParameters.happeningParticipationId === undefined) {
                throw new runtime.RequiredError('happeningParticipationId', 'Required parameter requestParameters.happeningParticipationId was null or undefined when calling rejectHappeningParticipation.');
            }
            if (requestParameters.clientHappeningMessageRequest === null || requestParameters.clientHappeningMessageRequest === undefined) {
                throw new runtime.RequiredError('clientHappeningMessageRequest', 'Required parameter requestParameters.clientHappeningMessageRequest was null or undefined when calling rejectHappeningParticipation.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/happenings/{happeningId}/participations/{happeningParticipationId}/reject`.replace(`{${"happeningId"}}`, encodeURIComponent(String(requestParameters.happeningId))).replace(`{${"happeningParticipationId"}}`, encodeURIComponent(String(requestParameters.happeningParticipationId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ClientHappeningMessageRequestToJSON(requestParameters.clientHappeningMessageRequest),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Rejects participation request for a happening and informs the participant by email
     * Rejects participation in a happening
     */
    rejectHappeningParticipation(happeningId, happeningParticipationId, clientHappeningMessageRequest, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.rejectHappeningParticipationRaw({ happeningId: happeningId, happeningParticipationId: happeningParticipationId, clientHappeningMessageRequest: clientHappeningMessageRequest, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
        });
    }
    /**
     * Updates a CalendarEvent
     * Updates a specific CalendarEvent
     */
    updateCalendarEventRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.happeningId === null || requestParameters.happeningId === undefined) {
                throw new runtime.RequiredError('happeningId', 'Required parameter requestParameters.happeningId was null or undefined when calling updateCalendarEvent.');
            }
            if (requestParameters.clientCalendarEventUpdateRequest === null || requestParameters.clientCalendarEventUpdateRequest === undefined) {
                throw new runtime.RequiredError('clientCalendarEventUpdateRequest', 'Required parameter requestParameters.clientCalendarEventUpdateRequest was null or undefined when calling updateCalendarEvent.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/happenings/calendar-events/{happeningId}`.replace(`{${"happeningId"}}`, encodeURIComponent(String(requestParameters.happeningId))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ClientCalendarEventUpdateRequestToJSON(requestParameters.clientCalendarEventUpdateRequest),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ClientHappeningUpdateConfirmationFromJSON(jsonValue));
        });
    }
    /**
     * Updates a CalendarEvent
     * Updates a specific CalendarEvent
     */
    updateCalendarEvent(happeningId, clientCalendarEventUpdateRequest, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateCalendarEventRaw({ happeningId: happeningId, clientCalendarEventUpdateRequest: clientCalendarEventUpdateRequest, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Updates a happening
     * Updates a specific happening
     */
    updateNeighborhoodTableRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.happeningId === null || requestParameters.happeningId === undefined) {
                throw new runtime.RequiredError('happeningId', 'Required parameter requestParameters.happeningId was null or undefined when calling updateNeighborhoodTable.');
            }
            if (requestParameters.clientNeighborhoodTableUpdateRequest === null || requestParameters.clientNeighborhoodTableUpdateRequest === undefined) {
                throw new runtime.RequiredError('clientNeighborhoodTableUpdateRequest', 'Required parameter requestParameters.clientNeighborhoodTableUpdateRequest was null or undefined when calling updateNeighborhoodTable.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/happenings/neighborhood-tables/{happeningId}`.replace(`{${"happeningId"}}`, encodeURIComponent(String(requestParameters.happeningId))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ClientNeighborhoodTableUpdateRequestToJSON(requestParameters.clientNeighborhoodTableUpdateRequest),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ClientHappeningUpdateConfirmationFromJSON(jsonValue));
        });
    }
    /**
     * Updates a happening
     * Updates a specific happening
     */
    updateNeighborhoodTable(happeningId, clientNeighborhoodTableUpdateRequest, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateNeighborhoodTableRaw({ happeningId: happeningId, clientNeighborhoodTableUpdateRequest: clientNeighborhoodTableUpdateRequest, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
}
exports.HappeningApi = HappeningApi;
/**
    * @export
    * @enum {string}
    */
var GetAllHappeningsHappeningTypeEnum;
(function (GetAllHappeningsHappeningTypeEnum) {
    GetAllHappeningsHappeningTypeEnum["NeighborhoodTable"] = "NEIGHBORHOOD_TABLE";
    GetAllHappeningsHappeningTypeEnum["Event"] = "EVENT";
})(GetAllHappeningsHappeningTypeEnum = exports.GetAllHappeningsHappeningTypeEnum || (exports.GetAllHappeningsHappeningTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var GetAllHappeningsByOwnParticipationHappeningTypeEnum;
(function (GetAllHappeningsByOwnParticipationHappeningTypeEnum) {
    GetAllHappeningsByOwnParticipationHappeningTypeEnum["NeighborhoodTable"] = "NeighborhoodTable";
    GetAllHappeningsByOwnParticipationHappeningTypeEnum["CalendarEvent"] = "CalendarEvent";
})(GetAllHappeningsByOwnParticipationHappeningTypeEnum = exports.GetAllHappeningsByOwnParticipationHappeningTypeEnum || (exports.GetAllHappeningsByOwnParticipationHappeningTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var GetOldHappeningsHappeningTypeEnum;
(function (GetOldHappeningsHappeningTypeEnum) {
    GetOldHappeningsHappeningTypeEnum["NeighborhoodTable"] = "NEIGHBORHOOD_TABLE";
    GetOldHappeningsHappeningTypeEnum["Event"] = "EVENT";
})(GetOldHappeningsHappeningTypeEnum = exports.GetOldHappeningsHappeningTypeEnum || (exports.GetOldHappeningsHappeningTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var GetOwnHappeningsHappeningTypeEnum;
(function (GetOwnHappeningsHappeningTypeEnum) {
    GetOwnHappeningsHappeningTypeEnum["NeighborhoodTable"] = "NEIGHBORHOOD_TABLE";
    GetOwnHappeningsHappeningTypeEnum["Event"] = "EVENT";
})(GetOwnHappeningsHappeningTypeEnum = exports.GetOwnHappeningsHappeningTypeEnum || (exports.GetOwnHappeningsHappeningTypeEnum = {}));
