import ForumIcon from '@mui/icons-material/Forum';
import { Box, Button, Stack, TextField, useTheme } from '@mui/material';

import React, { CSSProperties, FC } from 'react';
import {
  COMMENT_BTN_TEXT,
  COMMENT_INPUT_PLACEHOLDER
} from '../../constants/strings';

const useStyles = (): Record<string, CSSProperties> => ({
  iconContainerStyle: {
    flex: '1 1 5%',
    textAlign: 'center',
    marginTop: '.5rem'
  },
  iconStyle: {
    color: 'white'
  },
  inputContainerStyle: {
    flex: '1 1 85%',
    padding: '1rem 0'
  },
  btnContainer: {
    flex: '1 1 10%',
    textAlign: 'center'
  }
});
interface NoticeCommentInputProps {
  value: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  createComment: () => void;
}

const NoticeCommentInput: FC<NoticeCommentInputProps> = ({
  value,
  handleChange,
  createComment
}) => {
  const theme = useTheme();
  const styles = useStyles();
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{ backgroundColor: theme.palette.primary.main }}
    >
      <Box sx={styles.iconContainerStyle}>
        <ForumIcon sx={styles.iconStyle} />
      </Box>
      <Box sx={styles.inputContainerStyle}>
        <TextField
          fullWidth
          value={value}
          onChange={handleChange}
          placeholder={COMMENT_INPUT_PLACEHOLDER}
          sx={{ '& .MuiOutlinedInput-root': { backgroundColor: 'white' } }}
        />
      </Box>
      <Box sx={styles.btnContainer}>
        <Button
          variant="contained"
          onClick={createComment}
          disabled={value === ''}
        >
          {COMMENT_BTN_TEXT}
        </Button>
      </Box>
    </Stack>
  );
};

export default NoticeCommentInput;
