import {
  ClientUserContentFlagRequest,
  FlagsApi,
  FlagsApiInterface
} from 'digital-vital-backend-api';
import { APP_VARIANT_IDENTIFIER } from '../config';
import BackendApi from './BackendAPI';

export default class BackendFlagsApi extends BackendApi {
  private readonly _flagsApi: FlagsApiInterface;

  constructor(accessToken?: string) {
    super(accessToken);
    this._flagsApi = new FlagsApi(this.createConfiguration());
  }

  public flagContent(
    clientUserContentFlagRequest: ClientUserContentFlagRequest
  ): Promise<void> {
    return this.callApi(() =>
      this._flagsApi.flagContent(
        clientUserContentFlagRequest,
        APP_VARIANT_IDENTIFIER
      )
    );
  }
}
