import { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import type { To } from 'react-router';

type SimpleLinkProps = {
  to: To;
};

const SimpleLink: FC<SimpleLinkProps> = (
  props: PropsWithChildren<SimpleLinkProps>
) => {
  const { children, to } = props;
  return (
    <Link to={to} style={{ textDecoration: 'none' }}>
      {children}
    </Link>
  );
};

export default SimpleLink;
