"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientUserAccountDetailsToJSON = exports.ClientUserAccountDetailsFromJSONTyped = exports.ClientUserAccountDetailsFromJSON = void 0;
const runtime_1 = require("../runtime");
const ClientGeoArea_1 = require("./ClientGeoArea");
const ClientMediaItem_1 = require("./ClientMediaItem");
function ClientUserAccountDetailsFromJSON(json) {
    return ClientUserAccountDetailsFromJSONTyped(json, false);
}
exports.ClientUserAccountDetailsFromJSON = ClientUserAccountDetailsFromJSON;
function ClientUserAccountDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'email': json['email'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'displayName': json['displayName'],
        'homeGeoArea': ClientGeoArea_1.ClientGeoAreaFromJSON(json['homeGeoArea']),
        'profilePicture': !runtime_1.exists(json, 'profilePicture') ? undefined : ClientMediaItem_1.ClientMediaItemFromJSON(json['profilePicture']),
        'portalVariantIdentifier': !runtime_1.exists(json, 'portalVariantIdentifier') ? undefined : json['portalVariantIdentifier'],
    };
}
exports.ClientUserAccountDetailsFromJSONTyped = ClientUserAccountDetailsFromJSONTyped;
function ClientUserAccountDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'email': value.email,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'displayName': value.displayName,
        'homeGeoArea': ClientGeoArea_1.ClientGeoAreaToJSON(value.homeGeoArea),
        'profilePicture': ClientMediaItem_1.ClientMediaItemToJSON(value.profilePicture),
        'portalVariantIdentifier': value.portalVariantIdentifier,
    };
}
exports.ClientUserAccountDetailsToJSON = ClientUserAccountDetailsToJSON;
