import Keycloak from 'keycloak-js';
import React from 'react';

export type TAuthContext = {
  initialized: boolean;
  keycloak: Keycloak;
};

export const AuthContext = React.createContext<TAuthContext>({
  initialized: false,
  keycloak: new Keycloak()
});
