import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TreeView from '@mui/lab/TreeView';
import { Box, Button, Container, Typography, useTheme } from '@mui/material';
import {
  ClientGeoAreaExtended,
  ClientGeoAreaExtendedGeoAreaTypeEnum
} from 'digital-vital-backend-api/models';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BackendApiError } from '../../../api/BackendAPI';
import SuccessDialog from '../../../components/dialogs/SuccessDialog';
import Loader from '../../../components/Loader';
import ProtectedPage from '../../../components/ProtectedPage';
import {
  MEINENAHE_DESCRIPTION,
  PROFILE_AREA_UPDATE_SUCCESS,
  PROFILE_NOT_AUTHENTICATED,
  SAVE
} from '../../../constants/strings';
import useCurrentUser from '../../../hooks/useCurrentUser';
import useGeoAreaApi from '../../../hooks/useGeoAreaApi';
import useUserApi from '../../../hooks/useUserApi';
import RenderTreeWithCheckboxes, {
  RenderTree
} from './RenderTreeWithCheckboxes';

const rootAreaBaseState: ClientGeoAreaExtended = {
  id: '',
  name: '',
  geoAreaType: ClientGeoAreaExtendedGeoAreaTypeEnum.Unknown
};

const MeineNahe: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [allGeoAreaswithChild, setAllGeoAreaswithChild] =
    useState<ClientGeoAreaExtended>(rootAreaBaseState);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [isSuccessDialogOpen, setSuccessDialogOpen] = useState<boolean>(false);
  const geoAreaApi = useGeoAreaApi();
  const navigate = useNavigate();
  const theme = useTheme();
  const userApi = useUserApi();
  const { currentUser } = useCurrentUser();

  const disabledGeoAreas = useMemo(() => {
    const result = [];
    if (currentUser) {
      const { homeGeoArea } = currentUser;
      const { id } = homeGeoArea;
      result.push(id);
    }
    return result;
  }, [currentUser]);

  // node is always the root "Parent"
  // id is id of node clicked
  const getNodeById = useCallback(
    (nodes: ClientGeoAreaExtended, ids: string) => {
      const { id, childGeoAreas } = nodes;
      if (id === ids) {
        return nodes;
      }
      if (Array.isArray(childGeoAreas)) {
        let result = null;
        childGeoAreas.forEach((childNode) => {
          if (!!getNodeById(childNode, ids)) {
            result = getNodeById(childNode, ids);
          }
        });
        return result;
      }
      return null;
    },
    []
  );

  const getAllChild = useCallback((nodes: ClientGeoAreaExtended | null) => {
    if (nodes === null) {
      return [];
    }
    const { id, childGeoAreas } = nodes;
    let arrayTemp: string[] = [id];
    if (Array.isArray(childGeoAreas)) {
      childGeoAreas.forEach((childNode) => {
        arrayTemp = [...arrayTemp, ...getAllChild(childNode)];
        arrayTemp = arrayTemp.filter((v, i) => arrayTemp.indexOf(v) === i);
      });
    }
    return arrayTemp;
  }, []);

  const getChildById = useCallback(
    (node: ClientGeoAreaExtended, id: string) => {
      return getAllChild(getNodeById(node, id));
    },
    [getAllChild, getNodeById]
  );

  const navigateToProfile = useCallback(() => {
    navigate('/einstellungen');
  }, [navigate]);

  const getOnChange = useCallback(
    (checked: boolean, nodes: RenderTree) => {
      // gets all freshly selected or unselected nodes
      const allNode: string[] = getChildById(allGeoAreaswithChild, nodes.id);
      // combines newly selected nodes with existing selection
      // or filters out newly deselected nodes from existing selection
      const array = checked
        ? [...selected, ...allNode]
        : selected.filter((value) => !allNode.includes(value));
      // ensure that homeGeoArea of the currentUser is always selected
      const allChildren =
        !checked && currentUser
          ? [...array, currentUser.homeGeoArea.id]
          : array;
      // Remove duplicate childrens
      const noDuplicatesChildren = allChildren.filter(
        (item, index) => allChildren.indexOf(item) === index
      );

      setSelected(noDuplicatesChildren);
      if (noDuplicatesChildren.length > 0) {
        setDisabledButton(false);
      } else {
        setDisabledButton(true);
      }
    },
    [allGeoAreaswithChild, currentUser, getChildById, selected]
  );

  const saveSelected = useCallback(() => {
    const saveItems = async () => {
      await userApi.updateOwnUserSettings(selected);
      setSuccessDialogOpen(true);
    };
    saveItems();
  }, [selected, userApi]);

  const handleSuccessDialogClose = useCallback(() => {
    setSuccessDialogOpen(false);
  }, []);

  useEffect(() => {
    const loadUserSettings = async () => {
      if (!currentUser) {
        return;
      }
      try {
        setLoading(true);
        const userSettings = await userApi.getOwnUserSettings();
        const { selectedGeoAreas } = userSettings;
        if (selectedGeoAreas) {
          const selectedGeoAreaIds = selectedGeoAreas.map((items) => items.id);
          const { homeGeoArea } = currentUser;
          const { id } = homeGeoArea;
          const containsHomeGeoArea = selectedGeoAreaIds.some(
            (geoAreaId) => geoAreaId === id
          );
          const geoAreasOfUser = containsHomeGeoArea
            ? selectedGeoAreaIds
            : [...selectedGeoAreaIds, id];
          setSelected(geoAreasOfUser);
        }
      } catch (error) {
        // the user has no settings yet
        if (error instanceof BackendApiError && error.status === 404) {
          const { homeGeoArea } = currentUser;
          const { id } = homeGeoArea;
          setSelected([id]);
          setDisabledButton(true);
          return;
        }
        throw error;
      } finally {
        setLoading(false);
      }
    };
    loadUserSettings();
  }, [currentUser, userApi]);

  useEffect(() => {
    const loadGeoAreas = async () => {
      try {
        setLoading(true);
        const allGeoAreas = await geoAreaApi.getDefaultGeoAreaOfPortalVariant();
        setAllGeoAreaswithChild((oldAllGeoAreas) => ({
          ...oldAllGeoAreas,
          id: allGeoAreas.id,
          name: allGeoAreas.name,
          childGeoAreas: allGeoAreas.childGeoAreas,
          leaf: allGeoAreas.leaf,
          geoAreaType: allGeoAreas.geoAreaType,
          geoJson: allGeoAreas.geoJson,
          center: allGeoAreas.center,
          postalCodes: allGeoAreas.postalCodes,
          depth: allGeoAreas.depth
        }));
      } finally {
        setLoading(false);
      }
    };
    loadGeoAreas();
  }, [currentUser, geoAreaApi, userApi]);

  return (
    <ProtectedPage
      errorMessage={PROFILE_NOT_AUTHENTICATED}
      onErrorDialogClose={navigateToProfile}
    >
      <Container
        sx={{
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          paddingTop: '1rem'
        }}
      >
        <Typography variant="body2">{MEINENAHE_DESCRIPTION}</Typography>
        <Box
          sx={{
            marginTop: '5%',
            width: { md: '45%', sm: '60%', xs: '90%' },
            height: '500px',
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            boxShadow: '0px 3px 6px #00000029',
            borderRadius: '3px',
            opacity: 1,
            overflow: 'auto'
          }}
        >
          {loading ? (
            <Loader />
          ) : (
            <TreeView
              sx={{
                marginTop: '5%'
              }}
              expanded={[allGeoAreaswithChild.id]}
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
            >
              <RenderTreeWithCheckboxes
                nodes={allGeoAreaswithChild}
                selected={selected}
                disabled={disabledGeoAreas}
                getOnChange={getOnChange}
              />
            </TreeView>
          )}
        </Box>

        <Button
          onClick={saveSelected}
          disabled={disabledButton}
          sx={{
            marginTop: '5%',
            color: theme.palette.primary.contrastText,
            background: theme.palette.primary.main,
            '&:hover': {
              color: theme.palette.primary.main,
              backgroundColor: theme.palette.primary.contrastText
            },
            '&:disabled': {
              backgroundColor: theme.palette.action.disabledBackground
            }
          }}
        >
          {SAVE}
        </Button>
        <SuccessDialog
          message={PROFILE_AREA_UPDATE_SUCCESS}
          open={isSuccessDialogOpen}
          onClose={handleSuccessDialogClose}
        />
      </Container>
    </ProtectedPage>
  );
};

export default MeineNahe;
