import { Box, Typography } from '@mui/material';
import {
  ClientHappening,
  ClientHappeningGeoAreaMapping
} from 'digital-vital-backend-api';
import { FC, Fragment } from 'react';
import HappeningItem from '../../../components/HappeningItem';
import LocationTag from '../../../components/LocationTag';
import { NACHBARSCHAFTSTISCH_NO_REGISTRATION } from '../../../constants/strings';

export type MyHappeningListProps = {
  happeningGeoAreaMappings: Array<ClientHappeningGeoAreaMapping>;
  defaultImage: string;
  routePrefix: string;
};

const MyHappeningList: FC<MyHappeningListProps> = (
  props: MyHappeningListProps
) => {
  const { happeningGeoAreaMappings, defaultImage, routePrefix } = props;

  if (happeningGeoAreaMappings.length === 0) {
    return (
      <Typography variant="body1" sx={{ fontSize: '1rem' }}>
        {NACHBARSCHAFTSTISCH_NO_REGISTRATION}
      </Typography>
    );
  }

  return (
    <Fragment>
      {happeningGeoAreaMappings.map(
        (happeningGeoAreaMapping: ClientHappeningGeoAreaMapping) => {
          const { geoArea, happenings } = happeningGeoAreaMapping;
          return (
            <Box sx={{ mb: 2 }} key={geoArea.id}>
              <LocationTag locationName={geoArea.name} />
              {happenings.map((happening: ClientHappening) => {
                return (
                  <HappeningItem
                    key={happening.id}
                    defaultImage={defaultImage}
                    event={happening}
                    routePrefix={routePrefix}
                  />
                );
              })}
            </Box>
          );
        }
      )}
    </Fragment>
  );
};

export default MyHappeningList;
