import { FC, useEffect, useState } from 'react';
import { ClientHappeningGeoAreaMapping } from 'digital-vital-backend-api';
import useHappeningApi from '../../../hooks/useHappeningApi';
import {
  CALENDAR_PRIMARY,
  CALENDAR_TODAY_TEXT,
  NACHBARSCHAFTSTISCHE_PRIMARY,
  VERANSTALTUNGEN_PRIMARY
} from '../../../themes/colors/globalColors';
import Calendar from '../../../components/Calendar';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import {
  HEADER_TITLE_KALENDER,
  NAVBAR_MEIN_KALENDER
} from '../../../constants/strings';
import { Link as ReactRouterLink } from 'react-router-dom';

const MyCalendar: FC = () => {
  const happeningApi = useHappeningApi();
  const [happeningCalendarData, setHappeningCalendarData] = useState<
    Array<ClientHappeningGeoAreaMapping>
  >([]);

  useEffect(() => {
    const loadHappeningsAsync = async () => {
      const ownHappenings: Array<ClientHappeningGeoAreaMapping> =
        await happeningApi.getOwnHappenings();
      const registeredHappenings: Array<ClientHappeningGeoAreaMapping> =
        await happeningApi.getRegisteredHappenings();
      setHappeningCalendarData([...ownHappenings, ...registeredHappenings]);
    };
    loadHappeningsAsync();
  }, [happeningApi, setHappeningCalendarData]);

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          color="text.primary"
          variant="h3"
          to="/"
          component={ReactRouterLink}
        >
          {HEADER_TITLE_KALENDER}
        </Link>
        <Typography color="text.primary" variant="h3">
          {NAVBAR_MEIN_KALENDER}
        </Typography>
      </Breadcrumbs>
      <Calendar
        calendarHappeningData={happeningCalendarData}
        calendarHeaderTextColor={CALENDAR_TODAY_TEXT}
        calendarLegendEventColor={VERANSTALTUNGEN_PRIMARY}
        calendarLegendTableColor={NACHBARSCHAFTSTISCHE_PRIMARY}
        calendarPrimaryColor={CALENDAR_PRIMARY}
      />
    </>
  );
};

export default MyCalendar;
