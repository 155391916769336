import { Box, Typography } from '@mui/material';
import { ClientComment } from 'digital-vital-backend-api';
import { CSSProperties, FC } from 'react';
import { NO_COMMENTS_TEXT } from '../../constants/strings';
import NoticeCommentItem from './NoticeCommentItem';

interface NoticeCommentListProps {
  comments: Array<ClientComment>;
  commentsCount: number;
}
const useStyles = (): Record<string, CSSProperties> => ({
  commentsConatiner: {
    padding: '1rem',
    maxHeight: '15rem',
    overflow: 'hidden',
    overflowY: 'scroll'
  }
});
const NoticeCommentList: FC<NoticeCommentListProps> = ({
  comments,
  commentsCount
}) => {
  const styles = useStyles();
  return (
    <Box sx={styles.commentsConatiner}>
      {commentsCount === 0 ? (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="subtitle1">{NO_COMMENTS_TEXT}</Typography>
        </Box>
      ) : (
        comments.map((comment) => {
          return <NoticeCommentItem comment={comment} key={comment.id} />;
        })
      )}
    </Box>
  );
};

export default NoticeCommentList;
