import {
  ClientTemporaryMediaItem,
  MediaApi,
  MediaApiInterface
} from 'digital-vital-backend-api';
import { APP_VARIANT_IDENTIFIER } from '../config';
import BackendApi from './BackendAPI';

export default class BackendMediaItemAPI extends BackendApi {
  private readonly _mediaItemApi: MediaApiInterface;

  constructor(accessToken?: string) {
    super(accessToken);
    this._mediaItemApi = new MediaApi(this.createConfiguration());
  }

  public uploadTemporaryMediaItem(
    file: Blob
  ): Promise<ClientTemporaryMediaItem> {
    return this.callApi(() =>
      this._mediaItemApi.uploadTemporaryMediaItem(file, APP_VARIANT_IDENTIFIER)
    );
  }

  public getTemporaryMediaItem(
    temporaryMediaItemId: string
  ): Promise<ClientTemporaryMediaItem> {
    return this.callApi(() =>
      this._mediaItemApi.getTemporaryMediaItem(
        temporaryMediaItemId,
        APP_VARIANT_IDENTIFIER
      )
    );
  }

  public deleteTemporaryMediaItem(temporaryMediaItemId: string): Promise<void> {
    return this.callApi(() =>
      this._mediaItemApi.deleteTemporaryMediaItem(
        temporaryMediaItemId,
        APP_VARIANT_IDENTIFIER
      )
    );
  }
}
