import { Breadcrumbs, Button, Link, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
  GetPostsIncludedCategoriesEnum,
  GetPostsPostTypeEnum
} from 'digital-vital-backend-api';
import { FC, Reducer, useCallback, useReducer } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import Notice from '../../../assets/svgs/notice.svg';
import NoticeCheckboxes from '../../../components/notice/NoticeCheckboxes';
import NoticeList from '../../../components/notice/NoticeList';
import {
  HOME_TILE_PINNWAND,
  NOTICE_CATEGORY_LABEL,
  NOTICE_TAB_4_TEXT,
  NOTICE_TYPE_LABEL,
  NO_SEARCH_RESULTS,
  OFFER_OPTION_TEXT,
  SEARCH_NOTICE_BTN_TEXT,
  SEARCH_NOTICE_HEADING_TEXT,
  SEEK_OPTION_TEXT
} from '../../../constants/strings';
import usePostApi from '../../../hooks/usePostApi';
import { NoticeCategory, NoticeContentType } from '../../../types/notice';
import {
  initialState,
  PostCategoriesType,
  PostTypesType,
  reducer,
  setPostCategories,
  setPostType,
  setSearched,
  setSearchedNotices,
  TAction,
  TState
} from './SearchNoticeReducer';

const SearchNotice: FC = () => {
  const postApi = usePostApi();

  const [state, dispatch] = useReducer<Reducer<TState, TAction>>(
    reducer,
    initialState
  );

  const { postType, postCategories, searchedNotices, isSearched } = state;

  const typeCheckboxes = [
    {
      name: NoticeContentType.OFFER,
      value: postType[NoticeContentType.OFFER],
      label: OFFER_OPTION_TEXT
    },
    {
      name: NoticeContentType.SEEKING,
      value: postType[NoticeContentType.SEEKING],
      label: SEEK_OPTION_TEXT
    }
  ];
  const categoriesCheckboxes = [
    {
      name: NoticeCategory.CULTURE,
      value: postCategories[NoticeCategory.CULTURE],
      label: NoticeCategory.CULTURE
    },
    {
      name: NoticeCategory.HEALTH_SPORT,
      value: postCategories[NoticeCategory.HEALTH_SPORT],
      label: NoticeCategory.HEALTH_SPORT
    },
    {
      name: NoticeCategory.ENCOUNTER,
      value: postCategories[NoticeCategory.ENCOUNTER],
      label: NoticeCategory.ENCOUNTER
    },
    {
      name: NoticeCategory.NEIGHBORHOOD_HELP,
      value: postCategories[NoticeCategory.NEIGHBORHOOD_HELP],
      label: NoticeCategory.NEIGHBORHOOD_HELP
    },
    {
      name: NoticeCategory.GENERAL,
      value: postCategories[NoticeCategory.GENERAL],
      label: NoticeCategory.GENERAL
    }
  ];

  const toGetPostsIncludedCategoriesEnum = (
    categoryValue: string
  ): GetPostsIncludedCategoriesEnum => {
    switch (categoryValue) {
      case NoticeCategory.GENERAL:
        return GetPostsIncludedCategoriesEnum.General;
      case NoticeCategory.CULTURE:
        return GetPostsIncludedCategoriesEnum.Culture;
      case NoticeCategory.ENCOUNTER:
        return GetPostsIncludedCategoriesEnum.Encounter;
      case NoticeCategory.HEALTH_SPORT:
        return GetPostsIncludedCategoriesEnum.HealthSport;
      case NoticeCategory.NEIGHBORHOOD_HELP:
        return GetPostsIncludedCategoriesEnum.NeighborhoodHelp;
      default:
        throw new Error('Unknown Notice category!');
    }
  };
  const toGetPostsPostTypeEnum = (cType: string): GetPostsPostTypeEnum => {
    switch (cType) {
      case NoticeContentType.OFFER:
        return GetPostsPostTypeEnum.Offer;
      case NoticeContentType.SEEKING:
        return GetPostsPostTypeEnum.Seeking;
      default:
        throw new Error('Unknown NoticeType!');
    }
  };

  const getTypeOfSearch = useCallback(
    (types: string[]): GetPostsPostTypeEnum | undefined => {
      if (types.length === 1) return toGetPostsPostTypeEnum(types[0]);
      return undefined;
    },
    []
  );

  const getSelectedCategories = useCallback(
    (categories: string[]): Set<GetPostsIncludedCategoriesEnum> | undefined => {
      const selectedCategories: Set<GetPostsIncludedCategoriesEnum> = new Set();
      categories.forEach((category) => {
        selectedCategories.add(toGetPostsIncludedCategoriesEnum(category));
      });
      return selectedCategories.size > 0 ? selectedCategories : undefined;
    },
    []
  );

  const handleSearch = useCallback(
    (event) => {
      event.preventDefault();
      const searchNoticeAsync = async () => {
        const filteredCategories = Object.keys(postCategories).filter((key) => {
          return postCategories[key as keyof PostCategoriesType];
        });
        const filteredType = Object.keys(postType).filter((key) => {
          return postType[key as keyof PostTypesType];
        });
        const allPosts = await postApi.getPosts(
          getTypeOfSearch(filteredType),
          getSelectedCategories(filteredCategories)
        );
        dispatch(setSearchedNotices(allPosts));
        dispatch(setSearched(true));
      };
      searchNoticeAsync();
    },
    [getSelectedCategories, getTypeOfSearch, postApi, postCategories, postType]
  );
  const handleTypeCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        setPostType({
          ...postType,
          [event.target.name]: event.target.checked
        })
      );
    },
    [postType]
  );
  const handleCategoryCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        setPostCategories({
          ...postCategories,
          [event.target.name]: event.target.checked
        })
      );
    },
    [postCategories]
  );

  return (
    <Stack spacing={2}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          color="text.primary"
          variant="h3"
          to="/"
          component={ReactRouterLink}
        >
          {HOME_TILE_PINNWAND}
        </Link>
        <Typography color="text.primary" variant="h3">
          {NOTICE_TAB_4_TEXT}
        </Typography>
      </Breadcrumbs>
      <Box>
        <Box component="form">
          <Typography variant="body1" sx={{ fontSize: '1rem' }}>
            {SEARCH_NOTICE_HEADING_TEXT}
          </Typography>
          <NoticeCheckboxes
            checkboxLabel={NOTICE_TYPE_LABEL}
            checkboxes={typeCheckboxes}
            onChange={handleTypeCheckboxChange}
          />
          <NoticeCheckboxes
            checkboxLabel={NOTICE_CATEGORY_LABEL}
            checkboxes={categoriesCheckboxes}
            onChange={handleCategoryCheckboxChange}
          />
          <Box sx={{ textAlign: 'center', mt: 4 }}>
            <Button variant="contained" type="submit" onClick={handleSearch}>
              {SEARCH_NOTICE_BTN_TEXT}
            </Button>
          </Box>
        </Box>
        {isSearched && (
          <Box sx={{ my: 4 }}>
            {searchedNotices.length === 0 ? (
              <Typography variant="body1" align="center">
                {NO_SEARCH_RESULTS}
              </Typography>
            ) : (
              <NoticeList
                postGeoAreaMappings={searchedNotices}
                defaultImage={Notice}
              />
            )}
          </Box>
        )}
      </Box>
    </Stack>
  );
};

export default SearchNotice;
