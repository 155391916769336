"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientContactAuthorRequestToJSON = exports.ClientContactAuthorRequestFromJSONTyped = exports.ClientContactAuthorRequestFromJSON = void 0;
function ClientContactAuthorRequestFromJSON(json) {
    return ClientContactAuthorRequestFromJSONTyped(json, false);
}
exports.ClientContactAuthorRequestFromJSON = ClientContactAuthorRequestFromJSON;
function ClientContactAuthorRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'message': json['message'],
        'contactInformation': json['contactInformation'],
    };
}
exports.ClientContactAuthorRequestFromJSONTyped = ClientContactAuthorRequestFromJSONTyped;
function ClientContactAuthorRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'message': value.message,
        'contactInformation': value.contactInformation,
    };
}
exports.ClientContactAuthorRequestToJSON = ClientContactAuthorRequestToJSON;
