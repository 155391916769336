import { Box, Breadcrumbs, Link, Stack, Typography } from '@mui/material';
import { ClientHappeningGeoAreaMapping } from 'digital-vital-backend-api';
import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, Link as ReactRouterLink } from 'react-router-dom';
import Table from '../../../assets/svgs/table.svg';
import HappeningList from '../../../components/HappeningList';
import HappeningMap from '../../../components/HappeningMap';
import {
  NACHBARSCHAFTSTISCH_NO_PIN_HEADING,
  NACHBARSCHAFTSTISCH_NO_PIN_TEXT,
  NACHBARSCHAFTSTISCHE_OFFER_BTN_TEXT,
  HEADER_TITLE_NACHBARSCHAFTSTISCHE,
  NACHBARSCHAFTSTISCHE_TAB_2_TEXT
} from '../../../constants/strings';
import useHappeningApi from '../../../hooks/useHappeningApi';
import { HappeningType } from '../../../types/happening';

const MyRegisteredTables: FC = () => {
  const happeningApi = useHappeningApi();
  const navigate = useNavigate();
  const [myRegisteredTables, setMyRegisteredTables] = useState<
    Array<ClientHappeningGeoAreaMapping>
  >([]);

  const handleOfferTableClicked = useCallback(() => {
    navigate('/nachbarschaftstische/tischanbieten');
  }, [navigate]);

  useEffect(() => {
    const loadOwnTablesAsync = async () => {
      const registeredHappenings = await happeningApi.getRegisteredHappenings(
        HappeningType.NeighborhoodTable
      );
      setMyRegisteredTables(registeredHappenings);
    };
    loadOwnTablesAsync();
  }, [happeningApi]);

  return (
    <Stack spacing={2}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          color="text.primary"
          variant="h3"
          to="/"
          component={ReactRouterLink}
        >
          {HEADER_TITLE_NACHBARSCHAFTSTISCHE}
        </Link>
        <Typography color="text.primary" variant="h3">
          {NACHBARSCHAFTSTISCHE_TAB_2_TEXT}
        </Typography>
      </Breadcrumbs>
      <HappeningMap
        happenings={myRegisteredTables}
        markerUrl="/markers/marker-neighbourhoodtable.png"
      />
      <HappeningList
        happeningGeoAreaMappings={myRegisteredTables}
        createButtonText={NACHBARSCHAFTSTISCHE_OFFER_BTN_TEXT}
        defaultImage={Table}
        showCreateButton={false}
        onCreateButtonClick={handleOfferTableClicked}
      />
      {myRegisteredTables.length === 0 && (
        <Box>
          <Typography gutterBottom={true} variant="h2">
            {NACHBARSCHAFTSTISCH_NO_PIN_HEADING}
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1rem' }}>
            {NACHBARSCHAFTSTISCH_NO_PIN_TEXT}
          </Typography>
        </Box>
      )}
    </Stack>
  );
};

export default MyRegisteredTables;
