import { Box, Paper, Typography } from '@mui/material';
import { CSSProperties, FC } from 'react';
import { formatMonth } from '../utils/common';

const styles: Record<string, CSSProperties> = {
  dateBoxStyle: {
    padding: '1rem 1rem 1rem 0',
    flexBasis: '5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  datePaperStyle: {
    padding: '0.25rem 0.75rem',
    textAlign: 'center',
    width: '100%',
    boxShadow: '0px 10px 15px #00000029'
  }
};

type SimpleDateProps = {
  date: Date;
  isLargeScreen?: boolean;
};

const SimpleDate: FC<SimpleDateProps> = ({ date, isLargeScreen }) => {
  return (
    <Box sx={styles.dateBoxStyle}>
      <Paper sx={styles.datePaperStyle}>
        <Typography variant="body2" sx={{ fontSize: '2rem' }}>
          {date.getDate()}
        </Typography>
        <Typography variant={isLargeScreen ? 'body2' : 'h6'}>
          {formatMonth(date)}
        </Typography>
      </Paper>
    </Box>
  );
};

export default SimpleDate;
