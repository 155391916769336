import { debounce, Box, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import {
  VERANSTALTUNGEN_ORGANISER_CONTACT,
  VERANSTALTUNGEN_ORGANISER_CONTACT_PLACEHOLDER,
  VERANSTALTUNGEN_ORGANISER_NAME,
  VERANSTALTUNGEN_ORGANISER_NAME_PLACEHOLDER
} from '../../../constants/strings';
import { emailIsValid, phoneIsValid } from '../../../utils/common';
import { TOrganiser } from '../store/types';
import CustomTextField from './CustomTextField';

type OrganiserFormProps = {
  organiser: TOrganiser;
  onNameChange: (name: string) => void;
  onContactChange: (email: string) => void;
};

const OrganiserForm: FC<OrganiserFormProps> = (props) => {
  const { organiser, onNameChange, onContactChange } = props;
  const { name, contact } = organiser;

  const [isContactValid, setIsContactValid] = useState<boolean>(false);

  const handleNameChange = useCallback(
    (e) => {
      onNameChange(e.target.value);
    },
    [onNameChange]
  );

  const handleContactChange = useCallback(
    (e) => {
      onContactChange(e.target.value);
    },
    [onContactChange]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validateContact = useCallback(
    debounce(
      (value?: string) =>
        setIsContactValid(emailIsValid(value) || phoneIsValid(value)),
      100
    ),
    []
  );

  useEffect(() => {
    validateContact(contact);
  }, [contact, validateContact]);

  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ flexBasis: '50%', marginRight: '0.5rem' }}>
        <Typography gutterBottom={true} variant="h3">
          {VERANSTALTUNGEN_ORGANISER_NAME}
        </Typography>
        <CustomTextField
          fullWidth
          value={name}
          onChange={handleNameChange}
          placeholder={VERANSTALTUNGEN_ORGANISER_NAME_PLACEHOLDER}
        />
      </Box>
      <Box sx={{ flexBasis: '50%', marginLeft: '0.5rem' }}>
        <Typography gutterBottom={true} variant="h3">
          {VERANSTALTUNGEN_ORGANISER_CONTACT}
        </Typography>
        <CustomTextField
          fullWidth
          error={!isContactValid}
          value={contact}
          onChange={handleContactChange}
          placeholder={VERANSTALTUNGEN_ORGANISER_CONTACT_PLACEHOLDER}
        />
      </Box>
    </Box>
  );
};

export default OrganiserForm;
