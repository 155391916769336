import { Breadcrumbs, Link, Stack, Typography } from '@mui/material';
import { FC, Fragment } from 'react';
import { Link as ReactRouterLink, useOutletContext } from 'react-router-dom';
import { getThumbnailUrl } from '../../../api/InformationAPI';
import { HEADER_NEWS, NEWS_DESC_TEXT } from '../../../constants/strings';
import { TNewsOutletContext } from '../News';
import NewsListItem from './NewsListItem';

const NewsListView: FC = () => {
  const { newsArticles } = useOutletContext<TNewsOutletContext>();

  return (
    <Fragment>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          color="text.primary"
          variant="h3"
          to="/"
          component={ReactRouterLink}
        >
          {HEADER_NEWS}
        </Link>
      </Breadcrumbs>
      <Typography gutterBottom={true} variant="body1" sx={{ fontSize: '1rem' }}>
        {NEWS_DESC_TEXT}
      </Typography>
      <Typography gutterBottom={true} variant="h2">
        {HEADER_NEWS}
      </Typography>
      <Stack spacing={1}>
        {newsArticles.map((newsArticle) => {
          const { id, title, abstract, text, dateCreated, featuredImage } =
            newsArticle;
          return (
            <NewsListItem
              key={id}
              id={id}
              image={getThumbnailUrl(featuredImage)}
              title={title}
              abstract={abstract}
              text={text}
              date={dateCreated}
            />
          );
        })}
      </Stack>
    </Fragment>
  );
};

export default NewsListView;
