import FaceIcon from '@mui/icons-material/FaceOutlined';
import {
  Avatar,
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider
} from '@mui/material';
import type { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import {
  CSSProperties,
  FC,
  Reducer,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import { BackendApiError } from '../../api/BackendAPI';
import CharacterLimitIndicator from '../../components/CharacterLimitIndicator';
import SuccessDialog from '../../components/dialogs/SuccessDialog';
import EditableImage from '../../components/EditableImage';
import ExtraHelperText from '../../components/ExtraHelperText';
import ProtectedPage from '../../components/ProtectedPage';
import { DESC_MAX_LENGTH_250 } from '../../constants/constants';
import {
  ABOUT_ME,
  ABOUT_ME_INFO,
  CITY_NOT_VISIBLE_INFO,
  DISPLAY_NAME,
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  MANDATOY_FIELDS,
  NOT_VISIBLE,
  PROFILE_NOT_AUTHENTICATED,
  PROFILE_UPDATE_SUCCESS,
  SAVE,
  SETTINGS_CHOOSE_LOCATION,
  STREET,
  TELEPHONE,
  VISIBLE_PARTICIPANTS_ONLY,
  ZIPCODE
} from '../../constants/strings';
import useCurrentUser from '../../hooks/useCurrentUser';
import useMediaItemApi from '../../hooks/useMediaItemApi';
import useUserApi from '../../hooks/useUserApi';
import {
  // NAVIGATION_TEXT,
  SETTINGS_LABEL,
  SETTINGS_PROFILE_PIC
} from '../../themes/colors/globalColors';
import profileTheme from '../../themes/profileTheme';
import { hasLength } from '../../utils/common';
import GeoAreaSelector from './components/GeoAreaSelector';
import MissingSettingsDialog from './components/MissingSettingsDialog';
import {
  initialState,
  reducer,
  setAboutMe,
  setDisplayName,
  setFirstName,
  setHomeGeoAreaId,
  setImage,
  setImageId,
  setLastName,
  setStreet,
  setTelephone,
  setUserValues,
  setZipCode,
  TAction,
  TState
} from './ProfileReducer';

const styles: Record<string, CSSProperties> = {
  positionRelative: {
    position: 'relative'
  }
};

const UserIcon = (
  <Avatar sx={{ width: 100, height: 100, backgroundColor: 'white' }}>
    <FaceIcon htmlColor={SETTINGS_PROFILE_PIC} sx={{ width: 80, height: 80 }} />
  </Avatar>
);

const Profile: FC = () => {
  const navigate = useNavigate();
  const mediaItemApi = useMediaItemApi();
  const { currentUser, setCurrentUser } = useCurrentUser();
  const userApi = useUserApi();
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] =
    useState<boolean>(false);
  const [isMissingSettingsDialogOpen, setIsMissingSettingsDialogOpen] =
    useState<boolean>(false);
  const [state, dispatch] = useReducer<Reducer<TState, TAction>>(
    reducer,
    initialState
  );

  const {
    emailAddress,
    homeGeoAreaId,
    firstName,
    lastName,
    displayNameValues,
    displayName,
    street,
    zipCode,
    telephone,
    aboutMe,
    imageId,
    image
  } = state;

  const saveDisabled = useMemo(() => {
    return (
      !hasLength(firstName) ||
      !hasLength(lastName) ||
      !hasLength(displayName) ||
      !hasLength(homeGeoAreaId) ||
      aboutMe.length > DESC_MAX_LENGTH_250
    );
  }, [aboutMe.length, displayName, firstName, homeGeoAreaId, lastName]);

  const handleValueChange = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement>,
      actionFunction: (value: string) => TAction
    ) => {
      event.preventDefault();
      event.stopPropagation();
      dispatch(actionFunction(event.target.value));
    },
    []
  );

  const handleFirstNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleValueChange(event, setFirstName);
    },
    [handleValueChange]
  );

  const handleLastNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleValueChange(event, setLastName);
    },
    [handleValueChange]
  );

  const handleDisplayNameChange = useCallback(
    (event: SelectChangeEvent) => {
      dispatch(setDisplayName(event.target.value));
    },
    [dispatch]
  );

  const handleImageChange = useCallback((temporaryMediaItemId: string) => {
    dispatch(setImageId(temporaryMediaItemId));
  }, []);

  const handleGeoAreaChange = useCallback(
    (geoAreaId?: string) => {
      if (!geoAreaId) {
        dispatch(setHomeGeoAreaId(''));
        return;
      }
      dispatch(setHomeGeoAreaId(geoAreaId));
    },
    [dispatch]
  );

  const handleStreetChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleValueChange(event, setStreet);
    },
    [handleValueChange]
  );

  const handleZipCodeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleValueChange(event, setZipCode);
    },
    [handleValueChange]
  );

  const handleTelephoneChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleValueChange(event, setTelephone);
    },
    [handleValueChange]
  );

  const handleAboutMeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleValueChange(event, setAboutMe);
    },
    [handleValueChange]
  );

  const handleSave = useCallback(() => {
    const saveUserValues = async () => {
      if (!firstName || !lastName || !displayName || !homeGeoAreaId) {
        return;
      }
      const temporaryMediaItemId = imageId !== null ? imageId : undefined;
      const result = await userApi.updateOwnUser({
        firstName,
        lastName,
        displayName,
        homeGeoAreaId,
        street,
        zipCode,
        telephone,
        aboutMe,
        temporaryMediaItemId
      });
      setCurrentUser(result.user);
      try {
        await userApi.getOwnUserSettings();
        setIsSuccessDialogOpen(true);
      } catch (error) {
        // the user has no settings yet send him over to the settings page
        if (error instanceof BackendApiError && error.status === 404) {
          setIsMissingSettingsDialogOpen(true);
          return;
        }
        throw error;
      }
    };
    saveUserValues();
  }, [
    aboutMe,
    displayName,
    firstName,
    homeGeoAreaId,
    lastName,
    setCurrentUser,
    street,
    zipCode,
    telephone,
    userApi,
    imageId
  ]);

  const handleNotAuthenticatedDialogClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const navigateToHome = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const handleSuccessDialogClose = useCallback(() => {
    setIsSuccessDialogOpen(false);
    navigateToHome();
  }, [navigateToHome]);

  const handleMissingSettingsDialogClose = useCallback(() => {
    setIsMissingSettingsDialogOpen(false);
  }, []);

  const handleMissingSettingsDialogAction = useCallback(() => {
    navigate('/my-area/meine-naehe');
    setIsMissingSettingsDialogOpen(false);
  }, [navigate]);

  useEffect(() => {
    if (currentUser !== null) {
      dispatch(setUserValues(currentUser));
    }
  }, [currentUser, userApi]);

  useEffect(() => {
    const loadImage = async () => {
      if (imageId) {
        const tempMediaItem = await mediaItemApi.getTemporaryMediaItem(imageId);
        dispatch(setImage(tempMediaItem.mediaItem));
      }
    };
    loadImage();
  }, [imageId, mediaItemApi]);

  return (
    <ThemeProvider theme={profileTheme}>
      <ProtectedPage
        errorMessage={PROFILE_NOT_AUTHENTICATED}
        onErrorDialogClose={handleNotAuthenticatedDialogClose}
      >
        <Container maxWidth="md" style={{ paddingTop: '1rem' }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end ' }}>
            <EditableImage
              clientMediaItem={image ?? undefined}
              defaultImage={UserIcon}
              onChange={handleImageChange}
              rounded
            />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                id="firstName"
                error={!hasLength(firstName)}
                label={FIRST_NAME}
                value={firstName}
                onChange={handleFirstNameChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                id="lastName"
                error={!hasLength(lastName)}
                label={LAST_NAME}
                value={lastName}
                onChange={handleLastNameChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl>
                <InputLabel id="display-name-label">
                  {DISPLAY_NAME} *
                </InputLabel>
                <Select
                  id="display-name-select"
                  labelId="display-name-label"
                  error={!hasLength(displayName)}
                  onChange={handleDisplayNameChange}
                  value={displayName}
                >
                  {displayNameValues.map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={8} sx={styles.positionRelative}>
              <TextField
                id="email"
                label={EMAIL}
                value={emailAddress}
                required
                InputProps={{
                  readOnly: true
                }}
              />
              <ExtraHelperText text={NOT_VISIBLE} />
            </Grid>
            <Grid item xs={12} sx={styles.positionRelative}>
              <GeoAreaSelector
                geoAreaId={homeGeoAreaId}
                label={SETTINGS_CHOOSE_LOCATION}
                onChange={handleGeoAreaChange}
              />
              <ExtraHelperText text={CITY_NOT_VISIBLE_INFO} />
            </Grid>
            <Grid item xs={12}>
              <div style={{ float: 'right', marginRight: '4px' }}>
                <small
                  style={{
                    color: SETTINGS_LABEL
                  }}
                >
                  {MANDATOY_FIELDS}
                </small>
                <span>*</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={8} sx={styles.positionRelative}>
              <TextField
                id="street"
                label={STREET}
                onChange={handleStreetChange}
                value={street}
              />
              <ExtraHelperText text={VISIBLE_PARTICIPANTS_ONLY} />
            </Grid>
            <Grid item xs={12} sm={4} sx={styles.positionRelative}>
              <TextField
                id="zipCode"
                label={ZIPCODE}
                onChange={handleZipCodeChange}
                value={zipCode}
              />
            </Grid>
            <Grid item xs={12} sx={styles.positionRelative}>
              <TextField
                id="phone"
                label={TELEPHONE}
                onChange={handleTelephoneChange}
                value={telephone}
              />
              <ExtraHelperText text={VISIBLE_PARTICIPANTS_ONLY} />
            </Grid>
            <Grid item xs={12} sx={styles.positionRelative}>
              <TextField
                id="about_me"
                label={ABOUT_ME}
                onChange={handleAboutMeChange}
                value={aboutMe}
                multiline={true}
                error={aboutMe.length > DESC_MAX_LENGTH_250}
                minRows={3}
                inputProps={{
                  maxLength: 300
                }}
              />
              <ExtraHelperText text={ABOUT_ME_INFO} />
              <CharacterLimitIndicator
                field={aboutMe}
                limit={DESC_MAX_LENGTH_250}
              />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Button
                disabled={saveDisabled}
                onClick={handleSave}
                variant="contained"
              >
                {SAVE}
              </Button>
            </Grid>
          </Grid>
        </Container>
        <SuccessDialog
          open={isSuccessDialogOpen}
          message={PROFILE_UPDATE_SUCCESS}
          onClose={handleSuccessDialogClose}
        />
        <MissingSettingsDialog
          open={isMissingSettingsDialogOpen}
          onClose={handleMissingSettingsDialogClose}
          onAction={handleMissingSettingsDialogAction}
        />
      </ProtectedPage>
    </ThemeProvider>
  );
};
export default Profile;
