import FoodBankSharpIcon from '@mui/icons-material/FoodBankSharp';
import FormatListBulletedSharpIcon from '@mui/icons-material/FormatListBulletedSharp';
import GroupsIcon from '@mui/icons-material/Groups';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PendingIcon from '@mui/icons-material/Pending';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import SourceIcon from '@mui/icons-material/Source';
import { Card, Grid, Typography } from '@mui/material';
import { FC, Fragment, useMemo } from 'react';
import { Link, useOutletContext, type To } from 'react-router-dom';
import { DIRECTUS_URL } from '../../../config';
import {
  CATEGORY_PENDING_POSTS,
  MY_AREA_CREATE_EVENT,
  MY_AREA_CREATE_NEIGHBOURHOOD_TABLE,
  MY_AREA_CREATE_NOTICE,
  MY_AREA_DIRECTUS,
  MY_AREA_MY_LOCATION,
  MY_AREA_MY_OLD_EVENTS,
  MY_AREA_MY_OLD_TABLES,
  MY_AREA_MY_PROFILE,
  MY_AREA_MY_REGISTRATIONS,
  NOTICE_TAB_6_TEXT
} from '../../../constants/strings';
import {
  NACHBARSCHAFTSTISCHE_PRIMARY,
  NAVIGATION_PRIMARY,
  PINNWAND_PRIMARY,
  VERANSTALTUNGEN_PRIMARY
} from '../../../themes/colors/globalColors';
import { EDirectusRole, EPortalRoles } from '../../../types/roles';
import { TMyAreaOutletContext } from '../MyAreaView';

type MyWelcomeCardProps = {
  to: To;
  background: string;
  icon: React.ReactElement;
  text: string;
  href?: string;
  isExternal?: boolean;
};

const useStyles = () => ({
  primaryCardStyle: {
    position: 'relative',
    p: 1,
    minHeight: '12rem'
  },
  iconStyles: {
    color: 'common.white',
    fontSize: '4rem',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  },
  textStyle: {
    position: 'absolute',
    bottom: '8px',
    textTransform: 'uppercase'
  }
});

const MyWelcomeCard: FC<MyWelcomeCardProps> = ({
  to,
  background,
  icon,
  text,
  isExternal = false
}) => {
  const { primaryCardStyle, textStyle } = useStyles();

  return (
    <>
      {!isExternal ? (
        <Link to={to}>
          <Card
            sx={{
              ...primaryCardStyle,
              background
            }}
          >
            {icon}
            <Typography variant="body2" color="white" sx={textStyle}>
              {text}
            </Typography>
          </Card>
        </Link>
      ) : (
        <a href={to as string} target="_blank">
          <Card
            sx={{
              ...primaryCardStyle,
              background
            }}
          >
            {icon}
            <Typography variant="body2" color="white" sx={textStyle}>
              {text}
            </Typography>
          </Card>
        </a>
      )}
    </>
  );
};

const MyWelcome: FC = () => {
  const { portalRoles, directusRole } =
    useOutletContext<TMyAreaOutletContext>();
  const { iconStyles } = useStyles();

  const isCreateEventAllowed = useMemo(
    () => portalRoles.some((r) => r === EPortalRoles.EVENT_ORGANIZER),
    [portalRoles]
  );

  const showCMS = useMemo(() => {
    return (
      directusRole === EDirectusRole.CHIEFEDITOR ||
      directusRole === EDirectusRole.EDITOR ||
      directusRole === EDirectusRole.REPORTER
    );
  }, [directusRole]);

  const showPendingPosts = useMemo(
    () =>
      directusRole === EDirectusRole.CHIEFEDITOR ||
      directusRole === EDirectusRole.EDITOR,
    [directusRole]
  );

  return (
    <Grid container spacing={2}>
      <Grid item sm={4} xs={12}>
        <MyWelcomeCard
          to="/my-area/my-profile"
          background={NAVIGATION_PRIMARY}
          icon={<PermIdentityIcon sx={iconStyles} />}
          text={MY_AREA_MY_PROFILE}
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <MyWelcomeCard
          to="/my-area/my-registrations"
          background={NAVIGATION_PRIMARY}
          icon={<HowToRegIcon sx={iconStyles} />}
          text={MY_AREA_MY_REGISTRATIONS}
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <MyWelcomeCard
          to="/my-area/meine-naehe"
          background={NAVIGATION_PRIMARY}
          icon={<LocationOnOutlinedIcon sx={iconStyles} />}
          text={MY_AREA_MY_LOCATION}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <MyWelcomeCard
          to="/nachbarschaftstische/tischanbieten"
          background={NACHBARSCHAFTSTISCHE_PRIMARY}
          icon={<FoodBankSharpIcon sx={iconStyles} />}
          text={MY_AREA_CREATE_NEIGHBOURHOOD_TABLE}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <MyWelcomeCard
          to="/nachbarschaftstische/myoldtisch"
          background={NACHBARSCHAFTSTISCHE_PRIMARY}
          icon={<FoodBankSharpIcon sx={iconStyles} />}
          text={MY_AREA_MY_OLD_TABLES}
        />
      </Grid>
      {isCreateEventAllowed && (
        <Fragment>
          <Grid item sm={6} xs={12}>
            <MyWelcomeCard
              to="/veranstaltungen/createevents"
              background={VERANSTALTUNGEN_PRIMARY}
              icon={
                <GroupsIcon
                  sx={{ ...iconStyles, width: '1em', height: '1em' }}
                />
              }
              text={MY_AREA_CREATE_EVENT}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <MyWelcomeCard
              to="/veranstaltungen/myoldevents"
              background={VERANSTALTUNGEN_PRIMARY}
              icon={
                <GroupsIcon
                  sx={{ ...iconStyles, width: '1em', height: '1em' }}
                />
              }
              text={MY_AREA_MY_OLD_EVENTS}
            />
          </Grid>
        </Fragment>
      )}
      {/* <Grid item sm={6} xs={0} /> */}
      <Grid item sm={6} xs={12}>
        <MyWelcomeCard
          to="/pinnwand/createnotice"
          background={PINNWAND_PRIMARY}
          icon={<FormatListBulletedSharpIcon sx={iconStyles} />}
          text={MY_AREA_CREATE_NOTICE}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <MyWelcomeCard
          to="/pinnwand/mynotices"
          background={PINNWAND_PRIMARY}
          icon={<FormatListBulletedSharpIcon sx={iconStyles} />}
          text={NOTICE_TAB_6_TEXT}
        />
      </Grid>
      {showPendingPosts && (
        <Grid item sm={6} xs={12}>
          <MyWelcomeCard
            to="/my-area/pending-posts"
            background={NAVIGATION_PRIMARY}
            icon={<PendingIcon sx={iconStyles} />}
            text={CATEGORY_PENDING_POSTS}
          />
        </Grid>
      )}
      {showCMS && (
        <Grid item sm={6} xs={12}>
          <MyWelcomeCard
            to={DIRECTUS_URL}
            isExternal
            background={NAVIGATION_PRIMARY}
            icon={<SourceIcon sx={iconStyles} />}
            text={MY_AREA_DIRECTUS}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default MyWelcome;
