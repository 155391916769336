import { FC, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Container } from '@mui/material';
import { fetchHelpEntries, fetchHelpSections } from '../../api/InformationAPI';
import { THelpEntry, THelpSection } from '../../types/information';
import Loader from '../../components/Loader';

export type THelpOutletContext = {
  helpSections: Array<THelpSection>;
  helpEntries: Array<THelpEntry>;
};

const Help: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [helpSections, setHelpSections] = useState<Array<THelpSection>>([]);
  const [helpEntries, setHelpEntries] = useState<Array<THelpEntry>>([]);

  useEffect(() => {
    const loadHelpSections = async () => {
      setLoading(true);
      const entries = await fetchHelpSections();
      setHelpSections(entries);
      setLoading(false);
    };
    loadHelpSections();
  }, []);

  useEffect(() => {
    const loadHelpEntries = async () => {
      setLoading(true);
      const entries = await fetchHelpEntries();
      setHelpEntries(entries);
      setLoading(false);
    };
    loadHelpEntries();
  }, []);

  return (
    <Container sx={{ marginTop: '1em', marginBottom: '1em' }}>
      {loading ? (
        <Loader />
      ) : (
        <Outlet context={{ helpSections, helpEntries }} />
      )}
    </Container>
  );
};

export default Help;
