export const enum NoticeContentType {
  OFFER = 'OFFER',
  SEEKING = 'SEEKING'
}

export enum NoticeCategory {
  GENERAL = 'Sonstiges',
  CULTURE = 'Kultur',
  ENCOUNTER = 'Austausch & Begegnung',
  HEALTH_SPORT = 'Gesundheit & Sport',
  NEIGHBORHOOD_HELP = 'Nachbarschaftshilfe'
}
