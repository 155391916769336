"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientHappeningGeoAreaMappingToJSON = exports.ClientHappeningGeoAreaMappingFromJSONTyped = exports.ClientHappeningGeoAreaMappingFromJSON = void 0;
const ClientGeoArea_1 = require("./ClientGeoArea");
const ClientHappening_1 = require("./ClientHappening");
function ClientHappeningGeoAreaMappingFromJSON(json) {
    return ClientHappeningGeoAreaMappingFromJSONTyped(json, false);
}
exports.ClientHappeningGeoAreaMappingFromJSON = ClientHappeningGeoAreaMappingFromJSON;
function ClientHappeningGeoAreaMappingFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'geoArea': ClientGeoArea_1.ClientGeoAreaFromJSON(json['geoArea']),
        'happenings': (json['happenings'].map(ClientHappening_1.ClientHappeningFromJSON)),
    };
}
exports.ClientHappeningGeoAreaMappingFromJSONTyped = ClientHappeningGeoAreaMappingFromJSONTyped;
function ClientHappeningGeoAreaMappingToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'geoArea': ClientGeoArea_1.ClientGeoAreaToJSON(value.geoArea),
        'happenings': (value.happenings.map(ClientHappening_1.ClientHappeningToJSON)),
    };
}
exports.ClientHappeningGeoAreaMappingToJSON = ClientHappeningGeoAreaMappingToJSON;
