import { useEffect, useState } from 'react';
import BackendFlagsApi from '../api/FlagsAPI';
import useAuthContext from './useAuthContext';

const useFlagsApi = (): BackendFlagsApi => {
  const { keycloak } = useAuthContext();

  const [flagsApi, setFlagsApi] = useState<BackendFlagsApi>(
    () => new BackendFlagsApi(keycloak.token)
  );

  useEffect(() => {
    if (flagsApi.accessToken !== keycloak.token) {
      setFlagsApi(new BackendFlagsApi(keycloak.token));
    }
  }, [flagsApi.accessToken, keycloak.token]);

  return flagsApi;
};

export default useFlagsApi;
