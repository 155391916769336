import { Box, Grid, Typography } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

type FormItemProps = {
  icon: React.ReactChild;
  label: string;
};

const CreateFormItem: FC<FormItemProps> = (
  props: PropsWithChildren<FormItemProps>
) => {
  const { children, icon, label } = props;

  return (
    <Grid item xs={12} sm={4} sx={{ textAlign: 'center' }}>
      <Box sx={{ p: 3 }}>
        <Box sx={{ mb: '1rem' }}>
          {icon}
          <Typography variant="h4">{label}</Typography>
        </Box>
        {children}
      </Box>
    </Grid>
  );
};

export default CreateFormItem;
