import {
  Configuration,
  ConfigurationParameters,
  HTTPQuery
} from 'digital-vital-backend-api';
import { APP_BACKEND_URL } from '../config';

export type ApiErrorDetails = {
  timestamp: number;
  type: string;
  exception: string;
  path: string;
  message: string;
  detail: string;
};

export class BackendApiError extends Error {
  private readonly _status: number;

  private readonly _statusText: string;

  private readonly _details: ApiErrorDetails;

  constructor(status: number, statusText: string, details: ApiErrorDetails) {
    super(`${status}: ${statusText}`);
    this._status = status;
    this._statusText = statusText;
    this._details = details;
  }

  public get status(): number {
    return this._status;
  }

  public get statusText(): string {
    return this._statusText;
  }

  public get details(): ApiErrorDetails {
    return this._details;
  }
}

export default class BackendApi {
  private readonly _accessToken?: string;

  constructor(accessToken?: string) {
    this._accessToken = accessToken;
  }

  public get accessToken(): string | undefined {
    return this._accessToken;
  }

  private static queryString(params: HTTPQuery): string {
    return Object.keys(params)
      .map((key) => {
        const value = params[key];
        if (value instanceof Array || value instanceof Set) {
          const multiValue = Array.from(value)
            .map((singleValue) => encodeURIComponent(String(singleValue)))
            .join(`&${encodeURIComponent(key)}=`);
          return `${encodeURIComponent(key)}=${multiValue}`;
        }
        if (value instanceof Date) {
          return `${encodeURIComponent(key)}=${encodeURIComponent(
            value.toISOString()
          )}`;
        }
        if (value instanceof Object) {
          return BackendApi.queryString(value as HTTPQuery);
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(
          String(value)
        )}`;
      })
      .filter((part) => part.length > 0)
      .join('&');
  }

  protected createConfiguration(): Configuration {
    const configurationParameters: ConfigurationParameters = {
      accessToken: this._accessToken,
      basePath: APP_BACKEND_URL,
      queryParamsStringify: BackendApi.queryString
    };
    return new Configuration(configurationParameters);
  }

  protected async callApi<T>(apiCall: () => Promise<T>): Promise<T> {
    try {
      const response = await apiCall();
      return response;
    } catch (e) {
      if (e instanceof Response) {
        const details = (await e.json()) as ApiErrorDetails;
        throw new BackendApiError(e.status, e.statusText, details);
      } else {
        throw e;
      }
    }
  }
}
