import {
  Container,
  ThemeProvider,
  Breadcrumbs,
  Link,
  Typography,
  Stack,
  Box
} from '@mui/material';
import {
  CSSProperties,
  FC,
  Reducer,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState
} from 'react';
import {
  Outlet,
  useLocation,
  useNavigate,
  Link as ReactRouterLink
} from 'react-router-dom';
import {
  fetchCategoryByName,
  fetchCategoryPosts,
  fetchSubCategoriesByCategory
} from '../../api/InformationAPI';
import CategoryNavBar from '../../components/CategoryNavBar';
import Loader from '../../components/Loader';
import { CATEGORY_FIT } from '../../constants/strings';
import fithealthTheme from '../../themes/fithealthTheme';
import { TPost } from '../../types/information';
import { findById } from '../../utils/common';
import { FITHEALTH_PRIMARY } from '../../themes/colors/globalColors';
import {
  initialState,
  reducer,
  setCategory,
  setPosts,
  setSelectedSubCategory,
  setSubCategories,
  TAction,
  TState
} from './FitHealthReducer';

const MAIN_ROUTE = '/fit-gesund';

export type TFitHealthOutletContext = {
  currentPosts: Array<TPost>;
  posts: Array<TPost>;
};

const useStyles = (): Record<string, CSSProperties> => ({
  fullWidth: {
    width: '100%'
  },
  navBarBox: {
    position: 'fixed',
    background: FITHEALTH_PRIMARY,
    width: '100%',
    zIndex: 1000
  }
});
const FitHealthView: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const styles = useStyles();
  const navBarEl = useRef<null | HTMLDivElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [state, dispatch] = useReducer<Reducer<TState, TAction>>(
    reducer,
    initialState
  );

  const {
    selectedSubCategoryId,
    currentPosts,
    category,
    subCategories,
    posts
  } = state;
  const [navBarHeight, setNavBarHeight] = useState<number>(72);

  useEffect(() => {
    const fetchFitHealthCategory = async () => {
      setLoading(true);
      try {
        const fitHealthCategory = await fetchCategoryByName(CATEGORY_FIT);
        dispatch(setCategory(fitHealthCategory));
      } finally {
        setLoading(false);
      }
    };
    fetchFitHealthCategory();
  }, []);

  useEffect(() => {
    const fetchFitHealthSubCategories = async () => {
      if (!category) {
        return;
      }
      setLoading(true);
      try {
        const fitHealthSubCategories = await fetchSubCategoriesByCategory(
          category
        );
        dispatch(setSubCategories(fitHealthSubCategories));
      } finally {
        setLoading(false);
      }
    };
    fetchFitHealthSubCategories();
  }, [category]);

  useEffect(() => {
    const fetchFitHealthPosts = async () => {
      if (!category) {
        return;
      }
      setLoading(true);
      try {
        const fitHealthPosts = await fetchCategoryPosts(category);
        dispatch(setPosts(fitHealthPosts));
      } finally {
        setLoading(false);
      }
    };
    fetchFitHealthPosts();
  }, [category]);

  const changeCurrentSubCategory = useCallback(
    (newSubCategoryId: string) => {
      const { pathname } = location;
      dispatch(setSelectedSubCategory(newSubCategoryId));
      if (pathname !== MAIN_ROUTE) {
        navigate(MAIN_ROUTE);
      }
    },
    [location, navigate]
  );

  const selectedSubCategory = useMemo(() => {
    if (!selectedSubCategoryId) {
      return;
    }
    return findById(subCategories, selectedSubCategoryId);
  }, [selectedSubCategoryId, subCategories]);

  useEffect(() => {
    if (subCategories.length === 0) {
      return;
    }
    if (navBarEl.current && navBarEl.current.clientHeight !== navBarHeight) {
      setNavBarHeight(navBarEl.current.clientHeight);
    }
  }, [subCategories, navBarHeight]);

  return (
    <ThemeProvider theme={fithealthTheme}>
      <Box sx={styles.fullWidth}>
        <Box sx={styles.navBarBox} ref={navBarEl}>
          <Container>
            <CategoryNavBar
              selectedCategory={selectedSubCategory}
              currentSubCategories={[]}
              categories={subCategories}
              subCategories={[]}
              onCategoryChange={changeCurrentSubCategory}
              onSubCategoryChange={changeCurrentSubCategory}
            />
          </Container>
        </Box>
        <Box sx={{ height: `${navBarHeight}px` }} />
        <Container>
          <Box sx={{ padding: { sm: '10px', xs: '5px' }, marginTop: '0.5rem' }}>
            {loading ? (
              <Loader />
            ) : (
              <Stack spacing={2}>
                {location.pathname == MAIN_ROUTE && (
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link
                      underline="hover"
                      color="text.primary"
                      variant="h3"
                      to="/"
                      component={ReactRouterLink}
                    >
                      {CATEGORY_FIT}
                    </Link>
                    <Typography color="text.primary" variant="h3">
                      {selectedSubCategory?.name}
                    </Typography>
                  </Breadcrumbs>
                )}
                <Outlet context={{ currentPosts, posts }} />
              </Stack>
            )}
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default FitHealthView;
