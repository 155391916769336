import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { TUser, CurrentUserContext } from '../../contexts/CurrentUserContext';
import useAuthContext from '../../hooks/useAuthContext';
import useUserApi from '../../hooks/useUserApi';

export const CurrentUserProvider: FC<unknown> = (
  props: PropsWithChildren<unknown>
) => {
  const { children } = props;
  const [currentUser, setCurrentUser] = useState<TUser>(null);
  const { keycloak } = useAuthContext();
  const userApi = useUserApi();

  useEffect(() => {
    let isCanceled = false;
    const loadCurrentUserAsync = async () => {
      if (!keycloak.authenticated) {
        setCurrentUser(null);
        return;
      }
      const ownUser = await userApi.getOwnUser();
      if (isCanceled) {
        return;
      }
      setCurrentUser(ownUser);
    };
    loadCurrentUserAsync();
    return () => {
      isCanceled = true;
    };
  }, [keycloak.authenticated, userApi]);

  return (
    <CurrentUserContext.Provider value={{ currentUser, setCurrentUser }}>
      {children}
    </CurrentUserContext.Provider>
  );
};
