import { Breadcrumbs, Link, Stack, Typography } from '@mui/material';
import { ClientHappeningGeoAreaMapping } from 'digital-vital-backend-api';
import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, Link as ReactRouterLink } from 'react-router-dom';
import Table from '../../../assets/svgs/table.svg';
import HappeningList from '../../../components/HappeningList';
import Loader from '../../../components/Loader';
import {
  HEADER_TITLE_NACHBARSCHAFTSTISCHE,
  NACHBARSCHAFTSTISCHE_NEARBY_OFFERS_EMPTY_PLACEHOLDER,
  NACHBARSCHAFTSTISCHE_OFFER_BTN_TEXT,
  NACHBARSCHAFTSTISCHE_TAB_1_TEXT
} from '../../../constants/strings';
import useHappeningApi from '../../../hooks/useHappeningApi';
import { HappeningType } from '../../../types/happening';

const NearbyTables: FC = () => {
  const happeningApi = useHappeningApi();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [nearbyTables, setNearbyTables] = useState<
    Array<ClientHappeningGeoAreaMapping>
  >([]);

  const handleOfferTableClicked = useCallback(() => {
    navigate('/nachbarschaftstische/tischanbieten');
  }, [navigate]);

  const loadNearByTables = useCallback(() => {
    const loadNearByTablesAsync = async () => {
      try {
        setLoading(true);
        const allHappenings = await happeningApi.getAllHappenings(
          HappeningType.NeighborhoodTable
        );
        setNearbyTables(allHappenings);
      } finally {
        setLoading(false);
      }
    };
    loadNearByTablesAsync();
  }, [happeningApi]);

  useEffect(() => {
    loadNearByTables();
  }, [loadNearByTables]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Stack spacing={2}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          color="text.primary"
          variant="h3"
          to="/"
          component={ReactRouterLink}
        >
          {HEADER_TITLE_NACHBARSCHAFTSTISCHE}
        </Link>
        <Typography color="text.primary" variant="h3">
          {NACHBARSCHAFTSTISCHE_TAB_1_TEXT}
        </Typography>
      </Breadcrumbs>
      <HappeningList
        happeningGeoAreaMappings={nearbyTables}
        createButtonText={NACHBARSCHAFTSTISCHE_OFFER_BTN_TEXT}
        defaultImage={Table}
        showCreateButton={true}
        onCreateButtonClick={handleOfferTableClicked}
      />
      {nearbyTables.length === 0 && (
        <Typography variant="body1" sx={{ fontSize: '1rem' }}>
          {NACHBARSCHAFTSTISCHE_NEARBY_OFFERS_EMPTY_PLACEHOLDER}
        </Typography>
      )}
    </Stack>
  );
};

export default NearbyTables;
