import Keycloak, { type KeycloakInitOptions } from 'keycloak-js';
import {
  KEYCLOAK_BASE_URL,
  KEYCLOAK_CLIENT_ID,
  KEYCLOAK_REALM
} from '../config';

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloakConfig: Keycloak.KeycloakConfig = {
  url: KEYCLOAK_BASE_URL,
  realm: KEYCLOAK_REALM,
  clientId: KEYCLOAK_CLIENT_ID
};
const keycloakInstance: Keycloak = new Keycloak(keycloakConfig);

// Function to initiate login
const doLogin = (): void => {
  if (!keycloakInstance.authenticated) {
    keycloakInstance.login({ redirectUri: window.location.href });
  }
};

// Function to initiate log out
const doLogout = (): void => {
  if (keycloakInstance.authenticated) {
    keycloakInstance.logout({ redirectUri: window.location.origin });
  }
};

// Function to get a new token if the current one is expired
// or will expire in the less then the minValidity number of seconds
const updateToken = async (minValidity: number): Promise<void> => {
  try {
    await keycloakInstance.updateToken(minValidity);
  } catch (e) {
    doLogout();
  }
};

// Keycloak configurations
const keycloakProviderInitConfig: KeycloakInitOptions = {
  onLoad: 'check-sso',
  pkceMethod: 'S256'
};

// Function to get the username of the logged in user.
const getUsername = (): string => keycloakInstance.tokenParsed?.family_name;

const KeycloakService = {
  keycloakInstance,
  keycloakProviderInitConfig,
  doLogin,
  doLogout,
  updateToken,
  getUsername
};

export default KeycloakService;
