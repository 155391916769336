"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientPostToJSON = exports.ClientPostFromJSONTyped = exports.ClientPostFromJSON = exports.ClientPostCategoryEnum = exports.ClientPostTypeEnum = void 0;
const runtime_1 = require("../runtime");
const ClientGeoArea_1 = require("./ClientGeoArea");
const ClientMediaItem_1 = require("./ClientMediaItem");
/**
* @export
* @enum {string}
*/
var ClientPostTypeEnum;
(function (ClientPostTypeEnum) {
    ClientPostTypeEnum["Seeking"] = "SEEKING";
    ClientPostTypeEnum["Offer"] = "OFFER";
})(ClientPostTypeEnum = exports.ClientPostTypeEnum || (exports.ClientPostTypeEnum = {})); /**
* @export
* @enum {string}
*/
var ClientPostCategoryEnum;
(function (ClientPostCategoryEnum) {
    ClientPostCategoryEnum["Culture"] = "CULTURE";
    ClientPostCategoryEnum["Encounter"] = "ENCOUNTER";
    ClientPostCategoryEnum["HealthSport"] = "HEALTH_SPORT";
    ClientPostCategoryEnum["NeighborhoodHelp"] = "NEIGHBORHOOD_HELP";
    ClientPostCategoryEnum["General"] = "GENERAL";
})(ClientPostCategoryEnum = exports.ClientPostCategoryEnum || (exports.ClientPostCategoryEnum = {}));
function ClientPostFromJSON(json) {
    return ClientPostFromJSONTyped(json, false);
}
exports.ClientPostFromJSON = ClientPostFromJSON;
function ClientPostFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'creatorId': !runtime_1.exists(json, 'creatorId') ? undefined : json['creatorId'],
        'authorName': !runtime_1.exists(json, 'authorName') ? undefined : json['authorName'],
        'type': !runtime_1.exists(json, 'type') ? undefined : json['type'],
        'title': !runtime_1.exists(json, 'title') ? undefined : json['title'],
        'text': !runtime_1.exists(json, 'text') ? undefined : json['text'],
        'image': !runtime_1.exists(json, 'image') ? undefined : ClientMediaItem_1.ClientMediaItemFromJSON(json['image']),
        'category': !runtime_1.exists(json, 'category') ? undefined : json['category'],
        'geoarea': !runtime_1.exists(json, 'geoarea') ? undefined : ClientGeoArea_1.ClientGeoAreaFromJSON(json['geoarea']),
        'created': !runtime_1.exists(json, 'created') ? undefined : json['created'],
        'commentCount': !runtime_1.exists(json, 'commentCount') ? undefined : json['commentCount'],
    };
}
exports.ClientPostFromJSONTyped = ClientPostFromJSONTyped;
function ClientPostToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'creatorId': value.creatorId,
        'authorName': value.authorName,
        'type': value.type,
        'title': value.title,
        'text': value.text,
        'image': ClientMediaItem_1.ClientMediaItemToJSON(value.image),
        'category': value.category,
        'geoarea': ClientGeoArea_1.ClientGeoAreaToJSON(value.geoarea),
        'created': value.created,
        'commentCount': value.commentCount,
    };
}
exports.ClientPostToJSON = ClientPostToJSON;
