// eslint-disable-next-line import/named
import { Box, SxProps } from '@mui/system';
import { FC } from 'react';

const useStyles = (): Record<string, SxProps> => ({
  promotionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '2rem',
    marginBottom: '1rem'
  },
  logoStyles: { height: { md: 100, sm: 64, xs: 50 } },
  sponsorLogoStyles: { height: { md: 105, sm: 72, xs: 50 } }
});

const LogoPromotion: FC = () => {
  const styles = useStyles();
  return (
    <Box sx={styles.promotionContainer}>
      <Box
        component="img"
        sx={styles.logoStyles}
        alt="site logo"
        src="/images/logo.png"
      ></Box>
      <Box
        component="img"
        sx={styles.sponsorLogoStyles}
        alt="Promotion logo"
        src="/images/logo_inform.png"
      ></Box>
    </Box>
  );
};

export default LogoPromotion;
