export enum HappeningType {
  NeighborhoodTable = 'NEIGHBORHOOD_TABLE',
  Event = 'EVENT'
}

export enum EMealType {
  CLASSIC = 'Klassisch',
  VEGAN = 'Vegan',
  VEGETARIAN = 'Vegetarisch'
}

export enum EEventCategory {
  GENERAL = 'Allgemein',
  CULTURE = 'Kultur',
  EXCHANGE_ENCOUNTER = 'Austausch & Begegnung',
  HEALTH_SPORT = 'Gesundheit & Sport'
}
