import { Breadcrumbs, Link, Typography } from '@mui/material';
import { ClientHappeningGeoAreaMapping } from 'digital-vital-backend-api';
import { FC, useEffect, useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import Event from '../../../assets/svgs/event.svg';
import HappeningList from '../../../components/HappeningList';
import {
  HEADER_TITLE_VERANSTALTUNGEN,
  VERANSTALTUNGEN_OFFER_BTN_TEXT,
  VERANSTALTUNGEN_TAB_5_TEXT
} from '../../../constants/strings';
import useHappeningApi from '../../../hooks/useHappeningApi';
import { HappeningType } from '../../../types/happening';

const MyOldEvents: FC = () => {
  const happeningApi = useHappeningApi();
  const [myOldEvents, setMyOldEvents] = useState<
    Array<ClientHappeningGeoAreaMapping>
  >([]);

  useEffect(() => {
    const loadOwnEventsAsync = async () => {
      const ownHappenings = await happeningApi.getOldHappenings(
        HappeningType.Event
      );
      setMyOldEvents(ownHappenings);
    };
    loadOwnEventsAsync();
  }, [happeningApi]);

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          color="text.primary"
          variant="h3"
          to="/"
          component={ReactRouterLink}
        >
          {HEADER_TITLE_VERANSTALTUNGEN}
        </Link>
        <Typography color="text.primary" variant="h3">
          {VERANSTALTUNGEN_TAB_5_TEXT}
        </Typography>
      </Breadcrumbs>
      <HappeningList
        happeningGeoAreaMappings={myOldEvents}
        createButtonText={VERANSTALTUNGEN_OFFER_BTN_TEXT}
        defaultImage={Event}
        showCreateButton={false}
      />
    </>
  );
};

export default MyOldEvents;
