import { Box, Button, Stack } from '@mui/material';
import {
  ClientHappening,
  ClientHappeningGeoAreaMapping
} from 'digital-vital-backend-api';
import { CSSProperties, FC, useCallback } from 'react';
import HappeningItem from './HappeningItem';
import LocationTag from './LocationTag';

const useStyles = (): Record<string, CSSProperties> => ({
  offerBoxStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1em'
  }
});

export type HappeningListProps = {
  happeningGeoAreaMappings: Array<ClientHappeningGeoAreaMapping>;
  createButtonText: string;
  defaultImage: string;
  showCreateButton: boolean;
  onCreateButtonClick?: () => void;
};

const HappeningList: FC<HappeningListProps> = (props: HappeningListProps) => {
  const {
    happeningGeoAreaMappings,
    createButtonText,
    defaultImage,
    showCreateButton,
    onCreateButtonClick = () => {}
  } = props;
  const styles = useStyles();

  const handleCreateButtonClicked = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      if (showCreateButton) onCreateButtonClick();
    },
    [onCreateButtonClick, showCreateButton]
  );

  return (
    <>
      {happeningGeoAreaMappings.map(
        (happeningGeoAreaMapping: ClientHappeningGeoAreaMapping) => {
          const { geoArea, happenings } = happeningGeoAreaMapping;
          return (
            <Box sx={{ mb: 2 }} key={geoArea.id}>
              <LocationTag locationName={geoArea.name} />
              <Stack spacing={1}>
                {happenings.map((happening: ClientHappening) => {
                  return (
                    <HappeningItem
                      key={happening.id}
                      defaultImage={defaultImage}
                      event={happening}
                    />
                  );
                })}
              </Stack>
              {showCreateButton && (
                <Box sx={styles.offerBoxStyle}>
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{ textTransform: 'uppercase' }}
                    onClick={handleCreateButtonClicked}
                  >
                    {createButtonText}
                  </Button>
                </Box>
              )}
            </Box>
          );
        }
      )}
    </>
  );
};

export default HappeningList;
