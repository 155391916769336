"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class UserApi extends runtime.BaseAPI {
    /**
     * Returns full details of own user
     * Returns full details of own user
     */
    getOwnUserRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/users/own`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ClientUserExtendedFromJSON(jsonValue));
        });
    }
    /**
     * Returns full details of own user
     * Returns full details of own user
     */
    getOwnUser(portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getOwnUserRaw({ portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Returns settings of own user
     * Returns settings of own user
     */
    getOwnUserSettingsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/users/own/settings`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ClientUserSettingsFromJSON(jsonValue));
        });
    }
    /**
     * Returns settings of own user
     * Returns settings of own user
     */
    getOwnUserSettings(portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getOwnUserSettingsRaw({ portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Returns only id, display name, about me and home geo area of a user
     * Returns details of a user
     */
    getUserByIdRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.userId === null || requestParameters.userId === undefined) {
                throw new runtime.RequiredError('userId', 'Required parameter requestParameters.userId was null or undefined when calling getUserById.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ClientUserFromJSON(jsonValue));
        });
    }
    /**
     * Returns only id, display name, about me and home geo area of a user
     * Returns details of a user
     */
    getUserById(userId, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getUserByIdRaw({ userId: userId, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Updates profile information of own user
     * Updates profile information of own user
     */
    updateOwnUserRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientUserUpdateRequest === null || requestParameters.clientUserUpdateRequest === undefined) {
                throw new runtime.RequiredError('clientUserUpdateRequest', 'Required parameter requestParameters.clientUserUpdateRequest was null or undefined when calling updateOwnUser.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/users/own`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ClientUserUpdateRequestToJSON(requestParameters.clientUserUpdateRequest),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ClientUserUpdateConfirmationFromJSON(jsonValue));
        });
    }
    /**
     * Updates profile information of own user
     * Updates profile information of own user
     */
    updateOwnUser(clientUserUpdateRequest, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateOwnUserRaw({ clientUserUpdateRequest: clientUserUpdateRequest, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Updates settings of own user
     * Updates settings of own user
     */
    updateOwnUserSettingsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientUserUpdateSettingsRequest === null || requestParameters.clientUserUpdateSettingsRequest === undefined) {
                throw new runtime.RequiredError('clientUserUpdateSettingsRequest', 'Required parameter requestParameters.clientUserUpdateSettingsRequest was null or undefined when calling updateOwnUserSettings.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/users/own/settings`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ClientUserUpdateSettingsRequestToJSON(requestParameters.clientUserUpdateSettingsRequest),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ClientUserUpdateSettingsConfirmationFromJSON(jsonValue));
        });
    }
    /**
     * Updates settings of own user
     * Updates settings of own user
     */
    updateOwnUserSettings(clientUserUpdateSettingsRequest, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateOwnUserSettingsRaw({ clientUserUpdateSettingsRequest: clientUserUpdateSettingsRequest, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
}
exports.UserApi = UserApi;
