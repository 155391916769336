import { createTheme } from '@mui/material/styles';
import baseTheme from './baseTheme';
import { MuiTabComponent } from './baseThemeComponents';
import {
  CALENDAR_ACTIVE,
  CALENDAR_ACTIVE_BG,
  CALENDAR_INACTIVE,
  CALENDAR_PRIMARY,
  CALENDAR_TEXT
} from './colors/globalColors';

const calendarTheme = createTheme({
  ...baseTheme,
  palette: {
    primary: {
      main: CALENDAR_PRIMARY,
      contrastText: CALENDAR_TEXT
    },
    secondary: {
      main: CALENDAR_ACTIVE,
      contrastText: CALENDAR_PRIMARY
    }
  },
  components: {
    ...baseTheme.components,
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          color: CALENDAR_PRIMARY
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: '1rem'
        }
      }
    },
    MuiTab: MuiTabComponent(
      CALENDAR_INACTIVE,
      CALENDAR_ACTIVE,
      CALENDAR_ACTIVE_BG
    )
  }
});

export default calendarTheme;
