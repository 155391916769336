import { FC } from 'react';
import {
  ERROR_DIALOG_BTN_TEXT,
  ERROR_DIALOG_MSG,
  ERROR_DIALOG_TITLE
} from '../constants/strings';
import AlertDialog from './dialogs/AlertDialog';

type ErrorDialogProps = {
  msg?: string;
  msgList?: string[];
  open: boolean;
  onClose: () => void;
};

const ErrorDialog: FC<ErrorDialogProps> = (props) => {
  const { msg, msgList, open, onClose } = props;
  if (msg) {
    return (
      <AlertDialog
        open={open}
        title={ERROR_DIALOG_TITLE}
        msg={msg ?? ERROR_DIALOG_MSG}
        type="warning"
        btnText={ERROR_DIALOG_BTN_TEXT}
        onClose={onClose}
      />
    );
  } else {
    return (
      <AlertDialog
        open={open}
        title={ERROR_DIALOG_TITLE}
        msgList={msgList}
        type="warning"
        btnText={ERROR_DIALOG_BTN_TEXT}
        onClose={onClose}
      />
    );
  }
};

export default ErrorDialog;
