import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { Box, FormHelperText } from '@mui/material';
import { FC } from 'react';
import { SETTINGS_LABEL } from '../themes/colors/globalColors';

type ExtraHelperTextProps = {
  text: string;
  top?: number;
  right?: number;
};

const ExtraHelperText: FC<ExtraHelperTextProps> = (props) => {
  const { text, top = 12, right = 4 } = props;
  return (
    <Box
      sx={{
        position: 'absolute',
        top,
        right,
        alignItems: 'center',
        display: { xs: 'none', sm: 'flex' }
      }}
    >
      <FormHelperText>{text}</FormHelperText>
      <InfoRoundedIcon sx={{ color: SETTINGS_LABEL, fontSize: '0.8rem' }} />
    </Box>
  );
};

export default ExtraHelperText;
