import { ClientUserExtended } from 'digital-vital-backend-api';
import { TImage } from '../../types/common';
import { hasLength } from '../../utils/common';

export type TState = {
  emailAddress: string;
  homeGeoAreaId: string;
  firstName: string;
  lastName: string;
  displayNameValues: string[];
  displayName: string;
  street: string;
  zipCode: string;
  telephone: string;
  aboutMe: string;
  imageId: string | null;
  image: TImage | null;
};

export type TSetUserValues = {
  name: 'setUserValues';
  values: ClientUserExtended;
};

export type TSetHomeGeoAreaIdAction = {
  name: 'setHomeGeoAreaId';
  homeGeoAreaId: string;
};

export type TSetFirstName = {
  name: 'setFirstName';
  firstName: string;
};

export type TSetLastName = {
  name: 'setLastName';
  lastName: string;
};

export type TSetDisplayName = {
  name: 'setDisplayName';
  displayName: string;
};

export type TSetStreet = {
  name: 'setStreet';
  street: string;
};

export type TSetZipCode = {
  name: 'setZipCode';
  zipCode: string;
};

export type TSetTelephone = {
  name: 'setTelephone';
  telephone: string;
};

export type TSetAboutMe = {
  name: 'setAboutMe';
  aboutMe: string;
};

export type TSetImageId = {
  name: 'setImageId';
  imageId: string | null;
};

export type TSetImage = {
  name: 'setImage';
  image: TImage | null;
};

export type TAction =
  | TSetUserValues
  | TSetHomeGeoAreaIdAction
  | TSetFirstName
  | TSetLastName
  | TSetDisplayName
  | TSetStreet
  | TSetZipCode
  | TSetTelephone
  | TSetAboutMe
  | TSetImageId
  | TSetImage;

export const setUserValues = (values: ClientUserExtended): TAction => {
  return { name: 'setUserValues', values };
};

export const setHomeGeoAreaId = (homeGeoAreaId: string): TAction => {
  return { name: 'setHomeGeoAreaId', homeGeoAreaId };
};

export const setFirstName = (firstName: string): TAction => {
  return { name: 'setFirstName', firstName };
};

export const setLastName = (lastName: string): TAction => {
  return { name: 'setLastName', lastName };
};

export const setDisplayName = (displayName: string): TAction => {
  return { name: 'setDisplayName', displayName };
};

export const setStreet = (street: string): TAction => {
  return { name: 'setStreet', street };
};

export const setZipCode = (zipCode: string): TAction => {
  return { name: 'setZipCode', zipCode };
};

export const setTelephone = (telephone: string): TAction => {
  return { name: 'setTelephone', telephone };
};

export const setAboutMe = (aboutMe: string): TAction => {
  return { name: 'setAboutMe', aboutMe };
};

export const setImageId = (imageId: string): TAction => {
  return { name: 'setImageId', imageId };
};

export const setImage = (image: TImage | null): TAction => {
  return { name: 'setImage', image };
};

export const initialState: TState = {
  emailAddress: '',
  firstName: '',
  lastName: '',
  displayNameValues: [],
  displayName: '',
  homeGeoAreaId: '',
  street: '',
  zipCode: '',
  telephone: '',
  aboutMe: '',
  imageId: null,
  image: null
};

const createDisplayNameValues = (
  firstName: string,
  lastName: string
): string[] => {
  if (!hasLength(firstName) || !hasLength(lastName)) {
    return [];
  }
  return [
    firstName + ' ' + lastName,
    lastName + ' ' + firstName,
    firstName + ' ' + lastName?.charAt(0) + '.',
    firstName?.charAt(0) + '.' + ' ' + lastName
  ];
};

export const reducer = (state: TState, action: TAction): TState => {
  const name = action.name;
  switch (name) {
    case 'setUserValues':
      const {
        emailAddress,
        homeGeoArea,
        firstName,
        lastName,
        displayName,
        street,
        zipCode,
        telephone,
        aboutMe,
        profilePicture
      } = action.values;
      return {
        ...state,
        emailAddress,
        homeGeoAreaId: homeGeoArea?.id ?? '',
        firstName: firstName ?? '',
        lastName: lastName ?? '',
        displayNameValues: createDisplayNameValues(firstName, lastName),
        displayName: displayName ?? '',
        street: street ?? '',
        zipCode: zipCode ?? '',
        telephone: telephone ?? '',
        aboutMe: aboutMe ?? '',
        image: profilePicture ?? null
      };
    case 'setHomeGeoAreaId':
      return {
        ...state,
        homeGeoAreaId: action.homeGeoAreaId
      };
    case 'setFirstName':
      return {
        ...state,
        firstName: action.firstName,
        displayNameValues: createDisplayNameValues(
          action.firstName,
          state.lastName
        ),
        displayName: ''
      };
    case 'setLastName':
      return {
        ...state,
        lastName: action.lastName,
        displayNameValues: createDisplayNameValues(
          state.firstName,
          action.lastName
        ),
        displayName: ''
      };
    case 'setDisplayName':
      return {
        ...state,
        displayName: action.displayName
      };
    case 'setStreet':
      return {
        ...state,
        street: action.street
      };
    case 'setZipCode':
      return {
        ...state,
        zipCode: action.zipCode
      };
    case 'setTelephone':
      return {
        ...state,
        telephone: action.telephone
      };
    case 'setAboutMe':
      return {
        ...state,
        aboutMe: action.aboutMe
      };
    case 'setImageId':
      return {
        ...state,
        imageId: action.imageId
      };
    case 'setImage':
      return {
        ...state,
        image: action.image
      };
    default:
      throw new Error('Unknown action');
  }
};
