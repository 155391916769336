import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';
import { a11yProps, getCurrentTab } from '../utils/common';

export type TNavBarTab = {
  icon: JSX.Element;
  label: string;
  route: string;
};

export type TabNavBarProps = {
  basePath: string;
  tabs: Array<TNavBarTab>;
};

const TabNavBar: FC<TabNavBarProps> = (props: TabNavBarProps) => {
  const { basePath, tabs } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const routes = useMemo(() => tabs.map((t) => t.route), [tabs]);
  const [tab, setTab] = useState<string>(() =>
    getCurrentTab(routes, location.pathname)
  );

  const handleChange = (_event: SyntheticEvent, newValue: string) => {
    navigate(newValue);
  };

  useEffect(() => {
    const path = location.pathname;
    if (!path.startsWith(basePath)) {
      return;
    }
    const currentTab = getCurrentTab(routes, location.pathname);
    if (currentTab === tab) {
      return;
    }
    setTab(currentTab);
  }, [basePath, location.pathname, routes, tab]);

  return (
    <Tabs
      value={tab ?? false}
      onChange={handleChange}
      textColor="secondary"
      indicatorColor="secondary"
      variant="scrollable"
    >
      {tabs.map((t) => (
        <Tab
          key={t.route}
          icon={t.icon}
          label={t.label}
          {...a11yProps(t.route)}
        />
      ))}
    </Tabs>
  );
};

export default TabNavBar;
