import EmailIcon from '@mui/icons-material/Email';
import { TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
  ClientContactAuthorRequest,
  ClientContactOrganizerRequest,
  ClientHappeningExtended,
  ClientPost
} from 'digital-vital-backend-api';
import { FC, Reducer, useCallback, useMemo, useReducer } from 'react';
import {
  CONTACT_DIALOG_EMAIL_OR_PHONE_LABEL,
  CONTACT_ORGANIZER_ACTION_TEXT
} from '../../constants/strings';
import useHappeningApi from '../../hooks/useHappeningApi';
import usePostApi from '../../hooks/usePostApi';
import { isInRange } from '../../utils/common';
import AbstractDialog from './AbstractDialog';
import {
  initialState,
  reducer,
  reset,
  setContact,
  setMessage,
  TAction,
  TState
} from './ContactDialogReducer';

type ContactDialogProps = {
  open: boolean;
  onClose: () => void;
  happening?: ClientHappeningExtended;
  notice?: ClientPost;
  isNoticeRequest?: boolean;
};

const ContactDialog: FC<ContactDialogProps> = ({
  open,
  onClose,
  happening = undefined,
  notice = undefined,
  isNoticeRequest = false
}) => {
  const happeningApi = useHappeningApi();
  const postApi = usePostApi();

  const organizerName = useMemo(() => {
    if (isNoticeRequest)
      return 'Autor'; // TODO: Endpoint not updated {notice?.authorName || ''}
    else return happening?.organizerName || '';
  }, [happening, isNoticeRequest]);

  const [state, dispatch] = useReducer<Reducer<TState, TAction>>(
    reducer,
    initialState
  );

  const { message, contact } = state;

  const handleMessageChange = useCallback(
    (e) => dispatch(setMessage(e.target.value)),
    []
  );

  const handleContactChange = useCallback(
    (e) => dispatch(setContact(e.target.value)),
    []
  );

  const enableSend = useMemo(
    () => isInRange(10, 500, message) && isInRange(10, 100, contact),
    [message, contact]
  );

  const handleClose = useCallback(() => {
    dispatch(reset());
    onClose();
  }, [onClose]);

  const handleSendMsgToOrganizer = useCallback(() => {
    const SendMsgToOrganizer = async () => {
      if (!message) return;

      if (isNoticeRequest) {
        if (!notice) return;
        const { id } = notice;
        const contactOrganizerRequest: ClientContactAuthorRequest = {
          message,
          contactInformation: contact
        };
        await postApi.contactAuthor(id, contactOrganizerRequest);
      } else {
        if (!happening) return;
        const { id } = happening;
        const contactOrganizerRequest: ClientContactOrganizerRequest = {
          message,
          contactInformation: contact
        };
        await happeningApi.contactOrganizer(id, contactOrganizerRequest);
      }

      handleClose();
    };
    SendMsgToOrganizer();
  }, [
    message,
    isNoticeRequest,
    handleClose,
    notice,
    contact,
    postApi,
    happening,
    happeningApi
  ]);

  return (
    <AbstractDialog
      icon={<EmailIcon color="primary" sx={{ fontSize: '64px' }} />}
      open={open}
      btnActionText={CONTACT_ORGANIZER_ACTION_TEXT}
      hideCancelBtn={false}
      onClose={handleClose}
      onAction={handleSendMsgToOrganizer}
      maxWidth="none"
      isActionDisabled={!enableSend}
    >
      <Box sx={{ mb: 2 }}>
        <Typography gutterBottom={true}>
          {`Geben Sie in das untenstehende Textfeld Ihre Frage oder Anmerkung an ${organizerName} ein:`}
        </Typography>
        <TextField
          multiline
          rows={4}
          variant="outlined"
          placeholder="Hier tippen…"
          label="Nachricht"
          fullWidth
          value={message}
          onChange={handleMessageChange}
        />
      </Box>
      <Box>
        <Typography gutterBottom={true}>
          {CONTACT_DIALOG_EMAIL_OR_PHONE_LABEL}
        </Typography>
        <TextField
          label="Kontakt"
          fullWidth
          value={contact}
          onChange={handleContactChange}
        />
      </Box>
    </AbstractDialog>
  );
};

export default ContactDialog;
