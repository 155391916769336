import { ClientMediaItem } from 'digital-vital-backend-api';

export enum EPresentType {
  ON_SITE = 'vor Ort',
  ONLINE = 'online'
}

export type TAccessible = {
  treppe: boolean;
  fahrstuhl: boolean;
  treppenlift: boolean;
  parkplätze: boolean;
  bushaltestelle: boolean;
};

export type TAddress = {
  street: string;
  zipCode: string;
  city: string;
  fullAdress: string;
};

export type TLocation = {
  address?: string;
};

export type TOrganiser = {
  name: string;
  contact: string;
};

export type TEditableProperties = {
  title: string;
  description: string;
  cost: number;
  image?: ClientMediaItem;
  maxParticipants: number;
  startTime: number;
  endTime: number;
  accessibility?: string[];
  location?: TLocation;
  organizerName?: string;
  organizerContact?: string;
  registrationNotice?: string;
};

export type THappeningProperties = {
  title: string;
  description: string;
  cost: number;
  startTime: number;
  endTime: number;
  maxParticipants: number;
  accessibleDescription: Array<string>;
  street: string;
  zipCode: string;
  city: string;
  deleteImage: boolean;
  temporaryMediaItemId?: string;
  organizer?: string;
  organizerContact?: string;
  online: boolean;
  url?: string;
  registrationNotice?: string;
};
