import {
  ClickAwayListener,
  debounce,
  FormControl,
  Grow,
  InputLabel,
  MenuItem,
  MenuList,
  OutlinedInput,
  Paper,
  Popper
} from '@mui/material';
import { ClientGeoAreaExtended } from 'digital-vital-backend-api';
import Fuse from 'fuse.js';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { GEOAREA_NOT_FOUND } from '../../../constants/strings';
import useGeoAreaApi from '../../../hooks/useGeoAreaApi';
import { findById } from '../../../utils/common';

type GeoAreaSelectorProps = {
  geoAreaId?: string;
  label: string;
  onChange: (geoAreaId?: string) => void;
};

const GeoAreaSelector: FC<GeoAreaSelectorProps> = (
  props: GeoAreaSelectorProps
) => {
  const { geoAreaId, label, onChange } = props;
  const geoAreaApi = useGeoAreaApi();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [allGeoAreas, setAllGeoAreas] = useState<Array<ClientGeoAreaExtended>>(
    []
  );
  const [geoAreas, setGeoAreas] = useState<Array<ClientGeoAreaExtended>>([]);
  const open = useMemo(() => anchorEl !== null, [anchorEl]);
  const fuse = useMemo(
    () => new Fuse(allGeoAreas, { keys: ['name'] }),
    [allGeoAreas]
  );

  const handleKeyDown = useCallback((event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setAnchorEl(null);
    } else if (event.key === 'Escape') {
      setAnchorEl(null);
    }
  }, []);

  const delayedGeoAreaSearch = debounce(
    async (name: string, anchorElement: HTMLElement) => {
      const searchResult = fuse.search(name, { limit: 5 });
      const items = searchResult.map((r) => r.item);
      setGeoAreas(items);
      if (anchorEl !== anchorElement) {
        setAnchorEl(anchorElement);
      }
    },
    300
  );

  const handleClose = useCallback(() => {
    if (open) {
      setAnchorEl(null);
      delayedGeoAreaSearch.clear();
    }
  }, [delayedGeoAreaSearch, open]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      setInputValue(newValue);
      if (newValue.length < 3) {
        handleClose();
        return;
      }
      if (geoAreaId) {
        onChange();
      }
      delayedGeoAreaSearch(newValue, event.target);
    },
    [delayedGeoAreaSearch, geoAreaId, handleClose, onChange]
  );

  const handleGeoAreaSelected = useCallback(
    (geoArea: ClientGeoAreaExtended) => {
      setInputValue(geoArea.name);
      handleClose();
      onChange(geoArea.id);
    },
    [handleClose, onChange]
  );

  useEffect(() => {
    const loadAllGeoAreas = async () => {
      const portalVariantGeoArea =
        await geoAreaApi.getDefaultGeoAreaOfPortalVariant(true);
      const result = new Array<ClientGeoAreaExtended>();

      let childGeoAreas = [portalVariantGeoArea];
      while (childGeoAreas && childGeoAreas.length > 0) {
        let newChilds = new Array<ClientGeoAreaExtended>();
        childGeoAreas.forEach((geoArea) => {
          result.push({
            ...geoArea,
            childGeoAreas: undefined
          });
          const children = geoArea.childGeoAreas;
          if (children) {
            newChilds = [...newChilds, ...children];
          }
        });
        childGeoAreas = newChilds;
      }
      setAllGeoAreas(result);
    };
    loadAllGeoAreas();
  }, [geoAreaApi]);

  useEffect(() => {
    const loadCurrentGeoArea = async () => {
      if (!geoAreaId) {
        return;
      }
      let geoArea = findById(geoAreas, geoAreaId);
      if (!geoArea) {
        geoArea = await geoAreaApi.getGeoAreayById(geoAreaId);
      }
      setInputValue(geoArea.name);
    };
    loadCurrentGeoArea();
  }, [geoAreaApi, geoAreaId, geoAreas]);

  return (
    <FormControl>
      <InputLabel htmlFor="choose-geoarea">{label} *</InputLabel>
      <OutlinedInput
        id="choose-geoarea"
        required={true}
        error={!geoAreaId}
        onChange={handleInputChange}
        value={inputValue}
        autoComplete="off"
      />
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        transition
        disablePortal
        style={{ width: '100%', zIndex: 1000 }}
        onResize={undefined}
        onResizeCapture={undefined}
        nonce={undefined}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'left top' }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={false} onKeyDown={handleKeyDown}>
                  {geoAreas.map((g) => (
                    <MenuItem
                      key={g.id}
                      onClick={() => handleGeoAreaSelected(g)}
                    >
                      {g.postalCodes} {g.name}
                    </MenuItem>
                  ))}
                  {geoAreas.length === 0 && (
                    <MenuItem disabled>{GEOAREA_NOT_FOUND}</MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </FormControl>
  );
};

export default GeoAreaSelector;
