"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientHappeningLocationToJSON = exports.ClientHappeningLocationFromJSONTyped = exports.ClientHappeningLocationFromJSON = void 0;
const runtime_1 = require("../runtime");
function ClientHappeningLocationFromJSON(json) {
    return ClientHappeningLocationFromJSONTyped(json, false);
}
exports.ClientHappeningLocationFromJSON = ClientHappeningLocationFromJSON;
function ClientHappeningLocationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'address': !runtime_1.exists(json, 'address') ? undefined : json['address'],
        'lat': !runtime_1.exists(json, 'lat') ? undefined : json['lat'],
        'lon': !runtime_1.exists(json, 'lon') ? undefined : json['lon'],
    };
}
exports.ClientHappeningLocationFromJSONTyped = ClientHappeningLocationFromJSONTyped;
function ClientHappeningLocationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'address': value.address,
        'lat': value.lat,
        'lon': value.lon,
    };
}
exports.ClientHappeningLocationToJSON = ClientHappeningLocationToJSON;
