"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientUserSettingsToJSON = exports.ClientUserSettingsFromJSONTyped = exports.ClientUserSettingsFromJSON = void 0;
const runtime_1 = require("../runtime");
const ClientGeoArea_1 = require("./ClientGeoArea");
function ClientUserSettingsFromJSON(json) {
    return ClientUserSettingsFromJSONTyped(json, false);
}
exports.ClientUserSettingsFromJSON = ClientUserSettingsFromJSON;
function ClientUserSettingsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'userId': json['userId'],
        'selectedGeoAreas': !runtime_1.exists(json, 'selectedGeoAreas') ? undefined : (json['selectedGeoAreas'].map(ClientGeoArea_1.ClientGeoAreaFromJSON)),
    };
}
exports.ClientUserSettingsFromJSONTyped = ClientUserSettingsFromJSONTyped;
function ClientUserSettingsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'userId': value.userId,
        'selectedGeoAreas': value.selectedGeoAreas === undefined ? undefined : (value.selectedGeoAreas.map(ClientGeoArea_1.ClientGeoAreaToJSON)),
    };
}
exports.ClientUserSettingsToJSON = ClientUserSettingsToJSON;
