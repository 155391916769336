import GroupsIcon from '@mui/icons-material/Groups';
import {
  ClientCalendarEventCreateRequest,
  ClientCalendarEventCreateRequestCategoryEnum,
  ClientCalendarEventUpdateRequest,
  ClientCalendarEventUpdateRequestCategoryEnum,
  ClientHappeningExtended
} from 'digital-vital-backend-api';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BackendApiError } from '../../../api/BackendAPI';
import Event from '../../../assets/svgs/event.svg';
import ErrorDialog from '../../../components/ErrorDialog';
import ProtectedPage from '../../../components/ProtectedPage';
import CreateHappening from '../../../components/create-happening/CreateHappening';
import MissingAddressDialog from '../../../components/create-happening/components/MissingAddressDialog';
import { THappeningProperties } from '../../../components/create-happening/store/types';
import ConfirmationDialog from '../../../components/dialogs/ConfirmationDialog';
import { ADDRESS_ERROR } from '../../../constants/constants';
import {
  CONFIRM_DIALOG_MSG_TEXT,
  CONFIRM_DIALOG_TITLE_TEXT,
  CREATE_EVENT_NOT_AUTHENTICATED_TEXT,
  CREATE_UPDATE_HAPPENING_ADDRESS_ERROR,
  DEFAULT_ERROR,
  EVENT_DESC_PLACEHOLDER,
  EVENT_DESC_TEXT,
  EVENT_NAME_TEXT,
  OFFER_EVENT_HEADING_TEXT,
  VERANSTALTUNGEN_ADDRESS_MISSING_TEXT,
  VERANSTALTUNGEN_TYPE_TEXT
} from '../../../constants/strings';
import { validations } from '../../../constants/validations.json';
import useCurrentUser from '../../../hooks/useCurrentUser';
import useHappeningApi from '../../../hooks/useHappeningApi';
import useKeycloakUtils from '../../../hooks/useKeycloakUtils';
import { EEventCategory } from '../../../types/happening';
import { EPortalRoles } from '../../../types/roles';
import { validateNumber } from '../../../utils/common';

const EventIcon = <img src={Event} loading="lazy" height="100rem" />;

const toClientCreateRequestCategory = (
  category: string
): ClientCalendarEventCreateRequestCategoryEnum => {
  switch (category) {
    case EEventCategory.GENERAL:
      return ClientCalendarEventCreateRequestCategoryEnum.General;
    case EEventCategory.CULTURE:
      return ClientCalendarEventCreateRequestCategoryEnum.Culture;
    case EEventCategory.EXCHANGE_ENCOUNTER:
      return ClientCalendarEventCreateRequestCategoryEnum.ExchangeEncounter;
    case EEventCategory.HEALTH_SPORT:
      return ClientCalendarEventCreateRequestCategoryEnum.HealthSport;
    default:
      throw new Error('Unknown event category!');
  }
};

const toClientUpdateRequestCategory = (
  category: string
): ClientCalendarEventUpdateRequestCategoryEnum => {
  switch (category) {
    case EEventCategory.GENERAL:
      return ClientCalendarEventUpdateRequestCategoryEnum.General;
    case EEventCategory.CULTURE:
      return ClientCalendarEventUpdateRequestCategoryEnum.Culture;
    case EEventCategory.EXCHANGE_ENCOUNTER:
      return ClientCalendarEventUpdateRequestCategoryEnum.ExchangeEncounter;
    case EEventCategory.HEALTH_SPORT:
      return ClientCalendarEventUpdateRequestCategoryEnum.HealthSport;
    default:
      throw new Error('Unknown event category!');
  }
};

const CreateEvent: FC = () => {
  const happeningApi = useHappeningApi();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { currentUser } = useCurrentUser();
  const { getResourceRoles } = useKeycloakUtils();

  const [isAddressMissingDialogOpen, setIsAddressMissingDialogOpen] =
    useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>();
  const [category, setCategory] = useState<string>(EEventCategory.GENERAL);
  const [happeningDetails, setHappeningDetails] =
    useState<ClientHappeningExtended>();
  const [happeningProperties, setHappeningProperties] =
    useState<THappeningProperties>();

  const happeningId = useMemo(
    () => searchParams.get('eventId'),
    [searchParams]
  );
  const categories = useMemo(() => Object.values(EEventCategory), []);

  const isPortalAdmin = useMemo(() => {
    const roles = getResourceRoles('digital-vital-portal');
    if (roles && roles.some((r) => r === EPortalRoles.PORTAL_ADMIN)) {
      return true;
    }
    return false;
  }, [getResourceRoles]);

  const navigateToNearByEvents = useCallback(() => {
    navigate('/veranstaltungen/nearbyevents');
  }, [navigate]);

  const navigateToProfile = useCallback(() => {
    setIsAddressMissingDialogOpen(false);
    navigate('/my-area/my-profile');
  }, [navigate]);

  const handleAddressMissingDialogClose = useCallback(() => {
    setIsAddressMissingDialogOpen(false);
    navigateToNearByEvents();
  }, [navigateToNearByEvents]);

  const handleCategoryChange = useCallback((newCategory: string) => {
    setCategory(newCategory);
  }, []);

  const handleRejectConfirmation = useCallback(() => {
    setHappeningProperties(undefined);
    setIsConfirmationDialogOpen(false);
  }, []);

  const handleContinue = useCallback(
    (newHappeningProperties: THappeningProperties, errors: string[]) => {
      const { cost, maxParticipants } = newHappeningProperties;
      const allErrors = [...errors];
      allErrors.push(...validateNumber(validations.cost.Events, cost));
      allErrors.push(
        ...validateNumber(validations.participants.Events, maxParticipants)
      );
      if (allErrors.length > 0) {
        setErrorMessages(allErrors);
        setIsErrorDialogOpen(true);
      } else {
        setHappeningProperties(newHappeningProperties);
        setIsConfirmationDialogOpen(true);
      }
    },
    []
  );

  const handleErrorDialogClose = useCallback(() => {
    setIsErrorDialogOpen(false);
    setErrorMessages(undefined);
  }, []);

  const handleHappeningCreation = useCallback(() => {
    const createCalendarEvent = async () => {
      if (!happeningProperties) {
        return;
      }
      try {
        if (happeningId) {
          const clientCalendarEventUpdateRequest: ClientCalendarEventUpdateRequest =
            {
              ...happeningProperties,
              category: toClientUpdateRequestCategory(category)
            };
          await happeningApi.updateCalendarEvent(
            happeningId,
            clientCalendarEventUpdateRequest
          );
        } else {
          const clientCalendarEventCreateRequest: ClientCalendarEventCreateRequest =
            {
              ...happeningProperties,
              category: toClientCreateRequestCategory(category)
            };
          await happeningApi.createCalendarEvent(
            clientCalendarEventCreateRequest
          );
        }
        setIsConfirmationDialogOpen(false);
        navigateToNearByEvents();
      } catch (error: BackendApiError | unknown) {
        if (error instanceof BackendApiError) {
          switch (error.details.type) {
            case ADDRESS_ERROR:
              setErrorMessages([CREATE_UPDATE_HAPPENING_ADDRESS_ERROR]);
              break;
            // TODO: Other error types
            default:
              setErrorMessages([DEFAULT_ERROR]);
          }
        } else {
          setErrorMessages([DEFAULT_ERROR]);
        }
        setIsConfirmationDialogOpen(false);
        setIsErrorDialogOpen(true);
      }
    };
    createCalendarEvent();
  }, [
    category,
    happeningApi,
    happeningId,
    happeningProperties,
    navigateToNearByEvents
  ]);

  useEffect(() => {
    if (!currentUser) {
      setHappeningDetails(undefined);
      return;
    }
    if (happeningId) {
      const loadHappening = async () => {
        const happening = await happeningApi.getHappeningById(happeningId);
        const { creatorId } = happening;
        if (creatorId !== currentUser?.id && !isPortalAdmin) {
          return;
        }
        setHappeningDetails(happening);
      };
      if (happeningId !== happeningDetails?.id) {
        loadHappening();
      }
    } else {
      setHappeningDetails(undefined);
      const { street, city, zipCode } = currentUser;
      if (street && zipCode && city) {
        return;
      }
      setIsAddressMissingDialogOpen(true);
    }
  }, [currentUser, happeningApi, happeningDetails, happeningId, isPortalAdmin]);

  return (
    <ProtectedPage
      errorMessage={CREATE_EVENT_NOT_AUTHENTICATED_TEXT}
      onErrorDialogClose={navigateToNearByEvents}
    >
      <CreateHappening
        happening={happeningDetails}
        heading={OFFER_EVENT_HEADING_TEXT}
        titleLabel={EVENT_NAME_TEXT}
        descriptionLabel={EVENT_DESC_TEXT}
        descriptionPlaceholder={EVENT_DESC_PLACEHOLDER}
        categories={categories}
        currentCategory={category}
        categoryLabel={VERANSTALTUNGEN_TYPE_TEXT}
        categoryIcon={<GroupsIcon color="primary" sx={{ fontSize: '3em' }} />}
        imageIcon={EventIcon}
        showOrganiserForm={true}
        showPresentTypeSelection={true}
        showRegistrationHintInput={true}
        onCategoryChange={handleCategoryChange}
        onContinue={handleContinue}
      />
      <ConfirmationDialog
        title={CONFIRM_DIALOG_TITLE_TEXT}
        message={CONFIRM_DIALOG_MSG_TEXT}
        open={isConfirmationDialogOpen}
        onClose={handleRejectConfirmation}
        onAction={handleHappeningCreation}
      />
      <ErrorDialog
        msgList={errorMessages}
        open={isErrorDialogOpen}
        onClose={handleErrorDialogClose}
      />
      <MissingAddressDialog
        message={VERANSTALTUNGEN_ADDRESS_MISSING_TEXT}
        open={isAddressMissingDialogOpen}
        onAction={navigateToProfile}
        onClose={handleAddressMissingDialogClose}
      />
    </ProtectedPage>
  );
};

export default CreateEvent;
