import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react';
import { HAPPENING_ACCESSIBILITY_TEXT } from '../../../constants/strings';
import { TAccessible } from '../store/types';

interface AccessibilitySelectionProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  accessible: TAccessible;
}

const AccessibilitySelection: FC<AccessibilitySelectionProps> = ({
  onChange,
  accessible
}) => {
  const { treppe, fahrstuhl, treppenlift, parkplätze, bushaltestelle } =
    accessible;
  const checkboxes = [
    { name: 'treppe', value: treppe, label: 'Treppe' },
    { name: 'fahrstuhl', value: fahrstuhl, label: 'Fahrstuhl' },
    { name: 'treppenlift', value: treppenlift, label: 'Treppenlift' },
    { name: 'parkplätze', value: parkplätze, label: 'Parkplätze' },
    { name: 'bushaltestelle', value: bushaltestelle, label: 'Bushaltestelle' }
  ];
  return (
    <Box sx={{ mt: '1rem' }}>
      <Typography variant="h4">{HAPPENING_ACCESSIBILITY_TEXT}</Typography>
      <FormGroup aria-label="" row sx={{ marginLeft: '-15px' }}>
        {checkboxes.map(({ name, value, label }) => {
          return (
            <FormControlLabel
              key={name}
              value={value}
              control={
                <Checkbox
                  checked={value}
                  name={name}
                  onChange={onChange}
                  color="primary"
                />
              }
              label={label}
              labelPlacement="start"
            />
          );
        })}
      </FormGroup>
    </Box>
  );
};

export default AccessibilitySelection;
