import { FC } from 'react';
import { ThemeProvider } from '@mui/material';
import TableHappeningDetails from '../../NeighborhoodTables/components/TableHappeningDetails';
import neighborhoodTheme from '../../../themes/neighborhoodTheme';

const MyRegisteredTable: FC = () => {
  return (
    <ThemeProvider theme={neighborhoodTheme}>
      <TableHappeningDetails />
    </ThemeProvider>
  );
};

export default MyRegisteredTable;
