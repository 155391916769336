import EventIcon from '@mui/icons-material/Event';
import NearMeIcon from '@mui/icons-material/NearMe';
import { Box, Container, ThemeProvider } from '@mui/material';
import { CSSProperties, FC, useEffect, useMemo, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import TabNavBar from '../../components/TabNavBar';
import {
  NACHBARSCHAFTSTISCHE_TAB_1_TEXT,
  NAVBAR_MEIN_KALENDER
} from '../../constants/strings';
import useAuthContext from '../../hooks/useAuthContext';
import calendarTheme from '../../themes/calendarTheme';
import { CALENDAR_PRIMARY } from '../../themes/colors/globalColors';

const useStyles = (): Record<string, CSSProperties> => ({
  fullWidth: {
    width: '100%'
  },
  navBarBox: {
    position: 'fixed',
    background: CALENDAR_PRIMARY,
    width: '100%',
    zIndex: 1000
  }
});

const CalendarView: FC = () => {
  const BASE_PATH = '/kalender';
  const { keycloak } = useAuthContext();
  const styles = useStyles();
  const navBarEl = useRef<null | HTMLDivElement>(null);
  const [navBarHeight, setNavBarHeight] = useState<number>(72);

  const allTabs = useMemo(() => {
    const tabs = [
      {
        icon: <NearMeIcon />,
        label: NACHBARSCHAFTSTISCHE_TAB_1_TEXT,
        route: 'nearbyevents'
      }
    ];

    if (keycloak.authenticated) {
      const authTabs = [...tabs];
      authTabs.splice(1, 0, {
        icon: <EventIcon />,
        label: NAVBAR_MEIN_KALENDER,
        route: 'mycalendar'
      });
      return authTabs;
    }
    return tabs;
  }, [keycloak.authenticated]);

  useEffect(() => {
    if (navBarEl.current && navBarEl.current.clientHeight !== navBarHeight) {
      setNavBarHeight(navBarEl.current.clientHeight);
    }
  }, [navBarEl.current?.clientHeight, navBarHeight]);

  return (
    <ThemeProvider theme={calendarTheme}>
      <Box sx={styles.fullWidth}>
        <Box sx={styles.navBarBox} ref={navBarEl}>
          <Container>
            <TabNavBar basePath={BASE_PATH} tabs={allTabs} />
          </Container>
        </Box>
        <Box sx={{ height: `${navBarHeight}px` }} />
        <Container>
          <Box sx={{ padding: { sm: '10px', xs: '5px' } }}>
            <Outlet />
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default CalendarView;
