import {
  ClientHappening,
  ClientHappeningGeoAreaMapping,
  ClientHappeningHappeningTypeEnum,
  GeoPoint
} from 'digital-vital-backend-api';
import { FC, useEffect, useMemo, useState } from 'react';
import { APP_ROOT_AREA_ID } from '../config';
import useCurrentUser from '../hooks/useCurrentUser';
import useGeoAreaApi from '../hooks/useGeoAreaApi';
import LeafletMap, { LeafletMarker, LeafletPoint } from './LeafletMap';

const CENTER_OF_GERMANY: LeafletPoint = [51.165691, 10.451526];

type HappeningMapProps = {
  happenings: Array<ClientHappeningGeoAreaMapping>;
  markerUrl: string;
};

const HappeningMap: FC<HappeningMapProps> = (props: HappeningMapProps) => {
  const { happenings, markerUrl } = props;
  const geoAreaApi = useGeoAreaApi();
  const { currentUser } = useCurrentUser();
  const [homeGeoAreaCenter, setHomeGeoAreaCenter] = useState<GeoPoint>();

  useEffect(() => {
    const loadGeoAreaCenter = async (geoAreaId: string) => {
      const geoArea = await geoAreaApi.getGeoAreayById(geoAreaId);
      const center = geoArea.center;
      if (!center) {
        return;
      }
      setHomeGeoAreaCenter(center);
    };
    const geoAreaId = currentUser?.homeGeoArea?.id ?? APP_ROOT_AREA_ID;
    loadGeoAreaCenter(geoAreaId);
  }, [currentUser, geoAreaApi]);

  const center: LeafletPoint = useMemo(() => {
    if (!homeGeoAreaCenter) {
      return CENTER_OF_GERMANY;
    }
    const { latitude, longitude } = homeGeoAreaCenter;
    if (!latitude || !longitude) {
      return CENTER_OF_GERMANY;
    }
    return [latitude, longitude];
  }, [homeGeoAreaCenter]);

  const markers = useMemo(() => {
    const result: Array<LeafletMarker> = [];

    const allHappenings: Array<ClientHappening> = happenings.flatMap(
      (e) => e.happenings
    );
    allHappenings.forEach((h) => {
      const { id, title, description, location } = h;
      if (!location || !location.lat || !location.lon) {
        return;
      }
      const { lat, lon } = location;
      const url =
        h.happeningType === ClientHappeningHappeningTypeEnum.NeighborhoodTable
          ? `nearbytisch/${id}`
          : `nearbyevents/${id}`;

      result.push({
        title,
        description,
        url,
        location: [lat, lon]
      });
    });

    return result;
  }, [happenings]);

  return (
    <LeafletMap
      center={center}
      zoom={15}
      markers={markers}
      markerUrl={markerUrl}
    />
  );
};

export default HappeningMap;
