import {
  FC,
  Fragment,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState
} from 'react';
import { DialogContentText } from '@mui/material';
import NotAuthenticatedDialog from './dialogs/NotAuthenticatedDialog';
import useAuthContext from '../hooks/useAuthContext';

export type ProtectedPageProps = {
  errorMessage: string;
  onErrorDialogClose: () => void;
};

const ProtectedPage: FC<ProtectedPageProps> = (
  props: PropsWithChildren<ProtectedPageProps>
) => {
  const { keycloak } = useAuthContext();
  const { children, errorMessage, onErrorDialogClose: onClose } = props;

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(
    () => !keycloak.authenticated
  );

  const handleNotLoginDialogClosed = useCallback(() => {
    setIsDialogOpen(false);
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (isDialogOpen && keycloak.authenticated) {
      setIsDialogOpen(false);
    }
  }, [isDialogOpen, keycloak.authenticated]);

  return (
    <Fragment>
      {children}
      <NotAuthenticatedDialog
        open={isDialogOpen}
        onClose={handleNotLoginDialogClosed}
      >
        <DialogContentText id="alert-dialog-description">
          {errorMessage}
        </DialogContentText>
      </NotAuthenticatedDialog>
    </Fragment>
  );
};

export default ProtectedPage;
