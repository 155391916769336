import { useCallback, useMemo } from 'react';
import useAuthContext from './useAuthContext';

type KeycloakUtils = {
  userId: string | undefined;
  getResourceRoles: (resource: string) => string[] | undefined;
};

const useKeycloakUtils = (): KeycloakUtils => {
  const { keycloak } = useAuthContext();

  const getResourceRoles = useCallback(
    (resource: string): string[] | undefined => {
      const { resourceAccess } = keycloak;
      if (!resourceAccess) {
        return;
      }
      return resourceAccess[resource]?.roles;
    },
    [keycloak]
  );

  const userId = useMemo(
    () => keycloak.tokenParsed?.sub,
    [keycloak.tokenParsed]
  );

  return { userId, getResourceRoles };
};

export default useKeycloakUtils;
