"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataprivacyApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class DataprivacyApi extends runtime.BaseAPI {
    /**
     * Logged user can request the account deletion.The support is informed about the user request
     * Sends an e-mail to support with the information of the account to be deleted
     */
    deleteAccountRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientDataPrivacyRequest === null || requestParameters.clientDataPrivacyRequest === undefined) {
                throw new runtime.RequiredError('clientDataPrivacyRequest', 'Required parameter requestParameters.clientDataPrivacyRequest was null or undefined when calling deleteAccount.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/dataprivacy/delete-account`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ClientDataPrivacyRequestToJSON(requestParameters.clientDataPrivacyRequest),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Logged user can request the account deletion.The support is informed about the user request
     * Sends an e-mail to support with the information of the account to be deleted
     */
    deleteAccount(clientDataPrivacyRequest, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.deleteAccountRaw({ clientDataPrivacyRequest: clientDataPrivacyRequest, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
        });
    }
    /**
     * Logged user can request their data.The support is informed about the user request
     * Sends an e-mail to support with the information of the account requesting the data
     */
    requestReportRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/dataprivacy/report`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Logged user can request their data.The support is informed about the user request
     * Sends an e-mail to support with the information of the account requesting the data
     */
    requestReport(portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.requestReportRaw({ portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
        });
    }
}
exports.DataprivacyApi = DataprivacyApi;
