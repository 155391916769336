import GroupsIcon from '@mui/icons-material/Groups';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import NearMeIcon from '@mui/icons-material/NearMe';
import { Box, Container, ThemeProvider } from '@mui/material';
import { CSSProperties, FC, useEffect, useMemo, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import TabNavBar from '../../components/TabNavBar';
import {
  VERANSTALTUNGEN_TAB_1_TEXT,
  VERANSTALTUNGEN_TAB_2_TEXT,
  VERANSTALTUNGEN_TAB_3_TEXT,
  VERANSTALTUNGEN_TAB_4_TEXT,
  VERANSTALTUNGEN_TAB_5_TEXT
} from '../../constants/strings';
import { VERANSTALTUNGEN_PRIMARY } from '../../themes/colors/globalColors';
import eventsTheme from '../../themes/eventsTheme';
// import { ReactComponent as EventBalloon } from '../../assets/svgs/eventballoon.svg';
import useAuthContext from '../../hooks/useAuthContext';
import useKeycloakUtils from '../../hooks/useKeycloakUtils';
import { EPortalRoles } from '../../types/roles';

const useStyles = (): Record<string, CSSProperties> => ({
  rootBox: {
    width: '100%'
  },
  tabNavBarBox: {
    position: 'fixed',
    background: VERANSTALTUNGEN_PRIMARY,
    width: '100%',
    zIndex: 1100
  }
});

export type TEventsOutletContext = {
  isCreateAllowed: boolean;
};

const Events: FC = () => {
  const BASE_PATH = '/veranstaltungen';
  const { keycloak } = useAuthContext();
  const { getResourceRoles } = useKeycloakUtils();
  const styles = useStyles();
  const navBarEl = useRef<null | HTMLDivElement>(null);
  const [navBarHeight, setNavBarHeight] = useState<number>(72);
  const [isCreateAllowed, setIsCreateAllowed] = useState<boolean>(false);

  const allTabs = useMemo(() => {
    const tabs = [
      {
        icon: <NearMeIcon />,
        label: VERANSTALTUNGEN_TAB_1_TEXT,
        route: 'nearbyevents'
      }
    ];

    if (keycloak.authenticated) {
      if (isCreateAllowed) {
        tabs.splice(
          1,
          0,
          {
            icon: <GroupsIcon />,
            label: VERANSTALTUNGEN_TAB_3_TEXT,
            route: 'myevents'
          },
          {
            icon: <GroupsIcon />,
            label: VERANSTALTUNGEN_TAB_4_TEXT,
            route: 'createevents'
          },
          {
            icon: <GroupsIcon />,
            label: VERANSTALTUNGEN_TAB_5_TEXT,
            route: 'myoldevents'
          }
        );
      }

      tabs.splice(1, 0, {
        icon: <HowToRegIcon />,
        label: VERANSTALTUNGEN_TAB_2_TEXT,
        route: 'myregistrations'
      });
    }
    return tabs;
  }, [isCreateAllowed, keycloak.authenticated]);

  useEffect(() => {
    const roles = getResourceRoles('digital-vital-portal');

    if (
      roles &&
      roles.some(
        (r) =>
          r === EPortalRoles.EVENT_ORGANIZER || r === EPortalRoles.PORTAL_ADMIN
      )
    ) {
      setIsCreateAllowed(true);
    } else {
      setIsCreateAllowed(false);
    }
  }, [getResourceRoles, keycloak.authenticated]);

  useEffect(() => {
    if (navBarEl.current && navBarEl.current.clientHeight !== navBarHeight) {
      setNavBarHeight(navBarEl.current.clientHeight);
    }
  }, [navBarEl.current?.clientHeight, navBarHeight]);

  return (
    <ThemeProvider theme={eventsTheme}>
      <Box sx={styles.rootBox}>
        <Box sx={styles.tabNavBarBox} ref={navBarEl}>
          <Container>
            <TabNavBar basePath={BASE_PATH} tabs={allTabs} />
          </Container>
        </Box>
        <Box sx={{ height: `${navBarHeight}px` }} />
        <Container>
          <Box sx={{ padding: { sm: '10px', xs: '5px' } }}>
            <Outlet context={{ isCreateAllowed }} />
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Events;
