"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientHappeningParticipationToJSON = exports.ClientHappeningParticipationFromJSONTyped = exports.ClientHappeningParticipationFromJSON = exports.ClientHappeningParticipationStatusEnum = void 0;
const runtime_1 = require("../runtime");
/**
* @export
* @enum {string}
*/
var ClientHappeningParticipationStatusEnum;
(function (ClientHappeningParticipationStatusEnum) {
    ClientHappeningParticipationStatusEnum["Pending"] = "PENDING";
    ClientHappeningParticipationStatusEnum["Approved"] = "APPROVED";
    ClientHappeningParticipationStatusEnum["Rejected"] = "REJECTED";
})(ClientHappeningParticipationStatusEnum = exports.ClientHappeningParticipationStatusEnum || (exports.ClientHappeningParticipationStatusEnum = {}));
function ClientHappeningParticipationFromJSON(json) {
    return ClientHappeningParticipationFromJSONTyped(json, false);
}
exports.ClientHappeningParticipationFromJSON = ClientHappeningParticipationFromJSON;
function ClientHappeningParticipationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'happeningId': !runtime_1.exists(json, 'happeningId') ? undefined : json['happeningId'],
        'participantId': !runtime_1.exists(json, 'participantId') ? undefined : json['participantId'],
        'status': !runtime_1.exists(json, 'status') ? undefined : json['status'],
    };
}
exports.ClientHappeningParticipationFromJSONTyped = ClientHappeningParticipationFromJSONTyped;
function ClientHappeningParticipationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'happeningId': value.happeningId,
        'participantId': value.participantId,
        'status': value.status,
    };
}
exports.ClientHappeningParticipationToJSON = ClientHappeningParticipationToJSON;
