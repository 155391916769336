"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientUserUpdateSettingsConfirmationToJSON = exports.ClientUserUpdateSettingsConfirmationFromJSONTyped = exports.ClientUserUpdateSettingsConfirmationFromJSON = void 0;
const ClientUserSettings_1 = require("./ClientUserSettings");
function ClientUserUpdateSettingsConfirmationFromJSON(json) {
    return ClientUserUpdateSettingsConfirmationFromJSONTyped(json, false);
}
exports.ClientUserUpdateSettingsConfirmationFromJSON = ClientUserUpdateSettingsConfirmationFromJSON;
function ClientUserUpdateSettingsConfirmationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'settings': ClientUserSettings_1.ClientUserSettingsFromJSON(json['settings']),
    };
}
exports.ClientUserUpdateSettingsConfirmationFromJSONTyped = ClientUserUpdateSettingsConfirmationFromJSONTyped;
function ClientUserUpdateSettingsConfirmationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'settings': ClientUserSettings_1.ClientUserSettingsToJSON(value.settings),
    };
}
exports.ClientUserUpdateSettingsConfirmationToJSON = ClientUserUpdateSettingsConfirmationToJSON;
