import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Theme } from '@mui/system/createTheme/createTheme';
import { ClientPost, ClientPostCategoryEnum } from 'digital-vital-backend-api';
import { CSSProperties, FC, useMemo } from 'react';
import clip from 'text-clipper';
import {
  DESC_MAX_LENGTH_SM,
  DESC_MAX_LENGTH_XS,
  DESC_TITLE_LENGTH_XS
} from '../../constants/constants';
import ImageService, { ImageSizes } from '../../services/ImageService';
import { NoticeCategory } from '../../types/notice';
import OverviewCard from '../OverviewCard';
import NoticeType from './NoticeType';
const useStyles = (theme: Theme): Record<string, CSSProperties> => ({
  dividerStyle: {
    marginBottom: theme.spacing(1)
  },
  cardTextBoxStyle: {
    flex: '1 1 75%',
    position: 'relative'
  }
});

interface NoticeItemProps {
  defaultImage: string;
  notice: ClientPost;
  routePrefix?: string;
}

const NoticeItem: FC<NoticeItemProps> = ({
  defaultImage,
  notice,
  routePrefix = ''
}) => {
  const { id, title, text, image, category, type } = notice;

  const theme = useTheme();
  const styles = useStyles(theme);
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const toNoticeCategory = (
    categoryValue?: ClientPostCategoryEnum
  ): NoticeCategory => {
    switch (categoryValue) {
      case ClientPostCategoryEnum.General:
        return NoticeCategory.GENERAL;
      case ClientPostCategoryEnum.Culture:
        return NoticeCategory.CULTURE;
      case ClientPostCategoryEnum.Encounter:
        return NoticeCategory.ENCOUNTER;
      case ClientPostCategoryEnum.HealthSport:
        return NoticeCategory.HEALTH_SPORT;
      case ClientPostCategoryEnum.NeighborhoodHelp:
        return NoticeCategory.NEIGHBORHOOD_HELP;
      default:
        throw new Error('Unknown Notice category!');
    }
  };

  const readableCategory = useMemo(() => {
    return toNoticeCategory(category);
  }, [category]);

  return (
    <OverviewCard
      image={ImageService.getImageUrl(ImageSizes.THUMBNAIL, image)}
      defaultImage={defaultImage}
      detailsLink={`${routePrefix}${id}`}
      isLargeScreen={isLargeScreen}
      showOverviewTag
      tagTitle={readableCategory}
    >
      <Box sx={{ display: 'flex', height: ' 100%' }}>
        <NoticeType type={type} />
        <Box sx={styles.cardTextBoxStyle}>
          <Typography gutterBottom={true} variant="h3">
            {isLargeScreen ? title : clip(title ?? '', DESC_TITLE_LENGTH_XS)}
          </Typography>
          <Divider flexItem={true} sx={styles.dividerStyle} />
          <Typography
            variant="body2"
            component="p"
            sx={{ wordBreak: 'break-all', whiteSpace: 'normal' }}
          >
            {clip(
              text ?? '',
              isLargeScreen ? DESC_MAX_LENGTH_SM : DESC_MAX_LENGTH_XS
            )}
          </Typography>
        </Box>
      </Box>
    </OverviewCard>
  );
};

export default NoticeItem;
