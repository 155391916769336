import { DialogContentText } from '@mui/material';
import { FC } from 'react';
import AbstractDialog from '../../../components/dialogs/AbstractDialog';
import {
  PROFILE_MISSING_SETTINGS_BTN_ACTION,
  PROFILE_MISSING_SETTINGS_MSG,
  PROFILE_MISSING_SETTINGS_TITLE,
  REJECT_BTN_TEXT
} from '../../../constants/strings';

type MissingSettingsDialogProps = {
  open: boolean;
  onClose: () => void;
  onAction: () => void;
};

const MissingSettingsDialog: FC<MissingSettingsDialogProps> = (props) => {
  const { open, onClose, onAction } = props;
  return (
    <AbstractDialog
      open={open}
      title={PROFILE_MISSING_SETTINGS_TITLE}
      btnActionText={PROFILE_MISSING_SETTINGS_BTN_ACTION}
      btnCancelText={REJECT_BTN_TEXT}
      hideCancelBtn={false}
      onClose={onClose}
      onAction={onAction}
      maxWidth="25rem"
    >
      <DialogContentText>{PROFILE_MISSING_SETTINGS_MSG}</DialogContentText>
    </AbstractDialog>
  );
};

export default MissingSettingsDialog;
