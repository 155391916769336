import { FC, Fragment, useMemo } from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  // For some reason lint error "AccordionProps and AccordionSummaryProps not found in '@mui/material'" is occuring
  // hence
  // eslint-disable-next-line
  AccordionProps,
  AccordionSummary,
  // eslint-disable-next-line
  AccordionSummaryProps,
  styled,
  Typography
} from '@mui/material';
import {
  HILFE_ACTIONS_BG,
  HILFE_DETAILS_BG,
  HILFE_EXPAND_BUTTON,
  HILFE_EXPAND_BUTTON_EXPANDED,
  HILFE_LINK_BG
} from '../../../themes/colors/globalColors';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { THelpEntry, THelpSection } from '../../../types/information';
import { SHOW_MORE } from '../../../constants/strings';
import { THelpOutletContext } from '../Help';
import { hasLength } from '../../../utils/common';

type THelpSectionsWithEntries = {
  section: THelpSection;
  entries: Array<THelpEntry>;
};

const HelpLink = styled(Link)(() => ({
  backgroundColor: HILFE_LINK_BG,
  color: 'black',
  paddingTop: '4px',
  paddingLeft: '6px',
  paddingRight: '6px',
  paddingBottom: '4px',
  borderRadius: '6px',
  textDecoration: 'none'
}));

const AccordionStyled = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  borderBottom: `1px solid ${theme.palette.divider}`
}));

const AccordionSummaryStyled = styled((props: AccordionSummaryProps) => (
  <AccordionSummary expandIcon={<AddCircleOutlineIcon />} {...props} />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: HILFE_EXPAND_BUTTON
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
    color: HILFE_EXPAND_BUTTON_EXPANDED
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}));

const AccordionDetailsStyled = styled(AccordionDetails)(() => ({
  backgroundColor: HILFE_DETAILS_BG
}));
const AccordionActionsStyled = styled(AccordionActions)(() => ({
  backgroundColor: HILFE_ACTIONS_BG
}));

const HelpOverview: FC = () => {
  const { helpSections, helpEntries } = useOutletContext<THelpOutletContext>();
  const helpSectionsWithEntries: Array<THelpSectionsWithEntries> =
    useMemo(() => {
      return helpSections.map((s) => ({
        section: s,
        entries: helpEntries.filter((e) => e.section === s.id)
      }));
    }, [helpEntries, helpSections]);

  return (
    <Fragment>
      <h1>Hilfe</h1>
      {helpSectionsWithEntries.map((h) => (
        <Fragment key={h.section.id}>
          <h2>{h.section.name}</h2>
          {h.entries.map((e) => (
            <AccordionStyled key={e.id}>
              <AccordionSummaryStyled>
                <Typography variant="body2">{e.title}</Typography>
              </AccordionSummaryStyled>
              <AccordionDetailsStyled>
                <Typography
                  component="div"
                  dangerouslySetInnerHTML={{ __html: e.shortDescription }}
                  variant="body1"
                />
              </AccordionDetailsStyled>
              <AccordionActionsStyled>
                {hasLength(e.description) && (
                  <HelpLink to={`/help/entry/${e.id}`}>{SHOW_MORE}</HelpLink>
                )}
              </AccordionActionsStyled>
            </AccordionStyled>
          ))}
        </Fragment>
      ))}
    </Fragment>
  );
};

export default HelpOverview;
