import { FC, Fragment } from 'react';
import { Typography } from '@mui/material';

type SuccessViewProps = {
  happeningTitle: string;
  participantDisplayName?: string;
  result: string;
};

const SuccessView: FC<SuccessViewProps> = (props: SuccessViewProps) => {
  const { happeningTitle, participantDisplayName, result } = props;
  return (
    <Fragment>
      <Typography align="center" gutterBottom={true}>
        <b>{participantDisplayName}</b>
        {' wurde für deinen Nachbarschaftstisch '}
        <b>{happeningTitle}</b> {result}
      </Typography>
    </Fragment>
  );
};

export default SuccessView;
