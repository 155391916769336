import { createTheme } from '@mui/material/styles';
import { NAVIGATION_BTN_BG } from './colors/globalColors';

const baseTheme = createTheme({
  components: {
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1.5rem',
          fontWeight: '500'
        }
      }
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          marginBottom: '10px'
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTabs-scrollButtons': {
            color: NAVIGATION_BTN_BG
          }
        }
      }
    }
  },
  typography: {
    h1: {
      fontSize: '3rem',
      fontWeight: '400'
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: '500'
    },
    h3: {
      fontSize: '1.125rem',
      fontWeight: '700'
    },
    h4: {
      fontSize: '1.0625rem',
      fontWeight: '700'
    },
    h5: {
      fontSize: '1rem',
      fontWeight: '500'
    },
    h6: {
      fontSize: '0.875rem',
      fontWeight: '500'
    },
    body1: {
      fontSize: '0.875rem',
      fontWeight: '400'
    },
    body2: {
      fontSize: '1.125rem',
      fontWeight: '300'
    }
  }
});

export default baseTheme;
