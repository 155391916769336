import { useEffect, useState } from 'react';
import BackendPostApi from '../api/PostAPI';
import useAuthContext from './useAuthContext';

const usePostApi = (): BackendPostApi => {
  const { keycloak } = useAuthContext();

  const [postApi, setPostApi] = useState<BackendPostApi>(
    () => new BackendPostApi(keycloak.token)
  );

  useEffect(() => {
    if (postApi.accessToken !== keycloak.token) {
      setPostApi(new BackendPostApi(keycloak.token));
    }
  }, [postApi.accessToken, keycloak.token]);

  return postApi;
};

export default usePostApi;
