import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { ClientPostCreateRequestContentTypeEnum } from 'digital-vital-backend-api';
import { Dispatch, FC, useCallback, useEffect, useMemo } from 'react';
import { DESC_MAX_LENGTH_500 } from '../../constants/constants';
import {
  CREATE_NOTICE_BTN_TEXT,
  CREATE_NOTICE_DESC_TEXT,
  CREATE_NOTICE_TEXT_LABEL,
  CREATE_NOTICE_TEXT_PLACEHOLDER,
  CREATE_NOTICE_TITLE_LABEL,
  CREATE_NOTICE_TITLE_PLACEHOLDER,
  DESC_TEXT_INFO,
  MANDATOY_FIELDS,
  NOTICE_CATEGORY_LABEL,
  NOTICE_TYPE_LABEL,
  OFFER_OPTION_TEXT,
  SEEK_OPTION_TEXT,
  TITLE_TEXT_INFO,
  UPDATE_NOTICE_BTN_TEXT
} from '../../constants/strings';
import useMediaItemApi from '../../hooks/useMediaItemApi';
import { SETTINGS_LABEL } from '../../themes/colors/globalColors';
import { NoticeCategory } from '../../types/notice';
import { hasLength, isInRange } from '../../utils/common';
import {
  setCategory,
  setContentType,
  setImage,
  setImageId,
  setText,
  setTitle,
  TAction,
  TState
} from '../../views/BulletinBoard/components/CreateNoticeReducer';
import CharacterLimitIndicator from '../CharacterLimitIndicator';
import EditableImage from '../EditableImage';
import ExtraHelperText from '../ExtraHelperText';

interface CreateNoticeViewProps {
  noticeState: TState;
  dispatch: Dispatch<TAction>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createNotice: (event: any) => void;
  imageIcon: React.ReactElement;
  isUpdateNotice: boolean;
}

const CreateNoticeView: FC<CreateNoticeViewProps> = ({
  noticeState,
  dispatch,
  createNotice,
  imageIcon,
  isUpdateNotice
}) => {
  const mediaItemApi = useMediaItemApi();
  const { title, contentType, category, text, imageId, image } = noticeState;

  const isCreateNoticeEnabled = useMemo(() => {
    return (
      contentType && category && hasLength(title) && isInRange(20, 500, text)
    );
  }, [category, contentType, text, title]);

  const contentTypeRadios = [
    {
      value: ClientPostCreateRequestContentTypeEnum.Offer,
      label: OFFER_OPTION_TEXT
    },
    {
      value: ClientPostCreateRequestContentTypeEnum.Seeking,
      label: SEEK_OPTION_TEXT
    }
  ];

  const categoryRadios = [
    {
      value: NoticeCategory.CULTURE,
      label: NoticeCategory.CULTURE
    },
    {
      value: NoticeCategory.HEALTH_SPORT,
      label: NoticeCategory.HEALTH_SPORT
    },
    {
      value: NoticeCategory.ENCOUNTER,
      label: NoticeCategory.ENCOUNTER
    },
    {
      value: NoticeCategory.NEIGHBORHOOD_HELP,
      label: NoticeCategory.NEIGHBORHOOD_HELP
    },
    {
      value: NoticeCategory.GENERAL,
      label: NoticeCategory.GENERAL
    }
  ];
  const handleValueChange = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement>,
      actionFunction: (value: string) => TAction
    ) => {
      event.preventDefault();
      event.stopPropagation();
      dispatch(actionFunction(event.target.value));
    },
    [dispatch]
  );

  const handleTitleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleValueChange(event, setTitle);
    },
    [handleValueChange]
  );

  const handleTextChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleValueChange(event, setText);
    },
    [handleValueChange]
  );

  const handleContentTypeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleValueChange(event, setContentType);
    },
    [handleValueChange]
  );

  const handleCategoryChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleValueChange(event, setCategory);
    },
    [handleValueChange]
  );

  const handleImageChange = useCallback(
    (temporaryMediaItemId: string) => {
      dispatch(setImageId(temporaryMediaItemId));
    },
    [dispatch]
  );

  useEffect(() => {
    const loadImage = async () => {
      if (imageId) {
        const tempMediaItem = await mediaItemApi.getTemporaryMediaItem(imageId);
        dispatch(setImage(tempMediaItem.mediaItem));
      }
    };
    loadImage();
  }, [dispatch, imageId, mediaItemApi]);

  return (
    <Box component="form">
      <Typography variant="body1" sx={{ fontSize: '1rem' }}>
        {CREATE_NOTICE_DESC_TEXT}
      </Typography>
      <Stack direction="row" justifyContent="space-between" sx={{ mt: 2 }}>
        <Box>
          <FormControl>
            <Typography gutterBottom={true} variant="h3">
              {`${NOTICE_TYPE_LABEL} *`}
            </Typography>
            <RadioGroup
              row
              aria-labelledby="type-group-label"
              name="contentType"
              sx={{ alignItems: 'center' }}
              value={contentType}
              onChange={handleContentTypeChange}
            >
              <Typography variant="body1" sx={{ mr: 4 }}>
                Ich
              </Typography>
              {contentTypeRadios.map(({ value, label }) => {
                return (
                  <FormControlLabel
                    key={value}
                    value={value}
                    control={<Radio required />}
                    label={label}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        </Box>
        <Box>
          <EditableImage
            clientMediaItem={image ?? undefined}
            defaultImage={imageIcon}
            onChange={handleImageChange}
          />
        </Box>
      </Stack>
      <Box sx={{ mt: 2 }}>
        <FormControl>
          <Typography gutterBottom={true} variant="h3">
            {`${NOTICE_CATEGORY_LABEL} *`}
          </Typography>
          <RadioGroup
            row
            aria-labelledby="category-group-label"
            name="category"
            value={category}
            onChange={handleCategoryChange}
          >
            {categoryRadios.map(({ value, label }) => (
              <FormControlLabel
                key={value}
                value={value}
                control={<Radio required />}
                label={label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
      <Box sx={{ mt: 4, position: 'relative' }}>
        <Typography gutterBottom={true} variant="h3">
          {`${CREATE_NOTICE_TITLE_LABEL} *`}
        </Typography>
        <TextField
          fullWidth
          multiline
          placeholder={CREATE_NOTICE_TITLE_PLACEHOLDER}
          value={title}
          onChange={handleTitleChange}
          error={!title}
          required
        />
        <ExtraHelperText text={TITLE_TEXT_INFO} top={6} right={1} />
      </Box>
      <Box sx={{ mt: 4, position: 'relative' }}>
        <Typography gutterBottom={true} variant="h3">
          {`${CREATE_NOTICE_TEXT_LABEL} *`}
        </Typography>
        <TextField
          fullWidth
          multiline
          minRows={5}
          maxRows={8}
          placeholder={CREATE_NOTICE_TEXT_PLACEHOLDER}
          value={text}
          onChange={handleTextChange}
          error={!text || !isInRange(20, 500, text)}
          required
        />
        <ExtraHelperText text={DESC_TEXT_INFO} top={5} right={1} />
        <CharacterLimitIndicator field={text} limit={DESC_MAX_LENGTH_500} />
      </Box>
      <Box sx={{ float: 'right', mt: 3 }}>
        <small
          style={{
            color: SETTINGS_LABEL
          }}
        >
          {MANDATOY_FIELDS}
        </small>
        <span>*</span>
      </Box>
      <Box sx={{ textAlign: 'center', mt: 6 }}>
        <Button
          variant="contained"
          type="submit"
          disabled={!isCreateNoticeEnabled}
          onClick={createNotice}
        >
          {isUpdateNotice ? UPDATE_NOTICE_BTN_TEXT : CREATE_NOTICE_BTN_TEXT}
        </Button>
      </Box>
    </Box>
  );
};

export default CreateNoticeView;
