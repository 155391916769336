export type TState = {
  message: string;
  contact: string;
};

export const initialState: TState = {
  message: '',
  contact: ''
};

export type TSetMessage = {
  name: 'setMessage';
  message: string;
};

export type TSetContact = {
  name: 'setContact';
  contact: string;
};

export type TReset = {
  name: 'reset';
};

export type TAction = TSetMessage | TSetContact | TReset;

export const setMessage = (message: string): TAction => {
  return { name: 'setMessage', message };
};

export const setContact = (contact: string): TAction => {
  return { name: 'setContact', contact };
};

export const reset = (): TAction => {
  return { name: 'reset' };
};

export const reducer = (state: TState, action: TAction): TState => {
  const name = action.name;
  switch (name) {
    case 'setMessage':
      return {
        ...state,
        message: action.message
      };
    case 'setContact':
      return {
        ...state,
        contact: action.contact
      };
    case 'reset':
      return {
        message: '',
        contact: ''
      };
    default:
      throw new Error('Unknown action');
  }
};
