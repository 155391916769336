import { ClientPostGeoAreaMapping } from 'digital-vital-backend-api';
import { NoticeCategory, NoticeContentType } from '../../../types/notice';

export type PostCategoriesType = {
  [NoticeCategory.GENERAL]: boolean;
  [NoticeCategory.CULTURE]: boolean;
  [NoticeCategory.HEALTH_SPORT]: boolean;
  [NoticeCategory.ENCOUNTER]: boolean;
  [NoticeCategory.NEIGHBORHOOD_HELP]: boolean;
};

export type PostTypesType = {
  [NoticeContentType.OFFER]: boolean;
  [NoticeContentType.SEEKING]: boolean;
};

export type TState = {
  postType: PostTypesType;
  postCategories: PostCategoriesType;
  searchedNotices: ClientPostGeoAreaMapping[];
  isSearched: boolean;
};

export const initialState: TState = {
  postType: {
    [NoticeContentType.OFFER]: false,
    [NoticeContentType.SEEKING]: false
  },
  postCategories: {
    [NoticeCategory.GENERAL]: false,
    [NoticeCategory.CULTURE]: false,
    [NoticeCategory.HEALTH_SPORT]: false,
    [NoticeCategory.ENCOUNTER]: false,
    [NoticeCategory.NEIGHBORHOOD_HELP]: false
  },
  searchedNotices: [],
  isSearched: false
};

export type TSetPostTypeAction = {
  name: 'setPostType';
  postType: PostTypesType;
};

export type TSetPostCategoriesAction = {
  name: 'setPostCategories';
  postCategories: PostCategoriesType;
};

export type TSetSearchedNoticesAction = {
  name: 'setSearchedNotices';
  searchedNotices: ClientPostGeoAreaMapping[];
};
export type TSetSearchedAction = {
  name: 'setSearched';
  isSearched: boolean;
};

export type TAction =
  | TSetPostTypeAction
  | TSetPostCategoriesAction
  | TSetSearchedNoticesAction
  | TSetSearchedAction;

export const setPostType = (postType: PostTypesType): TAction => {
  return { name: 'setPostType', postType };
};

export const setPostCategories = (
  postCategories: PostCategoriesType
): TAction => {
  return { name: 'setPostCategories', postCategories };
};

export const setSearchedNotices = (
  searchedNotices: ClientPostGeoAreaMapping[]
): TAction => {
  return { name: 'setSearchedNotices', searchedNotices };
};

export const setSearched = (isSearched: boolean): TAction => {
  return { name: 'setSearched', isSearched };
};

export const reducer = (state: TState, action: TAction): TState => {
  const { name } = action;
  switch (name) {
    case 'setPostType':
      return {
        ...state,
        postType: action.postType
      };
    case 'setPostCategories':
      return {
        ...state,
        postCategories: action.postCategories
      };
    case 'setSearchedNotices':
      return {
        ...state,
        searchedNotices: action.searchedNotices
      };
    case 'setSearched':
      return {
        ...state,
        isSearched: action.isSearched
      };
    default:
      break;
  }
  return state;
};
