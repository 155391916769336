import { Navigate } from 'react-router-dom';
import { RouteObject } from './interfaces/common';
import BulletinBoard from './views/BulletinBoard/BulletinBoard';
import CreateNotice from './views/BulletinBoard/components/CreateNotice';
import Mynotices from './views/BulletinBoard/components/Mynotices';
import NearbyNotices from './views/BulletinBoard/components/NearbyNotices';
import NoticeDetails from './views/BulletinBoard/components/NoticeDetails';
import OfferNotices from './views/BulletinBoard/components/OfferNotices';
import SearchNotice from './views/BulletinBoard/components/SearchNotice';
import WantedNotices from './views/BulletinBoard/components/WantedNotices';
import CalendarView from './views/Calendar/CalendarView';
import MyCalendar from './views/Calendar/components/MyCalendar';
import NearbyCalendar from './views/Calendar/components/NearbyCalendar';
import CreateEvent from './views/Events/Components/CreateEvent';
import EventHappeningDetails from './views/Events/Components/EventHappeningDetails';
import MyEvents from './views/Events/Components/MyEvents';
import MyOldEvents from './views/Events/Components/MyOldEvents';
import MyRegisteredEvents from './views/Events/Components/MyRegisteredEvents';
import NearByEvents from './views/Events/Components/NearByEvents';
import Events from './views/Events/Events';
import FitHealthListView from './views/FitHealth/components/FitHealthListView';
import FitHealthPostView from './views/FitHealth/components/FitHealthPostView';
import FitHealthView from './views/FitHealth/FitHealthView';
import HelpEntry from './views/Help/components/HelpEntry';
import HelpOverview from './views/Help/components/HelpOverview';
import Help from './views/Help/Help';
import Home from './views/Home/Home';
import Contact from './views/Imprint/components/Contact';
import Impressum from './views/Imprint/components/Impressum';
import Privacy from './views/Imprint/components/Privacy';
import TermsOfUse from './views/Imprint/components/TermsOfUse';
import Imprint from './views/Imprint/Imprint';
import MyRegisteredHappenings from './views/MyArea/components/MyRegisteredHappenings';
import MyRegisteredTable from './views/MyArea/components/MyRegisteredTable';
import MyWelcome from './views/MyArea/components/MyWelcome';
import PendingPostDetail from './views/MyArea/components/PendingPostDetail';
import PendingPostsList from './views/MyArea/components/PendingPostsList';
import MyAreaView from './views/MyArea/MyAreaView';
import CreateTable from './views/NeighborhoodTables/components/CreateTable';
import MyOldTables from './views/NeighborhoodTables/components/MyOldTables';
import MyOwnTables from './views/NeighborhoodTables/components/MyOwnTables';
import MyRegisteredTables from './views/NeighborhoodTables/components/MyRegisteredTables';
import NearbyTables from './views/NeighborhoodTables/components/NearbyTables';
import TableHappeningDetails from './views/NeighborhoodTables/components/TableHappeningDetails';
import NeighborhoodTables from './views/NeighborhoodTables/NeighborhoodTables';
import NewsArticleView from './views/News/components/NewsArticleView';
import NewsListView from './views/News/components/NewsListView';
import News from './views/News/News';
import PostDetailView from './views/Posts/PostDetailView';
import PostListView from './views/Posts/PostListView';
import Search from './views/Posts/PostSearchView';
import PostView from './views/Posts/PostView';
import PostWelcomeView from './views/Posts/PostWelcomeView';
import Profile from './views/Profile/Profile';
import FontSize from './views/Settings/components/FontSize';
import MeineNahe from './views/Settings/components/MeineNahe';
import SettingsHome from './views/Settings/components/SettingsHome';
import Settings from './views/Settings/Settings';

const routes = (): RouteObject[] => {
  return [
    {
      path: '/',
      element: <Home />
    },
    {
      path: 'help',
      element: <Help />,
      children: [
        { path: '', element: <Navigate to="/" /> },
        {
          path: 'overview',
          element: <HelpOverview />
        },
        {
          path: 'entry',
          element: <Navigate to="../overview" />
        },
        {
          path: 'entry/:entryId',
          element: <HelpEntry />
        }
      ]
    },
    {
      path: 'nachbarschaftstische',
      element: <NeighborhoodTables />,
      children: [
        { path: '', element: <Navigate to="/" /> },
        {
          path: 'nearbytisch/*',
          element: <NearbyTables />
        },
        { path: 'nearbytisch/:eventId', element: <TableHappeningDetails /> },
        { path: 'myregistrations/*', element: <MyRegisteredTables /> },
        {
          path: 'myregistrations/:eventId',
          element: <TableHappeningDetails />
        },
        { path: 'mytisch/*', element: <MyOwnTables /> },
        { path: 'mytisch/:eventId', element: <TableHappeningDetails /> },
        { path: 'myoldtisch/*', element: <MyOldTables /> },
        { path: 'myoldtisch/:eventId', element: <TableHappeningDetails /> },
        { path: 'tischanbieten', element: <CreateTable /> }
      ]
    },
    {
      path: 'information',
      element: <PostView />,
      children: [
        { path: '', element: <PostWelcomeView /> },
        { path: 'category', element: <Navigate to=".." /> },
        { path: 'category/:categoryId', element: <PostListView /> },
        {
          path: 'category/:categoryId/sub-category',
          element: <Navigate to=".." />
        },
        {
          path: 'category/:categoryId/sub-category/:subCategoryId',
          element: <PostListView />
        },
        { path: 'post', element: <Navigate to=".." /> },
        { path: 'post/:postId', element: <PostDetailView /> },
        { path: 'search', element: <Search /> }
      ]
    },
    {
      path: 'veranstaltungen',
      element: <Events />,
      children: [
        { path: '', element: <Navigate to="/" /> },
        {
          path: 'nearbyevents/*',
          element: <NearByEvents />
        },
        { path: 'nearbyevents/:eventId', element: <EventHappeningDetails /> },
        { path: 'myregistrations/*', element: <MyRegisteredEvents /> },
        {
          path: 'myregistrations/:eventId',
          element: <EventHappeningDetails />
        },
        { path: 'myevents/*', element: <MyEvents /> },
        {
          path: 'myevents/:eventId',
          element: <EventHappeningDetails />
        },
        { path: 'myoldevents/*', element: <MyOldEvents /> },
        {
          path: 'myoldevents/:eventId',
          element: <EventHappeningDetails />
        },
        { path: 'createevents', element: <CreateEvent /> }
      ]
    },
    {
      path: 'aktuelles',
      element: <News />,
      children: [
        { path: '', element: <NewsListView /> },
        { path: 'artikel', element: <Navigate to=".." /> },
        { path: 'artikel/:articleId', element: <NewsArticleView /> }
      ]
    },
    {
      path: 'fit-gesund',
      element: <FitHealthView />,
      children: [
        { path: '', element: <FitHealthListView /> },
        { path: 'post', element: <Navigate to=".." /> },
        { path: 'post/:postId', element: <FitHealthPostView /> }
      ]
    },
    {
      path: 'pinnwand',
      element: <BulletinBoard />,
      children: [
        { path: '', element: <Navigate to="/" /> },
        { path: 'nearbynotices/*', element: <NearbyNotices /> },
        { path: 'nearbynotices/:noticeId', element: <NoticeDetails /> },
        { path: 'offernotices/*', element: <OfferNotices /> },
        { path: 'offernotices/:noticeId', element: <NoticeDetails /> },
        { path: 'seekingnotices/*', element: <WantedNotices /> },
        { path: 'seekingnotices/:noticeId', element: <NoticeDetails /> },
        { path: 'createnotice', element: <CreateNotice /> },
        { path: 'searchnotice/*', element: <SearchNotice /> },
        { path: 'searchnotice/:noticeId', element: <NoticeDetails /> },
        { path: 'mynotices/*', element: <Mynotices /> },
        { path: 'mynotices/:noticeId', element: <NoticeDetails /> }
      ]
    },
    {
      path: 'my-area',
      element: <MyAreaView />,
      children: [
        { path: '', element: <MyWelcome /> },
        {
          path: 'my-profile',
          element: <Profile />
        },
        {
          path: 'my-registrations',
          element: <MyRegisteredHappenings />
        },
        {
          path: 'my-registrations/table',
          element: <Navigate to="../my-registrations" />
        },
        {
          path: 'my-registrations/table/:eventId',
          element: <MyRegisteredTable />
        },
        { path: 'meine-naehe', element: <MeineNahe /> },
        {
          path: 'pending-posts',
          element: <PendingPostsList />
        },
        {
          path: 'pending-posts/post',
          element: <Navigate to="../pending-posts" />
        },
        {
          path: 'pending-posts/post/:postId',
          element: <PendingPostDetail />
        }
      ]
    },
    {
      path: 'einstellungen',
      element: <Settings />,
      children: [
        { path: '', element: <SettingsHome /> },
        { path: 'schriftgroesse', element: <FontSize /> }
      ]
    },
    {
      path: 'imprint',
      element: <Imprint />,
      children: [
        { path: '', element: <Navigate to="/" /> },
        { path: 'impressum', element: <Impressum /> },
        { path: 'kontakt', element: <Contact /> },
        { path: 'datenschutz', element: <Privacy /> },
        { path: 'nutzungsbedingungen', element: <TermsOfUse /> }
      ]
    },
    {
      path: 'kalender',
      element: <CalendarView />,
      children: [
        { path: '', element: <Navigate to="/" /> },
        { path: 'nearbyevents', element: <NearbyCalendar /> },
        { path: 'mycalendar', element: <MyCalendar /> }
      ]
    }
  ];
};

export default routes;
