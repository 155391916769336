import { DialogContentText } from '@mui/material';
import { FC } from 'react';
import { BACK_BTN_TEXT, CONFIRM_BTN_TEXT } from '../../constants/strings';
import AbstractDialog from '../dialogs/AbstractDialog';

interface TerminePublishConfirmationDialogProps {
  message: string;
  open: boolean;
  onClose: () => void;
  onAction: () => void;
}

const TerminePublishConfirmationDialog: FC<
  TerminePublishConfirmationDialogProps
> = ({ message, open, onClose, onAction }) => {
  return (
    <AbstractDialog
      open={open}
      btnActionText={CONFIRM_BTN_TEXT}
      btnCancelText={BACK_BTN_TEXT}
      hideCancelBtn={false}
      onClose={onClose}
      onAction={onAction}
    >
      <DialogContentText>{message}</DialogContentText>
    </AbstractDialog>
  );
};

export default TerminePublishConfirmationDialog;
