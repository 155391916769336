"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientHappeningMessageRequestToJSON = exports.ClientHappeningMessageRequestFromJSONTyped = exports.ClientHappeningMessageRequestFromJSON = void 0;
function ClientHappeningMessageRequestFromJSON(json) {
    return ClientHappeningMessageRequestFromJSONTyped(json, false);
}
exports.ClientHappeningMessageRequestFromJSON = ClientHappeningMessageRequestFromJSON;
function ClientHappeningMessageRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'message': json['message'],
    };
}
exports.ClientHappeningMessageRequestFromJSONTyped = ClientHappeningMessageRequestFromJSONTyped;
function ClientHappeningMessageRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'message': value.message,
    };
}
exports.ClientHappeningMessageRequestToJSON = ClientHappeningMessageRequestToJSON;
