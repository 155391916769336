import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, Typography } from '@mui/material';
import { CSSProperties, FC, useMemo } from 'react';
import {
  ORGANIZER_BADGE_TEXT,
  PARTICIPANT_BADGE_TEXT
} from '../../constants/strings';

const useStyles = (): Record<string, CSSProperties> => ({
  badgeStyle: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    background: 'white',
    padding: 1,
    alignItems: 'center',
    borderBottomRightRadius: '5px',
    boxShadow: '2'
  },
  iconStyle: {
    paddingRight: '.5rem'
  }
});

type HappeningDetailsBadgeProps = {
  isOrganizer: boolean;
  isParticipant: boolean;
  organizerText?: string;
  participantText?: string;
};

const HappeningDetailsBadge: FC<HappeningDetailsBadgeProps> = (props) => {
  const {
    isOrganizer,
    isParticipant,
    organizerText = ORGANIZER_BADGE_TEXT,
    participantText = PARTICIPANT_BADGE_TEXT
  } = props;
  const styles = useStyles();

  const badgeLabel = useMemo(() => {
    if (isOrganizer) return organizerText;
    else if (isParticipant) return participantText;
    return '';
  }, [isOrganizer, isParticipant, organizerText, participantText]);

  return (
    <Box sx={styles.badgeStyle}>
      <CheckCircleOutlineIcon
        color="primary"
        sx={styles.iconStyle}
        fontSize="medium"
      />
      <Typography variant="body2" fontWeight="bold">
        {badgeLabel}
      </Typography>
    </Box>
  );
};

export default HappeningDetailsBadge;
