import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Box, FormHelperText } from '@mui/material';
import { FC, useMemo } from 'react';

interface CharacterLimitIndicatorProps {
  field: string;
  limit: number;
}

const CharacterLimitIndicator: FC<CharacterLimitIndicatorProps> = ({
  field,
  limit
}) => {
  const isError = useMemo(() => field.length > limit, [field, limit]);
  return (
    <Box sx={{ position: 'absolute', right: 0, display: 'flex' }}>
      {isError && (
        <ReportProblemIcon
          sx={{
            height: '.65em',
            width: '.65em',
            marginTop: '4px',
            marginRight: '3px'
          }}
          color="error"
        />
      )}
      <FormHelperText
        error={isError}
      >{`${field.length}/${limit}`}</FormHelperText>
    </Box>
  );
};

export default CharacterLimitIndicator;
