import { Typography } from '@mui/material';
import { FC } from 'react';
import { CANCEL_SUCCESS_CLOSE_BTN_TXT } from '../../../constants/strings';
import AbstractDialog from '../../dialogs/AbstractDialog';

type HappeningCancellationSuccessDialogProps = {
  open: boolean;
  onClose: () => void;
  isEvent?: boolean;
};

const HappeningCancellationSuccessDialog: FC<
  HappeningCancellationSuccessDialogProps
> = ({ open, onClose, isEvent }) => {
  return (
    <AbstractDialog
      open={open}
      btnActionText={CANCEL_SUCCESS_CLOSE_BTN_TXT}
      hideCancelBtn={true}
      onClose={onClose}
      onAction={onClose}
    >
      <Typography align="center">
        {` ${
          isEvent ? ' Ihre Veranstaltung' : 'Ihrer Nachbarschaftstisch'
        } wurde abgesagt und `}
        <b>{'alle Teilnehmer '}</b>
        {'wurden über die Absage informiert'}
      </Typography>
    </AbstractDialog>
  );
};

export default HappeningCancellationSuccessDialog;
