export const FONT_SIZE_KEY = 'fontsize';

const storage = window.localStorage;

function getLocalSetting(key: string): string | null {
  return storage.getItem(key);
}

function saveLocalSetting(key: string, value: string): void {
  storage.setItem(key, value);
}

function removeLocalSetting(key: string): void {
  storage.removeItem(key);
}

function clear(): void {
  storage.clear();
}

const LocalSettingsService = {
  getLocalSetting,
  saveLocalSetting,
  removeLocalSetting,
  clear
};

export default LocalSettingsService;
