import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import { FC } from 'react';
import { SHOW_MORE } from '../constants/strings';

const ShowMoreContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  right: 0,
  padding: '5px 10px',
  backgroundColor: theme.palette.primary.main,
  textAlign: 'center',
  borderRadius: '10px 0px 0px 0px',
  color: theme.palette.primary.contrastText
}));

const ShowMoreTag: FC = () => {
  return (
    <ShowMoreContainer sx={{ display: { xs: 'none', sm: 'block' } }}>
      <Typography variant="subtitle2">{SHOW_MORE}</Typography>
    </ShowMoreContainer>
  );
};

export default ShowMoreTag;
