"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientContactOrganizerRequestToJSON = exports.ClientContactOrganizerRequestFromJSONTyped = exports.ClientContactOrganizerRequestFromJSON = void 0;
function ClientContactOrganizerRequestFromJSON(json) {
    return ClientContactOrganizerRequestFromJSONTyped(json, false);
}
exports.ClientContactOrganizerRequestFromJSON = ClientContactOrganizerRequestFromJSON;
function ClientContactOrganizerRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'message': json['message'],
        'contactInformation': json['contactInformation'],
    };
}
exports.ClientContactOrganizerRequestFromJSONTyped = ClientContactOrganizerRequestFromJSONTyped;
function ClientContactOrganizerRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'message': value.message,
        'contactInformation': value.contactInformation,
    };
}
exports.ClientContactOrganizerRequestToJSON = ClientContactOrganizerRequestToJSON;
