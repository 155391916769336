import { Alert, Typography, Breadcrumbs, Link } from '@mui/material';
import { FC, Fragment, useMemo } from 'react';
import {
  useOutletContext,
  useParams,
  Link as ReactRouterLink
} from 'react-router-dom';
import { findById } from '../../../utils/common';
import { TNewsOutletContext } from '../News';
import HTMLContent from '../../../components/HTMLContent';
import { getImageUrl } from '../../../api/InformationAPI';
import { HEADER_NEWS } from '../../../constants/strings';

type TParams = {
  articleId: string;
};

const NewsArticleView: FC = () => {
  const { newsArticles } = useOutletContext<TNewsOutletContext>();
  const { articleId } = useParams<TParams>();

  const currentArticle = useMemo(() => {
    if (!articleId) {
      return undefined;
    }
    return findById(newsArticles, articleId);
  }, [articleId, newsArticles]);

  if (!currentArticle) {
    return <Alert severity="error">Could not load news article!</Alert>;
  }

  return (
    <Fragment>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          color="text.primary"
          variant="h3"
          to="/"
          component={ReactRouterLink}
        >
          {HEADER_NEWS}
        </Link>
        <Typography color="text.primary" variant="h3">
          {currentArticle.title}
        </Typography>
      </Breadcrumbs>
      <Typography variant="h3" gutterBottom={true}>
        {currentArticle.title}
      </Typography>
      {currentArticle.subtitle && (
        <Typography variant="h4" gutterBottom={true}>
          {currentArticle.subtitle}
        </Typography>
      )}
      {currentArticle.featuredImage && (
        <div style={{ textAlign: 'center' }}>
          <img src={getImageUrl(currentArticle.featuredImage)} />
        </div>
      )}
      <Typography component="div">
        <HTMLContent content={currentArticle.text} />
      </Typography>
    </Fragment>
  );
};

export default NewsArticleView;
