import { AxiosRequestConfig } from 'axios';
import { DIRECTUS_URL } from '../config';

export type DirectusResponse<T> = {
  data: Array<T>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DirectusPostResponse = DirectusResponse<Record<string, any>>;

export const DirecutsRequestConfig: AxiosRequestConfig<unknown> = {
  baseURL: DIRECTUS_URL
};
