import { Breadcrumbs, Link, Stack, Typography } from '@mui/material';
import { ClientPost } from 'digital-vital-backend-api';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Link as ReactRouterLink,
  useLocation,
  useParams
} from 'react-router-dom';
import Notice from '../../../assets/svgs/notice.svg';
import NoticeDetailsContainer from '../../../components/notice/NoticeDetailsContainer';
import {
  HOME_TILE_PINNWAND,
  NOTICE_TAB_1_TEXT,
  NOTICE_TAB_2_TEXT,
  NOTICE_TAB_3_TEXT,
  NOTICE_TAB_4_TEXT,
  NOTICE_TAB_5_TEXT,
  NOTICE_TAB_6_TEXT
} from '../../../constants/strings';
import usePostApi from '../../../hooks/usePostApi';

const NoticeDetails: FC = () => {
  const postApi = usePostApi();
  const params = useParams();
  const location = useLocation();
  const currentLocationPathname = location.pathname.split('/');
  const noticeId = useMemo(() => params.noticeId, [params]);
  const [notice, setNotice] = useState<ClientPost>();

  const loadNoticeDetails = useCallback(() => {
    const loadNoticeDetailsAsync = async () => {
      if (!noticeId) {
        return;
      }
      try {
        const loadedNotice = await postApi.getPostById(noticeId);
        setNotice(loadedNotice);
      } catch (error) {
        // handle Error
      }
    };
    loadNoticeDetailsAsync();
  }, [postApi, noticeId]);

  useEffect(() => {
    loadNoticeDetails();
  }, [loadNoticeDetails]);

  const getTabName = useMemo(() => {
    const recordFromPathnametoTabName = [
      { pathname: 'nearbynotices', TabName: NOTICE_TAB_1_TEXT },
      { pathname: 'offernotices', TabName: NOTICE_TAB_2_TEXT },
      { pathname: 'seekingnotices', TabName: NOTICE_TAB_3_TEXT },
      { pathname: 'searchnotice', TabName: NOTICE_TAB_4_TEXT },
      { pathname: 'createnotice', TabName: NOTICE_TAB_5_TEXT },
      { pathname: 'mynotices', TabName: NOTICE_TAB_6_TEXT }
    ];
    return recordFromPathnametoTabName.find(
      (tabNameforReturn) =>
        tabNameforReturn.pathname == currentLocationPathname[2]
    );
  }, [currentLocationPathname]);
  const selectedCategoryURL =
    '/' + currentLocationPathname[1] + '/' + currentLocationPathname[2];

  return (
    <Stack spacing={2}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          color="text.primary"
          variant="h3"
          to="/"
          component={ReactRouterLink}
        >
          {HOME_TILE_PINNWAND}
        </Link>
        <Link
          underline="hover"
          color="text.primary"
          variant="h3"
          to={selectedCategoryURL}
          component={ReactRouterLink}
        >
          {getTabName?.TabName}
        </Link>
        <Typography color="text.primary" variant="h3">
          {notice?.title}
        </Typography>
      </Breadcrumbs>
      {notice && (
        <NoticeDetailsContainer notice={notice} defaultHeaderImage={Notice} />
      )}
    </Stack>
  );
};

export default NoticeDetails;
