"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MediaApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class MediaApi extends runtime.BaseAPI {
    /**
     * Deletes a temporary file that was uploaded by the user before.
     * Deletes a temporary media file
     */
    deleteTemporaryMediaItemRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.temporaryMediaItemId === null || requestParameters.temporaryMediaItemId === undefined) {
                throw new runtime.RequiredError('temporaryMediaItemId', 'Required parameter requestParameters.temporaryMediaItemId was null or undefined when calling deleteTemporaryMediaItem.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/media-items/temp/{temporaryMediaItemId}`.replace(`{${"temporaryMediaItemId"}}`, encodeURIComponent(String(requestParameters.temporaryMediaItemId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Deletes a temporary file that was uploaded by the user before.
     * Deletes a temporary media file
     */
    deleteTemporaryMediaItem(temporaryMediaItemId, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.deleteTemporaryMediaItemRaw({ temporaryMediaItemId: temporaryMediaItemId, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
        });
    }
    /**
     * Gets the current defined media item sizes with their maximum size.
     * Get the current defined media item sizes
     */
    getDefinedSizesRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            const response = yield this.request({
                path: `/media-items/sizes`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.ClientMediaItemSizeFromJSON));
        });
    }
    /**
     * Gets the current defined media item sizes with their maximum size.
     * Get the current defined media item sizes
     */
    getDefinedSizes(portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getDefinedSizesRaw({ portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Gets a temporary file that was uploaded by the user before.
     * Gets a temporary media file
     */
    getTemporaryMediaItemRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.temporaryMediaItemId === null || requestParameters.temporaryMediaItemId === undefined) {
                throw new runtime.RequiredError('temporaryMediaItemId', 'Required parameter requestParameters.temporaryMediaItemId was null or undefined when calling getTemporaryMediaItem.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/media-items/temp/{temporaryMediaItemId}`.replace(`{${"temporaryMediaItemId"}}`, encodeURIComponent(String(requestParameters.temporaryMediaItemId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ClientTemporaryMediaItemFromJSON(jsonValue));
        });
    }
    /**
     * Gets a temporary file that was uploaded by the user before.
     * Gets a temporary media file
     */
    getTemporaryMediaItem(temporaryMediaItemId, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getTemporaryMediaItemRaw({ temporaryMediaItemId: temporaryMediaItemId, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * The uploaded file can be used by this user to create entities with media items. Use the returned id (NOT the id of the media item) in create requests.  The temporary files have an expiration date and are removed automatically afterwards.
     * Uploads a temporary media file
     */
    uploadTemporaryMediaItemRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.file === null || requestParameters.file === undefined) {
                throw new runtime.RequiredError('file', 'Required parameter requestParameters.file was null or undefined when calling uploadTemporaryMediaItem.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const consumes = [
                { contentType: 'multipart/form-data' },
            ];
            // @ts-ignore: canConsumeForm may be unused
            const canConsumeForm = runtime.canConsumeForm(consumes);
            let formParams;
            let useForm = false;
            // use FormData to transmit files using content-type "multipart/form-data"
            useForm = canConsumeForm;
            if (useForm) {
                formParams = new FormData();
            }
            else {
                formParams = new URLSearchParams();
            }
            if (requestParameters.file !== undefined) {
                formParams.append('file', requestParameters.file);
            }
            const response = yield this.request({
                path: `/media-items/temp`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: formParams,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ClientTemporaryMediaItemFromJSON(jsonValue));
        });
    }
    /**
     * The uploaded file can be used by this user to create entities with media items. Use the returned id (NOT the id of the media item) in create requests.  The temporary files have an expiration date and are removed automatically afterwards.
     * Uploads a temporary media file
     */
    uploadTemporaryMediaItem(file, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.uploadTemporaryMediaItemRaw({ file: file, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
}
exports.MediaApi = MediaApi;
