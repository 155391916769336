import { Stack, Typography, Breadcrumbs, Link } from '@mui/material';
import { ClientHappeningGeoAreaMapping } from 'digital-vital-backend-api';
import { FC, useCallback, useEffect, useState } from 'react';
import {
  useNavigate,
  useOutletContext,
  Link as ReactRouterLink
} from 'react-router-dom';
import Event from '../../../assets/svgs/event.svg';
import HappeningList from '../../../components/HappeningList';
import HappeningMap from '../../../components/HappeningMap';
import {
  VERANSTALTUNGEN_NEARBY_OFFERS_EMPTY_PLACEHOLDER,
  VERANSTALTUNGEN_OFFER_BTN_TEXT,
  HOME_TILE_VERANSTALTUNGEN,
  VERANSTALTUNGEN_TAB_1_TEXT
} from '../../../constants/strings';
import useHappeningApi from '../../../hooks/useHappeningApi';
import { HappeningType } from '../../../types/happening';
import { TEventsOutletContext } from '../Events';

const NearByEvents: FC = () => {
  const { isCreateAllowed } = useOutletContext<TEventsOutletContext>();
  const happeningApi = useHappeningApi();
  const navigate = useNavigate();
  const [nearbyEvents, setNearbyEvents] = useState<
    Array<ClientHappeningGeoAreaMapping>
  >([]);

  const handleOfferEventClicked = useCallback(() => {
    navigate('/veranstaltungen/createevents');
  }, [navigate]);

  useEffect(() => {
    const loadNearByTablesAsync = async () => {
      const allHappenings = await happeningApi.getAllHappenings(
        HappeningType.Event
      );
      setNearbyEvents(allHappenings);
    };
    loadNearByTablesAsync();
  }, [happeningApi]);

  return (
    <Stack spacing={2}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          color="text.primary"
          variant="h3"
          to="/"
          component={ReactRouterLink}
        >
          {HOME_TILE_VERANSTALTUNGEN}
        </Link>
        <Typography color="text.primary" variant="h3">
          {VERANSTALTUNGEN_TAB_1_TEXT}
        </Typography>
      </Breadcrumbs>
      <HappeningMap
        happenings={nearbyEvents}
        markerUrl="/markers/marker-event.png"
      />
      <HappeningList
        happeningGeoAreaMappings={nearbyEvents}
        createButtonText={VERANSTALTUNGEN_OFFER_BTN_TEXT}
        defaultImage={Event}
        showCreateButton={isCreateAllowed}
        onCreateButtonClick={handleOfferEventClicked}
      />
      {nearbyEvents.length === 0 && (
        <Typography variant="body1" sx={{ fontSize: '1rem' }}>
          {VERANSTALTUNGEN_NEARBY_OFFERS_EMPTY_PLACEHOLDER}
        </Typography>
      )}
    </Stack>
  );
};

export default NearByEvents;
