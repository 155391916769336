import { FC, Fragment } from 'react';
import { useOutletContext } from 'react-router-dom';
import HTMLContent from '../../../components/HTMLContent';
import { TImprintOutletContext } from '../Imprint';

const Privacy: FC = () => {
  const { siteSettings } = useOutletContext<TImprintOutletContext>();

  return (
    <Fragment>
      {siteSettings ? (
        <HTMLContent content={siteSettings.privacy_information} />
      ) : (
        <iframe
          src="/imprint/datenschutz.html"
          frameBorder="0"
          style={{ overflow: 'hidden', height: '50vh', width: '100%' }}
        />
      )}
    </Fragment>
  );
};

export default Privacy;
