import { DialogContentText } from '@mui/material';
import { FC } from 'react';
import {
  BACK_BTN_TEXT,
  CONFIRM_BTN_TEXT,
  PARTICIPATION_CONFIRMATION_TITLE
} from '../../constants/strings';
import AbstractDialog from '../dialogs/AbstractDialog';

interface ParticipationConfirmationDailogProps {
  happeningTitle: string;
  open: boolean;
  onClose: () => void;
  onAction: () => void;
  isEvent: boolean;
}

const ParticipationConfirmationDailog: FC<
  ParticipationConfirmationDailogProps
> = ({ happeningTitle, open, onClose, onAction, isEvent }) => {
  return (
    <AbstractDialog
      open={open}
      title={
        isEvent ? 'Veranstaltung merken' : PARTICIPATION_CONFIRMATION_TITLE
      }
      btnActionText={CONFIRM_BTN_TEXT}
      btnCancelText={BACK_BTN_TEXT}
      hideCancelBtn={false}
      onClose={onClose}
      onAction={onAction}
    >
      <DialogContentText id="alert-dialog-description">
        {isEvent
          ? `Wollen Sie die Veranstaltung ${happeningTitle} merken?`
          : `Wollen Sie an ${happeningTitle} teilnehmen?`}
      </DialogContentText>
    </AbstractDialog>
  );
};

export default ParticipationConfirmationDailog;
