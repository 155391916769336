import { Breadcrumbs, Link, Stack, Typography } from '@mui/material';
import {
  ClientPostGeoAreaMapping,
  GetPostsPostTypeEnum
} from 'digital-vital-backend-api';
import { FC, useCallback, useEffect, useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import Notice from '../../../assets/svgs/notice.svg';
import NoticeList from '../../../components/notice/NoticeList';
import {
  HOME_TILE_PINNWAND,
  NOTICE_TAB_3_TEXT
} from '../../../constants/strings';
import usePostApi from '../../../hooks/usePostApi';
const WantedNotices: FC = () => {
  const postApi = usePostApi();
  const [wantedNotices, setWantedNotices] = useState<
    Array<ClientPostGeoAreaMapping>
  >([]);

  const loadWantedNotices = useCallback(() => {
    const loadWantedNoticesAsync = async () => {
      const allPosts = await postApi.getPosts(GetPostsPostTypeEnum.Seeking);
      setWantedNotices(allPosts);
    };
    loadWantedNoticesAsync();
  }, [postApi]);

  useEffect(() => {
    loadWantedNotices();
    return () => {
      setWantedNotices([]);
    };
  }, [loadWantedNotices]);
  return (
    <Stack spacing={2}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          color="text.primary"
          variant="h3"
          to="/"
          component={ReactRouterLink}
        >
          {HOME_TILE_PINNWAND}
        </Link>
        <Typography color="text.primary" variant="h3">
          {NOTICE_TAB_3_TEXT}
        </Typography>
      </Breadcrumbs>
      <NoticeList postGeoAreaMappings={wantedNotices} defaultImage={Notice} />
    </Stack>
  );
};

export default WantedNotices;
