import { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { CalendarProps } from '../interfaces/props';
import EventIcon from '@mui/icons-material/Event';
import {
  CALENDAR_NACHBARSCHAFTSTISCH,
  CALENDAR_TODAYS_DATE,
  CALENDAR_VERANSTALTUNG
} from '../constants/strings';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { styled } from '@mui/material';
import {
  ClientHappening,
  ClientHappeningGeoAreaMapping,
  ClientHappeningHappeningTypeEnum
} from 'digital-vital-backend-api';
import { HappeningForCalendar } from '../types/happeningForCalendar';
import {
  NACHBARSCHAFTSTISCHE_PRIMARY,
  VERANSTALTUNGEN_PRIMARY
} from '../themes/colors/globalColors';

const dateHeaderDivStyle = {
  display: 'flex',
  flexWrap: 'nowrap',
  justifyContent: 'center',
  lineHeight: '1'
} as const;

const dateLegendsParentDivStyle = {
  justifyContent: 'flex-start',
  paddingTop: '10px',
  lineHeight: 'inherit'
} as const;
const dateLegendMarker = {
  width: '1.3rem',
  height: '1.3rem',
  borderRadius: '50%',
  marginRight: '3px'
} as const;

const Calendar: FC<CalendarProps> = (props: CalendarProps) => {
  const {
    calendarHeaderTextColor,
    calendarLegendEventColor,
    calendarLegendTableColor,
    calendarPrimaryColor,
    calendarHappeningData
  } = props;

  const StyledSpan = styled('span')(() => ({
    color: calendarHeaderTextColor
  }));

  const DateHeaderDiv = styled('div')(() => ({
    color: calendarPrimaryColor,
    ...dateHeaderDivStyle
  }));
  const [todayDate, setTodayDate] = useState(0);
  const [currentMonth, setCurrentMonth] = useState('');
  useEffect(() => {
    const date = new Date();
    setTodayDate(date.getDate());
    setCurrentMonth(date.toLocaleString('de-DE', { month: 'long' }));
  }, [setTodayDate, setCurrentMonth]);
  const [happeningCalendarData, setHappeningCalendarData] = useState<
    Array<HappeningForCalendar>
  >([]);

  const convertHappenings = useCallback(
    (tableOrEvents: Array<ClientHappening>) => {
      return tableOrEvents.map((happening) => ({
        title: happening.title,
        url:
          happening.happeningType ===
          ClientHappeningHappeningTypeEnum.NeighborhoodTable
            ? '/nachbarschaftstische/nearbytisch/' + happening.id
            : '/veranstaltungen/nearbyevents/' + happening.id,
        start: happening.startTime,
        color:
          happening.happeningType ===
          ClientHappeningHappeningTypeEnum.NeighborhoodTable
            ? NACHBARSCHAFTSTISCHE_PRIMARY
            : VERANSTALTUNGEN_PRIMARY,
        allDay: true
      }));
    },
    []
  );

  const setupCalendarData = useCallback(
    (tablesAndEventsResponse: Array<ClientHappeningGeoAreaMapping>) => {
      const clientHappenings = tablesAndEventsResponse.flatMap(
        (clientHappeningGeoAreaMapping) =>
          clientHappeningGeoAreaMapping.happenings
      );
      const happenings: Array<HappeningForCalendar> =
        convertHappenings(clientHappenings);
      setHappeningCalendarData(happenings);
    },
    [convertHappenings]
  );

  useEffect(() => {
    setupCalendarData(calendarHappeningData);
  }, [setupCalendarData, calendarHappeningData]);

  return (
    <Fragment>
      <div
        style={{
          color: calendarHeaderTextColor,
          ...dateHeaderDivStyle
        }}
      >
        <div>
          <EventIcon fontSize={'large'} />
        </div>
        <div>
          <h4 style={{ margin: '10px 5px 5px 5px' }}>{CALENDAR_TODAYS_DATE}</h4>
        </div>
      </div>
      <DateHeaderDiv>
        <div style={{ maxHeight: '75px' }}>
          <h1 style={{ margin: '0px', fontWeight: 'bold', fontSize: '70px' }}>
            {todayDate}
          </h1>
        </div>
      </DateHeaderDiv>
      <DateHeaderDiv>
        <div>
          <span style={{ margin: '0px', fontWeight: 'bold', fontSize: '30px' }}>
            {currentMonth}
          </span>
        </div>
      </DateHeaderDiv>
      <div className="calendar-wrapper">
        <FullCalendar
          themeSystem="Lux"
          fixedWeekCount={false}
          locale="de"
          headerToolbar={{
            left: 'prev',
            center: 'title',
            right: 'next'
          }}
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          aspectRatio={1.7}
          height={'auto'}
          events={happeningCalendarData}
        />
      </div>
      <div
        style={{
          ...dateHeaderDivStyle,
          ...dateLegendsParentDivStyle
        }}
        className="date-legends-parent"
      >
        <div
          style={{
            ...dateHeaderDivStyle,
            justifyContent: 'flex-start',
            lineHeight: 'inherit',
            marginRight: '5px'
          }}
        >
          <div
            style={{
              backgroundColor: calendarLegendTableColor,
              ...dateLegendMarker
            }}
          ></div>
          <div>
            <StyledSpan>{CALENDAR_NACHBARSCHAFTSTISCH}</StyledSpan>
          </div>
        </div>
        <div
          style={{
            ...dateHeaderDivStyle,
            justifyContent: 'flex-start',
            lineHeight: 'inherit',
            marginRight: '5px'
          }}
        >
          <div
            style={{
              backgroundColor: calendarLegendEventColor,
              ...dateLegendMarker
            }}
          ></div>
          <div>
            <StyledSpan>{CALENDAR_VERANSTALTUNG}</StyledSpan>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Calendar;
