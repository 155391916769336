"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeoAreaApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class GeoAreaApi extends runtime.BaseAPI {
    /**
     * Returns the default geo area of a portal variant. Optionally, the children of the geo area can be also returned
     * Returns the default geo area of a portal variant
     */
    getDefaultGeoAreaOfPortalVariantRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.includeChildren === null || requestParameters.includeChildren === undefined) {
                throw new runtime.RequiredError('includeChildren', 'Required parameter requestParameters.includeChildren was null or undefined when calling getDefaultGeoAreaOfPortalVariant.');
            }
            const queryParameters = {};
            if (requestParameters.includeChildren !== undefined) {
                queryParameters['includeChildren'] = requestParameters.includeChildren;
            }
            if (requestParameters.includeGeoJson !== undefined) {
                queryParameters['includeGeoJson'] = requestParameters.includeGeoJson;
            }
            const headerParameters = {};
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            const response = yield this.request({
                path: `/geo-areas/portal-variant`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ClientGeoAreaExtendedFromJSON(jsonValue));
        });
    }
    /**
     * Returns the default geo area of a portal variant. Optionally, the children of the geo area can be also returned
     * Returns the default geo area of a portal variant
     */
    getDefaultGeoAreaOfPortalVariant(includeChildren, includeGeoJson, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getDefaultGeoAreaOfPortalVariantRaw({ includeChildren: includeChildren, includeGeoJson: includeGeoJson, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Returns the geo area with the given id. Optionally, the children of the geo area can be also returned
     * Returns a geo area by id
     */
    getGeoAreaByIdRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.geoAreaId === null || requestParameters.geoAreaId === undefined) {
                throw new runtime.RequiredError('geoAreaId', 'Required parameter requestParameters.geoAreaId was null or undefined when calling getGeoAreaById.');
            }
            if (requestParameters.includeChildren === null || requestParameters.includeChildren === undefined) {
                throw new runtime.RequiredError('includeChildren', 'Required parameter requestParameters.includeChildren was null or undefined when calling getGeoAreaById.');
            }
            const queryParameters = {};
            if (requestParameters.includeChildren !== undefined) {
                queryParameters['includeChildren'] = requestParameters.includeChildren;
            }
            if (requestParameters.includeGeoJson !== undefined) {
                queryParameters['includeGeoJson'] = requestParameters.includeGeoJson;
            }
            const headerParameters = {};
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            const response = yield this.request({
                path: `/geo-areas/{geoAreaId}`.replace(`{${"geoAreaId"}}`, encodeURIComponent(String(requestParameters.geoAreaId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ClientGeoAreaExtendedFromJSON(jsonValue));
        });
    }
    /**
     * Returns the geo area with the given id. Optionally, the children of the geo area can be also returned
     * Returns a geo area by id
     */
    getGeoAreaById(geoAreaId, includeChildren, includeGeoJson, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getGeoAreaByIdRaw({ geoAreaId: geoAreaId, includeChildren: includeChildren, includeGeoJson: includeGeoJson, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Searches in all geo areas, including the parents. The search considers the name and/or the zip codes of the geo area. For all alpha search terms a name search is done. For the first numeric search term (it might also contain spaces) a zip search is conducted. Every search term is connected with \'and\'. For the name search a normalization is done for both the search term and the names of the geo areas: - No umlauts and ß (äöüß → aous) - No double letters (Be**ll**ingen → Be**l**ingen, Zell am See → Zel am Se) - No abbreviated titles (\', St\', \', M\', \'(VGem)\', ...) - No non-alpha characters (\'(\', \'-\', ...)  The resulting words are matched with \'starts with\', ignoring their order, but all search terms have to match (\'Frankfurt am Main\' is matched by \'Main Fra\' but not by \'Frankfurt an der Oder\').  Cuts the tree from the top using the parameter `upToDepth`. If `includeAllChildrenOfMatchingAreas` is true all children of a matching area are added.
     * Searches in all geo areas
     */
    searchGeoAreasRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.search === null || requestParameters.search === undefined) {
                throw new runtime.RequiredError('search', 'Required parameter requestParameters.search was null or undefined when calling searchGeoAreas.');
            }
            const queryParameters = {};
            if (requestParameters.upToDepth !== undefined) {
                queryParameters['upToDepth'] = requestParameters.upToDepth;
            }
            if (requestParameters.includeAllChildrenOfMatchingAreas !== undefined) {
                queryParameters['includeAllChildrenOfMatchingAreas'] = requestParameters.includeAllChildrenOfMatchingAreas;
            }
            if (requestParameters.search !== undefined) {
                queryParameters['search'] = requestParameters.search;
            }
            const headerParameters = {};
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            const response = yield this.request({
                path: `/geo-areas/search`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.ClientGeoAreaExtendedFromJSON));
        });
    }
    /**
     * Searches in all geo areas, including the parents. The search considers the name and/or the zip codes of the geo area. For all alpha search terms a name search is done. For the first numeric search term (it might also contain spaces) a zip search is conducted. Every search term is connected with \'and\'. For the name search a normalization is done for both the search term and the names of the geo areas: - No umlauts and ß (äöüß → aous) - No double letters (Be**ll**ingen → Be**l**ingen, Zell am See → Zel am Se) - No abbreviated titles (\', St\', \', M\', \'(VGem)\', ...) - No non-alpha characters (\'(\', \'-\', ...)  The resulting words are matched with \'starts with\', ignoring their order, but all search terms have to match (\'Frankfurt am Main\' is matched by \'Main Fra\' but not by \'Frankfurt an der Oder\').  Cuts the tree from the top using the parameter `upToDepth`. If `includeAllChildrenOfMatchingAreas` is true all children of a matching area are added.
     * Searches in all geo areas
     */
    searchGeoAreas(search, upToDepth, includeAllChildrenOfMatchingAreas, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.searchGeoAreasRaw({ search: search, upToDepth: upToDepth, includeAllChildrenOfMatchingAreas: includeAllChildrenOfMatchingAreas, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
}
exports.GeoAreaApi = GeoAreaApi;
