"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientCommentToJSON = exports.ClientCommentFromJSONTyped = exports.ClientCommentFromJSON = void 0;
const runtime_1 = require("../runtime");
const ClientUser_1 = require("./ClientUser");
function ClientCommentFromJSON(json) {
    return ClientCommentFromJSONTyped(json, false);
}
exports.ClientCommentFromJSON = ClientCommentFromJSON;
function ClientCommentFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'postId': !runtime_1.exists(json, 'postId') ? undefined : json['postId'],
        'text': !runtime_1.exists(json, 'text') ? undefined : json['text'],
        'author': !runtime_1.exists(json, 'author') ? undefined : ClientUser_1.ClientUserFromJSON(json['author']),
        'created': !runtime_1.exists(json, 'created') ? undefined : json['created'],
    };
}
exports.ClientCommentFromJSONTyped = ClientCommentFromJSONTyped;
function ClientCommentToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'postId': value.postId,
        'text': value.text,
        'author': ClientUser_1.ClientUserToJSON(value.author),
        'created': value.created,
    };
}
exports.ClientCommentToJSON = ClientCommentToJSON;
