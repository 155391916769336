import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';

const SettingsCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(1),
  minHeight: '12rem',
  background: theme.palette.primary.main
}));

export default SettingsCard;
