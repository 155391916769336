import EmailIcon from '@mui/icons-material/Email';
import { Button } from '@mui/material';
import { CSSProperties, FC, useCallback } from 'react';
import { CONTACT_ORGANIZER_BTN_TEXT } from '../constants/strings';
import RenderOnAuthenticated from './RenderOnAuthenticated';

const useStyles = (): Record<string, CSSProperties> => ({
  buttonStyle: {
    position: 'absolute',
    right: '2%',
    textTransform: 'uppercase'
  }
});

type DetailsContactButtonProps = {
  onContactClick: () => void;
  bottomPosition?: string;
};

const DetailsContactButton: FC<DetailsContactButtonProps> = (
  props: DetailsContactButtonProps
) => {
  const { onContactClick, bottomPosition = '45%' } = props;
  const styles = useStyles();

  const handleContactClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      onContactClick();
    },
    [onContactClick]
  );

  return (
    <RenderOnAuthenticated>
      <Button
        variant="contained"
        onClick={handleContactClick}
        endIcon={<EmailIcon />}
        sx={{ ...styles.buttonStyle, bottom: bottomPosition }}
      >
        {CONTACT_ORGANIZER_BTN_TEXT}
      </Button>
    </RenderOnAuthenticated>
  );
};

export default DetailsContactButton;
