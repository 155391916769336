import {
  ClientHappeningExtendedHappeningTypeEnum,
  GetAllHappeningsByOwnParticipationHappeningTypeEnum,
  GetAllHappeningsHappeningTypeEnum,
  GetOldHappeningsHappeningTypeEnum,
  GetOwnHappeningsHappeningTypeEnum
} from 'digital-vital-backend-api';
import { HappeningType } from '../types/happening';

export default class HappeningTypeConverter {
  public static toHappeningType(
    happeningType: ClientHappeningExtendedHappeningTypeEnum
  ): HappeningType {
    switch (happeningType) {
      case ClientHappeningExtendedHappeningTypeEnum.NeighborhoodTable:
        return HappeningType.NeighborhoodTable;
      case ClientHappeningExtendedHappeningTypeEnum.Event:
        return HappeningType.Event;
    }
  }

  public static toGetAllHappeningsHappeningTypeEnum(
    happeningType?: HappeningType
  ): GetAllHappeningsHappeningTypeEnum | undefined {
    switch (happeningType) {
      case HappeningType.NeighborhoodTable:
        return GetAllHappeningsHappeningTypeEnum.NeighborhoodTable;
      case HappeningType.Event:
        return GetAllHappeningsHappeningTypeEnum.Event;
      default:
        return undefined;
    }
  }

  public static toGetAllHappeningsByOwnParticipationHappeningTypeEnum(
    happeningType?: HappeningType
  ): GetAllHappeningsByOwnParticipationHappeningTypeEnum | undefined {
    switch (happeningType) {
      case HappeningType.NeighborhoodTable:
        return GetAllHappeningsByOwnParticipationHappeningTypeEnum.NeighborhoodTable;
      case HappeningType.Event:
        return GetAllHappeningsByOwnParticipationHappeningTypeEnum.CalendarEvent;
      default:
        return undefined;
    }
  }

  public static toGetOwnHappeningsHappeningTypeEnum(
    happeningType?: HappeningType
  ): GetOwnHappeningsHappeningTypeEnum | undefined {
    switch (happeningType) {
      case HappeningType.NeighborhoodTable:
        return GetOwnHappeningsHappeningTypeEnum.NeighborhoodTable;
      case HappeningType.Event:
        return GetOwnHappeningsHappeningTypeEnum.Event;
      default:
        return undefined;
    }
  }

  public static toGetOldHappeningsHappeningTypeEnum(
    happeningType?: HappeningType
  ): GetOldHappeningsHappeningTypeEnum | undefined {
    switch (happeningType) {
      case HappeningType.NeighborhoodTable:
        return GetOldHappeningsHappeningTypeEnum.NeighborhoodTable;
      case HappeningType.Event:
        return GetOldHappeningsHappeningTypeEnum.Event;
      default:
        return undefined;
    }
  }
}
