import { Container, ThemeProvider } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { fetchNewsArticles } from '../../api/InformationAPI';
import Loader from '../../components/Loader';
import newsTheme from '../../themes/newsTheme';
import { TNewsArticle } from '../../types/information';

export type TNewsOutletContext = {
  newsArticles: Array<TNewsArticle>;
};

const News: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [newsArticles, setNewsArticles] = useState<Array<TNewsArticle>>([]);

  useEffect(() => {
    const loadNewsArticles = async () => {
      setLoading(true);
      const articles = await fetchNewsArticles();
      setNewsArticles(articles);
      setLoading(false);
    };
    loadNewsArticles();
  }, []);

  return (
    <ThemeProvider theme={newsTheme}>
      <Container sx={{ marginTop: '1em', marginBottom: '1em' }}>
        {loading ? <Loader /> : <Outlet context={{ newsArticles }} />}
      </Container>
    </ThemeProvider>
  );
};

export default News;
