import RestartAltIcon from '@mui/icons-material/RestartAlt';
import TextDecreaseIcon from '@mui/icons-material/TextDecrease';
import TextIncreaseIcon from '@mui/icons-material/TextIncrease';
import {
  Box,
  ButtonGroup,
  Container,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { FONT_SIZE_SETTINGS } from '../../../constants/strings';
import LocalSettingsService, {
  FONT_SIZE_KEY
} from '../../../services/LocalSettingsService';

const MINIMUM_FONT_SIZE = 16;
const DEFAULT_FONT_SIZE = 16;
const MAXIMUM_FONT_SIZE = 24;

const FontSizeIconButton = styled(IconButton)(() => ({
  margin: '0.5rem',
  padding: '1rem',
  borderRadius: 0
}));

const FontSize: FC = () => {
  const [fontSize, setFontSize] = useState<number>();

  const handleDecrease = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setFontSize((f) => {
        const size = f ?? DEFAULT_FONT_SIZE;
        return Math.max(size - 2, MINIMUM_FONT_SIZE);
      });
    },
    []
  );

  const handleReset = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setFontSize(DEFAULT_FONT_SIZE);
    },
    []
  );

  const handleIncrease = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setFontSize((f) => {
        const size = f ?? DEFAULT_FONT_SIZE;
        return Math.min(size + 2, MAXIMUM_FONT_SIZE);
      });
    },
    []
  );

  useEffect(() => {
    if (!fontSize) {
      return;
    }
    const rootElement = document.documentElement;
    rootElement.style.fontSize = `${fontSize}px`;
  }, [fontSize]);

  useEffect(() => {
    if (!fontSize) {
      return;
    }
    LocalSettingsService.saveLocalSetting(FONT_SIZE_KEY, fontSize.toString());
  }, [fontSize]);

  useEffect(() => {
    const value = LocalSettingsService.getLocalSetting(FONT_SIZE_KEY);
    if (value) {
      setFontSize(parseInt(value));
    } else {
      setFontSize(DEFAULT_FONT_SIZE);
    }
  }, []);

  return (
    <Container sx={{ paddingTop: '16px' }}>
      <Typography variant="h2" gutterBottom={true}>
        {FONT_SIZE_SETTINGS}
      </Typography>
      <Box display="flex" justifyContent="center">
        <ButtonGroup size="large">
          <Tooltip title="verkleinern" arrow>
            <FontSizeIconButton
              aria-label="decrease font size"
              size="large"
              color="primary"
              onClick={handleDecrease}
            >
              <TextDecreaseIcon />
            </FontSizeIconButton>
          </Tooltip>
          <Tooltip title="zurücksetzen" arrow>
            <FontSizeIconButton
              aria-label="reset font size"
              size="large"
              color="primary"
              onClick={handleReset}
            >
              <RestartAltIcon />
            </FontSizeIconButton>
          </Tooltip>
          <Tooltip title="vergrößern" arrow>
            <FontSizeIconButton
              aria-label="increase font size"
              size="large"
              color="primary"
              onClick={handleIncrease}
            >
              <TextIncreaseIcon />
            </FontSizeIconButton>
          </Tooltip>
        </ButtonGroup>
      </Box>
    </Container>
  );
};

export default FontSize;
