import { FC, Fragment } from 'react';
import { useOutletContext } from 'react-router-dom';
import HTMLContent from '../../../components/HTMLContent';
import { TImprintOutletContext } from '../Imprint';

const TermsOfUse: FC = () => {
  const { siteSettings } = useOutletContext<TImprintOutletContext>();

  return (
    <Fragment>
      {siteSettings ? (
        <HTMLContent content={siteSettings.terms_of_use} />
      ) : (
        <iframe
          src="/imprint/nutzungsbedingungen.html"
          frameBorder="0"
          style={{ overflow: 'hidden', height: '80vh', width: '100%' }}
        />
      )}
    </Fragment>
  );
};

export default TermsOfUse;
