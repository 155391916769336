/** @jsxImportSource @emotion/react */
import { FC } from 'react';
import { css } from '@emotion/react';

type HTMLContentProps = {
  content: string;
};

const styles = {
  content: css`
    img {
      max-width: 100%;
    }
  `
};

const HTMLContent: FC<HTMLContentProps> = (props) => {
  const { content } = props;
  return (
    <div css={styles.content} dangerouslySetInnerHTML={{ __html: content }} />
  );
};

export default HTMLContent;
