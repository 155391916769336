import { TImage } from '../../../types/common';

export type TState = {
  title: string;
  contentType: string;
  text: string;
  category: string;
  imageId: string | null;
  image: TImage | null;
};

export const initialState: TState = {
  title: '',
  contentType: '',
  text: '',
  category: '',
  imageId: null,
  image: null
};

export type TSetTitleAction = {
  name: 'setTitle';
  title: string;
};

export type TSetContentTypeAction = {
  name: 'setContentType';
  contentType: string;
};

export type TSetTextAction = {
  name: 'setText';
  text: string;
};

export type TSetCategoryAction = {
  name: 'setCategory';
  category: string;
};
export type TSetImageId = {
  name: 'setImageId';
  imageId: string | null;
};

export type TSetImage = {
  name: 'setImage';
  image: TImage | null;
};

export type TSetNotice = {
  name: 'setNotice';
  notice: TState;
};

export type TAction =
  | TSetTitleAction
  | TSetContentTypeAction
  | TSetTextAction
  | TSetCategoryAction
  | TSetImageId
  | TSetImage
  | TSetNotice;

export const setTitle = (title: string): TAction => {
  return { name: 'setTitle', title };
};

export const setCategory = (category: string): TAction => {
  return { name: 'setCategory', category };
};

export const setContentType = (contentType: string): TAction => {
  return { name: 'setContentType', contentType };
};

export const setText = (text: string): TAction => {
  return { name: 'setText', text };
};

export const setImageId = (imageId: string): TAction => {
  return { name: 'setImageId', imageId };
};

export const setImage = (image: TImage | null): TAction => {
  return { name: 'setImage', image };
};

export const setNotice = (notice: TState): TAction => {
  return { name: 'setNotice', notice };
};

export const reducer = (state: TState, action: TAction): TState => {
  const { name } = action;
  switch (name) {
    case 'setTitle':
      return {
        ...state,
        title: action.title
      };
    case 'setCategory':
      return {
        ...state,
        category: action.category
      };
    case 'setContentType':
      return {
        ...state,
        contentType: action.contentType
      };
    case 'setText':
      return {
        ...state,
        text: action.text
      };
    case 'setImageId':
      return {
        ...state,
        imageId: action.imageId
      };
    case 'setImage':
      return {
        ...state,
        image: action.image
      };
    case 'setNotice':
      return {
        ...state,
        ...action.notice
      };
    default:
      break;
  }
  return state;
};
