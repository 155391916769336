"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientUserUpdateConfirmationToJSON = exports.ClientUserUpdateConfirmationFromJSONTyped = exports.ClientUserUpdateConfirmationFromJSON = void 0;
const ClientUserExtended_1 = require("./ClientUserExtended");
function ClientUserUpdateConfirmationFromJSON(json) {
    return ClientUserUpdateConfirmationFromJSONTyped(json, false);
}
exports.ClientUserUpdateConfirmationFromJSON = ClientUserUpdateConfirmationFromJSON;
function ClientUserUpdateConfirmationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'user': ClientUserExtended_1.ClientUserExtendedFromJSON(json['user']),
    };
}
exports.ClientUserUpdateConfirmationFromJSONTyped = ClientUserUpdateConfirmationFromJSONTyped;
function ClientUserUpdateConfirmationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'user': ClientUserExtended_1.ClientUserExtendedToJSON(value.user),
    };
}
exports.ClientUserUpdateConfirmationToJSON = ClientUserUpdateConfirmationToJSON;
