import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import { ClientDataPrivacyRequest } from 'digital-vital-backend-api';
import { FC, useCallback, useState } from 'react';
import ConfirmationDialog from '../../../components/dialogs/ConfirmationDialog';
import InputDialog from '../../../components/dialogs/InputDialog';
import SuccessDialog from '../../../components/dialogs/SuccessDialog';
import {
  ACCOUNT_DELETION_CONFIRM_TEXT,
  ACCOUNT_DELETION_REASON_TEXT,
  ACCOUNT_DELETION_SUCCESS_TEXT,
  ACCOUNT_DELETION_TEXT,
  CONFIRM_BTN_TEXT_SHORT
} from '../../../constants/strings';
import useDataPrivacyApi from '../../../hooks/useDataPrivacyApi';

interface RequestAccountDeletionDialogProps {
  open: boolean;
  onClose: () => void;
}

const RequestAccountDeletionDialog: FC<RequestAccountDeletionDialogProps> = ({
  open,
  onClose
}) => {
  const dataPrivacyApi = useDataPrivacyApi();
  const [reason, setReason] = useState<string>('');
  const [isAccountDeletionDialogOpen, setAccountDeletionDialogOpen] =
    useState<boolean>(false);
  const [isSuccessDialogOpen, setSuccessDialogOpen] = useState<boolean>(false);

  const handleSuccessDialogClose = useCallback(() => {
    setSuccessDialogOpen(false);
  }, []);

  const handleAccountDeletionDialogClose = useCallback(() => {
    setAccountDeletionDialogOpen(false);
  }, []);

  const handleConfirmAccountDeletion = useCallback(() => {
    onClose();
    setAccountDeletionDialogOpen(true);
  }, [onClose]);

  const handleAccountDeletionRequest = useCallback(() => {
    const deleteAccountAsync = async () => {
      const clientDataPrivacyRequest: ClientDataPrivacyRequest = { reason };
      await dataPrivacyApi.deleteAccount(clientDataPrivacyRequest);
      handleAccountDeletionDialogClose();
      setSuccessDialogOpen(true);
    };
    deleteAccountAsync();
  }, [dataPrivacyApi, handleAccountDeletionDialogClose, reason]);
  return (
    <>
      <ConfirmationDialog
        message={ACCOUNT_DELETION_CONFIRM_TEXT}
        actionBtnText={CONFIRM_BTN_TEXT_SHORT}
        open={open}
        onClose={onClose}
        onAction={handleConfirmAccountDeletion}
      />
      <InputDialog
        open={isAccountDeletionDialogOpen}
        dialogIcon={
          <NoAccountsIcon color="primary" sx={{ fontSize: '64px' }} />
        }
        text={ACCOUNT_DELETION_REASON_TEXT}
        actionBtnText={ACCOUNT_DELETION_TEXT}
        onClose={handleAccountDeletionDialogClose}
        onAction={handleAccountDeletionRequest}
        isMsgRequired={false}
        input={reason}
        inputLabel="Grund"
        setInput={setReason}
      />
      <SuccessDialog
        onClose={handleSuccessDialogClose}
        message={ACCOUNT_DELETION_SUCCESS_TEXT}
        open={isSuccessDialogOpen}
      />
    </>
  );
};

export default RequestAccountDeletionDialog;
