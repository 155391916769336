// import AccessibleIcon from '@mui/icons-material/Accessible';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Link,
  Typography,
  useTheme
} from '@mui/material';
import { Theme } from '@mui/system/createTheme/createTheme';
import { ClientHappeningExtended } from 'digital-vital-backend-api';
import { CSSProperties, FC, Fragment } from 'react';
import { JOIN_URL, VERANSTALTUNG_TYPE } from '../../constants/strings';

const useStyles = (theme: Theme): Record<string, CSSProperties> => ({
  boxStyle: {
    marginLeft: '2.5rem',
    display: 'inline-flex',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  iconStyle: {
    fontSize: '48px',
    color: theme.palette.primary.main
  }
});

type HappeningAccessibilityViewProps = {
  happening: ClientHappeningExtended;
};

const HappeningUrlView: FC<HappeningAccessibilityViewProps> = (props) => {
  const { happening } = props;
  const { url } = happening;
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <Box sx={styles.boxStyle}>
      <Box sx={{ flex: '0 0 40%' }}>
        {/* <AccessibleIcon color="primary" sx={styles.iconStyle} /> */}
        <Typography align="right" component="span" variant="h4">
          {VERANSTALTUNG_TYPE}
        </Typography>
      </Box>
      <Box sx={{ flex: '1 0 60%' }}>
        <FormGroup row>
          <FormControlLabel
            control={<Checkbox defaultChecked color="primary" disabled />}
            label="Online"
            labelPlacement="end"
            sx={{ textTransform: 'capitalize' }}
          />
        </FormGroup>
      </Box>
      {url && (
        <Fragment>
          <Box sx={{ flex: '0 0 40%' }}>
            <Typography align="right" component="span" variant="h4">
              {JOIN_URL}
            </Typography>
          </Box>
          <Box sx={{ flex: '1 0 60%' }}>
            <Link href={url} target="_blank" underline="none" color="primary">
              {url}
            </Link>
          </Box>
        </Fragment>
      )}
    </Box>
  );
};

export default HappeningUrlView;
