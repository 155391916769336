"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientTemporaryMediaItemToJSON = exports.ClientTemporaryMediaItemFromJSONTyped = exports.ClientTemporaryMediaItemFromJSON = void 0;
const ClientMediaItem_1 = require("./ClientMediaItem");
function ClientTemporaryMediaItemFromJSON(json) {
    return ClientTemporaryMediaItemFromJSONTyped(json, false);
}
exports.ClientTemporaryMediaItemFromJSON = ClientTemporaryMediaItemFromJSON;
function ClientTemporaryMediaItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'mediaItem': ClientMediaItem_1.ClientMediaItemFromJSON(json['mediaItem']),
        'expirationTime': json['expirationTime'],
    };
}
exports.ClientTemporaryMediaItemFromJSONTyped = ClientTemporaryMediaItemFromJSONTyped;
function ClientTemporaryMediaItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'mediaItem': ClientMediaItem_1.ClientMediaItemToJSON(value.mediaItem),
        'expirationTime': value.expirationTime,
    };
}
exports.ClientTemporaryMediaItemToJSON = ClientTemporaryMediaItemToJSON;
