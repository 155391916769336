import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  DialogContentText,
  Typography
} from '@mui/material';
import {
  ClientComment,
  ClientCommentCreateRequest
} from 'digital-vital-backend-api';
import {
  CSSProperties,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  AUTHENTICATE_TO_COMMENT,
  COMMENTS_TEXT,
  OPEN_COMMENTS_BTN_TEXT
} from '../../constants/strings';
import useAuthContext from '../../hooks/useAuthContext';
import useCommentApi from '../../hooks/useCommentApi';
import NotAuthenticatedDialog from '../dialogs/NotAuthenticatedDialog';
import NoticeCommentInput from './NoticeCommentInput';
import NoticeCommentList from './NoticeCommentList';

interface NoticeCommentsContainerProps {
  noticeId?: string;
}
const useStyles = (): Record<string, CSSProperties> => ({
  accordionStyle: {
    backgroundColor: '#F5F5F5'
  },
  accordionSummaryStyle: {
    borderTop: 1,
    borderBottom: 1,
    minHeight: '64px'
  }
});

const NoticeCommentsContainer: FC<NoticeCommentsContainerProps> = ({
  noticeId = undefined
}) => {
  const styles = useStyles();
  const commentApi = useCommentApi();
  const { keycloak } = useAuthContext();

  const [newComment, setNewComment] = useState<string>('');
  const [comments, setComments] = useState<Array<ClientComment>>([]);
  const commentsCount = useMemo(() => comments.length, [comments]);
  const [isCommentsOpen, setCommentsOpen] = useState<boolean>(false);
  const [isAuthenticateDialogOpen, setAuthenticateDialogOpen] =
    useState<boolean>(false);

  const handleAuthenticateDialogClose = useCallback(() => {
    setAuthenticateDialogOpen(false);
  }, []);

  const handleCommentInputChange = useCallback(
    (e) => setNewComment(e.target.value),
    [setNewComment]
  );

  const toggleCommentsSection = useCallback(() => {
    setCommentsOpen((prev) => !prev);
  }, [setCommentsOpen]);

  const loadNoticeComments = useCallback(() => {
    const loadNoticeCommentsAsync = async () => {
      if (!noticeId) {
        return;
      }
      try {
        const loadedComments = await commentApi.getCommentsByPostId(noticeId);
        setComments(loadedComments);
        setCommentsOpen(loadedComments.length > 0);
      } catch (error) {
        // handle Error
      }
    };
    loadNoticeCommentsAsync();
  }, [commentApi, noticeId]);

  const loadNoticeCommentById = useCallback(
    (id: string) => {
      const loadNoticeCommetAsync = async () => {
        if (!id) {
          return;
        }
        try {
          const loadedComment = await commentApi.getCommentById(id);
          setComments((prev) => {
            const newcomments = [...prev];
            newcomments.push(loadedComment);
            return newcomments;
          });
        } catch (error) {
          // handle Error
        }
      };
      loadNoticeCommetAsync();
    },
    [commentApi]
  );

  const handleCommentCreation = useCallback(() => {
    const postCommentOnNoticeAsync = async () => {
      if (!noticeId || newComment === '') {
        return;
      }
      if (!keycloak.authenticated) {
        setAuthenticateDialogOpen(true);
        return;
      }
      try {
        const clientCommentCreateRequest: ClientCommentCreateRequest = {
          postId: noticeId,
          text: newComment
        };
        setNewComment('');
        const postedComment = await commentApi.createComment(
          clientCommentCreateRequest
        );
        if (postedComment.id) loadNoticeCommentById(postedComment.id);
      } catch (error) {
        // handle Error
      }
    };
    postCommentOnNoticeAsync();
  }, [
    commentApi,
    keycloak.authenticated,
    loadNoticeCommentById,
    newComment,
    noticeId
  ]);

  useEffect(() => {
    loadNoticeComments();
  }, [loadNoticeComments]);
  return (
    <>
      <Accordion sx={styles.accordionStyle} expanded={isCommentsOpen}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            '& .MuiAccordionSummary-content': {
              alignItems: 'center',
              justifyContent: 'space-between'
            },
            ...styles.accordionSummaryStyle
          }}
          onClick={toggleCommentsSection}
        >
          <Typography>{`${commentsCount} ${COMMENTS_TEXT}`}</Typography>
          <Button variant="contained">{OPEN_COMMENTS_BTN_TEXT}</Button>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <NoticeCommentList
            comments={comments}
            commentsCount={commentsCount}
          />
          <NoticeCommentInput
            value={newComment}
            handleChange={handleCommentInputChange}
            createComment={handleCommentCreation}
          />
        </AccordionDetails>
      </Accordion>
      <NotAuthenticatedDialog
        open={isAuthenticateDialogOpen}
        onClose={handleAuthenticateDialogClose}
      >
        <DialogContentText id="alert-dialog-description">
          {AUTHENTICATE_TO_COMMENT}
        </DialogContentText>
      </NotAuthenticatedDialog>
    </>
  );
};

export default NoticeCommentsContainer;
