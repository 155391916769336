import EmailIcon from '@mui/icons-material/Email';
import { TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useCallback, useMemo } from 'react';
import { CANCEL_BTN_TEXT } from '../../constants/strings';
import { isInRange } from '../../utils/common';
import AbstractDialog from './AbstractDialog';

type InputDialogProps = {
  open: boolean;
  dialogIcon?: JSX.Element;
  isMsgRequired?: boolean;
  text: string;
  actionBtnText: string;
  onClose: () => void;
  onAction: () => void;
  input: string;
  inputLabel: string;
  setInput: (input: string) => void;
};

const InputDialog: FC<InputDialogProps> = ({
  open,
  input,
  inputLabel,
  setInput,
  dialogIcon = <EmailIcon color="primary" sx={{ fontSize: '64px' }} />,
  text,
  actionBtnText,
  isMsgRequired = true,
  onClose,
  onAction
}) => {
  const handleMessageChange = useCallback(
    (e) => setInput(e.target.value),
    [setInput]
  );

  const enableSend = useMemo(
    () => isInRange(10, 500, input) || !isMsgRequired,
    [isMsgRequired, input]
  );

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);
  return (
    <AbstractDialog
      icon={dialogIcon}
      open={open}
      btnActionText={actionBtnText}
      btnCancelText={CANCEL_BTN_TEXT}
      hideCancelBtn={false}
      onClose={handleClose}
      onAction={onAction}
      maxWidth="none"
      isActionDisabled={!enableSend}
    >
      <Box sx={{ mb: 2 }}>
        <Typography gutterBottom={true}>{text}</Typography>
        <TextField
          multiline
          rows={4}
          variant="outlined"
          placeholder="Hier tippen…"
          label={inputLabel}
          fullWidth
          value={input}
          onChange={handleMessageChange}
        />
      </Box>
    </AbstractDialog>
  );
};

export default InputDialog;
