"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientHappeningUpdateConfirmationToJSON = exports.ClientHappeningUpdateConfirmationFromJSONTyped = exports.ClientHappeningUpdateConfirmationFromJSON = exports.ClientHappeningUpdateConfirmationHappeningTypeEnum = void 0;
const runtime_1 = require("../runtime");
const ClientMediaItem_1 = require("./ClientMediaItem");
/**
* @export
* @enum {string}
*/
var ClientHappeningUpdateConfirmationHappeningTypeEnum;
(function (ClientHappeningUpdateConfirmationHappeningTypeEnum) {
    ClientHappeningUpdateConfirmationHappeningTypeEnum["NeighborhoodTable"] = "NeighborhoodTable";
    ClientHappeningUpdateConfirmationHappeningTypeEnum["CalendarEvent"] = "CalendarEvent";
})(ClientHappeningUpdateConfirmationHappeningTypeEnum = exports.ClientHappeningUpdateConfirmationHappeningTypeEnum || (exports.ClientHappeningUpdateConfirmationHappeningTypeEnum = {}));
function ClientHappeningUpdateConfirmationFromJSON(json) {
    return ClientHappeningUpdateConfirmationFromJSONTyped(json, false);
}
exports.ClientHappeningUpdateConfirmationFromJSON = ClientHappeningUpdateConfirmationFromJSON;
function ClientHappeningUpdateConfirmationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'description': json['description'],
        'title': json['title'],
        'startTime': !runtime_1.exists(json, 'startTime') ? undefined : json['startTime'],
        'endTime': !runtime_1.exists(json, 'endTime') ? undefined : json['endTime'],
        'maxParticipants': !runtime_1.exists(json, 'maxParticipants') ? undefined : json['maxParticipants'],
        'accessibleDescription': !runtime_1.exists(json, 'accessibleDescription') ? undefined : json['accessibleDescription'],
        'cost': json['cost'],
        'happeningType': json['happeningType'],
        'street': json['street'],
        'zipCode': json['zipCode'],
        'city': json['city'],
        'telephone': !runtime_1.exists(json, 'telephone') ? undefined : json['telephone'],
        'image': !runtime_1.exists(json, 'image') ? undefined : ClientMediaItem_1.ClientMediaItemFromJSON(json['image']),
    };
}
exports.ClientHappeningUpdateConfirmationFromJSONTyped = ClientHappeningUpdateConfirmationFromJSONTyped;
function ClientHappeningUpdateConfirmationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'description': value.description,
        'title': value.title,
        'startTime': value.startTime,
        'endTime': value.endTime,
        'maxParticipants': value.maxParticipants,
        'accessibleDescription': value.accessibleDescription,
        'cost': value.cost,
        'happeningType': value.happeningType,
        'street': value.street,
        'zipCode': value.zipCode,
        'city': value.city,
        'telephone': value.telephone,
        'image': ClientMediaItem_1.ClientMediaItemToJSON(value.image),
    };
}
exports.ClientHappeningUpdateConfirmationToJSON = ClientHappeningUpdateConfirmationToJSON;
