import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import {
  ClientPost,
  ClientPostGeoAreaMapping
} from 'digital-vital-backend-api';
import { FC } from 'react';
import LocationTag from '../LocationTag';
import NoticeItem from './NoticeItem';

export type NoticeListProps = {
  postGeoAreaMappings: Array<ClientPostGeoAreaMapping>;
  defaultImage: string;
};

const NoticeList: FC<NoticeListProps> = ({
  postGeoAreaMappings,
  defaultImage
}) => {
  return (
    <>
      {postGeoAreaMappings.map(
        (postGeoAreaMapping: ClientPostGeoAreaMapping) => {
          const { geoArea, posts } = postGeoAreaMapping;
          return (
            <Box sx={{ mb: 2 }} key={geoArea?.id}>
              <LocationTag locationName={geoArea?.name ?? ''} />
              <Stack spacing={1}>
                {posts?.map((post: ClientPost) => {
                  return (
                    <NoticeItem
                      key={post.id}
                      defaultImage={defaultImage}
                      notice={post}
                    />
                  );
                })}
              </Stack>
            </Box>
          );
        }
      )}
    </>
  );
};

export default NoticeList;
