"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientNeighborhoodTableCreateRequestToJSON = exports.ClientNeighborhoodTableCreateRequestFromJSONTyped = exports.ClientNeighborhoodTableCreateRequestFromJSON = exports.ClientNeighborhoodTableCreateRequestMealTypeEnum = void 0;
const runtime_1 = require("../runtime");
/**
* @export
* @enum {string}
*/
var ClientNeighborhoodTableCreateRequestMealTypeEnum;
(function (ClientNeighborhoodTableCreateRequestMealTypeEnum) {
    ClientNeighborhoodTableCreateRequestMealTypeEnum["Classic"] = "CLASSIC";
    ClientNeighborhoodTableCreateRequestMealTypeEnum["Vegan"] = "VEGAN";
    ClientNeighborhoodTableCreateRequestMealTypeEnum["Vegetarian"] = "VEGETARIAN";
})(ClientNeighborhoodTableCreateRequestMealTypeEnum = exports.ClientNeighborhoodTableCreateRequestMealTypeEnum || (exports.ClientNeighborhoodTableCreateRequestMealTypeEnum = {}));
function ClientNeighborhoodTableCreateRequestFromJSON(json) {
    return ClientNeighborhoodTableCreateRequestFromJSONTyped(json, false);
}
exports.ClientNeighborhoodTableCreateRequestFromJSON = ClientNeighborhoodTableCreateRequestFromJSON;
function ClientNeighborhoodTableCreateRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'description': json['description'],
        'title': json['title'],
        'startTime': json['startTime'],
        'endTime': json['endTime'],
        'maxParticipants': json['maxParticipants'],
        'accessibleDescription': json['accessibleDescription'],
        'cost': json['cost'],
        'street': json['street'],
        'zipCode': json['zipCode'],
        'city': json['city'],
        'telephone': !runtime_1.exists(json, 'telephone') ? undefined : json['telephone'],
        'temporaryMediaItemId': !runtime_1.exists(json, 'temporaryMediaItemId') ? undefined : json['temporaryMediaItemId'],
        'mealType': json['mealType'],
    };
}
exports.ClientNeighborhoodTableCreateRequestFromJSONTyped = ClientNeighborhoodTableCreateRequestFromJSONTyped;
function ClientNeighborhoodTableCreateRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'description': value.description,
        'title': value.title,
        'startTime': value.startTime,
        'endTime': value.endTime,
        'maxParticipants': value.maxParticipants,
        'accessibleDescription': value.accessibleDescription,
        'cost': value.cost,
        'street': value.street,
        'zipCode': value.zipCode,
        'city': value.city,
        'telephone': value.telephone,
        'temporaryMediaItemId': value.temporaryMediaItemId,
        'mealType': value.mealType,
    };
}
exports.ClientNeighborhoodTableCreateRequestToJSON = ClientNeighborhoodTableCreateRequestToJSON;
