import { Typography } from '@mui/material';
import { FC } from 'react';
import {
  CANCEL_BTN_TEXT,
  DELETE_CONFIRM_BTN_TEXT,
  NOTICE_DELETE_CONFIRMATION
} from '../../constants/strings';
import AbstractDialog from '../dialogs/AbstractDialog';

type DeleteNoticeConfirmationDialogPropsProps = {
  open: boolean;
  onClose: () => void;
  onAction: () => void;
};

const DeleteNoticeConfirmationDialog: FC<
  DeleteNoticeConfirmationDialogPropsProps
> = ({ open, onClose, onAction }) => {
  return (
    <AbstractDialog
      open={open}
      btnCancelText={CANCEL_BTN_TEXT}
      btnActionText={DELETE_CONFIRM_BTN_TEXT}
      onClose={onClose}
      onAction={onAction}
    >
      <Typography align="center">{NOTICE_DELETE_CONFIRMATION}</Typography>
    </AbstractDialog>
  );
};

export default DeleteNoticeConfirmationDialog;
