import axios from 'axios';
import { TSiteSettings } from '../types/settings';
import { DirectusResponse, DirecutsRequestConfig } from './DirectusAPI';

function createSiteUrlFilter(url: string) {
  return {
    site_url: {
      _eq: url
    }
  };
}

export async function fetchSiteSettings(): Promise<TSiteSettings | null> {
  const currentUrl = window.location.host;
  const filter = createSiteUrlFilter(currentUrl);
  const response = await axios.get<DirectusResponse<TSiteSettings>>(
    '/items/site_settings?filter=' + encodeURIComponent(JSON.stringify(filter)),
    DirecutsRequestConfig
  );
  const data = response.data.data;
  if (data.length === 0) {
    return null;
  }
  return data[0];
}
