import { Breadcrumbs, Link, Typography } from '@mui/material';
import {
  ClientPostCreateRequest,
  ClientPostCreateRequestCategoryEnum,
  ClientPostCreateRequestContentTypeEnum,
  ClientPostUpdateRequest,
  ClientPostUpdateRequestCategoryEnum,
  ClientPostUpdateRequestPostTypeEnum
} from 'digital-vital-backend-api';
import {
  FC,
  Reducer,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState
} from 'react';
import {
  Link as ReactRouterLink,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import { BackendApiError } from '../../../api/BackendAPI';
import Notice from '../../../assets/svgs/notice.svg';
import ConfirmationDialog from '../../../components/dialogs/ConfirmationDialog';
import CreateNoticeView from '../../../components/notice/CreateNoticeView';
import ProtectedPage from '../../../components/ProtectedPage';
import {
  CONFIRM_DIALOG_MSG_TEXT,
  CONFIRM_DIALOG_TITLE_TEXT,
  CREATE_NOTICE_NOT_AUTHENTICATED,
  HOME_TILE_PINNWAND,
  NOTICE_TAB_5_TEXT
} from '../../../constants/strings';
import useCurrentUser from '../../../hooks/useCurrentUser';
import usePostApi from '../../../hooks/usePostApi';
import { NoticeCategory, NoticeContentType } from '../../../types/notice';
import { hasLength } from '../../../utils/common';
import {
  initialState,
  reducer,
  setNotice,
  TAction,
  TState
} from './CreateNoticeReducer';

const CreateNotice: FC = () => {
  const postApi = usePostApi();
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const [searchParams] = useSearchParams();

  const NoticeIcon = <img src={Notice} loading="lazy" height="100rem" />;

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const [state, dispatch] = useReducer<Reducer<TState, TAction>>(
    reducer,
    initialState
  );

  const noticeId = useMemo(
    () => searchParams.get('noticeId') || '',
    [searchParams]
  );
  const isUpdateNotice = useMemo(() => hasLength(noticeId), [noticeId]);

  const { title, contentType, category, text, imageId } = state;

  const handleRejectConfirmation = useCallback(() => {
    setIsConfirmationDialogOpen(false);
  }, []);

  const handleOpenConfirmation = useCallback((event) => {
    event.preventDefault();
    setIsConfirmationDialogOpen(true);
  }, []);

  const toClientPostCreateOrUpdateRequestContentTypeEnum = (
    cType: string
  ):
    | ClientPostCreateRequestContentTypeEnum
    | ClientPostUpdateRequestPostTypeEnum => {
    switch (cType) {
      case NoticeContentType.OFFER:
        return ClientPostCreateRequestContentTypeEnum.Offer;
      case NoticeContentType.SEEKING:
        return ClientPostCreateRequestContentTypeEnum.Seeking;
      default:
        throw new Error('Unknown NoticeType!');
    }
  };

  const toClientPostCreateOrUpdateRequestCategory = (
    categoryValue: string
  ):
    | ClientPostCreateRequestCategoryEnum
    | ClientPostUpdateRequestCategoryEnum => {
    switch (categoryValue) {
      case NoticeCategory.GENERAL:
        return ClientPostCreateRequestCategoryEnum.General;
      case NoticeCategory.CULTURE:
        return ClientPostCreateRequestCategoryEnum.Culture;
      case NoticeCategory.ENCOUNTER:
        return ClientPostCreateRequestCategoryEnum.Encounter;
      case NoticeCategory.HEALTH_SPORT:
        return ClientPostCreateRequestCategoryEnum.HealthSport;
      case NoticeCategory.NEIGHBORHOOD_HELP:
        return ClientPostCreateRequestCategoryEnum.NeighborhoodHelp;
      default:
        throw new Error('Unknown Notice category!');
    }
  };

  const toNoticeCategory = (categoryValue: string): string => {
    switch (categoryValue) {
      case ClientPostCreateRequestCategoryEnum.General:
        return NoticeCategory.GENERAL;
      case ClientPostCreateRequestCategoryEnum.Culture:
        return NoticeCategory.CULTURE;
      case ClientPostCreateRequestCategoryEnum.Encounter:
        return NoticeCategory.ENCOUNTER;
      case ClientPostCreateRequestCategoryEnum.HealthSport:
        return NoticeCategory.HEALTH_SPORT;
      case ClientPostCreateRequestCategoryEnum.NeighborhoodHelp:
        return NoticeCategory.NEIGHBORHOOD_HELP;
      default:
        throw new Error('Unknown Notice category!');
    }
  };

  const navigateToNearByNotices = useCallback(() => {
    navigate('/pinnwand/nearbynotices');
  }, [navigate]);

  const handleNoticeCreateOrUpdate = useCallback(() => {
    const createOrUpdateNotice = async () => {
      try {
        const temporaryMediaItemId = imageId !== null ? imageId : undefined;

        if (isUpdateNotice) {
          const clientPostUpdateRequest: ClientPostUpdateRequest =
            Object.assign(
              {
                title,
                postType: toClientPostCreateOrUpdateRequestContentTypeEnum(
                  contentType
                ) as ClientPostUpdateRequestPostTypeEnum,
                category: toClientPostCreateOrUpdateRequestCategory(
                  category
                ) as ClientPostUpdateRequestCategoryEnum,
                text
              },
              temporaryMediaItemId === undefined
                ? null
                : { temporaryMediaItemId }
            );
          await postApi.updatePost(noticeId, clientPostUpdateRequest);
        } else {
          const clientPostCreateRequest: ClientPostCreateRequest = {
            title,
            contentType: toClientPostCreateOrUpdateRequestContentTypeEnum(
              contentType
            ) as ClientPostCreateRequestContentTypeEnum,
            category: toClientPostCreateOrUpdateRequestCategory(
              category
            ) as ClientPostCreateRequestCategoryEnum,
            text,
            temporaryMediaItemId
          };
          await postApi.createPost(clientPostCreateRequest);
        }

        setIsConfirmationDialogOpen(false);
        navigateToNearByNotices();
      } catch (error) {
        if (error instanceof BackendApiError) {
          setIsConfirmationDialogOpen(false);
        }
      }
    };
    createOrUpdateNotice();
  }, [
    category,
    contentType,
    imageId,
    navigateToNearByNotices,
    noticeId,
    isUpdateNotice,
    postApi,
    text,
    title
  ]);

  useEffect(() => {
    if (!currentUser) {
      dispatch(setNotice(initialState));
      return;
    }
    if (!noticeId) {
      dispatch(setNotice(initialState));
    } else {
      const loadNotice = async () => {
        const loadedNotice = await postApi.getPostById(noticeId);
        const { creatorId } = loadedNotice;
        if (creatorId !== currentUser?.id) {
          return;
        }

        dispatch(
          setNotice({
            title: loadedNotice.title ?? '',
            contentType: loadedNotice.type ?? '',
            text: loadedNotice.text ?? '',
            category: toNoticeCategory(loadedNotice.category ?? ''),
            imageId: null,
            image: loadedNotice.image ?? null
          })
        );
      };
      loadNotice();
    }
  }, [currentUser, noticeId, postApi]);

  return (
    <ProtectedPage
      errorMessage={CREATE_NOTICE_NOT_AUTHENTICATED}
      onErrorDialogClose={navigateToNearByNotices}
    >
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          color="text.primary"
          variant="h3"
          to="/"
          component={ReactRouterLink}
        >
          {HOME_TILE_PINNWAND}
        </Link>
        <Typography color="text.primary" variant="h3">
          {NOTICE_TAB_5_TEXT}
        </Typography>
      </Breadcrumbs>
      <CreateNoticeView
        noticeState={state}
        dispatch={dispatch}
        imageIcon={NoticeIcon}
        isUpdateNotice={isUpdateNotice}
        createNotice={handleOpenConfirmation}
      />
      <ConfirmationDialog
        title={CONFIRM_DIALOG_TITLE_TEXT}
        message={CONFIRM_DIALOG_MSG_TEXT}
        open={isConfirmationDialogOpen}
        onClose={handleRejectConfirmation}
        onAction={handleNoticeCreateOrUpdate}
      />
    </ProtectedPage>
  );
};

export default CreateNotice;
