import { FC } from 'react';
import { Typography } from '@mui/material';
import AbstractDialog from '../../dialogs/AbstractDialog';
import {
  REQUEST_SUCCESS_DIALOG_BTN_TEXT,
  REQUEST_SUCCESS_DIALOG_NOTICE
} from '../../../constants/strings';

type RequestSuccessDialogProps = {
  title: string;
  message: string;
  open: boolean;
  onClose: () => void;
};

const RequestSuccessDialog: FC<RequestSuccessDialogProps> = (
  props: RequestSuccessDialogProps
) => {
  const { title, message, open, onClose } = props;
  return (
    <AbstractDialog
      open={open}
      title={title}
      btnActionText={REQUEST_SUCCESS_DIALOG_BTN_TEXT}
      hideCancelBtn={true}
      onClose={onClose}
      onAction={onClose}
    >
      <Typography align="center" gutterBottom={true}>
        {message}
      </Typography>
      <Typography align="center">{REQUEST_SUCCESS_DIALOG_NOTICE}</Typography>
    </AbstractDialog>
  );
};

export default RequestSuccessDialog;
