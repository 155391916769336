"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientUserUpdateSettingsRequestToJSON = exports.ClientUserUpdateSettingsRequestFromJSONTyped = exports.ClientUserUpdateSettingsRequestFromJSON = void 0;
function ClientUserUpdateSettingsRequestFromJSON(json) {
    return ClientUserUpdateSettingsRequestFromJSONTyped(json, false);
}
exports.ClientUserUpdateSettingsRequestFromJSON = ClientUserUpdateSettingsRequestFromJSON;
function ClientUserUpdateSettingsRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'selectedGeoAreaIds': json['selectedGeoAreaIds'],
    };
}
exports.ClientUserUpdateSettingsRequestFromJSONTyped = ClientUserUpdateSettingsRequestFromJSONTyped;
function ClientUserUpdateSettingsRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'selectedGeoAreaIds': value.selectedGeoAreaIds,
    };
}
exports.ClientUserUpdateSettingsRequestToJSON = ClientUserUpdateSettingsRequestToJSON;
