import { createTheme } from '@mui/material/styles';
import baseTheme from './baseTheme';
import { MuiTabComponent } from './baseThemeComponents';
import {
  FITHEALTH_PRIMARY,
  FITHEALTH_TEXT,
  FITHEALTH_ACTIVE,
  FITHEALTH_ACTIVE_BG,
  FITHEALTH_INACTIVE
} from './colors/globalColors';

const fithealthTheme = createTheme({
  ...baseTheme,
  palette: {
    primary: {
      main: FITHEALTH_PRIMARY,
      contrastText: FITHEALTH_TEXT
    },
    secondary: {
      main: FITHEALTH_TEXT,
      contrastText: FITHEALTH_PRIMARY
    }
  },
  components: {
    ...baseTheme.components,
    MuiList: {
      styleOverrides: {
        root: {
          marginTop: '0.5rem',
          marginBottom: '0.5rem'
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '2rem'
        }
      }
    },
    MuiTab: MuiTabComponent(
      FITHEALTH_INACTIVE,
      FITHEALTH_ACTIVE,
      FITHEALTH_ACTIVE_BG
    )
  }
});

export default fithealthTheme;
