import { Box, IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { FC, Fragment, useCallback, useState } from 'react';
import UpdateAddressMenu from './UpdateAddressMenu';
import { TAddress } from '../store/types';

type EditableAddressProps = {
  address?: TAddress;
  onAddressChange: (street: string, zipCode: string, city: string) => void;
};

const EditableAddress: FC<EditableAddressProps> = (props) => {
  const { address, onAddressChange } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleUpdateAddress = useCallback(
    (event) => {
      event.preventDefault();
      const { street, zipCode, city } = event.target;
      onAddressChange(street.value, zipCode.value, city.value);
      handleCloseMenu();
    },
    [handleCloseMenu, onAddressChange]
  );

  return (
    <Fragment>
      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
        <Typography variant="body1">{address?.fullAdress}</Typography>
        {address?.fullAdress != '' && (
          <IconButton
            size="small"
            aria-label="menu"
            onClick={handleOpenMenu}
            sx={{ display: 'inline' }}
          >
            <EditIcon sx={{ fontSize: '18px' }} />
          </IconButton>
        )}
      </Box>
      <UpdateAddressMenu
        address={address}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        onSubmit={handleUpdateAddress}
      />
    </Fragment>
  );
};

export default EditableAddress;
