import { Stack, Typography } from '@mui/material';
import { FC, Fragment } from 'react';
import { useOutletContext } from 'react-router-dom';
import { getThumbnailUrl } from '../../../api/InformationAPI';
import { CATEGORY_PENDING_POSTS } from '../../../constants/strings';
import { TMyAreaOutletContext } from '../MyAreaView';
import PendingPostCard from './PendingPostCard';

const PendingPostsList: FC = () => {
  const { pendingPosts } = useOutletContext<TMyAreaOutletContext>();

  return (
    <Fragment>
      <Typography gutterBottom={true} variant="h2">
        {CATEGORY_PENDING_POSTS}
      </Typography>
      <Stack spacing={1}>
        {pendingPosts.map((p) => {
          const { id, title, abstract, text, dateCreated, featuredImage } = p;

          return (
            <PendingPostCard
              key={id}
              id={id}
              image={getThumbnailUrl(featuredImage)}
              title={title}
              abstract={abstract}
              text={text}
              date={dateCreated}
            />
          );
        })}
      </Stack>
    </Fragment>
  );
};

export default PendingPostsList;
