"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientUserContentFlagRequestToJSON = exports.ClientUserContentFlagRequestFromJSONTyped = exports.ClientUserContentFlagRequestFromJSON = void 0;
const runtime_1 = require("../runtime");
function ClientUserContentFlagRequestFromJSON(json) {
    return ClientUserContentFlagRequestFromJSONTyped(json, false);
}
exports.ClientUserContentFlagRequestFromJSON = ClientUserContentFlagRequestFromJSON;
function ClientUserContentFlagRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'module': json['module'],
        'url': json['url'],
        'reason': !runtime_1.exists(json, 'reason') ? undefined : json['reason'],
    };
}
exports.ClientUserContentFlagRequestFromJSONTyped = ClientUserContentFlagRequestFromJSONTyped;
function ClientUserContentFlagRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'module': value.module,
        'url': value.url,
        'reason': value.reason,
    };
}
exports.ClientUserContentFlagRequestToJSON = ClientUserContentFlagRequestToJSON;
