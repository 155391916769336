import FlagIcon from '@mui/icons-material/Flag';
import {
  ClientHappeningExtendedHappeningTypeEnum,
  ClientUserContentFlagRequest
} from 'digital-vital-backend-api';
import { FC, useCallback, useMemo, useState } from 'react';
import { POST_LOGIN_REDIRECT_URL } from '../config';
import {
  CONFIRM_BTN_TEXT_SHORT,
  EVENT_TEXT,
  NEIGBOURHOODTABLE_TEXT,
  PINNWAND_TEXT,
  SEND_BTN_TEXT
} from '../constants/strings';
import useFlagsApi from '../hooks/useFlagsApi';
import ConfirmationDialog from './dialogs/ConfirmationDialog';
import InputDialog from './dialogs/InputDialog';
import SuccessDialog from './dialogs/SuccessDialog';

const PINNWAND = 'Pinnwand';
interface ReportingDialogProps {
  open: boolean;
  onClose: () => void;
  eventType?: string;
  eventId?: string;
}

const ReportingDialog: FC<ReportingDialogProps> = ({
  open,
  onClose,
  eventId,
  eventType
}) => {
  const flagsApi = useFlagsApi();
  const [reason, setReason] = useState<string>('');
  const [isReportingDialogOpen, setReportingDialogOpen] =
    useState<boolean>(false);
  const [isSuccessDialogOpen, setSuccessDialogOpen] = useState<boolean>(false);

  const handleSuccessDialogClose = useCallback(() => {
    setSuccessDialogOpen(false);
  }, []);

  const handleReportingDialogClose = useCallback(() => {
    setReportingDialogOpen(false);
  }, []);

  const handleConfirmReporting = useCallback(() => {
    onClose();
    setReportingDialogOpen(true);
  }, [onClose]);

  const getModuleAndUrlFromType = useCallback((): {
    moduleName: string;
    url: string;
  } => {
    switch (eventType) {
      case ClientHappeningExtendedHappeningTypeEnum.NeighborhoodTable:
        return {
          moduleName: 'NeighborhoodTable',
          url: `${POST_LOGIN_REDIRECT_URL}/nachbarschaftstische/nearbytisch/${eventId}`
        };
      case ClientHappeningExtendedHappeningTypeEnum.Event:
        return {
          moduleName: 'CalendarEvent',
          url: `${POST_LOGIN_REDIRECT_URL}/veranstaltungen/nearbyevents/${eventId}`
        };
      case PINNWAND:
        return {
          moduleName: 'Post',
          url: `${POST_LOGIN_REDIRECT_URL}/pinnwand/nearbynotices/${eventId}`
        };
      default:
        return { moduleName: 'unknown', url: `${POST_LOGIN_REDIRECT_URL}` };
    }
  }, [eventId, eventType]);

  const textFromType = useMemo((): string => {
    switch (eventType) {
      case ClientHappeningExtendedHappeningTypeEnum.NeighborhoodTable:
        return NEIGBOURHOODTABLE_TEXT;
      case ClientHappeningExtendedHappeningTypeEnum.Event:
        return EVENT_TEXT;
      case PINNWAND:
        return PINNWAND_TEXT;
      default:
        return '';
    }
  }, [eventType]);

  const confirmationText = useMemo(() => {
    if (eventType === ClientHappeningExtendedHappeningTypeEnum.Event) {
      return `Möchten Sie diese ${textFromType} melden?`;
    }
    return `Möchten Sie diesen ${textFromType} melden?`;
  }, [eventType, textFromType]);

  const reportingReasonText = useMemo(() => {
    if (eventType === ClientHappeningExtendedHappeningTypeEnum.Event) {
      return `Wieso ist diese ${textFromType} unangemessen?`;
    }
    return `Wieso ist dieser ${textFromType} unangemessen?`;
  }, [eventType, textFromType]);

  const reportingSuccessText = useMemo(() => {
    if (eventType === ClientHappeningExtendedHappeningTypeEnum.Event) {
      return `Diese ${textFromType} wurde erfolgreich gemeldet.`;
    }
    return `Dieser ${textFromType} wurde erfolgreich gemeldet.`;
  }, [eventType, textFromType]);

  const handleFlaggingRequest = useCallback(() => {
    const flagContentAsync = async () => {
      if (!eventType) return;
      const { moduleName, url } = getModuleAndUrlFromType();
      const clientUserContentFlagRequest: ClientUserContentFlagRequest = {
        module: moduleName,
        url,
        reason
      };
      await flagsApi.flagContent(clientUserContentFlagRequest);
      handleReportingDialogClose();
      setSuccessDialogOpen(true);
    };
    flagContentAsync();
  }, [
    eventType,
    flagsApi,
    getModuleAndUrlFromType,
    handleReportingDialogClose,
    reason
  ]);

  return (
    <>
      <ConfirmationDialog
        message={confirmationText}
        actionBtnText={CONFIRM_BTN_TEXT_SHORT}
        open={open}
        onClose={onClose}
        onAction={handleConfirmReporting}
      />
      <InputDialog
        open={isReportingDialogOpen}
        dialogIcon={<FlagIcon color="primary" sx={{ fontSize: '64px' }} />}
        text={reportingReasonText}
        actionBtnText={SEND_BTN_TEXT}
        onClose={handleReportingDialogClose}
        onAction={handleFlaggingRequest}
        isMsgRequired={false}
        input={reason}
        inputLabel="Grund"
        setInput={setReason}
      />
      <SuccessDialog
        onClose={handleSuccessDialogClose}
        message={reportingSuccessText}
        open={isSuccessDialogOpen}
      />
    </>
  );
};

export default ReportingDialog;
