import {
  ClientUser,
  ClientUserExtended,
  ClientUserUpdateConfirmation,
  ClientUserUpdateRequest,
  UserApi,
  UserApiInterface
} from 'digital-vital-backend-api';
import {
  ClientUserSettings,
  ClientUserUpdateSettingsConfirmation
} from 'digital-vital-backend-api/models';
import { APP_VARIANT_IDENTIFIER } from '../config';
import BackendApi from './BackendAPI';

export default class BackendUserAPI extends BackendApi {
  private readonly _userApi: UserApiInterface;

  constructor(accessToken?: string) {
    super(accessToken);
    this._userApi = new UserApi(this.createConfiguration());
  }

  public getOwnUser(): Promise<ClientUserExtended> {
    return this.callApi(() => this._userApi.getOwnUser(APP_VARIANT_IDENTIFIER));
  }

  public getUserById(userId: string): Promise<ClientUser> {
    return this.callApi(() =>
      this._userApi.getUserById(userId, APP_VARIANT_IDENTIFIER)
    );
  }

  public updateOwnUser(
    clientUserUpdateRequest: ClientUserUpdateRequest
  ): Promise<ClientUserUpdateConfirmation> {
    return this.callApi(() =>
      this._userApi.updateOwnUser(
        clientUserUpdateRequest,
        APP_VARIANT_IDENTIFIER
      )
    );
  }

  public getOwnUserSettings(): Promise<ClientUserSettings> {
    return this.callApi(() =>
      this._userApi.getOwnUserSettings(APP_VARIANT_IDENTIFIER)
    );
  }

  public updateOwnUserSettings(
    selectedGeoAreaIds: string[]
  ): Promise<ClientUserUpdateSettingsConfirmation> {
    return this.callApi(() =>
      this._userApi.updateOwnUserSettings({ selectedGeoAreaIds })
    );
  }
}
