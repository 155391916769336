import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { Theme } from '@mui/system/createTheme/createTheme';
import { ClientPost } from 'digital-vital-backend-api';
import { CSSProperties, FC, useCallback, useMemo, useState } from 'react';
import {
  NOTICE_DELETE_BTN_TEXT,
  NOTICE_EDIT_BTN_TEXT,
  REPORT_BTN_TXT
} from '../../constants/strings';
import useCurrentUser from '../../hooks/useCurrentUser';
import ImageService, { ImageSizes } from '../../services/ImageService';
import DetailsContactButton from '../DetailsContactButton';
import ContactOrganizerDialog from '../dialogs/ContactDialog';
import RenderOnAuthenticated from '../RenderOnAuthenticated';
import DetailsTypeTag from './DetailsTypeTag';
import NoticeCommentsContainer from './NoticeCommentsContainer';
const useStyles = (theme: Theme): Record<string, CSSProperties> => ({
  centerText: {
    textAlign: 'center'
  },
  titleStyle: {
    background: theme.palette.primary.main,
    height: '3rem',
    padding: '0.5rem 1rem'
  }
});

interface NoticeDetailsViewProps {
  notice: ClientPost;
  isPortalAdmin: boolean;
  onDeleteNotice: () => void;
  onEditNotice: () => void;
  onReporting: () => void;
  defaultHeaderImage: string;
}

const NoticeDetailsView: FC<NoticeDetailsViewProps> = ({
  notice,
  isPortalAdmin,
  onDeleteNotice,
  onEditNotice,
  onReporting,
  defaultHeaderImage
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const { palette } = theme;
  const { creatorId } = notice;
  const { currentUser } = useCurrentUser();

  const isAuthor = useMemo(
    () => creatorId === currentUser?.id,
    [creatorId, currentUser]
  );

  const [isContactOrganizerDialogOpen, setIsContactOrganizerDialogOpen] =
    useState<boolean>(false);

  const handleOrganizerContactDialogClose = useCallback(() => {
    setIsContactOrganizerDialogOpen(false);
  }, []);

  const handleOrganizerContactDialogOpen = useCallback(() => {
    setIsContactOrganizerDialogOpen(true);
  }, []);

  return (
    <Card>
      <Box>
        <Box position="relative">
          <CardMedia
            component="img"
            height="140"
            image={
              ImageService.getImageUrl(ImageSizes.MEDIUM, notice?.image) ??
              defaultHeaderImage
            }
          />
          {!isAuthor && (
            <DetailsContactButton
              onContactClick={handleOrganizerContactDialogOpen}
              bottomPosition="10%"
            />
          )}
          <DetailsTypeTag type={notice?.type} />
        </Box>
        <Box sx={styles.titleStyle}>
          <Typography variant="h2" color={palette.primary.contrastText}>
            {notice?.title}
          </Typography>
        </Box>
        <CardContent>
          <Typography variant="body2" sx={{ mb: '1.5rem' }}>
            {'gepinnt von '}
            <b>{notice?.authorName}</b>
          </Typography>
          <Typography variant="body2">{notice?.text}</Typography>
          <Stack direction="row" gap="1rem" justifyContent="flex-end">
            <RenderOnAuthenticated>
              <>
                {isAuthor && (
                  <Button variant="contained" onClick={onEditNotice}>
                    {NOTICE_EDIT_BTN_TEXT}
                  </Button>
                )}
                {(isAuthor || isPortalAdmin) && (
                  <Button variant="contained" onClick={onDeleteNotice}>
                    {NOTICE_DELETE_BTN_TEXT}
                  </Button>
                )}
                {!isAuthor && (
                  <Button
                    variant="contained"
                    onClick={onReporting}
                    sx={{ mr: '0.5rem' }}
                  >
                    {REPORT_BTN_TXT}
                  </Button>
                )}
              </>
            </RenderOnAuthenticated>
          </Stack>
        </CardContent>
      </Box>
      <NoticeCommentsContainer noticeId={notice?.id} />
      <ContactOrganizerDialog
        open={isContactOrganizerDialogOpen}
        onClose={handleOrganizerContactDialogClose}
        notice={notice}
        isNoticeRequest
      />
    </Card>
  );
};

export default NoticeDetailsView;
