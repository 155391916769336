import { createTheme } from '@mui/material/styles';
import baseTheme from './baseTheme';
import { MuiTabComponent } from './baseThemeComponents';
import {
  PINNWAND_ACTIVE,
  PINNWAND_ACTIVE_BG,
  PINNWAND_INACTIVE,
  PINNWAND_PRIMARY,
  PINNWAND_TEXT
} from './colors/globalColors';

const pinnwandTheme = createTheme({
  ...baseTheme,
  palette: {
    primary: {
      main: PINNWAND_PRIMARY,
      contrastText: PINNWAND_TEXT
    },
    secondary: {
      main: PINNWAND_ACTIVE,
      contrastText: PINNWAND_PRIMARY
    }
  },
  components: {
    ...baseTheme.components,
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          color: PINNWAND_PRIMARY
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: '1rem'
        }
      }
    },
    MuiTab: MuiTabComponent(
      PINNWAND_INACTIVE,
      PINNWAND_ACTIVE,
      PINNWAND_ACTIVE_BG
    )
  }
});

export default pinnwandTheme;
