export enum EDirectusRole {
  REPORTER = 'Directus_Reporter',
  EDITOR = 'Directus_Editor',
  CHIEFEDITOR = 'Directus_ChiefEditor'
}

export enum EPortalRoles {
  EVENT_ORGANIZER = 'organizer',
  PORTAL_ADMIN = 'portal_admin'
}
