import { FC, Fragment } from 'react';
import { ClientUser } from 'digital-vital-backend-api';
import { Avatar, Grid, Typography } from '@mui/material';
import { SETTINGS_PROFILE_PIC } from '../../../themes/colors/globalColors';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
type AboutMeViewProps = {
  user?: ClientUser;
  userProfilePicture?: string;
};

const AboutMeView: FC<AboutMeViewProps> = (props: AboutMeViewProps) => {
  const { user, userProfilePicture } = props;
  return (
    <Fragment>
      <Grid
        container
        flexDirection="column"
        spacing={1}
        justifyItems="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          {userProfilePicture ? (
            <Avatar
              src={userProfilePicture}
              style={{
                width: 80,
                height: 80,
                border: `1px solid ${SETTINGS_PROFILE_PIC}`
              }}
            />
          ) : (
            <AccountCircleRoundedIcon sx={{ fontSize: '2.75rem' }} />
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography paragraph={true}>{user?.aboutMe}</Typography>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default AboutMeView;
