import { Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import { CSSProperties, FC, Fragment, useMemo } from 'react';
import clip from 'text-clipper';
import FitHealth from '../../../assets/svgs/fithealth.svg';
import OverviewCard from '../../../components/OverviewCard';
import { isNil } from '../../../utils/common';

const useStyles = (): Record<string, CSSProperties> => ({
  textPreviewStyle: {
    overflow: 'hidden',
    maxHeight: '6rem'
  }
});

type FitHealthListItemProps = {
  id: string;
  title: string;
  abstract: string;
  text: string;
  image?: string;
};

const FitHealthListItem: FC<FitHealthListItemProps> = (
  props: FitHealthListItemProps
) => {
  const { id, title, abstract, text, image } = props;

  const theme = useTheme();
  const styles = useStyles();
  const smallScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const mediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const largeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const clippedText = useMemo(() => {
    const largeScreenValue = largeScreen ? 280 : 200;
    const maxChars = mediumScreen ? largeScreenValue : 130;
    if (isNil(abstract) || abstract.length < 1) {
      return clip(text, maxChars, {
        html: true,
        maxLines: 2,
        stripTags: ['img', 'svg', 'a', 'br', 'strong']
      });
    }
    return clip(abstract, maxChars, { maxLines: 2 });
  }, [abstract, largeScreen, mediumScreen, text]);

  return (
    <OverviewCard
      image={image}
      defaultImage={FitHealth}
      detailsLink={`/fit-gesund/post/${id}`}
      isLargeScreen={smallScreen}
    >
      <Typography gutterBottom variant="h3">
        {title}
      </Typography>
      {smallScreen && (
        <Fragment>
          <Divider flexItem={true} />
          <Typography
            dangerouslySetInnerHTML={{
              __html: clippedText
            }}
            component="div"
            gutterBottom={true}
            style={styles.textPreviewStyle}
            variant="body2"
          />
        </Fragment>
      )}
    </OverviewCard>
  );
};

export default FitHealthListItem;
