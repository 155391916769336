import { Avatar, Grid, Link } from '@mui/material';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import { FC, Fragment, useCallback } from 'react';
import { SETTINGS_PROFILE_PIC } from '../../../themes/colors/globalColors';

type ParticipantViewProps = {
  participantDisplayName?: string;
  participantProfilePicture?: string;
  onParticipantClick: () => void;
};

const ParticipantFragment: FC<ParticipantViewProps> = (props) => {
  const {
    participantDisplayName,
    participantProfilePicture,
    onParticipantClick
  } = props;

  const handleParticipantClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      event.stopPropagation();
      onParticipantClick();
    },
    [onParticipantClick]
  );

  return (
    <Fragment>
      <Grid item xs={12}>
        {participantProfilePicture ? (
          <Avatar
            src={participantProfilePicture}
            style={{
              border: `1px solid ${SETTINGS_PROFILE_PIC}`
            }}
          />
        ) : (
          <AccountCircleRoundedIcon sx={{ fontSize: '2.75rem' }} />
        )}
      </Grid>
      <Grid item xs={12}>
        <Link style={{ cursor: 'pointer' }} onClick={handleParticipantClick}>
          {participantDisplayName}
        </Link>
      </Grid>
    </Fragment>
  );
};

export default ParticipantFragment;
