import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import LogoutIcon from '@mui/icons-material/Logout';
import SearchIcon from '@mui/icons-material/Search';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { resolvePath, useLocation, useNavigate } from 'react-router-dom';
import {
  BACK_BTN_TEXT,
  LOGIN_BTN_TEXT,
  LOGOUT_TEXT,
  NAVIGATE_PROFILE_BTN_TEXT,
  SEARCH_HEADING
} from '../constants/strings';
import useCurrentUser from '../hooks/useCurrentUser';
import ImageService, { ImageSizes } from '../services/ImageService';
import KeycloakService from '../services/KeycloakService';
import {
  NAVIGATION_BTN_BG,
  SETTINGS_PROFILE_PIC
} from '../themes/colors/globalColors';
import { getThemeFromRoute, getTitleFromRoute } from '../utils/common';
import RenderOnAnonymous from './RenderOnAnonymous';
import RenderOnAuthenticated from './RenderOnAuthenticated';

const AppHeader: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const { pathname } = location;
  const showBack = pathname !== '/';
  const showSearch = pathname.startsWith('/information');
  const theme = getThemeFromRoute(pathname);
  const title = getTitleFromRoute(pathname);
  const currentUserAvatarUrl = useMemo(
    () =>
      ImageService.getImageUrl(
        ImageSizes.THUMBNAIL,
        currentUser?.profilePicture
      ),
    [currentUser]
  );

  const appBarEl = useRef<null | HTMLDivElement>(null);
  const [height, setHeight] = useState<number>(64);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );
  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const goBack = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      const parentPath = resolvePath('..', pathname);
      navigate(parentPath);
    },
    [navigate, pathname]
  );

  const goToSearch = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      navigate('/information/search');
    },
    [navigate]
  );

  const goToProfile = useCallback(() => {
    closeMenu();
    navigate('/my-area/my-profile');
  }, [navigate, closeMenu]);

  useEffect(() => {
    if (appBarEl.current && appBarEl.current.clientHeight !== height) {
      setHeight(appBarEl.current.clientHeight);
    }
  }, [appBarEl.current?.clientHeight, height]);

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="fixed" sx={{ top: 0 }} elevation={0} ref={appBarEl}>
        <Container disableGutters={true}>
          <Toolbar sx={{ flexWrap: { xs: 'wrap', sm: 'nowrap' } }}>
            {showBack && (
              <>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="back"
                  onClick={goBack}
                >
                  <ArrowBackIosIcon aria-hidden="true" />
                  <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                    {BACK_BTN_TEXT}
                  </Typography>
                </IconButton>
                <Typography
                  variant="subtitle1"
                  sx={{ flexGrow: 1 }}
                ></Typography>
              </>
            )}
            <Typography variant="body2" sx={{ flexGrow: 1, fontWeight: 400 }}>
              {title}
            </Typography>
            {showSearch && (
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="search"
                sx={{ mr: 2 }}
                onClick={goToSearch}
              >
                <SearchIcon />
                <Typography variant="subtitle1" sx={{ flexGrow: 1, ml: 1 }}>
                  {SEARCH_HEADING}
                </Typography>
              </IconButton>
            )}
            <RenderOnAnonymous>
              <Button
                variant="contained"
                color="secondary"
                style={{ background: NAVIGATION_BTN_BG }}
                onClick={KeycloakService.doLogin}
                sx={{ mb: { xs: 2, sm: 0 } }}
              >
                {LOGIN_BTN_TEXT}
              </Button>
            </RenderOnAnonymous>
            <RenderOnAuthenticated>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2, mb: { xs: 2, sm: 0 } }}
                onClick={openMenu}
              >
                {currentUserAvatarUrl ? (
                  <Avatar
                    src={currentUserAvatarUrl}
                    style={{
                      border: `1px solid ${SETTINGS_PROFILE_PIC}`
                    }}
                  />
                ) : (
                  <AccountCircleRoundedIcon sx={{ fontSize: '2.75rem' }} />
                )}
              </IconButton>
              <Box>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={closeMenu}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                  }}
                >
                  <MenuItem onClick={goToProfile}>
                    {NAVIGATE_PROFILE_BTN_TEXT}
                  </MenuItem>
                  <MenuItem onClick={KeycloakService.doLogout}>
                    <LogoutIcon sx={{ mr: 1 }} color="primary" />
                    <Typography
                      color="primary"
                      sx={{ textTransform: 'uppercase' }}
                    >
                      {LOGOUT_TEXT}
                    </Typography>
                  </MenuItem>
                </Menu>
              </Box>
            </RenderOnAuthenticated>
          </Toolbar>
        </Container>
      </AppBar>
      <Box sx={{ height: `${height}px` }} />
    </ThemeProvider>
  );
};

export default AppHeader;
