"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientHappeningToJSON = exports.ClientHappeningFromJSONTyped = exports.ClientHappeningFromJSON = exports.ClientHappeningHappeningTypeEnum = void 0;
const runtime_1 = require("../runtime");
const ClientHappeningLocation_1 = require("./ClientHappeningLocation");
const ClientMediaItem_1 = require("./ClientMediaItem");
/**
* @export
* @enum {string}
*/
var ClientHappeningHappeningTypeEnum;
(function (ClientHappeningHappeningTypeEnum) {
    ClientHappeningHappeningTypeEnum["NeighborhoodTable"] = "NEIGHBORHOOD_TABLE";
    ClientHappeningHappeningTypeEnum["Event"] = "EVENT";
})(ClientHappeningHappeningTypeEnum = exports.ClientHappeningHappeningTypeEnum || (exports.ClientHappeningHappeningTypeEnum = {}));
function ClientHappeningFromJSON(json) {
    return ClientHappeningFromJSONTyped(json, false);
}
exports.ClientHappeningFromJSON = ClientHappeningFromJSON;
function ClientHappeningFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'title': json['title'],
        'description': json['description'],
        'startTime': json['startTime'],
        'happeningType': !runtime_1.exists(json, 'happeningType') ? undefined : json['happeningType'],
        'image': !runtime_1.exists(json, 'image') ? undefined : ClientMediaItem_1.ClientMediaItemFromJSON(json['image']),
        'location': !runtime_1.exists(json, 'location') ? undefined : ClientHappeningLocation_1.ClientHappeningLocationFromJSON(json['location']),
        'externalId': !runtime_1.exists(json, 'externalId') ? undefined : json['externalId'],
    };
}
exports.ClientHappeningFromJSONTyped = ClientHappeningFromJSONTyped;
function ClientHappeningToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'title': value.title,
        'description': value.description,
        'startTime': value.startTime,
        'happeningType': value.happeningType,
        'image': ClientMediaItem_1.ClientMediaItemToJSON(value.image),
        'location': ClientHappeningLocation_1.ClientHappeningLocationToJSON(value.location),
        'externalId': value.externalId,
    };
}
exports.ClientHappeningToJSON = ClientHappeningToJSON;
