import { createTheme } from '@mui/material/styles';
import baseTheme from './baseTheme';
import { SETTINGS_PRIMARY } from './colors/globalColors';

const settingsTheme = createTheme({
  ...baseTheme,
  palette: {
    primary: {
      main: SETTINGS_PRIMARY,
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: SETTINGS_PRIMARY
    },
    background: {
      default: SETTINGS_PRIMARY
    },
    text: {
      primary: '#FFFFFF'
    }
  }
});
export default settingsTheme;
