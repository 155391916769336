import { ScopedCssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { FC, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import './App.scss';
import AppFooter from './components/AppFooter';
import AppHeader from './components/AppHeader';
import { CurrentUserProvider } from './components/context-providers/CurrentUserProvider';
import { ALERT_TEXT } from './constants/strings';
import routes from './routes';
import LocalSettingsService, {
  FONT_SIZE_KEY
} from './services/LocalSettingsService';
import baseTheme from './themes/baseTheme';
import { isAbsoluteHTTPUrl } from './utils/common';

const App: FC = () => {
  const routing = useRoutes(routes());

  useEffect(() => {
    const location = window.location;
    const currentBaseUrl = `${location.protocol}//${location.host}`;
    const links = document.getElementsByTagName('a');
    for (let i = 0; i < links.length; i++) {
      const link = links.item(i);
      if (!link) {
        continue;
      }
      const href = link.href;
      if (isAbsoluteHTTPUrl(href) && !href.startsWith(currentBaseUrl)) {
        link.onclick = (e) => {
          if (!confirm(ALERT_TEXT)) e.preventDefault();
        };
      }
    }
  });

  useEffect(() => {
    const value = LocalSettingsService.getLocalSetting(FONT_SIZE_KEY);
    if (value) {
      const fontSize = parseInt(value);
      const rootElement = document.documentElement;
      rootElement.style.fontSize = `${fontSize}px`;
    }
  }, []);

  return (
    <div className="App">
      <ScopedCssBaseline>
        <CurrentUserProvider>
          <AppHeader />
          <ThemeProvider theme={baseTheme}>
            <main>{routing}</main>
          </ThemeProvider>
          <AppFooter />
        </CurrentUserProvider>
      </ScopedCssBaseline>
    </div>
  );
};

export default App;
