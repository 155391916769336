import { useEffect, useState } from 'react';
import BackendHappeningApi from '../api/HappeningAPI';
import useAuthContext from './useAuthContext';

const useHappeningApi = (): BackendHappeningApi => {
  const { keycloak } = useAuthContext();

  const [happeningApi, setHappeningApi] = useState<BackendHappeningApi>(
    () => new BackendHappeningApi(keycloak.token)
  );

  useEffect(() => {
    if (happeningApi.accessToken !== keycloak.token) {
      setHappeningApi(new BackendHappeningApi(keycloak.token));
    }
  }, [happeningApi.accessToken, keycloak.token]);

  return happeningApi;
};

export default useHappeningApi;
