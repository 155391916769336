import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import EmailIcon from '@mui/icons-material/Email';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import {
  Box,
  Button,
  Fade,
  Grid,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import { ClientUser } from 'digital-vital-backend-api';
import { CSSProperties, FC, useCallback, useMemo, useState } from 'react';
import { CONTACT_ALL_TEXT, NO_PARTICIPANT_TEXT } from '../../constants/strings';
import RenderOnAuthenticated from '../RenderOnAuthenticated';

const styles: Record<string, CSSProperties> = {
  buttonStyle: {
    position: 'absolute',
    bottom: '10%',
    right: '2%',
    textTransform: 'uppercase'
  }
};

type HappeningDetailsParticipantsListProps = {
  buttonText: string;
  participants: Array<ClientUser>;
  onContactParticipants: (all: boolean, participant?: ClientUser) => void;
  isOrganizer: boolean;
};

const HappeningDetailsParticipantsList: FC<
  HappeningDetailsParticipantsListProps
> = ({ buttonText, participants, onContactParticipants, isOrganizer }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpenList = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const hasParticipants = useMemo(
    () => participants.length !== 0,
    [participants]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleSendEmail = useCallback(
    (participant: ClientUser) => {
      onContactParticipants(false, participant);
      handleClose();
    },
    [handleClose, onContactParticipants]
  );

  const handleSendEmailAll = useCallback(() => {
    onContactParticipants(true);
    handleClose();
  }, [handleClose, onContactParticipants]);

  return (
    <RenderOnAuthenticated>
      <Button
        variant="contained"
        sx={styles.buttonStyle}
        onClick={handleOpenList}
        startIcon={<PeopleAltIcon />}
        endIcon={<ArrowDropDownIcon />}
      >
        {buttonText}
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        sx={{
          '& .MuiPopover-paper': {
            width: '13.35rem'
          },
          textAlign: 'center',
          maxHeight: '280px'
        }}
      >
        {participants.map((participant) => {
          return (
            <MenuItem key={participant.id}>
              <Grid container sx={{ textAlign: 'left' }}>
                <Grid item xs={2}>
                  <AccountCircleIcon color="primary" />
                </Grid>
                <Grid item xs={8}>
                  {participant.displayName}
                </Grid>
                {isOrganizer && (
                  <Grid
                    item
                    xs={2}
                    onClick={() => {
                      handleSendEmail(participant);
                    }}
                  >
                    <EmailIcon color="primary" />
                  </Grid>
                )}
              </Grid>
            </MenuItem>
          );
        })}
        {isOrganizer && hasParticipants && (
          <Box>
            <Button
              variant="contained"
              onClick={handleSendEmailAll}
              startIcon={<EmailIcon />}
              sx={{ textTransform: 'none' }}
            >
              {CONTACT_ALL_TEXT}
            </Button>
          </Box>
        )}
        {!hasParticipants && (
          <Typography variant="body1">{NO_PARTICIPANT_TEXT}</Typography>
        )}
      </Menu>
    </RenderOnAuthenticated>
  );
};

export default HappeningDetailsParticipantsList;
