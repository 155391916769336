import HowToRegIcon from '@mui/icons-material/HowToReg';
import NearMeIcon from '@mui/icons-material/NearMe';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import TableBarIcon from '@mui/icons-material/TableBar';
import { Box, Container, ThemeProvider } from '@mui/material';
import { CSSProperties, FC, useEffect, useMemo, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import TabNavBar from '../../components/TabNavBar';
import {
  NACHBARSCHAFTSTISCHE_TAB_1_TEXT,
  NACHBARSCHAFTSTISCHE_TAB_2_TEXT,
  NACHBARSCHAFTSTISCHE_TAB_3_TEXT,
  NACHBARSCHAFTSTISCHE_TAB_4_TEXT,
  NACHBARSCHAFTSTISCHE_TAB_5_TEXT
} from '../../constants/strings';
import useAuthContext from '../../hooks/useAuthContext';
import { NACHBARSCHAFTSTISCHE_PRIMARY } from '../../themes/colors/globalColors';
import neighborhoodTheme from '../../themes/neighborhoodTheme';

const useStyles = (): Record<string, CSSProperties> => ({
  fullWidth: {
    width: '100%'
  },
  navBarBox: {
    position: 'fixed',
    background: NACHBARSCHAFTSTISCHE_PRIMARY,
    width: '100%',
    zIndex: 1000
  }
});

const NeighborhoodTables: FC = () => {
  const BASE_PATH = '/nachbarschaftstische';
  const { keycloak } = useAuthContext();
  const styles = useStyles();
  const navBarEl = useRef<null | HTMLDivElement>(null);
  const [navBarHeight, setNavBarHeight] = useState<number>(72);

  const allTabs = useMemo(() => {
    const tabs = [
      {
        icon: <NearMeIcon />,
        label: NACHBARSCHAFTSTISCHE_TAB_1_TEXT,
        route: 'nearbytisch'
      },
      {
        icon: <RestaurantIcon />,
        label: NACHBARSCHAFTSTISCHE_TAB_4_TEXT,
        route: 'tischanbieten'
      }
    ];

    if (keycloak.authenticated) {
      const authTabs = [...tabs];
      authTabs.splice(
        1,
        0,
        {
          icon: <HowToRegIcon />,
          label: NACHBARSCHAFTSTISCHE_TAB_2_TEXT,
          route: 'myregistrations'
        },
        {
          icon: <TableBarIcon />,
          label: NACHBARSCHAFTSTISCHE_TAB_3_TEXT,
          route: 'mytisch'
        }
      );

      authTabs.splice(4, 0, {
        icon: <TableBarIcon />,
        label: NACHBARSCHAFTSTISCHE_TAB_5_TEXT,
        route: 'myoldtisch'
      });

      return authTabs;
    }
    return tabs;
  }, [keycloak.authenticated]);

  useEffect(() => {
    if (navBarEl.current && navBarEl.current.clientHeight !== navBarHeight) {
      setNavBarHeight(navBarEl.current.clientHeight);
    }
  }, [navBarEl.current?.clientHeight, navBarHeight]);

  return (
    <ThemeProvider theme={neighborhoodTheme}>
      <Box sx={styles.fullWidth}>
        <Box sx={styles.navBarBox} ref={navBarEl}>
          <Container>
            <TabNavBar basePath={BASE_PATH} tabs={allTabs} />
          </Container>
        </Box>
        <Box sx={{ height: `${navBarHeight}px` }} />
        <Container>
          <Box sx={{ padding: { sm: '10px', xs: '5px' } }}>
            <Outlet />
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default NeighborhoodTables;
