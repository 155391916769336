"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageableObjectToJSON = exports.PageableObjectFromJSONTyped = exports.PageableObjectFromJSON = void 0;
const runtime_1 = require("../runtime");
const Sort_1 = require("./Sort");
function PageableObjectFromJSON(json) {
    return PageableObjectFromJSONTyped(json, false);
}
exports.PageableObjectFromJSON = PageableObjectFromJSON;
function PageableObjectFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'offset': !runtime_1.exists(json, 'offset') ? undefined : json['offset'],
        'sort': !runtime_1.exists(json, 'sort') ? undefined : Sort_1.SortFromJSON(json['sort']),
        'pageNumber': !runtime_1.exists(json, 'pageNumber') ? undefined : json['pageNumber'],
        'pageSize': !runtime_1.exists(json, 'pageSize') ? undefined : json['pageSize'],
        'paged': !runtime_1.exists(json, 'paged') ? undefined : json['paged'],
        'unpaged': !runtime_1.exists(json, 'unpaged') ? undefined : json['unpaged'],
    };
}
exports.PageableObjectFromJSONTyped = PageableObjectFromJSONTyped;
function PageableObjectToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'offset': value.offset,
        'sort': Sort_1.SortToJSON(value.sort),
        'pageNumber': value.pageNumber,
        'pageSize': value.pageSize,
        'paged': value.paged,
        'unpaged': value.unpaged,
    };
}
exports.PageableObjectToJSON = PageableObjectToJSON;
