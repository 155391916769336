export const isValidDate = (d: unknown): boolean => {
  return d instanceof Date && !isNaN(d.getTime());
};

export const copyDate = (to: Date | null, from: Date | null): Date | null => {
  if (to && from && isValidDate(to) && isValidDate(from)) {
    to.setFullYear(from.getFullYear(), from.getMonth(), from.getDate());
  }
  return to;
};

export const isDateEqual = (date1: Date, date2: Date): boolean => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

export const getSubsequentDate = (
  date1: Date | null,
  date2: Date | null
): Date | null => {
  if (date1 && date2) {
    return date1 > date2 ? date1 : date2;
  }
  return null;
};

export const getFormerDate = (
  date1: Date | null,
  date2: Date | null
): Date | null => {
  if (date1 && date2) {
    return date1 > date2 ? date2 : date1;
  }
  return null;
};

export const getFormattedDate = (date: Date | null): string => {
  if (!date) return '';
  const month = date.getUTCMonth() + 1;
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();
  return `${day}.${month}.${year}`;
};
