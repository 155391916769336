"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientHappeningExtendedToJSON = exports.ClientHappeningExtendedFromJSONTyped = exports.ClientHappeningExtendedFromJSON = exports.ClientHappeningExtendedCategoryEnum = exports.ClientHappeningExtendedMealTypeEnum = exports.ClientHappeningExtendedHappeningTypeEnum = void 0;
const runtime_1 = require("../runtime");
const ClientHappeningLocation_1 = require("./ClientHappeningLocation");
const ClientMediaItem_1 = require("./ClientMediaItem");
/**
* @export
* @enum {string}
*/
var ClientHappeningExtendedHappeningTypeEnum;
(function (ClientHappeningExtendedHappeningTypeEnum) {
    ClientHappeningExtendedHappeningTypeEnum["NeighborhoodTable"] = "NEIGHBORHOOD_TABLE";
    ClientHappeningExtendedHappeningTypeEnum["Event"] = "EVENT";
})(ClientHappeningExtendedHappeningTypeEnum = exports.ClientHappeningExtendedHappeningTypeEnum || (exports.ClientHappeningExtendedHappeningTypeEnum = {})); /**
* @export
* @enum {string}
*/
var ClientHappeningExtendedMealTypeEnum;
(function (ClientHappeningExtendedMealTypeEnum) {
    ClientHappeningExtendedMealTypeEnum["Classic"] = "CLASSIC";
    ClientHappeningExtendedMealTypeEnum["Vegan"] = "VEGAN";
    ClientHappeningExtendedMealTypeEnum["Vegetarian"] = "VEGETARIAN";
})(ClientHappeningExtendedMealTypeEnum = exports.ClientHappeningExtendedMealTypeEnum || (exports.ClientHappeningExtendedMealTypeEnum = {})); /**
* @export
* @enum {string}
*/
var ClientHappeningExtendedCategoryEnum;
(function (ClientHappeningExtendedCategoryEnum) {
    ClientHappeningExtendedCategoryEnum["HealthSport"] = "HEALTH_SPORT";
    ClientHappeningExtendedCategoryEnum["Culture"] = "CULTURE";
    ClientHappeningExtendedCategoryEnum["ExchangeEncounter"] = "EXCHANGE_ENCOUNTER";
    ClientHappeningExtendedCategoryEnum["General"] = "GENERAL";
})(ClientHappeningExtendedCategoryEnum = exports.ClientHappeningExtendedCategoryEnum || (exports.ClientHappeningExtendedCategoryEnum = {}));
function ClientHappeningExtendedFromJSON(json) {
    return ClientHappeningExtendedFromJSONTyped(json, false);
}
exports.ClientHappeningExtendedFromJSON = ClientHappeningExtendedFromJSON;
function ClientHappeningExtendedFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'title': json['title'],
        'description': json['description'],
        'startTime': json['startTime'],
        'happeningType': !runtime_1.exists(json, 'happeningType') ? undefined : json['happeningType'],
        'image': !runtime_1.exists(json, 'image') ? undefined : ClientMediaItem_1.ClientMediaItemFromJSON(json['image']),
        'location': !runtime_1.exists(json, 'location') ? undefined : ClientHappeningLocation_1.ClientHappeningLocationFromJSON(json['location']),
        'externalId': !runtime_1.exists(json, 'externalId') ? undefined : json['externalId'],
        'endTime': json['endTime'],
        'maxParticipants': json['maxParticipants'],
        'participantsCount': json['participantsCount'],
        'cost': json['cost'],
        'mealType': !runtime_1.exists(json, 'mealType') ? undefined : json['mealType'],
        'accessibility': !runtime_1.exists(json, 'accessibility') ? undefined : json['accessibility'],
        'creatorId': !runtime_1.exists(json, 'creatorId') ? undefined : json['creatorId'],
        'organizerName': !runtime_1.exists(json, 'organizerName') ? undefined : json['organizerName'],
        'organizerContact': !runtime_1.exists(json, 'organizerContact') ? undefined : json['organizerContact'],
        'category': !runtime_1.exists(json, 'category') ? undefined : json['category'],
        'online': !runtime_1.exists(json, 'online') ? undefined : json['online'],
        'url': !runtime_1.exists(json, 'url') ? undefined : json['url'],
        'registrationNotice': !runtime_1.exists(json, 'registrationNotice') ? undefined : json['registrationNotice'],
    };
}
exports.ClientHappeningExtendedFromJSONTyped = ClientHappeningExtendedFromJSONTyped;
function ClientHappeningExtendedToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'title': value.title,
        'description': value.description,
        'startTime': value.startTime,
        'happeningType': value.happeningType,
        'image': ClientMediaItem_1.ClientMediaItemToJSON(value.image),
        'location': ClientHappeningLocation_1.ClientHappeningLocationToJSON(value.location),
        'externalId': value.externalId,
        'endTime': value.endTime,
        'maxParticipants': value.maxParticipants,
        'participantsCount': value.participantsCount,
        'cost': value.cost,
        'mealType': value.mealType,
        'accessibility': value.accessibility,
        'creatorId': value.creatorId,
        'organizerName': value.organizerName,
        'organizerContact': value.organizerContact,
        'category': value.category,
        'online': value.online,
        'url': value.url,
        'registrationNotice': value.registrationNotice,
    };
}
exports.ClientHappeningExtendedToJSON = ClientHappeningExtendedToJSON;
