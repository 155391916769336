import {
  ClientComment,
  ClientCommentCreateConfirmation,
  ClientCommentCreateRequest,
  ClientCommentDeleteConfirmation,
  CommentsApi,
  CommentsApiInterface
} from 'digital-vital-backend-api';
import { APP_VARIANT_IDENTIFIER } from '../config';
import BackendApi from './BackendAPI';

export default class BackendCommentApi extends BackendApi {
  private readonly _commentsApi: CommentsApiInterface;

  constructor(accessToken?: string) {
    super(accessToken);
    this._commentsApi = new CommentsApi(this.createConfiguration());
  }

  public createComment(
    clientCommentCreateRequest: ClientCommentCreateRequest
  ): Promise<ClientCommentCreateConfirmation> {
    return this.callApi(() =>
      this._commentsApi.createComment(
        clientCommentCreateRequest,
        APP_VARIANT_IDENTIFIER
      )
    );
  }

  public deleteComment(
    commentId: string
  ): Promise<ClientCommentDeleteConfirmation> {
    return this.callApi(() =>
      this._commentsApi.deleteComment(commentId, APP_VARIANT_IDENTIFIER)
    );
  }

  public getCommentById(commentId: string): Promise<ClientComment> {
    return this.callApi(() =>
      this._commentsApi.getCommentById(commentId, APP_VARIANT_IDENTIFIER)
    );
  }

  public getCommentsByPostId(postId: string): Promise<Array<ClientComment>> {
    return this.callApi(() =>
      this._commentsApi.getCommentsByPostId(postId, APP_VARIANT_IDENTIFIER)
    );
  }
}
