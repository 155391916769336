"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientMediaItemToJSON = exports.ClientMediaItemFromJSONTyped = exports.ClientMediaItemFromJSON = void 0;
const runtime_1 = require("../runtime");
function ClientMediaItemFromJSON(json) {
    return ClientMediaItemFromJSONTyped(json, false);
}
exports.ClientMediaItemFromJSON = ClientMediaItemFromJSON;
function ClientMediaItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'urls': !runtime_1.exists(json, 'urls') ? undefined : json['urls'],
    };
}
exports.ClientMediaItemFromJSONTyped = ClientMediaItemFromJSONTyped;
function ClientMediaItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'urls': value.urls,
    };
}
exports.ClientMediaItemToJSON = ClientMediaItemToJSON;
