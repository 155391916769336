import { FC, useEffect, useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { ClientHappeningGeoAreaMapping } from 'digital-vital-backend-api';
import EventBalloon from '../../../assets/svgs/eventballoon.svg';
import HappeningList from '../../../components/HappeningList';
import {
  VERANSTALTUNGEN_NO_PIN_HEADING,
  VERANSTALTUNGEN_NO_PIN_TEXT,
  VERANSTALTUNGEN_OFFER_BTN_TEXT,
  HOME_TILE_VERANSTALTUNGEN,
  VERANSTALTUNGEN_TAB_2_TEXT
} from '../../../constants/strings';
import useHappeningApi from '../../../hooks/useHappeningApi';
import { HappeningType } from '../../../types/happening';
import { Box, Stack, Typography, Breadcrumbs, Link } from '@mui/material';
import HappeningMap from '../../../components/HappeningMap';

const MyRegisteredEvents: FC = () => {
  const happeningApi = useHappeningApi();
  const [myRegisteredEvents, setMyRegisteredEvents] = useState<
    Array<ClientHappeningGeoAreaMapping>
  >([]);

  useEffect(() => {
    const loadRegisteredEventsAsync = async () => {
      const ownHappenings = await happeningApi.getRegisteredHappenings(
        HappeningType.Event
      );
      setMyRegisteredEvents(ownHappenings);
    };
    loadRegisteredEventsAsync();
  }, [happeningApi]);

  return (
    <Stack spacing={2}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          color="text.primary"
          variant="h3"
          to="/"
          component={ReactRouterLink}
        >
          {HOME_TILE_VERANSTALTUNGEN}
        </Link>
        <Typography color="text.primary" variant="h3">
          {VERANSTALTUNGEN_TAB_2_TEXT}
        </Typography>
      </Breadcrumbs>
      <HappeningMap
        happenings={myRegisteredEvents}
        markerUrl="/markers/marker-event.png"
      />
      <HappeningList
        happeningGeoAreaMappings={myRegisteredEvents}
        createButtonText={VERANSTALTUNGEN_OFFER_BTN_TEXT}
        defaultImage={EventBalloon}
        showCreateButton={false}
      />
      {myRegisteredEvents.length === 0 && (
        <Box>
          <Typography gutterBottom={true} variant="h2">
            {VERANSTALTUNGEN_NO_PIN_HEADING}
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1rem' }}>
            {VERANSTALTUNGEN_NO_PIN_TEXT}
          </Typography>
        </Box>
      )}
    </Stack>
  );
};

export default MyRegisteredEvents;
