"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminuiUsersApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class AdminuiUsersApi extends runtime.BaseAPI {
    /**
     * Returns name, id, e-mail, geoarea, profile picture, portalVariant, etc.
     * Returns account details of a user
     */
    getUserAccountDetailsByIdRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.userId === null || requestParameters.userId === undefined) {
                throw new runtime.RequiredError('userId', 'Required parameter requestParameters.userId was null or undefined when calling getUserAccountDetailsById.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/adminui/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ClientUserAccountDetailsFromJSON(jsonValue));
        });
    }
    /**
     * Returns name, id, e-mail, geoarea, profile picture, portalVariant, etc.
     * Returns account details of a user
     */
    getUserAccountDetailsById(userId, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getUserAccountDetailsByIdRaw({ userId: userId, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * The users are returned dependent on the role the current user has: - Global admin (iese_admin): All users are returned - Portal admin (portal_admin): Only users belonging to that portal variant are returned
     * Returns all users
     */
    getUserAccountsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.page !== undefined) {
                queryParameters['page'] = requestParameters.page;
            }
            if (requestParameters.size !== undefined) {
                queryParameters['size'] = requestParameters.size;
            }
            const headerParameters = {};
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/adminui/users`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.PageClientUserAccountDetailsFromJSON(jsonValue));
        });
    }
    /**
     * The users are returned dependent on the role the current user has: - Global admin (iese_admin): All users are returned - Portal admin (portal_admin): Only users belonging to that portal variant are returned
     * Returns all users
     */
    getUserAccounts(page, size, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getUserAccountsRaw({ page: page, size: size, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * The users are returned dependent on the role the current user has: - Global admin (iese_admin): All matching users are returned - Portal admin (portal_admin): Only matching users belonging to that portal variant are returned
     * Returns all users, whose E-Mails contain the given search string. The search string must have at least 3 characters
     */
    searchUsersRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.searchParam === null || requestParameters.searchParam === undefined) {
                throw new runtime.RequiredError('searchParam', 'Required parameter requestParameters.searchParam was null or undefined when calling searchUsers.');
            }
            const queryParameters = {};
            if (requestParameters.searchParam !== undefined) {
                queryParameters['searchParam'] = requestParameters.searchParam;
            }
            if (requestParameters.page !== undefined) {
                queryParameters['page'] = requestParameters.page;
            }
            if (requestParameters.size !== undefined) {
                queryParameters['size'] = requestParameters.size;
            }
            const headerParameters = {};
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/adminui/users/search`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.PageClientUserAccountDetailsFromJSON(jsonValue));
        });
    }
    /**
     * The users are returned dependent on the role the current user has: - Global admin (iese_admin): All matching users are returned - Portal admin (portal_admin): Only matching users belonging to that portal variant are returned
     * Returns all users, whose E-Mails contain the given search string. The search string must have at least 3 characters
     */
    searchUsers(searchParam, page, size, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.searchUsersRaw({ searchParam: searchParam, page: page, size: size, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
}
exports.AdminuiUsersApi = AdminuiUsersApi;
