// Alert Types
export const INFO = 'info';
export const WARNING = 'warning';
export const SHORT_MONTH = 'short_month';
export const LONG_MONTH = 'long_month';
export const SONSTIGES = 'sonstiges';

export const NULL_UUID = '00000000-0000-0000-0000-000000000000';
export const NINE_UUID = '99999999-9999-9999-9999-999999999999';

export const DESC_MAX_LENGTH_500 = 500;
export const DESC_MAX_LENGTH_250 = 250;

export const ADDRESS_ERROR = 'ADDRESS_CANNOT_BE_RESOLVED';
export const MAX_NUMBER_HAPPENING_PARTICIPANTS =
  'MAX_NUMBER_HAPPENING_PARTICIPANTS';

export const DESC_MAX_LENGTH_SM = 200;
export const DESC_MAX_LENGTH_XS = 100;

export const DESC_TITLE_LENGTH_XS = 65;
