"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommentsApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class CommentsApi extends runtime.BaseAPI {
    /**
     * Creates a new Comment
     * Creates a new Comment
     */
    createCommentRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientCommentCreateRequest === null || requestParameters.clientCommentCreateRequest === undefined) {
                throw new runtime.RequiredError('clientCommentCreateRequest', 'Required parameter requestParameters.clientCommentCreateRequest was null or undefined when calling createComment.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/comments`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ClientCommentCreateRequestToJSON(requestParameters.clientCommentCreateRequest),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ClientCommentCreateConfirmationFromJSON(jsonValue));
        });
    }
    /**
     * Creates a new Comment
     * Creates a new Comment
     */
    createComment(clientCommentCreateRequest, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.createCommentRaw({ clientCommentCreateRequest: clientCommentCreateRequest, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Returns all comments for the given post
     * Returns all comments
     */
    deleteCommentRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.commentId === null || requestParameters.commentId === undefined) {
                throw new runtime.RequiredError('commentId', 'Required parameter requestParameters.commentId was null or undefined when calling deleteComment.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/comments/{commentId}`.replace(`{${"commentId"}}`, encodeURIComponent(String(requestParameters.commentId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ClientCommentDeleteConfirmationFromJSON(jsonValue));
        });
    }
    /**
     * Returns all comments for the given post
     * Returns all comments
     */
    deleteComment(commentId, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.deleteCommentRaw({ commentId: commentId, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Returns all comments for the given post
     * Returns all comments
     */
    getCommentByIdRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.commentId === null || requestParameters.commentId === undefined) {
                throw new runtime.RequiredError('commentId', 'Required parameter requestParameters.commentId was null or undefined when calling getCommentById.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/comments/{commentId}`.replace(`{${"commentId"}}`, encodeURIComponent(String(requestParameters.commentId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ClientCommentFromJSON(jsonValue));
        });
    }
    /**
     * Returns all comments for the given post
     * Returns all comments
     */
    getCommentById(commentId, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getCommentByIdRaw({ commentId: commentId, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Returns all comments for the given post
     * Returns all comments
     */
    getCommentsByPostIdRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.postId === null || requestParameters.postId === undefined) {
                throw new runtime.RequiredError('postId', 'Required parameter requestParameters.postId was null or undefined when calling getCommentsByPostId.');
            }
            const queryParameters = {};
            if (requestParameters.postId !== undefined) {
                queryParameters['postId'] = requestParameters.postId;
            }
            const headerParameters = {};
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/comments`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.ClientCommentFromJSON));
        });
    }
    /**
     * Returns all comments for the given post
     * Returns all comments
     */
    getCommentsByPostId(postId, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getCommentsByPostIdRaw({ postId: postId, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
}
exports.CommentsApi = CommentsApi;
