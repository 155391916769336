import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import Loader from './components/Loader';
import './index.scss';
import KeycloakService from './services/KeycloakService';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

/* Roboto fonts */
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import AuthContextProvider from './components/context-providers/AuthContextProvider';

ReactDOM.render(
  <React.StrictMode>
    <AuthContextProvider
      initOptions={KeycloakService.keycloakProviderInitConfig}
      keycloakInstance={KeycloakService.keycloakInstance}
      LoadingComponent={<Loader />}
    >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AuthContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
