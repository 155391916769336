import { TImage } from '../../../types/common';
import { EPresentType, TAccessible, TAddress, TOrganiser } from './types';

export type TState = {
  title: string;
  organiser: TOrganiser;
  description: string;
  registrationHint: string;
  address: TAddress;
  accessible: TAccessible;
  date: Date | null;
  startTime: Date | null;
  endTime: Date | null;
  maxParticipants: number;
  cost: number;
  imageId: string | null;
  deleteImage: boolean;
  image: TImage | null;
  presentType: EPresentType;
  url: string | null;
};

export const initialState: TState = {
  title: '',
  organiser: {
    name: '',
    contact: ''
  },
  description: '',
  registrationHint: '',
  address: {
    street: '',
    city: '',
    zipCode: '',
    fullAdress: ''
  },
  accessible: {
    treppe: false,
    fahrstuhl: false,
    treppenlift: false,
    parkplätze: false,
    bushaltestelle: false
  },
  date: null,
  startTime: null,
  endTime: null,
  maxParticipants: 5,
  cost: 0,
  imageId: null,
  deleteImage: false,
  image: null,
  presentType: EPresentType.ON_SITE,
  url: null
};
