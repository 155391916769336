"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientGeoAreaExtendedToJSON = exports.ClientGeoAreaExtendedFromJSONTyped = exports.ClientGeoAreaExtendedFromJSON = exports.ClientGeoAreaExtendedGeoAreaTypeEnum = void 0;
const runtime_1 = require("../runtime");
const GeoPoint_1 = require("./GeoPoint");
/**
* @export
* @enum {string}
*/
var ClientGeoAreaExtendedGeoAreaTypeEnum;
(function (ClientGeoAreaExtendedGeoAreaTypeEnum) {
    ClientGeoAreaExtendedGeoAreaTypeEnum["Nation"] = "NATION";
    ClientGeoAreaExtendedGeoAreaTypeEnum["FederalState"] = "FEDERAL_STATE";
    ClientGeoAreaExtendedGeoAreaTypeEnum["County"] = "COUNTY";
    ClientGeoAreaExtendedGeoAreaTypeEnum["AssociationOfMunicipalities"] = "ASSOCIATION_OF_MUNICIPALITIES";
    ClientGeoAreaExtendedGeoAreaTypeEnum["Municipality"] = "MUNICIPALITY";
    ClientGeoAreaExtendedGeoAreaTypeEnum["City"] = "CITY";
    ClientGeoAreaExtendedGeoAreaTypeEnum["CityDistrict"] = "CITY_DISTRICT";
    ClientGeoAreaExtendedGeoAreaTypeEnum["Unknown"] = "UNKNOWN";
})(ClientGeoAreaExtendedGeoAreaTypeEnum = exports.ClientGeoAreaExtendedGeoAreaTypeEnum || (exports.ClientGeoAreaExtendedGeoAreaTypeEnum = {}));
function ClientGeoAreaExtendedFromJSON(json) {
    return ClientGeoAreaExtendedFromJSONTyped(json, false);
}
exports.ClientGeoAreaExtendedFromJSON = ClientGeoAreaExtendedFromJSON;
function ClientGeoAreaExtendedFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'name': json['name'],
        'geoAreaType': json['geoAreaType'],
        'geoJson': !runtime_1.exists(json, 'geoJson') ? undefined : json['geoJson'],
        'center': !runtime_1.exists(json, 'center') ? undefined : GeoPoint_1.GeoPointFromJSON(json['center']),
        'postalCodes': !runtime_1.exists(json, 'postalCodes') ? undefined : json['postalCodes'],
        'leaf': !runtime_1.exists(json, 'leaf') ? undefined : json['leaf'],
        'depth': !runtime_1.exists(json, 'depth') ? undefined : json['depth'],
        'childGeoAreas': !runtime_1.exists(json, 'childGeoAreas') ? undefined : (json['childGeoAreas'].map(ClientGeoAreaExtendedFromJSON)),
    };
}
exports.ClientGeoAreaExtendedFromJSONTyped = ClientGeoAreaExtendedFromJSONTyped;
function ClientGeoAreaExtendedToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'geoAreaType': value.geoAreaType,
        'geoJson': value.geoJson,
        'center': GeoPoint_1.GeoPointToJSON(value.center),
        'postalCodes': value.postalCodes,
        'leaf': value.leaf,
        'depth': value.depth,
        'childGeoAreas': value.childGeoAreas === undefined ? undefined : (value.childGeoAreas.map(ClientGeoAreaExtendedToJSON)),
    };
}
exports.ClientGeoAreaExtendedToJSON = ClientGeoAreaExtendedToJSON;
