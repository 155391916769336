import { useEffect, useState } from 'react';
import BackendDataPrivacyApi from '../api/DataPrivacyAPI';
import useAuthContext from './useAuthContext';

const useDataPrivacyApi = (): BackendDataPrivacyApi => {
  const { keycloak } = useAuthContext();

  const [dataPrivacyApi, setDataPrivacyApi] = useState<BackendDataPrivacyApi>(
    () => new BackendDataPrivacyApi(keycloak.token)
  );

  useEffect(() => {
    if (dataPrivacyApi.accessToken !== keycloak.token) {
      setDataPrivacyApi(new BackendDataPrivacyApi(keycloak.token));
    }
  }, [dataPrivacyApi.accessToken, keycloak.token]);

  return dataPrivacyApi;
};

export default useDataPrivacyApi;
