import { createTheme } from '@mui/material/styles';
import baseTheme from './baseTheme';
import { MuiTabComponent } from './baseThemeComponents';
import {
  VERANSTALTUNGEN_ACTIVE,
  VERANSTALTUNGEN_ACTIVE_BG,
  VERANSTALTUNGEN_INACTIVE,
  VERANSTALTUNGEN_PRIMARY,
  VERANSTALTUNGEN_TEXT
} from './colors/globalColors';

const eventsTheme = createTheme({
  ...baseTheme,
  palette: {
    primary: {
      main: VERANSTALTUNGEN_PRIMARY,
      contrastText: VERANSTALTUNGEN_TEXT
    },
    secondary: {
      main: VERANSTALTUNGEN_ACTIVE,
      contrastText: VERANSTALTUNGEN_PRIMARY
    }
  },
  components: {
    ...baseTheme.components,
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          color: VERANSTALTUNGEN_PRIMARY
        }
      }
    },
    MuiTab: MuiTabComponent(
      VERANSTALTUNGEN_INACTIVE,
      VERANSTALTUNGEN_ACTIVE,
      VERANSTALTUNGEN_ACTIVE_BG
    )
  }
});

export default eventsTheme;
