"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeoPointToJSON = exports.GeoPointFromJSONTyped = exports.GeoPointFromJSON = void 0;
const runtime_1 = require("../runtime");
function GeoPointFromJSON(json) {
    return GeoPointFromJSONTyped(json, false);
}
exports.GeoPointFromJSON = GeoPointFromJSON;
function GeoPointFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'latitude': !runtime_1.exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !runtime_1.exists(json, 'longitude') ? undefined : json['longitude'],
        'valid': !runtime_1.exists(json, 'valid') ? undefined : json['valid'],
    };
}
exports.GeoPointFromJSONTyped = GeoPointFromJSONTyped;
function GeoPointToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'latitude': value.latitude,
        'longitude': value.longitude,
        'valid': value.valid,
    };
}
exports.GeoPointToJSON = GeoPointToJSON;
