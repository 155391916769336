import { FC } from 'react';
import { DialogContentText } from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { SUCCESS_DIALOG_BTN_TEXT } from '../../constants/strings';
import { SuccessDialogProps } from '../../interfaces/props';
import AbstractDialog from '../dialogs/AbstractDialog';

const SuccessDialog: FC<SuccessDialogProps> = (props: SuccessDialogProps) => {
  const { open, message, onClose } = props;
  return (
    <AbstractDialog
      icon={
        <CheckCircleRoundedIcon color="primary" sx={{ fontSize: '64px' }} />
      }
      open={open}
      btnActionText={SUCCESS_DIALOG_BTN_TEXT}
      hideCancelBtn={true}
      hideActionBtn={false}
      onClose={onClose}
      onAction={onClose}
    >
      <DialogContentText id="alert-dialog-description">
        {message}
      </DialogContentText>
    </AbstractDialog>
  );
};

export default SuccessDialog;
