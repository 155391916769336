import { Container, ThemeProvider } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  fetchPendingPosts,
  fetchUserPendingPosts
} from '../../api/InformationAPI';
import Loader from '../../components/Loader';
import ProtectedPage from '../../components/ProtectedPage';
import { MY_AREA_NOT_AUTHENTICATED } from '../../constants/strings';
import useKeycloakUtils from '../../hooks/useKeycloakUtils';
import myAreaTheme from '../../themes/myAreaTheme';
import { TPost } from '../../types/information';
import { EDirectusRole, EPortalRoles } from '../../types/roles';
import { asEnumValue } from '../../utils/common';

export type TMyAreaOutletContext = {
  pendingPosts: Array<TPost>;
  portalRoles: Array<EPortalRoles>;
  directusRole: EDirectusRole | undefined;
};

const MyAreaView: FC = () => {
  const { userId, getResourceRoles } = useKeycloakUtils();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [directusRole, setDirectusRole] = useState<EDirectusRole>();
  const [portalRoles, setPortalRoles] = useState<Array<EPortalRoles>>([]);
  const [pendingPosts, setPendingPosts] = useState<Array<TPost>>([]);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    const roles = getResourceRoles('directus');
    if (roles) {
      const role = roles[0];
      setDirectusRole(asEnumValue(EDirectusRole, role));
    } else {
      setDirectusRole(undefined);
    }
  }, [getResourceRoles]);

  useEffect(() => {
    const roles = getResourceRoles('digital-vital-portal') ?? [];
    const userPortalRoles = roles
      .map((r) => asEnumValue(EPortalRoles, r))
      .filter((p) => p !== undefined);
    setPortalRoles(userPortalRoles as Array<EPortalRoles>);
  }, [getResourceRoles]);

  useEffect(() => {
    const loadPostsAsync = async () => {
      if (!userId || !directusRole) {
        return;
      }
      try {
        setLoading(true);
        switch (directusRole) {
          case EDirectusRole.CHIEFEDITOR: {
            const posts = await fetchPendingPosts();
            setPendingPosts(posts);
            break;
          }
          case EDirectusRole.REPORTER: {
            const posts = await fetchUserPendingPosts(userId);
            setPendingPosts(posts);
            break;
          }
          default:
            break;
        }
      } finally {
        setLoading(false);
      }
    };
    loadPostsAsync();
  }, [directusRole, userId]);

  return (
    <ThemeProvider theme={myAreaTheme}>
      <ProtectedPage
        errorMessage={MY_AREA_NOT_AUTHENTICATED}
        onErrorDialogClose={goBack}
      >
        <Container sx={{ marginTop: '1em', marginBottom: '1em' }}>
          {loading ? (
            <Loader />
          ) : (
            <Outlet context={{ pendingPosts, portalRoles, directusRole }} />
          )}
        </Container>
      </ProtectedPage>
    </ThemeProvider>
  );
};

export default MyAreaView;
