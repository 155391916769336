import { FC } from 'react';
import { ClientHappeningExtended } from 'digital-vital-backend-api';
import NotAuthenticatedDialog from '../dialogs/NotAuthenticatedDialog';
import { Typography } from '@mui/material';

type ParticipationNotAuthenticatedDialogProps = {
  happening: ClientHappeningExtended;
  open: boolean;
  onClose: () => void;
};

const ParticipationNotAuthenticatedDialog: FC<
  ParticipationNotAuthenticatedDialogProps
> = (props: ParticipationNotAuthenticatedDialogProps) => {
  const { happening, open, onClose } = props;
  return (
    <NotAuthenticatedDialog open={open} onClose={onClose}>
      <Typography align="center">
        {'Sie müssen sich anmelden um Teilnehmer für'}
      </Typography>
      <Typography align="center">
        <strong>
          <q>{happening.title}</q>
        </strong>
      </Typography>
      <Typography align="center">{'bestätigen zu können!'}</Typography>
    </NotAuthenticatedDialog>
  );
};

export default ParticipationNotAuthenticatedDialog;
