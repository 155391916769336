import { useEffect, useState } from 'react';
import BackendGeoAreaAPI from '../api/GeoAreaAPI';
import useAuthContext from './useAuthContext';

const useGeoAreaApi = (): BackendGeoAreaAPI => {
  const { keycloak } = useAuthContext();

  const [geoAreaApi, setGeoAreaApi] = useState<BackendGeoAreaAPI>(
    () => new BackendGeoAreaAPI(keycloak.token)
  );

  useEffect(() => {
    if (geoAreaApi.accessToken !== keycloak.token) {
      setGeoAreaApi(new BackendGeoAreaAPI(keycloak.token));
    }
  }, [geoAreaApi.accessToken, keycloak.token]);

  return geoAreaApi;
};

export default useGeoAreaApi;
