"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientPostUpdateRequestToJSON = exports.ClientPostUpdateRequestFromJSONTyped = exports.ClientPostUpdateRequestFromJSON = exports.ClientPostUpdateRequestCategoryEnum = exports.ClientPostUpdateRequestPostTypeEnum = void 0;
const runtime_1 = require("../runtime");
/**
* @export
* @enum {string}
*/
var ClientPostUpdateRequestPostTypeEnum;
(function (ClientPostUpdateRequestPostTypeEnum) {
    ClientPostUpdateRequestPostTypeEnum["Seeking"] = "SEEKING";
    ClientPostUpdateRequestPostTypeEnum["Offer"] = "OFFER";
})(ClientPostUpdateRequestPostTypeEnum = exports.ClientPostUpdateRequestPostTypeEnum || (exports.ClientPostUpdateRequestPostTypeEnum = {})); /**
* @export
* @enum {string}
*/
var ClientPostUpdateRequestCategoryEnum;
(function (ClientPostUpdateRequestCategoryEnum) {
    ClientPostUpdateRequestCategoryEnum["Culture"] = "CULTURE";
    ClientPostUpdateRequestCategoryEnum["Encounter"] = "ENCOUNTER";
    ClientPostUpdateRequestCategoryEnum["HealthSport"] = "HEALTH_SPORT";
    ClientPostUpdateRequestCategoryEnum["NeighborhoodHelp"] = "NEIGHBORHOOD_HELP";
    ClientPostUpdateRequestCategoryEnum["General"] = "GENERAL";
})(ClientPostUpdateRequestCategoryEnum = exports.ClientPostUpdateRequestCategoryEnum || (exports.ClientPostUpdateRequestCategoryEnum = {}));
function ClientPostUpdateRequestFromJSON(json) {
    return ClientPostUpdateRequestFromJSONTyped(json, false);
}
exports.ClientPostUpdateRequestFromJSON = ClientPostUpdateRequestFromJSON;
function ClientPostUpdateRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'postType': json['postType'],
        'title': json['title'],
        'text': json['text'],
        'temporaryMediaItemId': !runtime_1.exists(json, 'temporaryMediaItemId') ? undefined : json['temporaryMediaItemId'],
        'category': json['category'],
    };
}
exports.ClientPostUpdateRequestFromJSONTyped = ClientPostUpdateRequestFromJSONTyped;
function ClientPostUpdateRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'postType': value.postType,
        'title': value.title,
        'text': value.text,
        'temporaryMediaItemId': value.temporaryMediaItemId,
        'category': value.category,
    };
}
exports.ClientPostUpdateRequestToJSON = ClientPostUpdateRequestToJSON;
