import {
  ClientCalendarEventCreateRequest,
  ClientCalendarEventUpdateRequest,
  ClientContactOrganizerRequest,
  ClientHappeningContactParticipantsConfirmation,
  ClientHappeningCreateConfirmation,
  ClientHappeningDeleteConfirmation,
  ClientHappeningExtended,
  ClientHappeningGeoAreaMapping,
  ClientHappeningMessageRequest,
  ClientHappeningParticipation,
  ClientNeighborhoodTableCreateRequest,
  ClientNeighborhoodTableUpdateRequest,
  ClientUser,
  HappeningApi,
  HappeningApiInterface,
  PublishToTermineRegionalConfirmation
} from 'digital-vital-backend-api';
import { APP_VARIANT_IDENTIFIER } from '../config';
import { HappeningType } from '../types/happening';
import BackendApi from './BackendAPI';
import HappeningTypeConverter from './HappeningTypeConverter';

export default class BackendHappeningApi extends BackendApi {
  private readonly _happeningApi: HappeningApiInterface;

  constructor(accessToken?: string) {
    super(accessToken);
    this._happeningApi = new HappeningApi(this.createConfiguration());
  }

  public createCalendarEvent(
    clientCalendarEventCreateRequest: ClientCalendarEventCreateRequest
  ): Promise<ClientHappeningCreateConfirmation> {
    return this.callApi(() =>
      this._happeningApi.createCalendarEvent(
        clientCalendarEventCreateRequest,
        APP_VARIANT_IDENTIFIER
      )
    );
  }

  public createNeighborhoodTable(
    clientNeighborhoodTableCreateRequest: ClientNeighborhoodTableCreateRequest
  ): Promise<ClientHappeningCreateConfirmation> {
    return this.callApi(() =>
      this._happeningApi.createNeighborhoodTable(
        clientNeighborhoodTableCreateRequest,
        APP_VARIANT_IDENTIFIER
      )
    );
  }

  public updateCalendarEvent(
    happeningId: string,
    clientCalendarEventUpdateRequest: ClientCalendarEventUpdateRequest
  ): Promise<ClientHappeningCreateConfirmation> {
    return this.callApi(() =>
      this._happeningApi.updateCalendarEvent(
        happeningId,
        clientCalendarEventUpdateRequest,
        APP_VARIANT_IDENTIFIER
      )
    );
  }

  public updateNeighborhoodTable(
    happeningId: string,
    clientNeighborhoodTableUpdateRequest: ClientNeighborhoodTableUpdateRequest
  ): Promise<ClientHappeningCreateConfirmation> {
    return this.callApi(() =>
      this._happeningApi.updateNeighborhoodTable(
        happeningId,
        clientNeighborhoodTableUpdateRequest,
        APP_VARIANT_IDENTIFIER
      )
    );
  }

  public getAllHappenings(
    happeningType?: HappeningType
  ): Promise<Array<ClientHappeningGeoAreaMapping>> {
    return this.callApi(() =>
      this._happeningApi.getAllHappenings(
        HappeningTypeConverter.toGetAllHappeningsHappeningTypeEnum(
          happeningType
        ),
        APP_VARIANT_IDENTIFIER
      )
    );
  }

  public getHappeningById(
    happeningId: string
  ): Promise<ClientHappeningExtended> {
    return this.callApi(() =>
      this._happeningApi.getHappeningById(happeningId, APP_VARIANT_IDENTIFIER)
    );
  }

  public getOldHappenings(
    happeningType?: HappeningType
  ): Promise<Array<ClientHappeningGeoAreaMapping>> {
    return this.callApi(() =>
      this._happeningApi.getOldHappenings(
        HappeningTypeConverter.toGetOldHappeningsHappeningTypeEnum(
          happeningType
        ),
        APP_VARIANT_IDENTIFIER
      )
    );
  }

  public getOwnHappenings(
    happeningType?: HappeningType
  ): Promise<Array<ClientHappeningGeoAreaMapping>> {
    return this.callApi(() =>
      this._happeningApi.getOwnHappenings(
        HappeningTypeConverter.toGetOwnHappeningsHappeningTypeEnum(
          happeningType
        ),
        APP_VARIANT_IDENTIFIER
      )
    );
  }

  public getRegisteredHappenings(
    happeningType?: HappeningType
  ): Promise<Array<ClientHappeningGeoAreaMapping>> {
    return this.callApi(() =>
      this._happeningApi.getAllHappeningsByOwnParticipation(
        HappeningTypeConverter.toGetAllHappeningsByOwnParticipationHappeningTypeEnum(
          happeningType
        ),
        APP_VARIANT_IDENTIFIER
      )
    );
  }

  public async hasCurrentUserHappeningParticipation(
    happeningId: string,
    happeningType: HappeningType
  ): Promise<boolean> {
    const allRegisteredHappenings = await this.getRegisteredHappenings(
      happeningType
    );
    return allRegisteredHappenings
      .flatMap((v) => v.happenings)
      .some((h) => h.id === happeningId);
  }

  public contactOrganizer(
    happeningId: string,
    contactOrganizerRequest: ClientContactOrganizerRequest
  ): Promise<void> {
    return this.callApi(() =>
      this._happeningApi.contactOrganizer(
        happeningId,
        contactOrganizerRequest,
        APP_VARIANT_IDENTIFIER
      )
    );
  }

  public contactParticipant(
    happeningId: string,
    participantId: string,
    messageRequest: ClientHappeningMessageRequest
  ): Promise<void> {
    return this.callApi(() =>
      this._happeningApi.contactParticipant(
        happeningId,
        participantId,
        messageRequest,
        APP_VARIANT_IDENTIFIER
      )
    );
  }

  public contactParticipants(
    happeningId: string,
    messageRequest: ClientHappeningMessageRequest
  ): Promise<ClientHappeningContactParticipantsConfirmation> {
    return this.callApi(() =>
      this._happeningApi.contactParticipants(
        happeningId,
        messageRequest,
        APP_VARIANT_IDENTIFIER
      )
    );
  }

  public joinHappening(happeningId: string): Promise<void> {
    return this.callApi(() =>
      this._happeningApi.joinHappening(happeningId, APP_VARIANT_IDENTIFIER)
    );
  }

  public getHappeningParticipation(
    happeningId: string,
    participationId: string
  ): Promise<ClientHappeningParticipation> {
    return this.callApi(() =>
      this._happeningApi.getHappeningParticipationById(
        happeningId,
        participationId,
        APP_VARIANT_IDENTIFIER
      )
    );
  }

  public cancelHappeningParticipation(happeningId: string): Promise<void> {
    return this.callApi(() =>
      this._happeningApi.cancelHappeningParticipation(
        happeningId,
        APP_VARIANT_IDENTIFIER
      )
    );
  }

  public rejectHappeningParticipation(
    happeningId: string,
    participationId: string,
    clientHappeningMessageRequest: ClientHappeningMessageRequest
  ): Promise<void> {
    return this.callApi(() =>
      this._happeningApi.rejectHappeningParticipation(
        happeningId,
        participationId,
        clientHappeningMessageRequest,
        APP_VARIANT_IDENTIFIER
      )
    );
  }

  public acceptHappeningParticipation(
    happeningId: string,
    participationId: string
  ): Promise<void> {
    return this.callApi(() =>
      this._happeningApi.acceptHappeningParticipation(
        happeningId,
        participationId,
        APP_VARIANT_IDENTIFIER
      )
    );
  }

  public getHappeningParticipants(
    happeningId: string
  ): Promise<Array<ClientUser>> {
    return this.callApi(() =>
      this._happeningApi.getHappeningParticipants(
        happeningId,
        APP_VARIANT_IDENTIFIER
      )
    );
  }

  public cancelHappening(
    happeningId: string,
    clientHappeningMessageRequest: ClientHappeningMessageRequest
  ): Promise<void> {
    return this.callApi(() =>
      this._happeningApi.cancelHappening(
        happeningId,
        clientHappeningMessageRequest,
        APP_VARIANT_IDENTIFIER
      )
    );
  }

  public deleteHappening(
    happeningId: string
  ): Promise<ClientHappeningDeleteConfirmation> {
    return this.callApi(() =>
      this._happeningApi.deleteHappening(happeningId, APP_VARIANT_IDENTIFIER)
    );
  }

  public publishToTermineRegional(
    happeningId: string
  ): Promise<PublishToTermineRegionalConfirmation> {
    return this.callApi(() =>
      this._happeningApi.publishToTermineRegional(
        happeningId,
        APP_VARIANT_IDENTIFIER
      )
    );
  }
}
