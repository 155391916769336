import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ClientPostTypeEnum } from 'digital-vital-backend-api';
import { CSSProperties, FC } from 'react';
import { OFFER_TEXT, SEEKING_TEXT } from '../../constants/strings';

const useStyles = (): Record<string, CSSProperties> => ({
  tagStyle: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    background: 'white',
    padding: 1,
    alignItems: 'center',
    borderRadius: '0px 0px 5px 5px'
  },
  iconStyle: {
    paddingRight: '.5rem',
    fontSize: ' 1.75rem'
  }
});

interface DetailsTypeTagProps {
  type?: ClientPostTypeEnum;
}

const DetailsTypeTag: FC<DetailsTypeTagProps> = ({ type = '' }) => {
  const styles = useStyles();
  return (
    <Box sx={styles.tagStyle}>
      {
        {
          OFFER: (
            <>
              <LocalOfferIcon color="primary" sx={styles.iconStyle} />
              <Typography variant="body2" fontWeight="bold">
                {OFFER_TEXT}
              </Typography>
            </>
          ),
          SEEKING: (
            <>
              <ManageSearchIcon color="primary" sx={styles.iconStyle} />
              <Typography variant="body2" fontWeight="bold">
                {SEEKING_TEXT}
              </Typography>
            </>
          )
        }[type]
      }
    </Box>
  );
};

export default DetailsTypeTag;
