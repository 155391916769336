import { FC } from 'react';
import { useOutletContext } from 'react-router-dom';
import PostDetail from '../../components/PostDetail';
import { TPostOutletContext } from './PostView';

const PostDetailView: FC = () => {
  const { posts } = useOutletContext<TPostOutletContext>();

  return <PostDetail posts={posts} />;
};

export default PostDetailView;
