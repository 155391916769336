"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientUserExtendedToJSON = exports.ClientUserExtendedFromJSONTyped = exports.ClientUserExtendedFromJSON = void 0;
const runtime_1 = require("../runtime");
const ClientGeoArea_1 = require("./ClientGeoArea");
const ClientMediaItem_1 = require("./ClientMediaItem");
function ClientUserExtendedFromJSON(json) {
    return ClientUserExtendedFromJSONTyped(json, false);
}
exports.ClientUserExtendedFromJSON = ClientUserExtendedFromJSON;
function ClientUserExtendedFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'displayName': json['displayName'],
        'aboutMe': !runtime_1.exists(json, 'aboutMe') ? undefined : json['aboutMe'],
        'homeGeoArea': ClientGeoArea_1.ClientGeoAreaFromJSON(json['homeGeoArea']),
        'profilePicture': !runtime_1.exists(json, 'profilePicture') ? undefined : ClientMediaItem_1.ClientMediaItemFromJSON(json['profilePicture']),
        'emailAddress': json['emailAddress'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'street': !runtime_1.exists(json, 'street') ? undefined : json['street'],
        'zipCode': !runtime_1.exists(json, 'zipCode') ? undefined : json['zipCode'],
        'city': !runtime_1.exists(json, 'city') ? undefined : json['city'],
        'telephone': !runtime_1.exists(json, 'telephone') ? undefined : json['telephone'],
        'portalVariantIdentifier': !runtime_1.exists(json, 'portalVariantIdentifier') ? undefined : json['portalVariantIdentifier'],
    };
}
exports.ClientUserExtendedFromJSONTyped = ClientUserExtendedFromJSONTyped;
function ClientUserExtendedToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'displayName': value.displayName,
        'aboutMe': value.aboutMe,
        'homeGeoArea': ClientGeoArea_1.ClientGeoAreaToJSON(value.homeGeoArea),
        'profilePicture': ClientMediaItem_1.ClientMediaItemToJSON(value.profilePicture),
        'emailAddress': value.emailAddress,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'street': value.street,
        'zipCode': value.zipCode,
        'city': value.city,
        'telephone': value.telephone,
        'portalVariantIdentifier': value.portalVariantIdentifier,
    };
}
exports.ClientUserExtendedToJSON = ClientUserExtendedToJSON;
