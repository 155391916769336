import React, {
  FC,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState
} from 'react';
import { Menu, MenuItem, Tab, Tabs } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { TCategory, TSubCategory } from '../types/information';

type CategoryNavBarProps = {
  selectedCategory: TCategory | undefined;
  currentSubCategories: Array<TSubCategory>;
  categories: Array<TCategory>;
  subCategories: Array<TSubCategory>;
  onCategoryChange: (categoryId: string) => void;
  onSubCategoryChange: (subCategoryId: string) => void;
};

const CategoryNavBar: FC<CategoryNavBarProps> = (props) => {
  const {
    selectedCategory,
    currentSubCategories,
    categories,
    subCategories,
    onCategoryChange,
    onSubCategoryChange
  } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const subCategoryMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const hasSubCategories = useCallback(
    (category: TCategory) => {
      const { id } = category;
      return subCategories.some((s) => s.categoryId === id);
    },
    [subCategories]
  );

  const showSubCategoryMenu = useCallback(
    (category: TCategory, event: React.MouseEvent<HTMLElement>) => {
      if (category.id !== selectedCategory?.id) {
        return;
      }
      if (subCategories.length > 0) {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
      }
    },
    [selectedCategory, subCategories]
  );

  const closeSubCategoryMenu = () => {
    setAnchorEl(undefined);
  };

  const handleCategoryChange = useCallback(
    (_event: SyntheticEvent, value: string) => {
      onCategoryChange(value);
    },
    [onCategoryChange]
  );

  const handleSubCategoryChange = useCallback(
    (subCategory: TSubCategory, event: React.MouseEvent<HTMLLIElement>) => {
      event.preventDefault();
      event.stopPropagation();
      onSubCategoryChange(subCategory.id);
      closeSubCategoryMenu();
    },
    [onSubCategoryChange]
  );

  return (
    <>
      <Tabs
        value={selectedCategory?.id ?? false}
        onChange={handleCategoryChange}
        textColor="secondary"
        variant="scrollable"
        indicatorColor="secondary"
      >
        {categories.map((c) => {
          if (!hasSubCategories(c)) {
            return (
              <Tab
                key={c.id}
                disableRipple={true}
                label={c.name}
                value={c.id}
              />
            );
          }
          return (
            <Tab
              key={c.id}
              disableRipple={true}
              icon={<KeyboardArrowDownIcon />}
              iconPosition="end"
              label={c.name}
              value={c.id}
              onClick={(event) => showSubCategoryMenu(c, event)}
            />
          );
        })}
      </Tabs>
      <Menu
        open={subCategoryMenuOpen}
        onClose={closeSubCategoryMenu}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {currentSubCategories.map((s) => {
          return (
            <MenuItem
              key={s.id}
              onClick={(event) => handleSubCategoryChange(s, event)}
            >
              {s.name}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default CategoryNavBar;
