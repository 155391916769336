"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientPostCreateRequestToJSON = exports.ClientPostCreateRequestFromJSONTyped = exports.ClientPostCreateRequestFromJSON = exports.ClientPostCreateRequestCategoryEnum = exports.ClientPostCreateRequestContentTypeEnum = void 0;
const runtime_1 = require("../runtime");
/**
* @export
* @enum {string}
*/
var ClientPostCreateRequestContentTypeEnum;
(function (ClientPostCreateRequestContentTypeEnum) {
    ClientPostCreateRequestContentTypeEnum["Seeking"] = "SEEKING";
    ClientPostCreateRequestContentTypeEnum["Offer"] = "OFFER";
})(ClientPostCreateRequestContentTypeEnum = exports.ClientPostCreateRequestContentTypeEnum || (exports.ClientPostCreateRequestContentTypeEnum = {})); /**
* @export
* @enum {string}
*/
var ClientPostCreateRequestCategoryEnum;
(function (ClientPostCreateRequestCategoryEnum) {
    ClientPostCreateRequestCategoryEnum["Culture"] = "CULTURE";
    ClientPostCreateRequestCategoryEnum["Encounter"] = "ENCOUNTER";
    ClientPostCreateRequestCategoryEnum["HealthSport"] = "HEALTH_SPORT";
    ClientPostCreateRequestCategoryEnum["NeighborhoodHelp"] = "NEIGHBORHOOD_HELP";
    ClientPostCreateRequestCategoryEnum["General"] = "GENERAL";
})(ClientPostCreateRequestCategoryEnum = exports.ClientPostCreateRequestCategoryEnum || (exports.ClientPostCreateRequestCategoryEnum = {}));
function ClientPostCreateRequestFromJSON(json) {
    return ClientPostCreateRequestFromJSONTyped(json, false);
}
exports.ClientPostCreateRequestFromJSON = ClientPostCreateRequestFromJSON;
function ClientPostCreateRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contentType': json['contentType'],
        'title': json['title'],
        'text': json['text'],
        'temporaryMediaItemId': !runtime_1.exists(json, 'temporaryMediaItemId') ? undefined : json['temporaryMediaItemId'],
        'category': json['category'],
    };
}
exports.ClientPostCreateRequestFromJSONTyped = ClientPostCreateRequestFromJSONTyped;
function ClientPostCreateRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'contentType': value.contentType,
        'title': value.title,
        'text': value.text,
        'temporaryMediaItemId': value.temporaryMediaItemId,
        'category': value.category,
    };
}
exports.ClientPostCreateRequestToJSON = ClientPostCreateRequestToJSON;
