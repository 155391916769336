import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DateRangeIcon from '@mui/icons-material/DateRange';
import EuroIcon from '@mui/icons-material/Euro';
import HomeIcon from '@mui/icons-material/Home';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import {
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  Link,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import {
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ClientHappeningExtended } from 'digital-vital-backend-api';
// eslint-disable-next-line import/named
import moment, { Moment } from 'moment';
import 'moment/locale/de';
import React, {
  FC,
  Fragment,
  Reducer,
  useCallback,
  useEffect,
  useMemo,
  useReducer
} from 'react';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';
import { DESC_MAX_LENGTH_500 } from '../../constants/constants';
import {
  ADDRESS_TEXT,
  DAY_TEXT,
  DESC_TEXT_INFO,
  FURTHER_BTN_TEXT,
  HEADER_TITLE_NACHBARSCHAFTSTISCHE,
  HEADER_TITLE_VERANSTALTUNGEN,
  NACHBARSCHAFTSTISCHE_TAB_1_TEXT,
  NACHBARSCHAFTSTISCHE_TAB_2_TEXT,
  NACHBARSCHAFTSTISCHE_TAB_3_TEXT,
  NACHBARSCHAFTSTISCHE_TAB_4_TEXT,
  PARTICIPANTS_TEXT,
  PRICE_TEXT,
  REGISTRATION_HINT_LABEL,
  REGISTRATION_HINT_PLACEHOLDER,
  TIME_TEXT,
  TITLE_TEXT_INFO,
  VERANSTALTUNGEN_TAB_1_TEXT,
  VERANSTALTUNGEN_TAB_2_TEXT,
  VERANSTALTUNGEN_TAB_3_TEXT,
  VERANSTALTUNGEN_TAB_4_TEXT
} from '../../constants/strings';
import { validations } from '../../constants/validations.json';
import useCurrentUser from '../../hooks/useCurrentUser';
import useMediaItemApi from '../../hooks/useMediaItemApi';
import {
  isFloatValid,
  isIntValid,
  validateString,
  validateTimeRange
} from '../../utils/common';
import { isDateEqual } from '../../utils/date';
import CharacterLimitIndicator from '../CharacterLimitIndicator';
import EditableImage from '../EditableImage';
import ExtraHelperText from '../ExtraHelperText';
import AccessibilitySelection from './components/AccessibilitySelection';
import CreateFormItem from './components/CreateFormItem';
import CustomSelect from './components/CustomSelect';
import CustomTextField from './components/CustomTextField';
import EditableAddress from './components/EditableAddress';
import NumberInput from './components/NumberInput';
import OrganiserForm from './components/OrganiserForm';
import PresentTypeSelection from './components/PresentTypeSelection';
import {
  reset,
  setAccessibleValue,
  setAddress,
  setCost,
  setDate,
  setDeleteImage,
  setDescription,
  setEditableProperties,
  setEndTime,
  setImage,
  setImageId,
  setMaxParticipants,
  setOrganiserContact,
  setOrganiserName,
  setPresentType,
  setRegistrationHint,
  setStartTime,
  setTitle,
  setUrl,
  TAction
} from './store/actions';
import { reducer } from './store/reducer';
import { initialState, TState } from './store/state';
import { EPresentType, TAccessible, THappeningProperties } from './store/types';

type CreateHappeningProps = {
  happening?: ClientHappeningExtended;
  heading: string;
  titleLabel: string;
  descriptionLabel: string;
  descriptionPlaceholder: string;
  categories: Array<string>;
  currentCategory: string;
  categoryLabel: string;
  categoryIcon: React.ReactElement;
  imageIcon: React.ReactElement;
  showOrganiserForm: boolean;
  showPresentTypeSelection: boolean;
  showRegistrationHintInput: boolean;
  onCategoryChange: (category: string) => void;
  onContinue: (request: THappeningProperties, errors: string[]) => void;
};

const CreateHappening: FC<CreateHappeningProps> = (
  props: CreateHappeningProps
) => {
  const {
    happening,
    heading,
    titleLabel,
    descriptionLabel,
    descriptionPlaceholder,
    categories,
    currentCategory,
    categoryLabel,
    categoryIcon,
    imageIcon,
    showOrganiserForm,
    showPresentTypeSelection,
    showRegistrationHintInput,
    onCategoryChange,
    onContinue
  } = props;
  const theme = useTheme();
  const mediaItemApi = useMediaItemApi();
  const { currentUser } = useCurrentUser();
  const { palette } = theme;
  const [state, dispatch] = useReducer<Reducer<TState, TAction>>(
    reducer,
    initialState
  );

  const {
    title,
    organiser,
    description,
    registrationHint,
    address,
    accessible,
    date,
    startTime,
    endTime,
    maxParticipants,
    cost,
    imageId,
    deleteImage,
    image,
    presentType,
    url
  } = state;

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        setAccessibleValue(
          event.target.name as keyof TAccessible,
          event.target.checked
        )
      );
    },
    []
  );

  const handleUpdateAddress = useCallback(
    (street: string, zipCode: string, city: string) => {
      dispatch(setAddress(street, zipCode, city));
    },
    []
  );

  const handleTitleChange = useCallback(
    (e) => dispatch(setTitle(e.target.value)),
    []
  );

  const handleOrganiserNameChange = useCallback(
    (name) => dispatch(setOrganiserName(name)),
    []
  );

  const handleOrganiserContactChange = useCallback(
    (contact) => dispatch(setOrganiserContact(contact)),
    []
  );

  const handleDescriptionChange = useCallback(
    (e) => dispatch(setDescription(e.target.value)),
    []
  );

  const handleRegistrationHintChange = useCallback(
    (e) => dispatch(setRegistrationHint(e.target.value)),
    []
  );

  const handleImageChange = useCallback((temporaryMediaItemId: string) => {
    dispatch(setImageId(temporaryMediaItemId));
  }, []);

  const handleDeleteImage = useCallback((removeImage: boolean) => {
    dispatch(setDeleteImage(removeImage));
    dispatch(setImageId(null));
    dispatch(setImage(null));
  }, []);

  const handleDateChange = useCallback((d: unknown) => {
    const newDate = d as Moment;
    dispatch(setDate(newDate?.toDate()));
  }, []);

  const handleStartTimeChange = useCallback((d: unknown) => {
    const newStartTime = d as Moment;
    dispatch(setStartTime(newStartTime?.toDate()));
  }, []);

  const handleEndTimeChange = useCallback((d: unknown) => {
    const newEndTime = d as Moment;
    dispatch(setEndTime(newEndTime?.toDate()));
  }, []);

  const handleCategoryChange = useCallback(
    (e) => onCategoryChange(e.target.value),
    [onCategoryChange]
  );

  const handleMaxParticipantsChange = useCallback((e) => {
    if (!isIntValid(e.target.value)) return;

    // converting to number to avoid "NaN" input error
    const convertionTonumber = Number(
      e.target.value.toString().replace(/\D/g, '')
    );

    dispatch(setMaxParticipants(convertionTonumber));
  }, []);

  const handleCostChange = useCallback((e) => {
    if (!isFloatValid(e.target.value)) return;

    // To convert To DE type number with "," but still keeping it only numbers
    const convertionTonumber = e.target.value
      .toString()
      .replace(/[a-zA-Z]/g, '');

    dispatch(setCost(convertionTonumber));
  }, []);

  const handlePresentTypeChange = useCallback(
    (p) => dispatch(setPresentType(p)),
    []
  );

  const handleUrlChange = useCallback((u) => dispatch(setUrl(u)), []);

  const createRequestObject = useCallback(() => {
    const accessibleKeys = Object.keys(accessible);

    const accessibleDescription = accessibleKeys.filter((key: string) => {
      return accessible[key as keyof TAccessible];
    });

    const { street, zipCode, city } = address;
    const temporaryMediaItemId = imageId !== null ? imageId : undefined;
    const organizer = organiser.name !== '' ? organiser.name : undefined;
    const organizerContact =
      organiser.contact !== '' ? organiser.contact : undefined;
    const registrationNotice =
      registrationHint !== '' ? registrationHint : undefined;
    const costCastedToFloat = parseFloat(
      cost.toString().replace(',', '.').replace(' ', '')
    );
    return {
      description,
      title,
      startTime: startTime ? startTime.valueOf() : 0,
      endTime: endTime ? endTime.valueOf() : 0,
      maxParticipants,
      accessibleDescription,
      cost: costCastedToFloat,
      street,
      zipCode,
      city,
      temporaryMediaItemId,
      deleteImage,
      organizer,
      organizerContact,
      online: presentType !== EPresentType.ON_SITE,
      url: url ?? undefined,
      registrationNotice
    };
  }, [
    accessible,
    address,
    cost,
    description,
    endTime,
    imageId,
    deleteImage,
    maxParticipants,
    organiser.contact,
    organiser.name,
    presentType,
    registrationHint,
    startTime,
    title,
    url
  ]);

  const validateFields = useCallback(
    (requestObject: THappeningProperties): string[] => {
      const errors = new Array<string>();

      const appendErrors = (errorsToAppend: string[]) => {
        if (errorsToAppend.length > 0) {
          errors.push(...errorsToAppend);
        }
      };

      appendErrors(validateString(validations.title, requestObject.title));
      appendErrors(
        validateString(validations.description, requestObject.description)
      );

      appendErrors(
        validateTimeRange(
          validations.Time,
          requestObject.startTime,
          requestObject.endTime
        )
      );
      return errors;
    },
    []
  );

  const handleHappeningCreation = useCallback(() => {
    const requestObject = createRequestObject();
    const errors = validateFields(requestObject);
    onContinue(requestObject, errors);
  }, [createRequestObject, onContinue, validateFields]);

  const shouldDisableTime = useCallback(
    (timeValue: number, clockType: string) => {
      const currentDate = new Date();
      if (date && isDateEqual(currentDate, date)) {
        switch (clockType) {
          case 'hours':
            return timeValue < currentDate.getHours() + 1;
          default:
            break;
        }
      }
      return false;
    },
    [date]
  );

  const IconStyle = useMemo(
    () => ({ color: palette.primary.main, fontSize: '48px' }),
    [palette.primary.main]
  );

  useEffect(() => {
    const loadImage = async () => {
      if (imageId) {
        const tempMediaItem = await mediaItemApi.getTemporaryMediaItem(imageId);
        dispatch(setImage(tempMediaItem.mediaItem));
      }
    };
    loadImage();
  }, [imageId, mediaItemApi]);

  useEffect(() => {
    dispatch(reset());
    if (!currentUser) {
      return;
    }
    if (!happening) {
      const { street, city, zipCode } = currentUser;
      if (street && zipCode && city) {
        dispatch(setAddress(street, zipCode, city));
      }
    } else {
      dispatch(setEditableProperties(happening));
    }
  }, [currentUser, happening]);

  const location = useLocation();

  const currentLocationPathname = useMemo(() => {
    const { pathname } = location;
    return pathname.split('/');
  }, [location]);

  const moduleName = useMemo(() => {
    const pathToModuleNameMap: Record<string, string> = {
      nachbarschaftstische: HEADER_TITLE_NACHBARSCHAFTSTISCHE,
      veranstaltungen: HEADER_TITLE_VERANSTALTUNGEN
    };
    return pathToModuleNameMap[currentLocationPathname[1]];
  }, [currentLocationPathname]);

  const tabName = useMemo(() => {
    const pathToTabNameMap: Record<string, Record<string, string>> = {
      nachbarschaftstische: {
        nearbytisch: NACHBARSCHAFTSTISCHE_TAB_1_TEXT,
        myregistrations: NACHBARSCHAFTSTISCHE_TAB_2_TEXT,
        mytisch: NACHBARSCHAFTSTISCHE_TAB_3_TEXT,
        tischanbieten: NACHBARSCHAFTSTISCHE_TAB_4_TEXT
      },
      veranstaltungen: {
        nearbyevents: VERANSTALTUNGEN_TAB_1_TEXT,
        myregistrations: VERANSTALTUNGEN_TAB_2_TEXT,
        myevents: VERANSTALTUNGEN_TAB_3_TEXT,
        createevents: VERANSTALTUNGEN_TAB_4_TEXT
      }
    };

    return pathToTabNameMap[currentLocationPathname[1]][
      currentLocationPathname[2]
    ];
  }, [currentLocationPathname]);

  return (
    <Fragment>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          color="text.primary"
          variant="h3"
          to="/"
          component={ReactRouterLink}
        >
          {moduleName}
        </Link>
        <Typography color="text.primary" variant="h3">
          {tabName}
        </Typography>
      </Breadcrumbs>
      <Typography variant="body1" sx={{ fontSize: '1rem' }}>
        {heading}
      </Typography>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ flexBasis: '70%', position: 'relative' }}>
          <Box height="1rem" />
          <Typography gutterBottom={true} variant="h3">
            {titleLabel}
          </Typography>
          <CustomTextField
            fullWidth
            value={title}
            error={!title || title.length < 3}
            onChange={handleTitleChange}
          />
          <ExtraHelperText text={TITLE_TEXT_INFO} top={20} right={1} />
          {showOrganiserForm ? (
            <Box marginTop="1rem" marginBottom="1rem">
              <OrganiserForm
                organiser={organiser}
                onNameChange={handleOrganiserNameChange}
                onContactChange={handleOrganiserContactChange}
              />
            </Box>
          ) : (
            <Box height="1rem" />
          )}
        </Box>
        <Box sx={{ flexBasis: '30%', textAlign: 'right' }}>
          <EditableImage
            clientMediaItem={image ?? undefined}
            defaultImage={imageIcon}
            onChange={handleImageChange}
            onDeleteImage={handleDeleteImage}
          />
        </Box>
      </Box>
      <Box sx={{ position: 'relative' }}>
        <Typography gutterBottom={true} variant="h3">
          {descriptionLabel}
        </Typography>
        <TextField
          id="outlined-multiline-static"
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          error={!description || description.length < 10}
          placeholder={descriptionPlaceholder}
          value={description}
          onChange={handleDescriptionChange}
        />
        <ExtraHelperText text={DESC_TEXT_INFO} top={5} right={1} />
        <CharacterLimitIndicator
          field={description}
          limit={DESC_MAX_LENGTH_500}
        />
      </Box>
      {showRegistrationHintInput && (
        <Box sx={{ marginTop: '1rem' }}>
          <Typography gutterBottom={true} variant="h3">
            {REGISTRATION_HINT_LABEL}
          </Typography>
          <CustomTextField
            variant="outlined"
            fullWidth
            placeholder={REGISTRATION_HINT_PLACEHOLDER}
            value={registrationHint}
            onChange={handleRegistrationHintChange}
          />
        </Box>
      )}
      <Box sx={{ borderBottom: 'solid 1px #707070' }}>
        <Grid container>
          <CreateFormItem
            icon={<DateRangeIcon sx={{ ...IconStyle }} />}
            label={DAY_TEXT}
          >
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale="de"
            >
              <DesktopDatePicker
                inputFormat="DD.MM.YYYY"
                mask="__.__.____"
                minDate={moment()}
                value={date}
                onChange={handleDateChange}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: 'tt.mm.jjjj'
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </CreateFormItem>
          <CreateFormItem
            icon={<AccessTimeIcon sx={{ ...IconStyle }} />}
            label={TIME_TEXT}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale="de"
              >
                <TimePicker
                  ampm={false}
                  value={startTime}
                  onChange={handleStartTimeChange}
                  renderInput={(params) => <CustomTextField {...params} />}
                  shouldDisableTime={shouldDisableTime}
                  disableOpenPicker={true}
                />
              </LocalizationProvider>
              <Typography variant="body2" sx={{ px: 1 }}>
                bis
              </Typography>
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale="de"
              >
                <TimePicker
                  ampm={false}
                  value={endTime}
                  onChange={handleEndTimeChange}
                  renderInput={(params) => <CustomTextField {...params} />}
                  shouldDisableTime={shouldDisableTime}
                  disableOpenPicker={true}
                />
              </LocalizationProvider>
            </Box>
          </CreateFormItem>
          <CreateFormItem
            icon={<HomeIcon sx={{ ...IconStyle }} />}
            label={ADDRESS_TEXT}
          >
            <EditableAddress
              address={address}
              onAddressChange={handleUpdateAddress}
            />
          </CreateFormItem>
          <CreateFormItem icon={categoryIcon} label={categoryLabel}>
            <FormControl>
              <CustomSelect
                value={currentCategory}
                onChange={handleCategoryChange}
              >
                {categories.map((c) => (
                  <MenuItem key={c} value={c}>
                    {c}
                  </MenuItem>
                ))}
              </CustomSelect>
            </FormControl>
          </CreateFormItem>
          <CreateFormItem
            icon={<PeopleAltIcon sx={{ ...IconStyle }} />}
            label={PARTICIPANTS_TEXT}
          >
            <NumberInput
              value={maxParticipants}
              onChange={handleMaxParticipantsChange}
              inputProps={{
                maxLength: 3,
                inputMode: 'numeric',
                pattern: '[0-9]*'
              }}
            />
          </CreateFormItem>
          <CreateFormItem
            icon={<EuroIcon sx={{ ...IconStyle }} />}
            label={PRICE_TEXT}
          >
            <NumberInput
              value={cost}
              sx={{
                width: '100px'
              }}
              inputProps={{ maxLength: 5, inputMode: 'numeric' }}
              onChange={handleCostChange}
              endAdornment={<InputAdornment position="end">€</InputAdornment>}
            ></NumberInput>
          </CreateFormItem>
        </Grid>
      </Box>
      <Box display="flex" justifyContent="center">
        <Stack>
          <AccessibilitySelection
            onChange={handleCheckboxChange}
            accessible={accessible}
          />
          {showPresentTypeSelection && (
            <PresentTypeSelection
              presentType={presentType}
              url={url}
              onTypeChange={handlePresentTypeChange}
              onUrlChange={handleUrlChange}
            />
          )}
        </Stack>
      </Box>
      <Box sx={{ m: 5, textAlign: 'center', textTransform: 'uppercase' }}>
        <Button variant="contained" onClick={handleHappeningCreation}>
          {FURTHER_BTN_TEXT}
        </Button>
      </Box>
    </Fragment>
  );
};

export default CreateHappening;
