"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientDataPrivacyRequestToJSON = exports.ClientDataPrivacyRequestFromJSONTyped = exports.ClientDataPrivacyRequestFromJSON = void 0;
const runtime_1 = require("../runtime");
function ClientDataPrivacyRequestFromJSON(json) {
    return ClientDataPrivacyRequestFromJSONTyped(json, false);
}
exports.ClientDataPrivacyRequestFromJSON = ClientDataPrivacyRequestFromJSON;
function ClientDataPrivacyRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'reason': !runtime_1.exists(json, 'reason') ? undefined : json['reason'],
    };
}
exports.ClientDataPrivacyRequestFromJSONTyped = ClientDataPrivacyRequestFromJSONTyped;
function ClientDataPrivacyRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'reason': value.reason,
    };
}
exports.ClientDataPrivacyRequestToJSON = ClientDataPrivacyRequestToJSON;
