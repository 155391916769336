import { Stack } from '@mui/material';
import { FC, Fragment } from 'react';
import { useOutletContext } from 'react-router-dom';
import { getThumbnailUrl } from '../../../api/InformationAPI';
import { TFitHealthOutletContext } from '../FitHealthView';
import FitHealthListItem from './FitHealthListItem';

const FitHealthListView: FC = () => {
  const { currentPosts } = useOutletContext<TFitHealthOutletContext>();

  return (
    <Fragment>
      <Stack spacing={1}>
        {currentPosts.map((currentPost) => {
          const { id, title, abstract, text, featuredImage } = currentPost;

          return (
            <FitHealthListItem
              key={id}
              id={id}
              image={getThumbnailUrl(featuredImage)}
              title={title}
              abstract={abstract}
              text={text}
            />
          );
        })}
      </Stack>
    </Fragment>
  );
};

export default FitHealthListView;
