import { ClientMediaItem } from 'digital-vital-backend-api';

export enum ImageSizes {
  THUMBNAIL = 0,
  SMALL = 2,
  MEDIUM = 3,
  LARGE = 4,
  ORIGINAL = -1 // default
}

interface ImageUrls {
  readonly [index: string]: string;
}

const DEFAULT_IMAGE_SIZE_NAME = ImageSizes[ImageSizes.ORIGINAL].toLowerCase();

function getImageUrls(mediaItem: ClientMediaItem): ImageUrls | undefined {
  const { urls } = mediaItem;
  if (!urls) {
    return undefined;
  }
  return JSON.parse(JSON.stringify(urls));
}

function getImageUrl(
  imageSize: ImageSizes,
  mediaItem?: ClientMediaItem
): string | undefined {
  if (!mediaItem) {
    return undefined;
  }
  const imageUrls = getImageUrls(mediaItem);
  if (!imageUrls) {
    return undefined;
  }
  const imageSizeName = ImageSizes[imageSize].toLowerCase();
  if (imageSizeName in imageUrls) {
    return imageUrls[imageSizeName];
  }
  return imageUrls[DEFAULT_IMAGE_SIZE_NAME];
}

const ImageService = {
  getImageUrl
};

export default ImageService;
