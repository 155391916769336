"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientMediaItemSizeToJSON = exports.ClientMediaItemSizeFromJSONTyped = exports.ClientMediaItemSizeFromJSON = void 0;
const runtime_1 = require("../runtime");
function ClientMediaItemSizeFromJSON(json) {
    return ClientMediaItemSizeFromJSONTyped(json, false);
}
exports.ClientMediaItemSizeFromJSON = ClientMediaItemSizeFromJSON;
function ClientMediaItemSizeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !runtime_1.exists(json, 'name') ? undefined : json['name'],
        'version': !runtime_1.exists(json, 'version') ? undefined : json['version'],
        'squared': !runtime_1.exists(json, 'squared') ? undefined : json['squared'],
        'maxSize': !runtime_1.exists(json, 'maxSize') ? undefined : json['maxSize'],
    };
}
exports.ClientMediaItemSizeFromJSONTyped = ClientMediaItemSizeFromJSONTyped;
function ClientMediaItemSizeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'version': value.version,
        'squared': value.squared,
        'maxSize': value.maxSize,
    };
}
exports.ClientMediaItemSizeToJSON = ClientMediaItemSizeToJSON;
