import { DialogContentText } from '@mui/material';
import { FC } from 'react';
import { BACK_BTN_TEXT, CONFIRM_BTN_TEXT } from '../../constants/strings';
import { ConfirmationDialogProps } from '../../interfaces/props';
import AbstractDialog from '../dialogs/AbstractDialog';

const ConfirmationDialog: FC<ConfirmationDialogProps> = (
  props: ConfirmationDialogProps
) => {
  const {
    title = '',
    message,
    actionBtnText = CONFIRM_BTN_TEXT,
    open,
    onClose,
    onAction
  } = props;
  return (
    <AbstractDialog
      open={open}
      title={title}
      btnActionText={actionBtnText}
      btnCancelText={BACK_BTN_TEXT}
      hideCancelBtn={false}
      onClose={onClose}
      onAction={onAction}
    >
      <DialogContentText id="alert-dialog-description">
        {message}
      </DialogContentText>
    </AbstractDialog>
  );
};

export default ConfirmationDialog;
