import { OutlinedInput, styled } from '@mui/material';

const NumberInput = styled(OutlinedInput)({
  'input.MuiOutlinedInput-input': {
    width: '100px',
    textAlign: 'center',
    padding: '10px'
  }
});

export default NumberInput;
