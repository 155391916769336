import axios from 'axios';
import { APP_VARIANT_IDENTIFIER, DIRECTUS_URL } from '../config';
import {
  TCategory,
  THelpEntry,
  THelpSection,
  TNewsArticle,
  TPost,
  TSubCategory
} from '../types/information';
import {
  DirectusPostResponse,
  DirectusResponse,
  DirecutsRequestConfig
} from './DirectusAPI';

function createCategoryNameFilter(name: string) {
  return {
    name: {
      _eq: name
    }
  };
}

export async function fetchCategories(): Promise<Array<TCategory>> {
  const response = await axios.get<DirectusResponse<TCategory>>(
    '/items/Category',
    DirecutsRequestConfig
  );
  return response.data.data;
}

export async function fetchCategoryByName(name: string): Promise<TCategory> {
  const filter = createCategoryNameFilter(name);
  const response = await axios.get<DirectusResponse<TCategory>>(
    '/items/Category?filter=' + encodeURIComponent(JSON.stringify(filter)),
    DirecutsRequestConfig
  );
  const category = response.data.data[0];
  return category;
}

function convertSubCategories(
  result: Array<Record<string, string>>
): Array<TSubCategory> {
  return result.map((r) => ({
    id: r.id,
    name: r.name,
    categoryId: r.category
  }));
}

function createPortalVariantFilter() {
  return {
    portal_variant_identifier: {
      _eq: APP_VARIANT_IDENTIFIER
    }
  };
}

export async function fetchSubCategories(): Promise<Array<TSubCategory>> {
  const filter = createPortalVariantFilter();
  const response = await axios.get<DirectusResponse<Record<string, string>>>(
    '/items/SubCategory?filter=' + encodeURIComponent(JSON.stringify(filter)),
    DirecutsRequestConfig
  );
  return convertSubCategories(response.data.data);
}

function createSubCategoryCategoryFilter(category: string) {
  const portalVariantFilter = createPortalVariantFilter();
  return {
    _and: [
      {
        category: {
          _eq: category
        }
      },
      portalVariantFilter
    ]
  };
}

export async function fetchSubCategoriesByCategory(
  category: TCategory
): Promise<Array<TSubCategory>> {
  const { id } = category;
  const filter = createSubCategoryCategoryFilter(id);
  const response = await axios.get<DirectusResponse<Record<string, string>>>(
    '/items/SubCategory?filter=' + encodeURIComponent(JSON.stringify(filter)),
    DirecutsRequestConfig
  );
  return convertSubCategories(response.data.data);
}

function createPendingPostFilter() {
  const portalVariantFilter = createPortalVariantFilter();
  return {
    _and: [
      {
        status: {
          _eq: 'draft'
        }
      },
      {
        submitted: {
          _eq: true
        }
      },
      portalVariantFilter
    ]
  };
}

function createPostIdFilter(id: string) {
  return {
    id: {
      _eq: id
    }
  };
}

function createUserPendingPostFilter(userId: string) {
  return {
    _and: [
      {
        status: {
          _eq: 'draft'
        }
      },
      {
        submitted: {
          _eq: true
        }
      },
      {
        dv_user_created: {
          _eq: userId
        }
      }
    ]
  };
}

function createPublishedFilter() {
  return {
    status: {
      _eq: 'published'
    }
  };
}

function createPublishedPostFilter() {
  const portalVariantFilter = createPortalVariantFilter();
  const publishedFilter = createPublishedFilter();
  return {
    _and: [portalVariantFilter, publishedFilter]
  };
}

function createCategoryPostFilter(category: TCategory) {
  const portalVariantFilter = createPortalVariantFilter();
  const publishedFilter = createPublishedFilter();
  return {
    _and: [
      {
        category: {
          _eq: category.id
        }
      },
      portalVariantFilter,
      publishedFilter
    ]
  };
}

function createNotExpiredNewsArticleFilter() {
  const portalVariantFilter = createPortalVariantFilter();
  const publishedFilter = createPublishedFilter();
  return {
    _and: [
      {
        date_expired: {
          _gt: '$NOW'
        }
      },
      portalVariantFilter,
      publishedFilter
    ]
  };
}

type TPostFilter =
  | ReturnType<typeof createPortalVariantFilter>
  | ReturnType<typeof createPendingPostFilter>
  | ReturnType<typeof createUserPendingPostFilter>
  | ReturnType<typeof createPublishedFilter>
  | ReturnType<typeof createPostIdFilter>
  | ReturnType<typeof createCategoryPostFilter>;

function convertPostResponse(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  result: Array<Record<string, any>>
): Array<TPost> {
  return result.map((r) => ({
    id: r.id,
    title: r.title,
    subtitle: r.subtitle,
    featuredImage: r.featured_image,
    abstract: r.abstract,
    text: r.text,
    category: r.category,
    subcategory: r.subcategory ? r.subcategory.toString() : null,
    dateCreated: new Date(r.date_created),
    status: r.status,
    portalVariantIdentifier: r.portal_variant_identifier
  }));
}

async function fetchPosts(filter?: TPostFilter): Promise<Array<TPost>> {
  const response = await axios.get<DirectusPostResponse>(
    '/items/Post?filter=' +
      encodeURIComponent(JSON.stringify(filter)) +
      '&sort=-date_created',
    DirecutsRequestConfig
  );
  return convertPostResponse(response.data.data);
}

export function fetchCategoryPosts(category: TCategory): Promise<Array<TPost>> {
  const filter = createCategoryPostFilter(category);
  return fetchPosts(filter);
}

export function fetchPublishedPosts(): Promise<Array<TPost>> {
  const filter = createPublishedPostFilter();
  return fetchPosts(filter);
}

export function fetchPendingPosts(): Promise<Array<TPost>> {
  const filter = createPendingPostFilter();
  return fetchPosts(filter);
}

export function fetchUserPendingPosts(userId: string): Promise<Array<TPost>> {
  const filter = createUserPendingPostFilter(userId);
  return fetchPosts(filter);
}

export async function fetchPostById(id: string): Promise<TPost | null> {
  const filter = createPostIdFilter(id);
  const posts = await fetchPosts(filter);
  return posts[0] ?? null;
}

export async function searchPosts(searchTerm: string): Promise<Array<TPost>> {
  const response = await axios.get<DirectusPostResponse>(
    '/items/Post?search=' + encodeURIComponent(searchTerm),
    DirecutsRequestConfig
  );
  return convertPostResponse(response.data.data);
}

export async function fetchNewsArticles(): Promise<Array<TNewsArticle>> {
  const filter = createNotExpiredNewsArticleFilter();
  const response = await axios.get<DirectusPostResponse>(
    'items/NewsArticle?filter=' +
      encodeURIComponent(JSON.stringify(filter)) +
      '&sort=-date_created',
    DirecutsRequestConfig
  );
  return response.data.data.map((d) => ({
    id: d.id,
    title: d.title,
    subtitle: d.subtitle,
    featuredImage: d.featured_image,
    abstract: d.abstract,
    text: d.text,
    category: d.category,
    dateCreated: new Date(d.date_created),
    dateExpired: new Date(d.date_expired)
  }));
}

export async function fetchHelpSections(): Promise<Array<THelpSection>> {
  const response = await axios.get<DirectusPostResponse>(
    'items/HelpSection',
    DirecutsRequestConfig
  );
  return response.data.data.map((d) => ({
    id: d.id,
    name: d.name
  }));
}

export async function fetchHelpEntries(): Promise<Array<THelpEntry>> {
  const response = await axios.get<DirectusPostResponse>(
    'items/help',
    DirecutsRequestConfig
  );
  return response.data.data.map((d) => ({
    id: d.id,
    title: d.title,
    section: d.section,
    shortDescription: d.short_description,
    description: d.description
  }));
}

export function getThumbnailUrl(assetId?: string): string | undefined {
  if (!assetId) {
    return undefined;
  }
  return `${DIRECTUS_URL}/assets/${assetId}?fit=cover&width=160&height=160&quality=90`;
}

export function getImageUrl(assetId: string): string {
  return `${DIRECTUS_URL}/assets/${assetId}?fit=contain&height=300&quality=90`;
}
