import { Grid, Typography } from '@mui/material';
import { FC } from 'react';
import ParticipantFragment from './ParticipantFragment';

type ConfirmationAcceptViewProps = {
  participantDisplayName?: string;
  participantProfilePicture?: string;
  onParticipantClick: () => void;
};

const ConfirmationAcceptView: FC<ConfirmationAcceptViewProps> = (
  props: ConfirmationAcceptViewProps
) => {
  const {
    participantDisplayName,
    participantProfilePicture,
    onParticipantClick
  } = props;

  return (
    <Grid
      container
      flexDirection="column"
      spacing={1}
      justifyItems="center"
      alignItems="center"
    >
      <ParticipantFragment
        participantDisplayName={participantDisplayName}
        participantProfilePicture={participantProfilePicture}
        onParticipantClick={onParticipantClick}
      />
      <Grid item xs={12}>
        <Typography>
          {`Sind sie sicher das sie ${participantDisplayName} akzeptieren möchten?`}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ConfirmationAcceptView;
