import { Container, ThemeProvider } from '@mui/material';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import settingsTheme from '../../themes/settingsTheme';

const Settings: FC = () => {
  return (
    <ThemeProvider theme={settingsTheme}>
      <Container sx={{ marginTop: '1em', marginBottom: '1em' }}>
        <Outlet />
      </Container>
    </ThemeProvider>
  );
};
export default Settings;
