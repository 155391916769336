"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientPostUpdateConfirmationToJSON = exports.ClientPostUpdateConfirmationFromJSONTyped = exports.ClientPostUpdateConfirmationFromJSON = exports.ClientPostUpdateConfirmationCategoryEnum = exports.ClientPostUpdateConfirmationPostTypeEnum = void 0;
const runtime_1 = require("../runtime");
const ClientMediaItem_1 = require("./ClientMediaItem");
/**
* @export
* @enum {string}
*/
var ClientPostUpdateConfirmationPostTypeEnum;
(function (ClientPostUpdateConfirmationPostTypeEnum) {
    ClientPostUpdateConfirmationPostTypeEnum["Seeking"] = "SEEKING";
    ClientPostUpdateConfirmationPostTypeEnum["Offer"] = "OFFER";
})(ClientPostUpdateConfirmationPostTypeEnum = exports.ClientPostUpdateConfirmationPostTypeEnum || (exports.ClientPostUpdateConfirmationPostTypeEnum = {})); /**
* @export
* @enum {string}
*/
var ClientPostUpdateConfirmationCategoryEnum;
(function (ClientPostUpdateConfirmationCategoryEnum) {
    ClientPostUpdateConfirmationCategoryEnum["Culture"] = "CULTURE";
    ClientPostUpdateConfirmationCategoryEnum["Encounter"] = "ENCOUNTER";
    ClientPostUpdateConfirmationCategoryEnum["HealthSport"] = "HEALTH_SPORT";
    ClientPostUpdateConfirmationCategoryEnum["NeighborhoodHelp"] = "NEIGHBORHOOD_HELP";
    ClientPostUpdateConfirmationCategoryEnum["General"] = "GENERAL";
})(ClientPostUpdateConfirmationCategoryEnum = exports.ClientPostUpdateConfirmationCategoryEnum || (exports.ClientPostUpdateConfirmationCategoryEnum = {}));
function ClientPostUpdateConfirmationFromJSON(json) {
    return ClientPostUpdateConfirmationFromJSONTyped(json, false);
}
exports.ClientPostUpdateConfirmationFromJSON = ClientPostUpdateConfirmationFromJSON;
function ClientPostUpdateConfirmationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'postType': !runtime_1.exists(json, 'postType') ? undefined : json['postType'],
        'title': !runtime_1.exists(json, 'title') ? undefined : json['title'],
        'text': !runtime_1.exists(json, 'text') ? undefined : json['text'],
        'image': !runtime_1.exists(json, 'image') ? undefined : ClientMediaItem_1.ClientMediaItemFromJSON(json['image']),
        'category': !runtime_1.exists(json, 'category') ? undefined : json['category'],
    };
}
exports.ClientPostUpdateConfirmationFromJSONTyped = ClientPostUpdateConfirmationFromJSONTyped;
function ClientPostUpdateConfirmationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'postType': value.postType,
        'title': value.title,
        'text': value.text,
        'image': ClientMediaItem_1.ClientMediaItemToJSON(value.image),
        'category': value.category,
    };
}
exports.ClientPostUpdateConfirmationToJSON = ClientPostUpdateConfirmationToJSON;
