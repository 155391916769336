import AccessibleIcon from '@mui/icons-material/Accessible';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  useTheme
} from '@mui/material';
import { Theme } from '@mui/system/createTheme/createTheme';
import { ClientHappeningExtended } from 'digital-vital-backend-api';
import { CSSProperties, FC } from 'react';
import { SONSTIGES } from '../../constants/constants';
import { ACCESSIBILITY_TEXT } from '../../constants/strings';

const useStyles = (theme: Theme): Record<string, CSSProperties> => ({
  iconStyle: {
    fontSize: '48px',
    color: theme.palette.primary.main
  }
});

type HappeningAccessibilityViewProps = {
  happening: ClientHappeningExtended;
};

const HappeningAccessibilityView: FC<HappeningAccessibilityViewProps> = (
  props
) => {
  const { happening } = props;
  const { accessibility } = happening;
  const theme = useTheme();
  const styles = useStyles(theme);

  /* TODO: This logic can be removed later when events, tisch using "sonsiges" has
  past the date. */
  const filterAccessibility = accessibility
    ? accessibility.filter((a) => a !== SONSTIGES)
    : [];

  return (
    <Box
      sx={{
        marginLeft: 4,
        p: 3,
        display: 'inline-flex',
        alignItems: 'center'
      }}
    >
      <AccessibleIcon color="primary" sx={styles.iconStyle} />
      <Typography component="span" variant="h4">
        {ACCESSIBILITY_TEXT}
      </Typography>
      {filterAccessibility && (
        <FormGroup
          aria-label="accessibility"
          row
          sx={{ justifyContent: 'center' }}
        >
          {filterAccessibility.map((access: string) => (
            <FormControlLabel
              key={access}
              value={access}
              control={<Checkbox defaultChecked color="primary" disabled />}
              label={access}
              labelPlacement="start"
              sx={{ textTransform: 'capitalize' }}
            />
          ))}
        </FormGroup>
      )}
    </Box>
  );
};

export default HappeningAccessibilityView;
