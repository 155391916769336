import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react';

interface NoticeCheckboxesProps {
  checkboxLabel: string;
  checkboxes: { name: string; value: boolean; label: string }[];
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const NoticeCheckboxes: FC<NoticeCheckboxesProps> = ({
  checkboxLabel,
  checkboxes,
  onChange
}) => {
  return (
    <Box sx={{ mt: '1rem' }}>
      <Typography gutterBottom={true} variant="h3">
        {checkboxLabel}
      </Typography>
      <FormGroup row>
        {checkboxes.map(({ name, value, label }) => {
          return (
            <FormControlLabel
              key={name}
              control={
                <Checkbox checked={value} name={name} onChange={onChange} />
              }
              label={label}
            />
          );
        })}
      </FormGroup>
    </Box>
  );
};

export default NoticeCheckboxes;
