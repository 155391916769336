/* eslint-disable @typescript-eslint/no-explicit-any */
import { Theme } from '@mui/system/createTheme/createTheme';
import { APP_LOCALE } from '../config';
import {
  FONT_SIZE_SETTINGS,
  HEADER_TITLE_FITHEALTH,
  HEADER_TITLE_INFORMATION,
  HEADER_TITLE_KALENDER,
  HEADER_TITLE_NACHBARSCHAFTSTISCHE,
  HEADER_TITLE_NEWS,
  HEADER_TITLE_SETTINGS,
  HEADER_TITLE_VERANSTALTUNGEN,
  HOME_TILE_PINNWAND,
  LINK_PROFILE,
  NEARBY_ME_SETTINGS,
  PROFILE_SETTINGS,
  TBD_SETTINGS,
  WELCOME_TEXT
} from '../constants/strings';
import calendarTheme from '../themes/calendarTheme';
import eventsTheme from '../themes/eventsTheme';
import fithealthTheme from '../themes/fithealthTheme';
import informationTheme from '../themes/informationTheme';
import navigatorTheme from '../themes/navigatorTheme';
import neighborhoodTheme from '../themes/neighborhoodTheme';
import newsTheme from '../themes/newsTheme';
import pinnwandTheme from '../themes/pinnwandTheme';
import {
  IdObject,
  TNumberValidation,
  TStringValidation,
  TTimeRangeValidation
} from '../types/common';

export const a11yProps = (route: string): any => {
  return {
    value: route,
    'aria-controls': `simple-tabpanel-${route}`
  };
};

const headings: Record<string, string> = {
  information: HEADER_TITLE_INFORMATION,
  nachbarschaftstische: HEADER_TITLE_NACHBARSCHAFTSTISCHE,
  veranstaltungen: HEADER_TITLE_VERANSTALTUNGEN,
  aktuelles: HEADER_TITLE_NEWS,
  kalender: HEADER_TITLE_KALENDER,
  'fit-gesund': HEADER_TITLE_FITHEALTH,
  einstellungen: HEADER_TITLE_SETTINGS,
  profile: PROFILE_SETTINGS,
  'meine-naehe': NEARBY_ME_SETTINGS,
  schriftgroesse: FONT_SIZE_SETTINGS,
  tbd: TBD_SETTINGS,
  pinnwand: HOME_TILE_PINNWAND,
  'my-area': LINK_PROFILE,
  unknown: WELCOME_TEXT
};

export const getTitleFromRoute = (route: string): string => {
  const splittedRoute = route.split('/');
  const primaryRoute = splittedRoute[1];
  const secondaryRoute = splittedRoute[2];
  if (primaryRoute in headings) {
    if (secondaryRoute in headings) {
      return headings[secondaryRoute];
    }
    return headings[primaryRoute];
  }
  return headings.unknown;
};

const themes: Record<string, Theme> = {
  information: informationTheme,
  nachbarschaftstische: neighborhoodTheme,
  veranstaltungen: eventsTheme,
  aktuelles: newsTheme,
  'fit-gesund': fithealthTheme,
  kalender: calendarTheme,
  pinnwand: pinnwandTheme,
  unknown: navigatorTheme
};

export const getThemeFromRoute = (route: string): Theme => {
  const primaryRoute = route.split('/')[1];
  if (primaryRoute in themes) {
    return themes[primaryRoute];
  }
  return themes.unknown;
};

export const getCurrentTab = (tabs: Array<string>, route: string): string => {
  const result = tabs.find((tab) => {
    return route.indexOf(tab) !== -1;
  });
  return result || '';
};

const currencyFormatter = new Intl.NumberFormat(APP_LOCALE, {
  style: 'currency',
  currency: 'EUR'
});

export const currencyFormat = (num: number): string => {
  return currencyFormatter.format(num);
};

const monthFormatter = new Intl.DateTimeFormat(APP_LOCALE, { month: 'long' });

export const formatMonth = (date: Date): string => {
  return monthFormatter.format(date);
};

const dateFormatter = new Intl.DateTimeFormat(APP_LOCALE, {
  dateStyle: 'long'
});

export const formatDate = (date: Date): string => {
  return dateFormatter.format(date);
};

const timeFormatter = new Intl.DateTimeFormat(APP_LOCALE, {
  timeStyle: 'short'
});

export const formatTime = (date: Date): string => {
  return timeFormatter.format(date);
};

export function findById<T extends IdObject>(
  array: Array<T>,
  id: string
): T | undefined {
  return array.find((a) => a.id === id);
}

const HTTP_PROTO_PATTERN = /^http(s)?:\/\//i;

export function isAbsoluteHTTPUrl(url: string): boolean {
  return HTTP_PROTO_PATTERN.test(url);
}

type Nil = undefined | null;

export function hasLength(value: string | Nil): boolean {
  return value !== undefined && value !== null && value.length > 0;
}

export function isNil<T>(value: T | Nil): value is Nil {
  return value === undefined || value === null;
}

export const isInRange = (
  minLength: number,
  maxLength: number,
  text?: string | null
): boolean => {
  return isNil(text) || (text.length >= minLength && text.length <= maxLength);
};

export function asEnumValue<T extends Record<string, string>>(
  enumType: T,
  value: string | undefined
): T[keyof T] | undefined {
  if (!value) {
    return undefined;
  }
  const enumValues = Object.values(enumType);
  if (!enumValues.includes(value)) {
    return undefined;
  }
  return value as T[keyof T];
}

const emailRegExp = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

export function emailIsValid(email: string | Nil): boolean {
  return !isNil(email) && emailRegExp.test(email);
}

const phoneRegExp = new RegExp(/^(\d{3})(\d)?(-|\/| )?(\d)*/);

export function phoneIsValid(phone: string | Nil): boolean {
  return !isNil(phone) && phoneRegExp.test(phone);
}

export function urlIsValid(url: string | Nil): boolean {
  if (isNil(url)) {
    return false;
  }
  try {
    new URL(url);
  } catch (_) {
    return false;
  }
  return true;
}

const regexForInt = /^[\d]*$/;

export function isIntValid(input: string): boolean {
  if (isNil(input)) {
    return true;
  }
  return regexForInt.test(input);
}

const regexForFloat = /^[\d]+([\.|,][\d]{0,2})?$/;

export function isFloatValid(input: string): boolean {
  if (!hasLength(input)) {
    return true;
  }
  return regexForFloat.test(input);
}

export function validateString(
  validation: TStringValidation,
  input?: string | null
): string[] {
  const errors = [];
  if (!hasLength(input)) {
    errors.push(validation.messages.blank);
  }
  if (!isInRange(validation.min, validation.max, input)) {
    errors.push(validation.messages.range);
  }
  return errors;
}

export function validateNumber(
  validation: TNumberValidation,
  input: number
): string[] {
  const errors = [];
  if (input < validation.min || input > validation.max) {
    errors.push(validation.message);
  }
  return errors;
}

export function validateTimeRange(
  validation: TTimeRangeValidation,
  startTime: number,
  endTime: number
): string[] {
  const errors = [];
  if (!startTime || !endTime) {
    errors.push(validation.messages.blank);
  }
  if (startTime && endTime && !(startTime < endTime)) {
    errors.push(validation.messages.interval);
  }
  return errors;
}
