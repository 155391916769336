import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { Box, Typography } from '@mui/material';
import { ClientPostTypeEnum } from 'digital-vital-backend-api';
import { CSSProperties, FC } from 'react';
import { OFFER_TEXT, SEEKING_TEXT } from '../../constants/strings';

const useStyles = (): Record<string, CSSProperties> => ({
  containerStyles: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    paddingRight: 2
  },
  iconStyles: {
    fontSize: '2rem',
    marginBottom: 1
  }
});

interface NoticeTypeProps {
  type?: ClientPostTypeEnum;
}

const NoticeType: FC<NoticeTypeProps> = ({ type = '' }) => {
  const styles = useStyles();
  return (
    <Box sx={styles.containerStyles}>
      {
        {
          OFFER: (
            <>
              <LocalOfferIcon color="primary" sx={styles.iconStyles} />
              <Typography variant="h6">{OFFER_TEXT}</Typography>
            </>
          ),
          SEEKING: (
            <>
              <ManageSearchIcon color="primary" sx={styles.iconStyles} />
              <Typography variant="h6">{SEEKING_TEXT}</Typography>
            </>
          )
        }[type]
      }
    </Box>
  );
};

export default NoticeType;
