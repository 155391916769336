"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetPostsIncludedCategoriesEnum = exports.GetPostsPostTypeEnum = exports.GetOwnPostsPostTypeEnum = exports.PostsApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class PostsApi extends runtime.BaseAPI {
    /**
     * Logged user can contact author of a post via email.
     * Contact the author of the post via e-mail
     */
    contactAuthorRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.postId === null || requestParameters.postId === undefined) {
                throw new runtime.RequiredError('postId', 'Required parameter requestParameters.postId was null or undefined when calling contactAuthor.');
            }
            if (requestParameters.clientContactAuthorRequest === null || requestParameters.clientContactAuthorRequest === undefined) {
                throw new runtime.RequiredError('clientContactAuthorRequest', 'Required parameter requestParameters.clientContactAuthorRequest was null or undefined when calling contactAuthor.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/posts/{postId}/contact-author`.replace(`{${"postId"}}`, encodeURIComponent(String(requestParameters.postId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ClientContactAuthorRequestToJSON(requestParameters.clientContactAuthorRequest),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Logged user can contact author of a post via email.
     * Contact the author of the post via e-mail
     */
    contactAuthor(postId, clientContactAuthorRequest, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.contactAuthorRaw({ postId: postId, clientContactAuthorRequest: clientContactAuthorRequest, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
        });
    }
    /**
     * Creates a new Post
     * Creates a new Post
     */
    createPostRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientPostCreateRequest === null || requestParameters.clientPostCreateRequest === undefined) {
                throw new runtime.RequiredError('clientPostCreateRequest', 'Required parameter requestParameters.clientPostCreateRequest was null or undefined when calling createPost.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/posts`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ClientPostCreateRequestToJSON(requestParameters.clientPostCreateRequest),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ClientPostCreateConfirmationFromJSON(jsonValue));
        });
    }
    /**
     * Creates a new Post
     * Creates a new Post
     */
    createPost(clientPostCreateRequest, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.createPostRaw({ clientPostCreateRequest: clientPostCreateRequest, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Deletes a post
     * Deletes a specific post
     */
    deletePostRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.postId === null || requestParameters.postId === undefined) {
                throw new runtime.RequiredError('postId', 'Required parameter requestParameters.postId was null or undefined when calling deletePost.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/posts/{postId}`.replace(`{${"postId"}}`, encodeURIComponent(String(requestParameters.postId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ClientPostDeleteConfirmationFromJSON(jsonValue));
        });
    }
    /**
     * Deletes a post
     * Deletes a specific post
     */
    deletePost(postId, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.deletePostRaw({ postId: postId, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Returns all posts created by the current user
     * Returns all posts created by the current user
     */
    getOwnPostsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.postType !== undefined) {
                queryParameters['postType'] = requestParameters.postType;
            }
            const headerParameters = {};
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/posts/own`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.ClientPostGeoAreaMappingFromJSON));
        });
    }
    /**
     * Returns all posts created by the current user
     * Returns all posts created by the current user
     */
    getOwnPosts(postType, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getOwnPostsRaw({ postType: postType, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Returns the post specified by the id
     * Returns a post with the specified id
     */
    getPostByIdRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.postId === null || requestParameters.postId === undefined) {
                throw new runtime.RequiredError('postId', 'Required parameter requestParameters.postId was null or undefined when calling getPostById.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/posts/{postId}`.replace(`{${"postId"}}`, encodeURIComponent(String(requestParameters.postId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ClientPostFromJSON(jsonValue));
        });
    }
    /**
     * Returns the post specified by the id
     * Returns a post with the specified id
     */
    getPostById(postId, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getPostByIdRaw({ postId: postId, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Returns all posts in the geoareas subscribed by the user or the portal geoareas
     * Returns all posts
     */
    getPostsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.postType !== undefined) {
                queryParameters['postType'] = requestParameters.postType;
            }
            if (requestParameters.includedCategories) {
                queryParameters['includedCategories'] = requestParameters.includedCategories;
            }
            const headerParameters = {};
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/posts`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.ClientPostGeoAreaMappingFromJSON));
        });
    }
    /**
     * Returns all posts in the geoareas subscribed by the user or the portal geoareas
     * Returns all posts
     */
    getPosts(postType, includedCategories, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getPostsRaw({ postType: postType, includedCategories: includedCategories, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Updates an existing Post
     * Updates a Post
     */
    updatePostRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.postId === null || requestParameters.postId === undefined) {
                throw new runtime.RequiredError('postId', 'Required parameter requestParameters.postId was null or undefined when calling updatePost.');
            }
            if (requestParameters.clientPostUpdateRequest === null || requestParameters.clientPostUpdateRequest === undefined) {
                throw new runtime.RequiredError('clientPostUpdateRequest', 'Required parameter requestParameters.clientPostUpdateRequest was null or undefined when calling updatePost.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters.portalVariantIdentifier !== undefined && requestParameters.portalVariantIdentifier !== null) {
                headerParameters['Portal-Variant-Identifier'] = String(requestParameters.portalVariantIdentifier);
            }
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer_auth", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/posts/{postId}`.replace(`{${"postId"}}`, encodeURIComponent(String(requestParameters.postId))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: models_1.ClientPostUpdateRequestToJSON(requestParameters.clientPostUpdateRequest),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ClientPostUpdateConfirmationFromJSON(jsonValue));
        });
    }
    /**
     * Updates an existing Post
     * Updates a Post
     */
    updatePost(postId, clientPostUpdateRequest, portalVariantIdentifier, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updatePostRaw({ postId: postId, clientPostUpdateRequest: clientPostUpdateRequest, portalVariantIdentifier: portalVariantIdentifier }, initOverrides);
            return yield response.value();
        });
    }
}
exports.PostsApi = PostsApi;
/**
    * @export
    * @enum {string}
    */
var GetOwnPostsPostTypeEnum;
(function (GetOwnPostsPostTypeEnum) {
    GetOwnPostsPostTypeEnum["Seeking"] = "SEEKING";
    GetOwnPostsPostTypeEnum["Offer"] = "OFFER";
})(GetOwnPostsPostTypeEnum = exports.GetOwnPostsPostTypeEnum || (exports.GetOwnPostsPostTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var GetPostsPostTypeEnum;
(function (GetPostsPostTypeEnum) {
    GetPostsPostTypeEnum["Seeking"] = "SEEKING";
    GetPostsPostTypeEnum["Offer"] = "OFFER";
})(GetPostsPostTypeEnum = exports.GetPostsPostTypeEnum || (exports.GetPostsPostTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var GetPostsIncludedCategoriesEnum;
(function (GetPostsIncludedCategoriesEnum) {
    GetPostsIncludedCategoriesEnum["Culture"] = "CULTURE";
    GetPostsIncludedCategoriesEnum["Encounter"] = "ENCOUNTER";
    GetPostsIncludedCategoriesEnum["HealthSport"] = "HEALTH_SPORT";
    GetPostsIncludedCategoriesEnum["NeighborhoodHelp"] = "NEIGHBORHOOD_HELP";
    GetPostsIncludedCategoriesEnum["General"] = "GENERAL";
})(GetPostsIncludedCategoriesEnum = exports.GetPostsIncludedCategoriesEnum || (exports.GetPostsIncludedCategoriesEnum = {}));
