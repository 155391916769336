"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientAssignRoleToUserRequestToJSON = exports.ClientAssignRoleToUserRequestFromJSONTyped = exports.ClientAssignRoleToUserRequestFromJSON = void 0;
function ClientAssignRoleToUserRequestFromJSON(json) {
    return ClientAssignRoleToUserRequestFromJSONTyped(json, false);
}
exports.ClientAssignRoleToUserRequestFromJSON = ClientAssignRoleToUserRequestFromJSON;
function ClientAssignRoleToUserRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'clientId': json['clientId'],
        'role': json['role'],
    };
}
exports.ClientAssignRoleToUserRequestFromJSONTyped = ClientAssignRoleToUserRequestFromJSONTyped;
function ClientAssignRoleToUserRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'clientId': value.clientId,
        'role': value.role,
    };
}
exports.ClientAssignRoleToUserRequestToJSON = ClientAssignRoleToUserRequestToJSON;
