import { Grid, TextField, Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import ParticipantFragment from './ParticipantFragment';

type ConfirmationRejectViewProps = {
  participantDisplayName?: string;
  participantProfilePicture?: string;
  rejectMessage: string;
  onParticipantClick: () => void;
  onRejectMessageChanged: (rejectMessage: string) => void;
};

const ConfirmationRejectView: FC<ConfirmationRejectViewProps> = (
  props: ConfirmationRejectViewProps
) => {
  const {
    participantDisplayName,
    participantProfilePicture,
    rejectMessage,
    onParticipantClick,
    onRejectMessageChanged
  } = props;

  const handleRejectMessageChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      event.stopPropagation();
      onRejectMessageChanged(event.target.value);
    },
    [onRejectMessageChanged]
  );

  return (
    <Grid
      container
      flexDirection="column"
      spacing={1}
      justifyItems="center"
      alignItems="center"
    >
      <ParticipantFragment
        participantDisplayName={participantDisplayName}
        participantProfilePicture={participantProfilePicture}
        onParticipantClick={onParticipantClick}
      />
      <Grid item xs={12}>
        <Typography>
          {`Sind sie sicher das sie ${participantDisplayName} ablehnen möchten?`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>{'Gib einen Grund für deine Ablehnung an:'}</Typography>
        <TextField
          multiline
          rows={4}
          variant="outlined"
          placeholder="Hier tippen…"
          label="Nachricht"
          fullWidth
          value={rejectMessage}
          onChange={handleRejectMessageChange}
          sx={{ mt: 2 }}
        />
      </Grid>
    </Grid>
  );
};

export default ConfirmationRejectView;
