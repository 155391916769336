import { FC } from 'react';
import { useOutletContext } from 'react-router-dom';
import PostDetail from '../../../components/PostDetail';
import { TMyAreaOutletContext } from '../MyAreaView';

const PendingPostDetail: FC = () => {
  const { pendingPosts } = useOutletContext<TMyAreaOutletContext>();

  return <PostDetail posts={pendingPosts} />;
};

export default PendingPostDetail;
