import RestaurantIcon from '@mui/icons-material/Restaurant';
import { useTheme } from '@mui/material';
import {
  ClientHappeningExtended,
  ClientNeighborhoodTableCreateRequest,
  ClientNeighborhoodTableCreateRequestMealTypeEnum,
  ClientNeighborhoodTableUpdateRequest,
  ClientNeighborhoodTableUpdateRequestMealTypeEnum
} from 'digital-vital-backend-api';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BackendApiError } from '../../../api/BackendAPI';
import Table from '../../../assets/svgs/table.svg';
import MissingAddressDialog from '../../../components/create-happening/components/MissingAddressDialog';
import CreateHappening from '../../../components/create-happening/CreateHappening';
import { THappeningProperties } from '../../../components/create-happening/store/types';
import ConfirmationDialog from '../../../components/dialogs/ConfirmationDialog';
import ErrorDialog from '../../../components/ErrorDialog';
import ProtectedPage from '../../../components/ProtectedPage';
import { ADDRESS_ERROR } from '../../../constants/constants';
import {
  CONFIRM_DIALOG_MSG_TEXT,
  CONFIRM_DIALOG_TITLE_TEXT,
  CREATE_TABLE_NOT_AUTHENTICATED,
  CREATE_UPDATE_HAPPENING_ADDRESS_ERROR,
  DEFAULT_ERROR,
  MEAL_TYPE_TEXT,
  NACHBARSCHAFTSTISCH_ADDRESS_MISSING_TEXT,
  OFFER_TABLE_HEADING_TEXT,
  TABLE_DESC_PLACEHOLDER,
  TABLE_DESC_TEXT,
  TABLE_NAME_TEXT
} from '../../../constants/strings';
import { validations } from '../../../constants/validations.json';
import useCurrentUser from '../../../hooks/useCurrentUser';
import useHappeningApi from '../../../hooks/useHappeningApi';
import useKeycloakUtils from '../../../hooks/useKeycloakUtils';
import { EMealType } from '../../../types/happening';
import { EPortalRoles } from '../../../types/roles';
import { validateNumber } from '../../../utils/common';

const TableIcon = <img src={Table} loading="lazy" height="100rem" />;

const toClientUpdateRequestMealTypeEnum = (
  mealType: string
): ClientNeighborhoodTableUpdateRequestMealTypeEnum => {
  switch (mealType) {
    case EMealType.CLASSIC:
      return ClientNeighborhoodTableUpdateRequestMealTypeEnum.Classic;
    case EMealType.VEGAN:
      return ClientNeighborhoodTableUpdateRequestMealTypeEnum.Vegan;
    case EMealType.VEGETARIAN:
      return ClientNeighborhoodTableUpdateRequestMealTypeEnum.Vegetarian;
    default:
      throw new Error('Unknown mealType!');
  }
};

const toClientCreateRequestMealTypeEnum = (
  mealType: string
): ClientNeighborhoodTableCreateRequestMealTypeEnum => {
  switch (mealType) {
    case EMealType.CLASSIC:
      return ClientNeighborhoodTableCreateRequestMealTypeEnum.Classic;
    case EMealType.VEGAN:
      return ClientNeighborhoodTableCreateRequestMealTypeEnum.Vegan;
    case EMealType.VEGETARIAN:
      return ClientNeighborhoodTableCreateRequestMealTypeEnum.Vegetarian;
    default:
      throw new Error('Unknown mealType!');
  }
};

const CreateTable: FC = () => {
  const happeningApi = useHappeningApi();
  const navigate = useNavigate();
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const { currentUser } = useCurrentUser();
  const { getResourceRoles } = useKeycloakUtils();

  const [isAddressMissingDialogOpen, setIsAddressMissingDialogOpen] =
    useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>();
  const [category, setCategory] = useState<string>(EMealType.CLASSIC);
  const [happeningDetails, setHappeningDetails] =
    useState<ClientHappeningExtended>();
  const [happeningProperties, setHappeningProperties] =
    useState<THappeningProperties>();

  const happeningId = useMemo(
    () => searchParams.get('eventId'),
    [searchParams]
  );
  const isPortalAdmin = useMemo(() => {
    const roles = getResourceRoles('digital-vital-portal');
    if (roles && roles.some((r) => r === EPortalRoles.PORTAL_ADMIN)) {
      return true;
    }
    return false;
  }, [getResourceRoles]);

  const categories = useMemo(() => Object.values(EMealType), []);

  const handleRejectConfirmation = useCallback(() => {
    setHappeningProperties(undefined);
    setIsConfirmationDialogOpen(false);
  }, []);

  const handleCategoryChange = useCallback((newCategory: string) => {
    setCategory(newCategory);
  }, []);

  const handleContinue = useCallback(
    (newHappeningProperties: THappeningProperties, errors: string[]) => {
      const { maxParticipants, cost } = newHappeningProperties;
      const allErrors = [...errors];
      allErrors.push(
        ...validateNumber(
          validations.participants.NeighborhoodTables,
          maxParticipants
        )
      );
      allErrors.push(
        ...validateNumber(validations.cost.NeighborhoodTables, cost ? cost : 0)
      );
      if (allErrors.length > 0) {
        setErrorMessages(allErrors);
        setIsErrorDialogOpen(true);
      } else {
        setHappeningProperties(newHappeningProperties);
        setIsConfirmationDialogOpen(true);
      }
    },
    []
  );

  const navigateToProfile = useCallback(() => {
    setIsAddressMissingDialogOpen(false);
    navigate('/my-area/my-profile');
  }, [navigate]);

  const navigateToNearByTisch = useCallback(() => {
    if (isPortalAdmin) {
      navigate('/nachbarschaftstische/nearbytisch');
    } else {
      navigate('/nachbarschaftstische/mytisch');
    }
  }, [isPortalAdmin, navigate]);

  const handleAddressMissingDialogClose = useCallback(() => {
    setIsAddressMissingDialogOpen(false);
    navigateToNearByTisch();
  }, [navigateToNearByTisch]);

  const handleHappeningCreation = useCallback(() => {
    const createOrUpdateHappening = async () => {
      if (!happeningProperties) {
        return;
      }
      try {
        if (happeningId) {
          const clientNeighborhoodTableUpdateRequest: ClientNeighborhoodTableUpdateRequest =
            {
              ...happeningProperties,
              mealType: toClientUpdateRequestMealTypeEnum(category)
            };
          await happeningApi.updateNeighborhoodTable(
            happeningId,
            clientNeighborhoodTableUpdateRequest
          );
        } else {
          const clientNeighborhoodTableCreateRequest: ClientNeighborhoodTableCreateRequest =
            {
              ...happeningProperties,
              mealType: toClientCreateRequestMealTypeEnum(category)
            };
          await happeningApi.createNeighborhoodTable(
            clientNeighborhoodTableCreateRequest
          );
        }
        setIsConfirmationDialogOpen(false);
        navigateToNearByTisch();
      } catch (error: BackendApiError | unknown) {
        if (error instanceof BackendApiError) {
          switch (error.details.type) {
            case ADDRESS_ERROR:
              setErrorMessages([CREATE_UPDATE_HAPPENING_ADDRESS_ERROR]);
              break;
            // TODO: Other error types
            default:
              setErrorMessages([DEFAULT_ERROR]);
          }
        } else {
          setErrorMessages([DEFAULT_ERROR]);
        }
        setIsConfirmationDialogOpen(false);
        setIsErrorDialogOpen(true);
      }
    };
    createOrUpdateHappening();
  }, [
    happeningProperties,
    happeningId,
    navigateToNearByTisch,
    happeningApi,
    category
  ]);

  const handleErrorDialogClose = useCallback(() => {
    setIsErrorDialogOpen(false);
  }, []);

  useEffect(() => {
    if (!currentUser) {
      setHappeningDetails(undefined);
      return;
    }
    if (!happeningId) {
      setHappeningDetails(undefined);
      const { street, city, zipCode } = currentUser;
      if (street && zipCode && city) {
        return;
      }
      setIsAddressMissingDialogOpen(true);
    } else {
      const loadHappening = async () => {
        const happening = await happeningApi.getHappeningById(happeningId);
        const { creatorId } = happening;
        if (creatorId !== currentUser?.id && !isPortalAdmin) {
          return;
        }
        setHappeningDetails(happening);
      };
      if (happeningId !== happeningDetails?.id) {
        loadHappening();
      }
    }
  }, [currentUser, happeningApi, happeningDetails, happeningId, isPortalAdmin]);

  return (
    <ProtectedPage
      errorMessage={CREATE_TABLE_NOT_AUTHENTICATED}
      onErrorDialogClose={navigateToNearByTisch}
    >
      <CreateHappening
        happening={happeningDetails}
        heading={OFFER_TABLE_HEADING_TEXT}
        titleLabel={TABLE_NAME_TEXT}
        descriptionLabel={TABLE_DESC_TEXT}
        descriptionPlaceholder={TABLE_DESC_PLACEHOLDER}
        categories={categories}
        currentCategory={category}
        categoryLabel={MEAL_TYPE_TEXT}
        categoryIcon={
          <RestaurantIcon
            sx={{ color: theme.palette.primary.main, fontSize: '48px' }}
          />
        }
        imageIcon={TableIcon}
        showOrganiserForm={false}
        showPresentTypeSelection={false}
        showRegistrationHintInput={false}
        onCategoryChange={handleCategoryChange}
        onContinue={handleContinue}
      />
      <ConfirmationDialog
        title={CONFIRM_DIALOG_TITLE_TEXT}
        message={CONFIRM_DIALOG_MSG_TEXT}
        open={isConfirmationDialogOpen}
        onClose={handleRejectConfirmation}
        onAction={handleHappeningCreation}
      />
      <ErrorDialog
        msgList={errorMessages}
        open={isErrorDialogOpen}
        onClose={handleErrorDialogClose}
      />
      <MissingAddressDialog
        message={NACHBARSCHAFTSTISCH_ADDRESS_MISSING_TEXT}
        open={isAddressMissingDialogOpen}
        onAction={navigateToProfile}
        onClose={handleAddressMissingDialogClose}
      />
    </ProtectedPage>
  );
};

export default CreateTable;
