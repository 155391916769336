"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientHappeningContactParticipantsConfirmationToJSON = exports.ClientHappeningContactParticipantsConfirmationFromJSONTyped = exports.ClientHappeningContactParticipantsConfirmationFromJSON = void 0;
const runtime_1 = require("../runtime");
function ClientHappeningContactParticipantsConfirmationFromJSON(json) {
    return ClientHappeningContactParticipantsConfirmationFromJSONTyped(json, false);
}
exports.ClientHappeningContactParticipantsConfirmationFromJSON = ClientHappeningContactParticipantsConfirmationFromJSON;
function ClientHappeningContactParticipantsConfirmationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'userIdsSuccessfulNotification': !runtime_1.exists(json, 'userIdsSuccessfulNotification') ? undefined : json['userIdsSuccessfulNotification'],
        'userIdsFailedNotification': !runtime_1.exists(json, 'userIdsFailedNotification') ? undefined : json['userIdsFailedNotification'],
    };
}
exports.ClientHappeningContactParticipantsConfirmationFromJSONTyped = ClientHappeningContactParticipantsConfirmationFromJSONTyped;
function ClientHappeningContactParticipantsConfirmationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'userIdsSuccessfulNotification': value.userIdsSuccessfulNotification,
        'userIdsFailedNotification': value.userIdsFailedNotification,
    };
}
exports.ClientHappeningContactParticipantsConfirmationToJSON = ClientHappeningContactParticipantsConfirmationToJSON;
