import { createTheme } from '@mui/material/styles';
import baseTheme from './baseTheme';
import {
  NAVIGATION_BTN_BG,
  NAVIGATION_BTN_HOVER_BG,
  NAVIGATION_PRIMARY,
  NAVIGATION_TEXT
} from './colors/globalColors';

const navigatorTheme = createTheme({
  ...baseTheme,
  palette: {
    primary: {
      main: NAVIGATION_PRIMARY,
      contrastText: NAVIGATION_TEXT
    },
    secondary: {
      main: NAVIGATION_BTN_BG,
      contrastText: NAVIGATION_PRIMARY
    }
  },
  components: {
    ...baseTheme.components,
    MuiButton: {
      styleOverrides: {
        containedSecondary: {
          '&:hover': {
            backgroundColor: NAVIGATION_BTN_HOVER_BG
          }
        }
      }
    }
  }
});

export default navigatorTheme;
