import {
  ClientHappeningExtended,
  ClientHappeningExtendedHappeningTypeEnum,
  ClientUser
} from 'digital-vital-backend-api';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HappeningTypeConverter from '../../api/HappeningTypeConverter';
import {
  EVENT_REJECTION_CONFIRMATION_MESSAGE,
  EVENT_REJECTION_CONFIRMATION_TITLE,
  EVENT_REJECTION_SUCCESS_MESSAGE,
  REJECTION_CONFIRMATION_MESSAGE,
  REJECTION_CONFIRMATION_TITLE,
  REJECTION_SUCCESS_MESSAGE,
  TERMINE_PUBLISH_CONFIRMATION_TEXT,
  TERMINE_PUBLISH_SUCCESS_TEXT
} from '../../constants/strings';
import useAuthContext from '../../hooks/useAuthContext';
import useHappeningApi from '../../hooks/useHappeningApi';
import useKeycloakUtils from '../../hooks/useKeycloakUtils';
import useQuery from '../../hooks/useQuery';
import { EPortalRoles } from '../../types/roles';
import { phoneIsValid } from '../../utils/common';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';
import ContactOrganizerDialog from '../dialogs/ContactDialog';
import NotAuthenticatedDialog from '../dialogs/NotAuthenticatedDialog';
import ShareDialog from '../dialogs/ShareDialog';
import SuccessDialog from '../dialogs/SuccessDialog';
import ErrorDialog from '../ErrorDialog';
import ReportingDialog from '../ReportingDialog';
import CancelHappeningDialog from './contact-dialogs/CancelHappeningDialog';
import ContactParticipantDialog from './contact-dialogs/ContactParticipantDialog';
import OrganizerPhoneDialog from './contact-dialogs/OrganizerPhoneDialog';
import DeleteHappeningDialog from './HappeningDeleteConfirmationDialog';
import HappeningDetailsView from './HappeningDetailsView';
import ParticipationRequestDialog from './participation-request-dialog/ParticipationRequestDialog';
import ParticipationConfirmationDailog from './ParticipationConfirmationDailog';
import ParticipationNotAuthenticatedDialog from './ParticipationNotAuthenticatedDialog';
import RequestSuccessDialog from './success-dialogs/RequestSuccessDialog';
import TerminePublishSuccessDialog from './success-dialogs/TerminePublishSuccessDialog';
import TerminePublishConfirmationDialog from './TerminePublishConfirmationDialog';

type HappeningDetailsProps = {
  happening?: ClientHappeningExtended;
  categoryText: string;
  defaultHeaderImage: string;
  hasOrganizerEmail: boolean;
  isPublic: boolean;
  joinButtonText: string;
  notAuthenticatedContent: React.ReactChild;
  participantBadgeText: string;
  participantButtonText: string;
  rejectionButtonText: string;
  requestSuccessDialogTitle: string;
  requestSuccessDialogMessage: string;
  typeIcon: JSX.Element;
  typeText: string;
  onEditHappening: () => void;
};

const HappeningDetails: FC<HappeningDetailsProps> = (
  props: HappeningDetailsProps
) => {
  const {
    happening,
    categoryText,
    defaultHeaderImage,
    hasOrganizerEmail,
    isPublic,
    joinButtonText,
    notAuthenticatedContent,
    participantBadgeText,
    participantButtonText,
    rejectionButtonText,
    requestSuccessDialogTitle,
    requestSuccessDialogMessage,
    typeIcon,
    typeText,
    onEditHappening
  } = props;
  const { keycloak } = useAuthContext();
  const { getResourceRoles } = useKeycloakUtils();
  const navigate = useNavigate();
  const happeningApi = useHappeningApi();
  const query = useQuery();
  const participationId = useMemo(() => query.get('participation'), [query]);

  const [isErrorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
  const [participants, setParticipants] = useState<Array<ClientUser>>([]);
  const [isParticipant, setIsParticipant] = useState<boolean>(false);
  const [isPortalAdmin, setIsPortalAdmin] = useState<boolean>(false);
  const [externalId, setExternalId] = useState<string | undefined>(undefined);
  const [isTermineConfirmationDialogOpen, setTermineConfirmationDialogOpen] =
    useState<boolean>(false);
  const [
    isTerminePublishSuccessDialogOpen,
    setTerminePublishSuccessDialogOpen
  ] = useState<boolean>(false);
  const [hasOwnParticipation, setHasOwnParticipation] =
    useState<boolean>(false);
  const [isAuthDialogOpen, setAuthDialogOpen] = useState<boolean>(false);
  const [isParticipationAuthDialogOpen, setParticipationAuthDialogOpen] =
    useState<boolean>(false);
  const [isParticipantDialogOpen, setIsParticipantDialogOpen] =
    useState<boolean>(
      () => participationId !== null && keycloak.authenticated === true
    );
  const [isRequestDialogOpen, setRequestDialogOpen] = useState<boolean>(false);
  const [isSuccessDialogOpen, setSuccessDialogOpen] = useState<boolean>(false);
  const [isOrganizerPhoneDialogOpen, setIsOrganizerPhoneDialogOpen] =
    useState<boolean>(false);
  const [isContactOrganizerDialogOpen, setIsContactOrganizerDialogOpen] =
    useState<boolean>(false);
  const [isContactParticipantsDialogOpen, setIsContactParticipantsDialogOpen] =
    useState<boolean>(false);
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] =
    useState<boolean>(false);
  const [
    isReportingConfirmationDialogOpen,
    setReportingConfirmationDialogOpen
  ] = useState<boolean>(false);
  const [
    isParticipationConfirmationDialogOpen,
    setParticipationConfirmationDialogOpen
  ] = useState<boolean>(false);
  const [isContactAllParticipants, setIsContactAllParticipants] =
    useState<boolean>(false);
  const [selectedParticipant, setSelectedParticipant] = useState<
    ClientUser | undefined
  >();
  const [isCancelHappeningDialogOpen, setCancelHappeningDialogOpen] =
    useState<boolean>(false);
  const [isDeleteHappeningDialogOpen, setDeleteHappeningDialogOpen] =
    useState<boolean>(false);
  const [isShareHappeningDialogOpen, setShareHappeningDialogOpen] =
    useState<boolean>(false);

  const isEvent = useMemo(
    () =>
      happening?.happeningType ===
      ClientHappeningExtendedHappeningTypeEnum.Event,
    [happening?.happeningType]
  );

  const handleErrorDialogClose = useCallback(() => {
    setErrorDialogOpen(false);
    setErrorMsg(undefined);
  }, []);

  const handleReportingConfirmationDialogOpen = useCallback(() => {
    setReportingConfirmationDialogOpen(true);
  }, [setReportingConfirmationDialogOpen]);

  const handleReportingConfirmationDialogClose = useCallback(() => {
    setReportingConfirmationDialogOpen(false);
  }, []);

  const createHappeningParticipant = useCallback(() => {
    const createHappeningParticipantAsync = async () => {
      if (!happening) {
        return;
      }
      await happeningApi.joinHappening(happening.id);
      setParticipationConfirmationDialogOpen(false);
      setRequestDialogOpen(true);
    };
    createHappeningParticipantAsync();
  }, [happening, happeningApi]);

  const handleTerminePublishSucessDialogOpen = useCallback(() => {
    setTerminePublishSuccessDialogOpen(true);
  }, []);

  const handleTerminePublishSucessDialogClose = useCallback(() => {
    setTerminePublishSuccessDialogOpen(false);
  }, []);

  const handleTermineConfirmationDialogOpen = useCallback(() => {
    setTermineConfirmationDialogOpen(true);
  }, []);

  const handleTermineConfirmationDialogClose = useCallback(() => {
    setTermineConfirmationDialogOpen(false);
  }, []);

  const publishToTermine = useCallback(() => {
    const publishToTermineAsync = async () => {
      if (!happening) {
        return;
      }
      try {
        handleTermineConfirmationDialogClose();
        const response = await happeningApi.publishToTermineRegional(
          happening.id
        );
        setExternalId(response.externalId);
        handleTerminePublishSucessDialogOpen();
      } catch (error) {
        if (error instanceof Error) {
          setErrorMsg(error.message);
        }
        setErrorDialogOpen(true);
      }
    };
    publishToTermineAsync();
  }, [
    handleTermineConfirmationDialogClose,
    handleTerminePublishSucessDialogOpen,
    happening,
    happeningApi
  ]);

  const handleRequestClick = useCallback(() => {
    if (!keycloak.authenticated) {
      setAuthDialogOpen(true);
      return;
    }
    if (hasOwnParticipation) {
      setConfirmationDialogOpen(true);
      return;
    }
    setParticipationConfirmationDialogOpen(true);
  }, [hasOwnParticipation, keycloak.authenticated]);

  const navigateToNearByHappenning = useCallback(() => {
    if (!happening) return;
    switch (happening?.happeningType) {
      case ClientHappeningExtendedHappeningTypeEnum.NeighborhoodTable:
        navigate('/nachbarschaftstische/nearbytisch');
        break;
      case ClientHappeningExtendedHappeningTypeEnum.Event:
        navigate('/veranstaltungen/nearbyevents');
        break;
      default:
        return;
    }
  }, [happening, navigate]);

  const handleRequestAuthDialogClose = useCallback(() => {
    setAuthDialogOpen(false);
  }, []);

  const handleConfirmationDialogClose = useCallback(() => {
    setConfirmationDialogOpen(false);
  }, []);

  const handleParticipationConfirmationDialogClose = useCallback(() => {
    setParticipationConfirmationDialogOpen(false);
  }, []);

  const handleParticipationAuthDialogClose = useCallback(() => {
    setParticipationAuthDialogOpen(false);
  }, []);

  const handleParticipantDialogClose = useCallback(() => {
    setIsParticipantDialogOpen(false);
  }, []);

  const handleRequestDialogClose = useCallback(() => {
    setRequestDialogOpen(false);
  }, []);

  const handleSuccessDialogClose = useCallback(() => {
    setSuccessDialogOpen(false);
  }, []);

  const handleContactOrganizer = useCallback(() => {
    if (!happening) {
      return;
    }
    const { organizerContact } = happening;
    if (phoneIsValid(organizerContact)) {
      setIsOrganizerPhoneDialogOpen(true);
    } else {
      setIsContactOrganizerDialogOpen(true);
    }
  }, [happening]);

  const handleOrganizerContactDialogClose = useCallback(() => {
    setIsContactOrganizerDialogOpen(false);
    setIsContactAllParticipants(false);
    setSelectedParticipant(undefined);
  }, []);

  const handleParticipantsContactDialogOpen = useCallback(
    (all: boolean, participant?: ClientUser) => {
      setIsContactAllParticipants(all);
      setSelectedParticipant(participant);
      setIsContactParticipantsDialogOpen(true);
    },
    []
  );

  const handleParticipantsContact = useCallback(() => {
    setIsContactParticipantsDialogOpen(false);
  }, []);

  const handleOrganizerPhoneDialogClose = useCallback(() => {
    setIsOrganizerPhoneDialogOpen(false);
  }, []);

  const handleCancelHappening = useCallback(() => {
    setCancelHappeningDialogOpen(true);
  }, []);

  const handleCancelHappeningDialogClose = useCallback(() => {
    setCancelHappeningDialogOpen(false);
  }, []);

  const handleDeleteHappening = useCallback(() => {
    setDeleteHappeningDialogOpen(true);
  }, []);

  const handleDeleteHappeningDialogClose = useCallback(() => {
    setDeleteHappeningDialogOpen(false);
  }, []);

  const handleShareHappening = useCallback(() => {
    setShareHappeningDialogOpen(true);
  }, []);

  const handleShareHappeningDialogClose = useCallback(() => {
    setShareHappeningDialogOpen(false);
  }, []);

  const cancelHappeningParticipation = useCallback(() => {
    const cancelHappeningParticipantAsync = async () => {
      if (!happening) {
        return;
      }
      await happeningApi.cancelHappeningParticipation(happening.id);
      setHasOwnParticipation(false);
      handleConfirmationDialogClose();
      setSuccessDialogOpen(true);
    };
    cancelHappeningParticipantAsync();
  }, [happening, happeningApi, handleConfirmationDialogClose]);

  const deleteHappening = useCallback(() => {
    const deleteHappeningAsync = async () => {
      if (!happening) {
        return;
      }
      const { id } = happening;
      await happeningApi.deleteHappening(id);
      setDeleteHappeningDialogOpen(false);
      navigateToNearByHappenning();
    };
    deleteHappeningAsync();
  }, [happening, happeningApi, navigateToNearByHappenning]);

  /*
   * TODO: Need an approach to display a short display name
   */
  const shortenDisplayName = useCallback((name: string): string => {
    let displayName = name;
    if (displayName.length > 12)
      displayName = `${name.substring(0, 11)}${
        displayName[12] !== '.' ? '.' : ''
      }`;
    return displayName;
  }, []);

  const loadParticipants = useCallback(() => {
    const loadParticipantsAsync = async () => {
      const happeningId = happening?.id;
      if (!keycloak.authenticated || !happeningId) {
        return;
      }
      try {
        const loadedParticipants = await happeningApi.getHappeningParticipants(
          happeningId
        );
        setParticipants(
          loadedParticipants.map((participant) => {
            const { displayName } = participant;
            return {
              ...participant,
              displayName: shortenDisplayName(displayName)
            };
          })
        );
        setIsParticipant(true);
      } catch (error) {
        setIsParticipant(false);
        setParticipants([]);
      }
    };
    loadParticipantsAsync();
  }, [happening, keycloak.authenticated, happeningApi, shortenDisplayName]);

  useEffect(() => {
    if (!keycloak.authenticated && participationId !== null) {
      setParticipationAuthDialogOpen(true);
    }
  }, [keycloak.authenticated, participationId]);

  useEffect(() => {
    if (!happening) {
      return;
    }
    loadParticipants();
  }, [loadParticipants, happening]);

  useEffect(() => {
    const loadIsOwnHappeningAsync = async () => {
      if (!happening || !happening.happeningType || !keycloak.authenticated) {
        return;
      }
      const { id, happeningType } = happening;
      const hasCurrentUserHappeningParticipation =
        await happeningApi.hasCurrentUserHappeningParticipation(
          id,
          HappeningTypeConverter.toHappeningType(happeningType)
        );
      setHasOwnParticipation(hasCurrentUserHappeningParticipation);
    };
    loadIsOwnHappeningAsync();
  }, [happening, happeningApi, keycloak.authenticated]);

  useEffect(() => {
    if (!keycloak.authenticated) {
      return;
    }
    const roles = getResourceRoles('digital-vital-portal');
    if (roles && roles.some((r) => r === EPortalRoles.PORTAL_ADMIN)) {
      setIsPortalAdmin(true);
    }
  }, [getResourceRoles, keycloak]);

  if (!happening) {
    return null;
  }

  return (
    <>
      <HappeningDetailsView
        happening={happening}
        categoryText={categoryText}
        participants={participants}
        isParticipant={isParticipant}
        isPortalAdmin={isPortalAdmin}
        isPublic={isPublic}
        isEvent={isEvent}
        externalId={happening.externalId ?? externalId}
        hasOwnParticipation={hasOwnParticipation}
        hasOrganizerEmail={hasOrganizerEmail}
        defaultHeaderImage={defaultHeaderImage}
        joinButtonText={joinButtonText}
        participantBadgeText={participantBadgeText}
        participantButtonText={participantButtonText}
        rejectionButtonText={rejectionButtonText}
        typeIcon={typeIcon}
        typeText={typeText}
        onRequestButtonClick={handleRequestClick}
        onContactOrganizer={handleContactOrganizer}
        onContactParticipants={handleParticipantsContactDialogOpen}
        onCancelHappening={handleCancelHappening}
        onDeleteHappening={handleDeleteHappening}
        onEditHappening={onEditHappening}
        onShareHappening={handleShareHappening}
        onReportHappening={handleReportingConfirmationDialogOpen}
        onTerminePublish={handleTermineConfirmationDialogOpen}
      />
      <NotAuthenticatedDialog
        open={isAuthDialogOpen}
        onClose={handleRequestAuthDialogClose}
      >
        {notAuthenticatedContent}
      </NotAuthenticatedDialog>
      <ParticipationNotAuthenticatedDialog
        happening={happening}
        open={isParticipationAuthDialogOpen}
        onClose={handleParticipationAuthDialogClose}
      />
      <RequestSuccessDialog
        title={requestSuccessDialogTitle}
        message={requestSuccessDialogMessage}
        open={isRequestDialogOpen}
        onClose={handleRequestDialogClose}
      />
      <ParticipationRequestDialog
        happening={happening}
        participationId={participationId}
        open={isParticipantDialogOpen}
        onClose={handleParticipantDialogClose}
      />
      <ContactOrganizerDialog
        open={isContactOrganizerDialogOpen}
        onClose={handleOrganizerContactDialogClose}
        happening={happening}
      />
      <OrganizerPhoneDialog
        contact={happening.organizerContact}
        open={isOrganizerPhoneDialogOpen}
        onClose={handleOrganizerPhoneDialogClose}
      />
      <ContactParticipantDialog
        open={isContactParticipantsDialogOpen}
        onClose={handleParticipantsContact}
        happening={happening}
        participant={selectedParticipant}
        isContactAllParticipants={isContactAllParticipants}
      />
      <CancelHappeningDialog
        open={isCancelHappeningDialogOpen}
        onClose={handleCancelHappeningDialogClose}
        happening={happening}
        isEvent={isEvent}
      />
      <DeleteHappeningDialog
        open={isDeleteHappeningDialogOpen}
        onClose={handleDeleteHappeningDialogClose}
        onAction={deleteHappening}
      />
      <ConfirmationDialog
        title={
          isEvent
            ? EVENT_REJECTION_CONFIRMATION_TITLE
            : REJECTION_CONFIRMATION_TITLE
        }
        message={
          isEvent
            ? EVENT_REJECTION_CONFIRMATION_MESSAGE
            : REJECTION_CONFIRMATION_MESSAGE
        }
        open={isConfirmationDialogOpen}
        onClose={handleConfirmationDialogClose}
        onAction={cancelHappeningParticipation}
      />
      <ParticipationConfirmationDailog
        happeningTitle={happening.title}
        open={isParticipationConfirmationDialogOpen}
        onClose={handleParticipationConfirmationDialogClose}
        onAction={createHappeningParticipant}
        isEvent={isEvent}
      />
      <TerminePublishConfirmationDialog
        message={TERMINE_PUBLISH_CONFIRMATION_TEXT}
        open={isTermineConfirmationDialogOpen}
        onClose={handleTermineConfirmationDialogClose}
        onAction={publishToTermine}
      />
      <TerminePublishSuccessDialog
        message={TERMINE_PUBLISH_SUCCESS_TEXT}
        open={isTerminePublishSuccessDialogOpen}
        onClose={handleTerminePublishSucessDialogClose}
      />
      <SuccessDialog
        message={
          isEvent ? EVENT_REJECTION_SUCCESS_MESSAGE : REJECTION_SUCCESS_MESSAGE
        }
        open={isSuccessDialogOpen}
        onClose={handleSuccessDialogClose}
      />
      <ShareDialog
        title={happening.title}
        open={isShareHappeningDialogOpen}
        onClose={handleShareHappeningDialogClose}
      />
      <ReportingDialog
        open={isReportingConfirmationDialogOpen}
        onClose={handleReportingConfirmationDialogClose}
        eventType={happening.happeningType}
        eventId={happening.id}
      />
      <ErrorDialog
        open={isErrorDialogOpen}
        msg={errorMsg}
        onClose={handleErrorDialogClose}
      />
    </>
  );
};

export default HappeningDetails;
