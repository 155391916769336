import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { DialogContentText, List, ListItem, ListItemText } from '@mui/material';
import { FC } from 'react';
import { INFO, WARNING } from '../../constants/constants';
import { AlertDialogProps } from '../../interfaces/props';
import AbstractDialog from './AbstractDialog';

const AlertDialog: FC<AlertDialogProps> = (props: AlertDialogProps) => {
  const { open, onClose, title, msg, msgList, type, btnText } = props;

  let icon;
  switch (type) {
    case INFO:
      icon = <InfoIcon color="primary" sx={{ fontSize: '64px' }} />;
      break;
    case WARNING:
      icon = <WarningIcon color="primary" sx={{ fontSize: '64px' }} />;
      break;
    default:
      icon = <InfoIcon color="primary" sx={{ fontSize: '64px' }} />;
      break;
  }

  return (
    <AbstractDialog
      open={open}
      title={title}
      icon={icon}
      hideCancelBtn={true}
      maxWidth={msgList ? '40rem' : undefined}
      btnActionText={btnText}
      onAction={onClose}
      onClose={onClose}
    >
      {msg && (
        <DialogContentText id="alert-dialog-description">
          {msg}
        </DialogContentText>
      )}
      {msgList && (
        <List dense={true}>
          {msgList.map((m, i) => (
            <ListItem key={i}>
              <ListItemText primary={m} />
            </ListItem>
          ))}
        </List>
      )}
    </AbstractDialog>
  );
};

export default AlertDialog;
