import { FC, Fragment, useMemo } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { Alert, Typography } from '@mui/material';
import { findById } from '../../../utils/common';
import { THelpOutletContext } from '../Help';
import HTMLContent from '../../../components/HTMLContent';

type TParams = {
  entryId: string;
};

const HelpEntry: FC = () => {
  const { helpEntries } = useOutletContext<THelpOutletContext>();
  const { entryId } = useParams<TParams>();

  const currentEntry = useMemo(() => {
    if (!entryId) {
      return undefined;
    }
    return findById(helpEntries, entryId);
  }, [entryId, helpEntries]);

  if (!currentEntry) {
    return <Alert severity="error">Could not load post!</Alert>;
  }

  return (
    <Fragment>
      <Typography variant="h2">{currentEntry.title}</Typography>
      <Typography component="div">
        <HTMLContent content={currentEntry.description} />
      </Typography>
    </Fragment>
  );
};

export default HelpEntry;
