import {
  ClientGeoAreaExtended,
  GeoAreaApiInterface,
  GeoAreaApi
} from 'digital-vital-backend-api';
import { APP_VARIANT_IDENTIFIER } from '../config';
import BackendApi from './BackendAPI';

export default class BackendGeoAreaAPI extends BackendApi {
  private readonly _geoAreaApi: GeoAreaApiInterface;

  constructor(accessToken?: string) {
    super(accessToken);
    this._geoAreaApi = new GeoAreaApi(this.createConfiguration());
  }

  public getGeoAreayById(
    geoAreaId: string,
    includeChildren = false
  ): Promise<ClientGeoAreaExtended> {
    return this.callApi(() =>
      this._geoAreaApi.getGeoAreaById(
        geoAreaId,
        includeChildren,
        false,
        APP_VARIANT_IDENTIFIER
      )
    );
  }

  public searchGeoArea(
    search: string,
    upToDepth: number
  ): Promise<Array<ClientGeoAreaExtended>> {
    return this.callApi(() =>
      this._geoAreaApi.searchGeoAreas(
        search,
        upToDepth,
        undefined,
        APP_VARIANT_IDENTIFIER
      )
    );
  }

  public getDefaultGeoAreaOfPortalVariant(
    includeChildren = true
  ): Promise<ClientGeoAreaExtended> {
    return this.callApi(() =>
      this._geoAreaApi.getDefaultGeoAreaOfPortalVariant(
        includeChildren,
        false,
        APP_VARIANT_IDENTIFIER
      )
    );
  }
}
