import { TImage } from '../../../types/common';
import { EPresentType, TAccessible, TEditableProperties } from './types';

export type TSetTitle = {
  name: 'setTitle';
  title: string;
};

export type TSetOrganiserName = {
  name: 'setOrganiserName';
  organiserName: string;
};

export type TSetOrganiserContact = {
  name: 'setOrganiserContact';
  organiserContact: string;
};

export type TSetDescription = {
  name: 'setDescription';
  description: string;
};

export type TSetRegistrationHint = {
  name: 'setRegistrationHint';
  registrationHint: string;
};

export type TSetAddress = {
  name: 'setAddress';
  street: string;
  zipCode: string;
  city: string;
};

export type TSetAccessibleValue = {
  name: 'setAccessibleValue';
  key: keyof TAccessible;
  value: boolean;
};

export type TSetDate = {
  name: 'setDate';
  date: Date | null;
};

export type TSetStartTime = {
  name: 'setStartTime';
  startTime: Date | null;
};

export type TSetEndTime = {
  name: 'setEndTime';
  endTime: Date | null;
};

export type TSetMaxParticipants = {
  name: 'setMaxParticipants';
  maxParticipants: string | number;
};

export type TSetCost = {
  name: 'setCost';
  cost: number;
};

export type TSetImageId = {
  name: 'setImageId';
  imageId: string | null;
};

export type TSetDeleteImage = {
  name: 'setDeleteImage';
  deleteImage: boolean;
};

export type TSetImage = {
  name: 'setImage';
  image: TImage | null;
};

export type TSetPresentType = {
  name: 'setPresentType';
  presentType: EPresentType;
};

export type TSetUrl = {
  name: 'setUrl';
  url: string;
};

export type TSetEditableProperties = {
  name: 'setEditableProperties';
  editableProperties: TEditableProperties;
};

export type TReset = {
  name: 'reset';
};

export type TAction =
  | TSetTitle
  | TSetOrganiserName
  | TSetOrganiserContact
  | TSetDescription
  | TSetRegistrationHint
  | TSetAddress
  | TSetAccessibleValue
  | TSetDate
  | TSetStartTime
  | TSetEndTime
  | TSetMaxParticipants
  | TSetCost
  | TSetImageId
  | TSetDeleteImage
  | TSetImage
  | TSetPresentType
  | TSetUrl
  | TSetEditableProperties
  | TReset;

export const setTitle = (title: string): TAction => {
  return { name: 'setTitle', title };
};

export const setOrganiserName = (organiserName: string): TAction => {
  return { name: 'setOrganiserName', organiserName };
};

export const setOrganiserContact = (organiserContact: string): TAction => {
  return { name: 'setOrganiserContact', organiserContact };
};

export const setDescription = (description: string): TAction => {
  return { name: 'setDescription', description };
};

export const setRegistrationHint = (registrationHint: string): TAction => {
  return { name: 'setRegistrationHint', registrationHint };
};

export const setAddress = (
  street: string,
  zipCode: string,
  city: string
): TAction => {
  return { name: 'setAddress', street, zipCode, city };
};

export const setAccessibleValue = (
  key: keyof TAccessible,
  value: boolean
): TAction => {
  return { name: 'setAccessibleValue', key, value };
};

export const setDate = (date: Date | null): TAction => {
  return { name: 'setDate', date };
};

export const setStartTime = (startTime: Date | null): TAction => {
  return { name: 'setStartTime', startTime };
};

export const setEndTime = (endTime: Date | null): TAction => {
  return { name: 'setEndTime', endTime };
};

export const setMaxParticipants = (
  maxParticipants: string | number
): TAction => {
  return {
    name: 'setMaxParticipants',
    maxParticipants
  };
};

export const setCost = (cost: number): TAction => {
  return { name: 'setCost', cost };
};

export const setImageId = (imageId: string | null): TAction => {
  return { name: 'setImageId', imageId };
};

export const setDeleteImage = (deleteImage: boolean): TAction => {
  return { name: 'setDeleteImage', deleteImage };
};

export const setImage = (image: TImage | null): TAction => {
  return { name: 'setImage', image };
};

export const setPresentType = (presentType: EPresentType): TAction => {
  return { name: 'setPresentType', presentType };
};

export const setUrl = (url: string): TAction => {
  return { name: 'setUrl', url };
};

export const setEditableProperties = (
  editableProperties: TEditableProperties
): TAction => {
  return { name: 'setEditableProperties', editableProperties };
};

export const reset = (): TAction => {
  return { name: 'reset' };
};
