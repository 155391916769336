import { Breadcrumbs, Link, Stack, Typography } from '@mui/material';
import { ClientPostGeoAreaMapping } from 'digital-vital-backend-api';
import { FC, useCallback, useEffect, useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import Notice from '../../../assets/svgs/notice.svg';
import NoticeList from '../../../components/notice/NoticeList';
import {
  HOME_TILE_PINNWAND,
  NEARBY_NOTICE_DESC_TEXT,
  NOTICE_TAB_1_TEXT
} from '../../../constants/strings';
import usePostApi from '../../../hooks/usePostApi';
const NearbyNotices: FC = () => {
  const postApi = usePostApi();
  const [nearbyNotices, setNearbyNotices] = useState<
    Array<ClientPostGeoAreaMapping>
  >([]);

  const loadNearbyNotices = useCallback(() => {
    const loadNearByNoticesAsync = async () => {
      const allPosts = await postApi.getPosts();
      setNearbyNotices(allPosts);
    };
    loadNearByNoticesAsync();
  }, [postApi]);

  useEffect(() => {
    loadNearbyNotices();
    return () => {
      setNearbyNotices([]);
    };
  }, [loadNearbyNotices]);
  return (
    <Stack spacing={2}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          color="text.primary"
          variant="h3"
          to="/"
          component={ReactRouterLink}
        >
          {HOME_TILE_PINNWAND}
        </Link>
        <Typography color="text.primary" variant="h3">
          {NOTICE_TAB_1_TEXT}
        </Typography>
      </Breadcrumbs>
      <Typography variant="body1" sx={{ fontSize: '1rem', mb: 1 }}>
        {NEARBY_NOTICE_DESC_TEXT}
      </Typography>
      <NoticeList postGeoAreaMappings={nearbyNotices} defaultImage={Notice} />
    </Stack>
  );
};

export default NearbyNotices;
