import FormatSizeIcon from '@mui/icons-material/FormatSize';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import { Container, Grid, Typography } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import RenderOnAuthenticated from '../../../components/RenderOnAuthenticated';
import {
  ACCOUNT_DELETION_TEXT,
  FONT_SIZE_SETTINGS
} from '../../../constants/strings';
import RequestAccountDeletionDialog from './RequestAccountDeletionDialog';
import SettingsCard from './SettingsCard';

const IconStyles = {
  color: 'common.white',
  fontSize: '4rem',
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)'
} as const;

const textStyle = {
  position: 'absolute',
  bottom: '8px',
  textTransform: 'uppercase'
} as const;

const SettingsHome: FC = () => {
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] =
    useState<boolean>(false);

  const handleConfirmationDialogClose = useCallback(() => {
    setConfirmationDialogOpen(false);
  }, []);

  const handleAccountDeletionClick = useCallback(() => {
    setConfirmationDialogOpen(true);
  }, []);

  return (
    <Container>
      <Grid container spacing={1}>
        <Grid item lg={12} xs={12}>
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <Link to="schriftgroesse">
                <SettingsCard>
                  <FormatSizeIcon sx={IconStyles} />
                  <Typography variant="body2" color="white" sx={textStyle}>
                    {FONT_SIZE_SETTINGS}
                  </Typography>
                </SettingsCard>
              </Link>
            </Grid>
            <Grid item sm={6} xs={12}>
              <RenderOnAuthenticated>
                <SettingsCard
                  onClick={handleAccountDeletionClick}
                  sx={{ cursor: 'pointer' }}
                >
                  <NoAccountsIcon sx={IconStyles} />
                  <Typography variant="body2" color="white" sx={textStyle}>
                    {ACCOUNT_DELETION_TEXT}
                  </Typography>
                </SettingsCard>
              </RenderOnAuthenticated>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <RenderOnAuthenticated>
        <RequestAccountDeletionDialog
          open={isConfirmationDialogOpen}
          onClose={handleConfirmationDialogClose}
        />
      </RenderOnAuthenticated>
    </Container>
  );
};

export default SettingsHome;
