import { Typography } from '@mui/material';
import { FC } from 'react';
import { CANCEL_SUCCESS_CLOSE_BTN_TXT } from '../../../constants/strings';
import AbstractDialog from '../../dialogs/AbstractDialog';

type OrganizerPhoneDialogProps = {
  contact?: string;
  open: boolean;
  onClose: () => void;
};

const OrganizerPhoneDialog: FC<OrganizerPhoneDialogProps> = (props) => {
  const { contact, open, onClose } = props;

  return (
    <AbstractDialog
      open={open}
      btnActionText={CANCEL_SUCCESS_CLOSE_BTN_TXT}
      hideCancelBtn={true}
      onClose={onClose}
      onAction={onClose}
    >
      <Typography align="center">
        {
          'Der Organisator dieser Veranstaltung möchte per Telefon kontaktiert werden. Sie können den Organisator unter '
        }
        <b>{contact}</b>
        {' erreichen.'}
      </Typography>
    </AbstractDialog>
  );
};

export default OrganizerPhoneDialog;
