import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import { ClientComment } from 'digital-vital-backend-api';
import moment from 'moment';
import { CSSProperties, FC, useMemo } from 'react';
import ImageService, { ImageSizes } from '../../services/ImageService';
interface NoticeCommentProps {
  comment: ClientComment;
}
const useStyles = (): Record<string, CSSProperties> => ({
  commentBubleStyle: {
    backgroundColor: 'white',
    padding: '1rem',
    borderRadius: '.25rem',
    flexGrow: 1
  },
  commentTimestampStyle: {
    color: '#939393'
  },
  userAvatarStyle: { border: 'solid 1px #939393' }
});
const NoticeCommentItem: FC<NoticeCommentProps> = ({ comment }) => {
  const styles = useStyles();
  const { author, text, created } = comment;
  const userAvatarUrl = useMemo(
    () =>
      ImageService.getImageUrl(ImageSizes.THUMBNAIL, author?.profilePicture),
    [author]
  );
  return (
    <Box sx={{ my: '1rem' }}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Box sx={{ mb: '1rem' }}>
          {userAvatarUrl ? (
            <Avatar
              src={userAvatarUrl}
              alt={author?.displayName}
              sx={styles.userAvatarStyle}
            />
          ) : (
            <AccountCircleRoundedIcon
              color="primary"
              sx={{ fontSize: '2.75rem' }}
            />
          )}
        </Box>
        <Box>
          <Box sx={styles.commentBubleStyle}>
            <Typography
              component="span"
              color="primary"
              fontWeight="bold"
              sx={{ mr: '.25rem' }}
            >
              {`${author?.displayName}: `}
            </Typography>
            <Typography component="span">{text}</Typography>
          </Box>
          <Typography variant="caption" sx={styles.commentTimestampStyle}>
            {moment(created).fromNow()}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default NoticeCommentItem;
