import { createTheme } from '@mui/material/styles';
import baseTheme from './baseTheme';
import { NEUIGKEITEN_PRIMARY, NEUIGKEITEN_TEXT } from './colors/globalColors';

const newsTheme = createTheme({
  ...baseTheme,
  palette: {
    primary: {
      main: NEUIGKEITEN_PRIMARY,
      contrastText: NEUIGKEITEN_TEXT
    },
    secondary: {
      main: NEUIGKEITEN_TEXT,
      contrastText: NEUIGKEITEN_PRIMARY
    }
  }
});

export default newsTheme;
