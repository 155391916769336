"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientCalendarEventUpdateRequestToJSON = exports.ClientCalendarEventUpdateRequestFromJSONTyped = exports.ClientCalendarEventUpdateRequestFromJSON = exports.ClientCalendarEventUpdateRequestCategoryEnum = void 0;
const runtime_1 = require("../runtime");
/**
* @export
* @enum {string}
*/
var ClientCalendarEventUpdateRequestCategoryEnum;
(function (ClientCalendarEventUpdateRequestCategoryEnum) {
    ClientCalendarEventUpdateRequestCategoryEnum["HealthSport"] = "HEALTH_SPORT";
    ClientCalendarEventUpdateRequestCategoryEnum["Culture"] = "CULTURE";
    ClientCalendarEventUpdateRequestCategoryEnum["ExchangeEncounter"] = "EXCHANGE_ENCOUNTER";
    ClientCalendarEventUpdateRequestCategoryEnum["General"] = "GENERAL";
})(ClientCalendarEventUpdateRequestCategoryEnum = exports.ClientCalendarEventUpdateRequestCategoryEnum || (exports.ClientCalendarEventUpdateRequestCategoryEnum = {}));
function ClientCalendarEventUpdateRequestFromJSON(json) {
    return ClientCalendarEventUpdateRequestFromJSONTyped(json, false);
}
exports.ClientCalendarEventUpdateRequestFromJSON = ClientCalendarEventUpdateRequestFromJSON;
function ClientCalendarEventUpdateRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'description': json['description'],
        'title': json['title'],
        'startTime': !runtime_1.exists(json, 'startTime') ? undefined : json['startTime'],
        'endTime': !runtime_1.exists(json, 'endTime') ? undefined : json['endTime'],
        'maxParticipants': !runtime_1.exists(json, 'maxParticipants') ? undefined : json['maxParticipants'],
        'accessibleDescription': !runtime_1.exists(json, 'accessibleDescription') ? undefined : json['accessibleDescription'],
        'cost': json['cost'],
        'street': json['street'],
        'zipCode': json['zipCode'],
        'city': json['city'],
        'telephone': !runtime_1.exists(json, 'telephone') ? undefined : json['telephone'],
        'temporaryMediaItemId': !runtime_1.exists(json, 'temporaryMediaItemId') ? undefined : json['temporaryMediaItemId'],
        'deleteImage': !runtime_1.exists(json, 'deleteImage') ? undefined : json['deleteImage'],
        'organizer': !runtime_1.exists(json, 'organizer') ? undefined : json['organizer'],
        'organizerContact': !runtime_1.exists(json, 'organizerContact') ? undefined : json['organizerContact'],
        'registrationNotice': !runtime_1.exists(json, 'registrationNotice') ? undefined : json['registrationNotice'],
        'category': json['category'],
        'online': json['online'],
        'url': !runtime_1.exists(json, 'url') ? undefined : json['url'],
    };
}
exports.ClientCalendarEventUpdateRequestFromJSONTyped = ClientCalendarEventUpdateRequestFromJSONTyped;
function ClientCalendarEventUpdateRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'description': value.description,
        'title': value.title,
        'startTime': value.startTime,
        'endTime': value.endTime,
        'maxParticipants': value.maxParticipants,
        'accessibleDescription': value.accessibleDescription,
        'cost': value.cost,
        'street': value.street,
        'zipCode': value.zipCode,
        'city': value.city,
        'telephone': value.telephone,
        'temporaryMediaItemId': value.temporaryMediaItemId,
        'deleteImage': value.deleteImage,
        'organizer': value.organizer,
        'organizerContact': value.organizerContact,
        'registrationNotice': value.registrationNotice,
        'category': value.category,
        'online': value.online,
        'url': value.url,
    };
}
exports.ClientCalendarEventUpdateRequestToJSON = ClientCalendarEventUpdateRequestToJSON;
