import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Theme } from '@mui/system/createTheme/createTheme';
import { CSSProperties, FC, useMemo } from 'react';
import clip from 'text-clipper';
import {
  DESC_MAX_LENGTH_SM,
  DESC_MAX_LENGTH_XS,
  DESC_TITLE_LENGTH_XS
} from '../constants/constants';
import { HappeningItemProps } from '../interfaces/props';
import ImageService, { ImageSizes } from '../services/ImageService';
import OverviewCard from './OverviewCard';
import SimpleDate from './SimpleDate';

const useStyles = (theme: Theme): Record<string, CSSProperties> => ({
  dividerStyle: {
    marginBottom: theme.spacing(1)
  },
  cardTextBoxStyle: {
    flex: '1 1 75%'
  }
});

const HappeningItem: FC<HappeningItemProps> = (props: HappeningItemProps) => {
  const { defaultImage, event, routePrefix = '' } = props;
  const { id, title, description, image, startTime } = event;

  const theme = useTheme();
  const styles = useStyles(theme);
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const dateTime = useMemo(() => new Date(startTime), [startTime]);

  return (
    <OverviewCard
      image={ImageService.getImageUrl(ImageSizes.THUMBNAIL, image)}
      defaultImage={defaultImage}
      detailsLink={`${routePrefix}${id}`}
      isLargeScreen={isLargeScreen}
    >
      <Box sx={{ display: 'flex' }}>
        <SimpleDate date={dateTime} isLargeScreen={isLargeScreen} />
        <Box sx={styles.cardTextBoxStyle}>
          <Typography gutterBottom={true} variant="h3">
            {isLargeScreen ? title : clip(title ?? '', DESC_TITLE_LENGTH_XS)}
          </Typography>
          <Divider flexItem={true} sx={styles.dividerStyle} />
          <Typography variant="body2" component="p">
            {clip(
              description,
              isLargeScreen ? DESC_MAX_LENGTH_SM : DESC_MAX_LENGTH_XS
            )}
          </Typography>
        </Box>
      </Box>
    </OverviewCard>
  );
};

export default HappeningItem;
