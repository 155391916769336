import { FC, Fragment, PropsWithChildren } from 'react';
import useAuthContext from '../hooks/useAuthContext';

/*
 * Component to render elements only for user who haven't logged In.
 * Every element placed inside this component is passed as props to this component.
 */
const RenderOnAnonymous: FC<unknown> = (props: PropsWithChildren<unknown>) => {
  const { children } = props;
  const { keycloak } = useAuthContext();

  if (keycloak.authenticated) {
    return null;
  }

  return <Fragment>{children}</Fragment>;
};

export default RenderOnAnonymous;
