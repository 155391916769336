import EditIcon from '@mui/icons-material/Edit';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Container, ThemeProvider } from '@mui/material';
import { CSSProperties, FC, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import TabNavBar from '../../components/TabNavBar';
import {
  NOTICE_TAB_1_TEXT,
  NOTICE_TAB_2_TEXT,
  NOTICE_TAB_3_TEXT,
  NOTICE_TAB_4_TEXT,
  NOTICE_TAB_5_TEXT,
  NOTICE_TAB_6_TEXT
} from '../../constants/strings';
import useAuthContext from '../../hooks/useAuthContext';
import { PINNWAND_PRIMARY } from '../../themes/colors/globalColors';
import pinnwandTheme from '../../themes/pinnwandTheme';
const useStyles = (): Record<string, CSSProperties> => ({
  fullWidth: {
    width: '100%'
  },
  navBarBox: {
    position: 'fixed',
    background: PINNWAND_PRIMARY,
    width: '100%',
    zIndex: 1000
  }
});

const BulletinBoard: FC = () => {
  const BASE_PATH = '/pinnwand';
  const { keycloak } = useAuthContext();
  const styles = useStyles();
  const allTabs = useMemo(() => {
    const tabs = [
      {
        icon: <FormatListBulletedIcon />,
        label: NOTICE_TAB_1_TEXT,
        route: 'nearbynotices'
      },
      {
        icon: <LocalOfferIcon />,
        label: NOTICE_TAB_2_TEXT,
        route: 'offernotices'
      },
      {
        icon: <ManageSearchIcon />,
        label: NOTICE_TAB_3_TEXT,
        route: 'seekingnotices'
      },
      {
        icon: <SearchIcon />,
        label: NOTICE_TAB_4_TEXT,
        route: 'searchnotice'
      },
      {
        icon: <EditIcon />,
        label: NOTICE_TAB_5_TEXT,
        route: 'createnotice'
      }
    ];
    if (keycloak.authenticated) {
      const authTabs = [...tabs];
      authTabs.splice(3, 0, {
        icon: <FormatListBulletedIcon />,
        label: NOTICE_TAB_6_TEXT,
        route: 'mynotices'
      });
      return authTabs;
    }
    return tabs;
  }, [keycloak.authenticated]);

  return (
    <ThemeProvider theme={pinnwandTheme}>
      <Box sx={styles.fullWidth}>
        <Box sx={styles.navBarBox}>
          <Container>
            <TabNavBar basePath={BASE_PATH} tabs={allTabs} />
          </Container>
        </Box>
        <Container>
          <Box sx={{ height: { sm: 72, xs: 72 } }} />
          <Box sx={{ padding: { sm: '10px', xs: '5px' } }}>
            <Outlet />
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default BulletinBoard;
