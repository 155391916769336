import { NINE_UUID } from '../../constants/constants';
import {
  CATEGORY_FIT,
  CATEGORY_NEWS,
  INFO_HOME_HEADING
} from '../../constants/strings';
import { TCategory, TPost, TSubCategory } from '../../types/information';

export type TState = {
  selectedCategoryId: string | undefined;
  selectedSubCategoryId: string | undefined;
  currentSubCategories: Array<TSubCategory>;
  categories: Array<TCategory>;
  subCategories: Array<TSubCategory>;
  posts: Array<TPost>;
};

export type TSetSelectedCategoryAction = {
  name: 'setSelectedCategory';
  categoryId?: string;
};

export type TSetSelectedSubCategoryAction = {
  name: 'setSelectedSubCategory';
  subCategoryId?: string;
};

export type TSetCategoriesAction = {
  name: 'setCategoriesAction';
  categories: Array<TCategory>;
};

export type TSetSubCategoriesAction = {
  name: 'setSubCategoriesAction';
  subCategories: Array<TSubCategory>;
};

export type TSetPostsAction = {
  name: 'setPostsAction';
  posts: Array<TPost>;
};

export type TAction =
  | TSetSelectedCategoryAction
  | TSetSelectedSubCategoryAction
  | TSetCategoriesAction
  | TSetSubCategoriesAction
  | TSetPostsAction;

export const setSelectedCategory = (categoryId?: string): TAction => {
  return { name: 'setSelectedCategory', categoryId };
};

export const setSelectedSubCategory = (subCategoryId: string): TAction => {
  return { name: 'setSelectedSubCategory', subCategoryId };
};

export const setCategories = (categories: Array<TCategory>): TAction => {
  return { name: 'setCategoriesAction', categories };
};

export const setSubCategories = (
  subCategories: Array<TSubCategory>
): TAction => {
  return { name: 'setSubCategoriesAction', subCategories };
};

export const setPosts = (posts: Array<TPost>): TAction => {
  return { name: 'setPostsAction', posts };
};

export const initialState: TState = {
  selectedCategoryId: undefined,
  selectedSubCategoryId: undefined,
  currentSubCategories: [],
  categories: [],
  subCategories: [],
  posts: []
};

const overviewCategory = {
  id: NINE_UUID,
  name: INFO_HOME_HEADING
} as const;

const setCategoriesReducer = (
  state: TState,
  action: TSetCategoriesAction
): TState => {
  const { categories } = action;

  const informationCategories = categories.filter(
    (c) => c.name !== CATEGORY_NEWS && c.name !== CATEGORY_FIT
  );

  const allCategories = [overviewCategory, ...informationCategories];

  return {
    ...state,
    categories: allCategories,
    currentSubCategories: []
  };
};

export const reducer = (state: TState, action: TAction): TState => {
  const name = action.name;
  switch (name) {
    case 'setSelectedCategory': {
      const { categoryId } = action;
      const { subCategories } = state;
      return {
        ...state,
        selectedCategoryId: categoryId,
        selectedSubCategoryId: undefined,
        currentSubCategories: subCategories.filter(
          (s) => s.categoryId === categoryId
        )
      };
    }
    case 'setSelectedSubCategory': {
      const { subCategoryId } = action;
      return {
        ...state,
        selectedSubCategoryId: subCategoryId
      };
    }
    case 'setCategoriesAction':
      return setCategoriesReducer(state, action);
    case 'setSubCategoriesAction':
      return {
        ...state,
        subCategories: action.subCategories
      };
    case 'setPostsAction':
      return {
        ...state,
        posts: action.posts
      };
    default:
      throw new Error('Unknown ');
  }
};
