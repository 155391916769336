import { Box, Button, CircularProgress, Typography } from '@mui/material';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import React, {
  CSSProperties,
  FC,
  useCallback,
  useMemo,
  useState,
  useEffect
} from 'react';
import {
  DELETE_IMAGE_DIALOG_TITLE,
  UPDATE_IMAGE_DIALOG_TITLE,
  UPLOAD_IMAGE_DIALOG_BTN_CLOSE,
  UPLOAD_IMAGE_DIALOG_BTN_DELETE,
  UPLOAD_IMAGE_DIALOG_BTN_FINISHED,
  UPLOAD_IMAGE_DIALOG_BTN_UPLOAD,
  UPLOAD_IMAGE_DIALOG_TITLE
} from '../../constants/strings';
import useMediaItemApi from '../../hooks/useMediaItemApi';
import Default from '../../assets/svgs/default.svg';
import AbstractDialog from './AbstractDialog';
import { ClientTemporaryMediaItem } from 'digital-vital-backend-api';
import ImageService, { ImageSizes } from '../../services/ImageService';
import ConfirmationDialog from './ConfirmationDialog';

const useStyles = (): Record<string, CSSProperties> => ({
  progressBoxStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '4rem'
  },
  imageAreaStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonAreaStyle: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch'
  },
  buttonBoxStyle: {
    flex: '0 0 auto',
    margin: '0.25rem'
  },
  hintBoxStyle: {
    flex: '0 0 auto',
    margin: '0.25rem'
  }
});

type UploadImageDialogProps = {
  open: boolean;
  onClose: () => void;
  imageUrl?: string;
  onUploadFinished: (temporaryMediaItemId: string) => void;
  onDeleteImage?: (deleteImage: boolean) => void;
};

const UploadImageDialog: FC<UploadImageDialogProps> = (
  props: UploadImageDialogProps
) => {
  const { open, imageUrl, onClose, onUploadFinished, onDeleteImage } = props;
  const mediaItemApi = useMediaItemApi();
  const styles = useStyles();
  const [processing, setProcessing] = useState<boolean>(false);
  const [temporaryMediaItem, setTemporaryMediaItem] =
    useState<ClientTemporaryMediaItem>();
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] =
    useState<boolean>(false);
  const [imageUrlState, setimageUrlState] = useState<string>();

  const deleteTemporaryMediaItem = useCallback(async () => {
    if (!temporaryMediaItem) {
      return;
    }
    const { id } = temporaryMediaItem;
    await mediaItemApi.deleteTemporaryMediaItem(id);
    setTemporaryMediaItem(undefined);
  }, [mediaItemApi, temporaryMediaItem]);

  const uploadFile = useCallback(
    (file: Blob) => {
      const uploadFileAsync = async () => {
        setProcessing(true);
        try {
          await deleteTemporaryMediaItem();
          const result = await mediaItemApi.uploadTemporaryMediaItem(file);
          setTemporaryMediaItem(result);
        } finally {
          setProcessing(false);
        }
      };
      uploadFileAsync();
    },
    [deleteTemporaryMediaItem, mediaItemApi]
  );

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const fileList = event.target.files;
      if (!fileList) {
        return;
      }
      const file = fileList[0];
      uploadFile(file);
      // We have to do this so when we choose the same file again to upload the default onChange procs
      event.target.value = '';
    },
    [uploadFile]
  );

  const handleAction = useCallback(() => {
    if (!temporaryMediaItem) {
      return;
    }
    const { id } = temporaryMediaItem;
    onUploadFinished(id);
  }, [temporaryMediaItem, onUploadFinished]);

  const handleClose = useCallback(() => {
    const handleCloseAsync = async () => {
      await deleteTemporaryMediaItem();
      onClose();
    };
    handleCloseAsync();
  }, [onClose, deleteTemporaryMediaItem]);

  const isDeleteButtonDisabled = useMemo(() => {
    if (!imageUrl) {
      if (!temporaryMediaItem) {
        return true;
      }
      return false;
    }
    if (!temporaryMediaItem) {
      if (!imageUrl) {
        return true;
      }
      return false;
    }
  }, [imageUrl, temporaryMediaItem]);

  const handleImageDelete = useCallback(() => {
    const handleImageDeleteAsync = async () => {
      // we reuse the same logic for Button disabled or not
      if (!isDeleteButtonDisabled) {
        setConfirmationDialogOpen(true);
        setProcessing(true);
      }
      if (temporaryMediaItem) {
        try {
          await deleteTemporaryMediaItem();
        } finally {
          setProcessing(false);
          setConfirmationDialogOpen(false);
        }
      }
      if (imageUrl && onDeleteImage) {
        try {
          onDeleteImage(true);
        } finally {
          setProcessing(false);
          setConfirmationDialogOpen(false);
          setimageUrlState(undefined);
        }
      }
    };
    handleImageDeleteAsync();
  }, [
    imageUrl,
    isDeleteButtonDisabled,
    onDeleteImage,
    temporaryMediaItem,
    deleteTemporaryMediaItem
  ]);

  const handleRejectConfirmation = useCallback(() => {
    setConfirmationDialogOpen(false);
  }, []);

  const handleOpenConfirmationDialog = useCallback(() => {
    if (!isDeleteButtonDisabled) {
      setConfirmationDialogOpen(true);
    }
  }, [isDeleteButtonDisabled]);

  const imageUrlFromTemporaryMediaItem = useMemo(() => {
    return ImageService.getImageUrl(
      ImageSizes.THUMBNAIL,
      temporaryMediaItem?.mediaItem
    );
  }, [temporaryMediaItem]);

  useEffect(() => {
    setimageUrlState(imageUrl);
  }, [imageUrl]);

  return (
    <>
      <AbstractDialog
        open={open}
        title={imageUrl ? UPDATE_IMAGE_DIALOG_TITLE : UPLOAD_IMAGE_DIALOG_TITLE}
        icon={
          <DownloadForOfflineIcon color="primary" sx={{ fontSize: '64px' }} />
        }
        btnActionText={UPLOAD_IMAGE_DIALOG_BTN_FINISHED}
        btnCancelText={UPLOAD_IMAGE_DIALOG_BTN_CLOSE}
        onAction={handleAction}
        onClose={handleClose}
        isActionDisabled={!temporaryMediaItem}
        maxWidth="35rem"
      >
        <Box sx={styles.contentStyle}>
          <Box sx={styles.imageAreaStyle}>
            {processing ? (
              <CircularProgress />
            ) : (
              <img
                src={
                  (imageUrlFromTemporaryMediaItem || imageUrlState) ?? Default
                }
              />
            )}
          </Box>
          <Box sx={styles.buttonAreaStyle}>
            <Box sx={styles.buttonBoxStyle}>
              <input
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={handleFileChange}
                id="image-upload"
              />
              <label htmlFor="image-upload">
                <Button
                  fullWidth
                  color="primary"
                  component="span"
                  disabled={processing}
                  variant="contained"
                >
                  {UPLOAD_IMAGE_DIALOG_BTN_UPLOAD}
                </Button>
              </label>
            </Box>
            <Box sx={styles.buttonBoxStyle}>
              <Button
                fullWidth
                color="secondary"
                component="span"
                disabled={processing || isDeleteButtonDisabled}
                onClick={handleOpenConfirmationDialog}
                variant="contained"
              >
                {UPLOAD_IMAGE_DIALOG_BTN_DELETE}
              </Button>
            </Box>
          </Box>
        </Box>
        <Box sx={styles.hintBoxStyle}>
          <Typography variant="body2">
            Bitte veröffentlichen Sie fremde Fotos nur mit dem ausdrücklichen
            Einverständnis des Urhebers
          </Typography>
        </Box>
      </AbstractDialog>
      <ConfirmationDialog
        title={UPLOAD_IMAGE_DIALOG_BTN_DELETE}
        message={DELETE_IMAGE_DIALOG_TITLE}
        open={isConfirmationDialogOpen}
        onClose={handleRejectConfirmation}
        onAction={handleImageDelete}
      />
    </>
  );
};

export default UploadImageDialog;
