"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientPostGeoAreaMappingToJSON = exports.ClientPostGeoAreaMappingFromJSONTyped = exports.ClientPostGeoAreaMappingFromJSON = void 0;
const runtime_1 = require("../runtime");
const ClientGeoArea_1 = require("./ClientGeoArea");
const ClientPost_1 = require("./ClientPost");
function ClientPostGeoAreaMappingFromJSON(json) {
    return ClientPostGeoAreaMappingFromJSONTyped(json, false);
}
exports.ClientPostGeoAreaMappingFromJSON = ClientPostGeoAreaMappingFromJSON;
function ClientPostGeoAreaMappingFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'geoArea': !runtime_1.exists(json, 'geoArea') ? undefined : ClientGeoArea_1.ClientGeoAreaFromJSON(json['geoArea']),
        'posts': !runtime_1.exists(json, 'posts') ? undefined : (json['posts'].map(ClientPost_1.ClientPostFromJSON)),
    };
}
exports.ClientPostGeoAreaMappingFromJSONTyped = ClientPostGeoAreaMappingFromJSONTyped;
function ClientPostGeoAreaMappingToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'geoArea': ClientGeoArea_1.ClientGeoAreaToJSON(value.geoArea),
        'posts': value.posts === undefined ? undefined : (value.posts.map(ClientPost_1.ClientPostToJSON)),
    };
}
exports.ClientPostGeoAreaMappingToJSON = ClientPostGeoAreaMappingToJSON;
