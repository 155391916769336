import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ThemeProvider
} from '@mui/material';
import { FC, PropsWithChildren, useCallback } from 'react';
import { ALERT_BTN_BACK, ALERT_BTN_CONFIRM } from '../../constants/strings';
import navigatorTheme from '../../themes/navigatorTheme';

type AbstractDialogProps = {
  open: boolean;
  icon?: JSX.Element;
  title?: string;
  disableModalCloseActions?: boolean;
  hideActionBtn?: boolean;
  btnActionText?: string;
  hideCancelBtn?: boolean;
  btnCancelText?: string;
  onAction?: () => void;
  onClose: () => void;
  maxWidth?: string;
  isActionDisabled?: boolean;
};

const AbstractDialog: FC<AbstractDialogProps> = (
  props: PropsWithChildren<AbstractDialogProps>
) => {
  const {
    open,
    icon,
    title,
    disableModalCloseActions,
    hideActionBtn,
    btnActionText,
    hideCancelBtn,
    btnCancelText,
    onAction,
    onClose,
    maxWidth,
    isActionDisabled,
    children
  } = props;

  const btnStyle = {
    minWidth: '95px',
    textTransform: 'uppercase'
  };

  const handleClose = useCallback(
    (_event, reason) => {
      if (
        disableModalCloseActions &&
        (reason === 'backdropClick' || reason === 'escapeKeyDown')
      ) {
        return;
      }
      onClose();
    },
    [disableModalCloseActions, onClose]
  );

  return (
    <ThemeProvider theme={navigatorTheme}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiPaper-root.MuiDialog-paper': {
            maxWidth: maxWidth || '21rem',
            overflowY: 'visible',
            borderRadius: 2
          }
        }}
      >
        <Box
          sx={{
            margin: '2rem',
            border: 1,
            borderColor: navigatorTheme.palette.primary.main,
            borderRadius: 2,
            paddingTop: 1
          }}
        >
          <Box
            sx={{
              textAlign: 'center',
              position: 'absolute',
              top: '-16px',
              left: 0,
              right: 0
            }}
          >
            {icon || <InfoIcon color="primary" sx={{ fontSize: '64px' }} />}
          </Box>
          {title && (
            <DialogTitle
              id="alert-dialog-title"
              sx={{ textAlign: 'center', mt: 3 }}
            >
              {title}
            </DialogTitle>
          )}
          <DialogContent>{children}</DialogContent>
          <DialogActions sx={{ justifyContent: 'center', py: 3 }}>
            {!hideCancelBtn && (
              <Button
                variant="contained"
                color="secondary"
                onClick={onClose}
                sx={btnStyle}
              >
                {btnCancelText || ALERT_BTN_BACK}
              </Button>
            )}
            {!hideActionBtn && (
              <Button
                variant="contained"
                color="primary"
                onClick={onAction}
                autoFocus
                sx={btnStyle}
                disabled={isActionDisabled || false}
              >
                {btnActionText || ALERT_BTN_CONFIRM}
              </Button>
            )}
          </DialogActions>
        </Box>
      </Dialog>
    </ThemeProvider>
  );
};

export default AbstractDialog;
