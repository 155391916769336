import { CSSProperties, FC, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Container, ThemeProvider } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import LockIcon from '@mui/icons-material/Lock';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import PolicyIcon from '@mui/icons-material/Policy';
import TabNavBar from '../../components/TabNavBar';
import {
  IMPRESSUM_TAB_IMPRINT_TEXT,
  IMPRESSUM_TAB_PRIVACY_TEXT,
  IMPRESSUM_TAB_CONTACT_TEXT,
  IMPRESSUM_TAB_TERMS_OF_USE_TEXT
} from '../../constants/strings';
import { NAVIGATION_PRIMARY } from '../../themes/colors/globalColors';
import impressumTheme from '../../themes/impressumTheme';
import { TSiteSettings } from '../../types/settings';
import { fetchSiteSettings } from '../../api/SiteSettingsAPI';
import Loader from '../../components/Loader';

export type TImprintOutletContext = {
  siteSettings: TSiteSettings;
};

const useStyles = (): Record<string, CSSProperties> => ({
  fullWidth: {
    width: '100%'
  },
  navBarBox: {
    position: 'fixed',
    background: NAVIGATION_PRIMARY,
    width: '100%'
  }
});

const Imprint: FC = () => {
  const BASE_PATH = '/imprint';
  const TABS = [
    {
      icon: <InfoIcon />,
      label: IMPRESSUM_TAB_IMPRINT_TEXT,
      route: 'impressum'
    },
    {
      icon: <ContactPageIcon />,
      label: IMPRESSUM_TAB_CONTACT_TEXT,
      route: 'kontakt'
    },
    {
      icon: <LockIcon />,
      label: IMPRESSUM_TAB_PRIVACY_TEXT,
      route: 'datenschutz'
    },
    {
      icon: <PolicyIcon />,
      label: IMPRESSUM_TAB_TERMS_OF_USE_TEXT,
      route: 'nutzungsbedingungen'
    }
  ];

  const styles = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [siteSettings, setSiteSettings] = useState<TSiteSettings | null>();

  useEffect(() => {
    const loadSiteSettings = async () => {
      setLoading(true);
      try {
        const settings = await fetchSiteSettings();
        setSiteSettings(settings);
      } finally {
        setLoading(false);
      }
    };
    loadSiteSettings();
  }, []);

  return (
    <ThemeProvider theme={impressumTheme}>
      <Box sx={styles.fullWidth}>
        <Box sx={styles.navBarBox}>
          <Container>
            <TabNavBar basePath={BASE_PATH} tabs={TABS} />
          </Container>
        </Box>
        <Container>
          <Box sx={{ height: { sm: 72, xs: 72 } }} />
          <Box sx={{ padding: { sm: '10px', xs: '5px' } }}>
            {loading ? <Loader /> : <Outlet context={{ siteSettings }} />}
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Imprint;
