"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientCalendarEventCreateRequestToJSON = exports.ClientCalendarEventCreateRequestFromJSONTyped = exports.ClientCalendarEventCreateRequestFromJSON = exports.ClientCalendarEventCreateRequestCategoryEnum = void 0;
const runtime_1 = require("../runtime");
/**
* @export
* @enum {string}
*/
var ClientCalendarEventCreateRequestCategoryEnum;
(function (ClientCalendarEventCreateRequestCategoryEnum) {
    ClientCalendarEventCreateRequestCategoryEnum["HealthSport"] = "HEALTH_SPORT";
    ClientCalendarEventCreateRequestCategoryEnum["Culture"] = "CULTURE";
    ClientCalendarEventCreateRequestCategoryEnum["ExchangeEncounter"] = "EXCHANGE_ENCOUNTER";
    ClientCalendarEventCreateRequestCategoryEnum["General"] = "GENERAL";
})(ClientCalendarEventCreateRequestCategoryEnum = exports.ClientCalendarEventCreateRequestCategoryEnum || (exports.ClientCalendarEventCreateRequestCategoryEnum = {}));
function ClientCalendarEventCreateRequestFromJSON(json) {
    return ClientCalendarEventCreateRequestFromJSONTyped(json, false);
}
exports.ClientCalendarEventCreateRequestFromJSON = ClientCalendarEventCreateRequestFromJSON;
function ClientCalendarEventCreateRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'description': json['description'],
        'title': json['title'],
        'startTime': json['startTime'],
        'endTime': json['endTime'],
        'maxParticipants': json['maxParticipants'],
        'accessibleDescription': json['accessibleDescription'],
        'cost': json['cost'],
        'street': json['street'],
        'zipCode': json['zipCode'],
        'city': json['city'],
        'telephone': !runtime_1.exists(json, 'telephone') ? undefined : json['telephone'],
        'temporaryMediaItemId': !runtime_1.exists(json, 'temporaryMediaItemId') ? undefined : json['temporaryMediaItemId'],
        'organizer': !runtime_1.exists(json, 'organizer') ? undefined : json['organizer'],
        'organizerContact': !runtime_1.exists(json, 'organizerContact') ? undefined : json['organizerContact'],
        'registrationNotice': !runtime_1.exists(json, 'registrationNotice') ? undefined : json['registrationNotice'],
        'category': json['category'],
        'online': json['online'],
        'url': !runtime_1.exists(json, 'url') ? undefined : json['url'],
    };
}
exports.ClientCalendarEventCreateRequestFromJSONTyped = ClientCalendarEventCreateRequestFromJSONTyped;
function ClientCalendarEventCreateRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'description': value.description,
        'title': value.title,
        'startTime': value.startTime,
        'endTime': value.endTime,
        'maxParticipants': value.maxParticipants,
        'accessibleDescription': value.accessibleDescription,
        'cost': value.cost,
        'street': value.street,
        'zipCode': value.zipCode,
        'city': value.city,
        'telephone': value.telephone,
        'temporaryMediaItemId': value.temporaryMediaItemId,
        'organizer': value.organizer,
        'organizerContact': value.organizerContact,
        'registrationNotice': value.registrationNotice,
        'category': value.category,
        'online': value.online,
        'url': value.url,
    };
}
exports.ClientCalendarEventCreateRequestToJSON = ClientCalendarEventCreateRequestToJSON;
