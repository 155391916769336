import { useContext } from 'react';
import {
  TCurrentUserContext,
  CurrentUserContext
} from '../contexts/CurrentUserContext';

const useCurrentUser = (): TCurrentUserContext => {
  return useContext(CurrentUserContext);
};

export default useCurrentUser;
