import { ClientGeoAreaExtended } from 'digital-vital-backend-api/models';
import { ChangeEvent, FC, useCallback, useMemo } from 'react';
import { Checkbox, FormControlLabel, useTheme } from '@mui/material';
import TreeItem from '@mui/lab/TreeItem';

export interface RenderTree {
  id: string;
  name: string;
  children?: RenderTree[];
}

export type TreeProps = {
  selected?: string[];
  disabled?: string[];
  nodes: ClientGeoAreaExtended;
  getOnChange?(checked: boolean, nodes: RenderTree): void;
};

const RenderTreeWithCheckboxes: FC<TreeProps> = (props: TreeProps) => {
  const { nodes, selected, disabled, getOnChange } = props;
  const theme = useTheme();

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (getOnChange) {
        getOnChange(event.currentTarget.checked, nodes);
      }
    },
    [getOnChange, nodes]
  );

  const isDisabled = useMemo(() => {
    const { id } = nodes;
    return disabled?.some((item) => item === id);
  }, [disabled, nodes]);

  const isChecked = useMemo(() => {
    const { id } = nodes;
    return selected?.some((item) => item === id);
  }, [nodes, selected]);

  return (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      label={
        <FormControlLabel
          control={
            <Checkbox
              checked={isChecked}
              disabled={isDisabled}
              onChange={handleChange}
              onClick={(e) => e.stopPropagation()}
              sx={{
                '&.MuiCheckbox-root': {
                  color: theme.palette.primary.main
                }
              }}
            />
          }
          label={nodes.name}
        />
      }
    >
      {Array.isArray(nodes.childGeoAreas)
        ? nodes.childGeoAreas.map((childNodes) => (
            <RenderTreeWithCheckboxes
              key={childNodes.id}
              nodes={childNodes}
              selected={selected}
              disabled={disabled}
              getOnChange={getOnChange}
            />
          ))
        : null}
    </TreeItem>
  );
};
export default RenderTreeWithCheckboxes;
