// Header Strings
export const WELCOME_TEXT = 'Willkommen bei digital.vital';
export const LOGIN_BTN_TEXT = 'Anmelden';

// Home Strings
export const HOME_HEADING = 'Startseite';
export const HOME_TILE_NACHBARSCHAFTSTISCHE = 'Nachbarschaftstische';
export const HOME_TILE_VERANSTALTUNGEN = 'Veranstaltungen';
export const HOME_TILE_INFOMATION = 'Anlaufstellen, Vorsorge und mehr';
export const HOME_TILE_CALENDER = 'Kalender';
export const HOME_TILE_PINNWAND = 'Pinnwand';
export const HOME_TILE_FORUM = 'Forum';
export const HOME_TILE_NEUIGKEITEN = 'Neuigkeiten';
export const HOME_TILE_FIT = 'Fit & gesund im Alter';
export const HOME_TILE_ANPASSEN = 'Anpassen';
export const HOME_PROFILE_INCOMPLETE_DIALOG_TITLE = 'Profil vervollständigen';
export const HOME_PROFILE_INCOMPLETE_DIALOG_TEXT =
  'Vervollständigen Sie Ihr Profil um Digital Vital optimal nutzen zu können';
export const HOME_PROFILE_INCOMPLETE_DIALOG_CLOSE_BUTTON = 'Abmelden';
export const HOME_PROFILE_INCOMPLETE_DIALOG_ACTION_BUTTON = 'Zu meinem Profil';
export const COMING_SOON_TEXT = 'Bald verfügbar';

// Impressum String
export const IMPRESSUM_TAB_IMPRINT_TEXT = 'Impressum';
export const IMPRESSUM_TAB_CONTACT_TEXT = 'Kontakt';
export const IMPRESSUM_TAB_PRIVACY_TEXT = 'Datenschutz';
export const IMPRESSUM_TAB_TERMS_OF_USE_TEXT = 'Nutzungsbedingungen';

// Nachbarschaftstische Strings
export const HEADER_TITLE_NACHBARSCHAFTSTISCHE = 'Nachbarschaftstische';
export const NACHBARSCHAFTSTISCHE_TAB_1_TEXT = 'Angebote in der Nähe';
export const NACHBARSCHAFTSTISCHE_TAB_2_TEXT = 'Meine Anmeldungen';
export const NACHBARSCHAFTSTISCHE_TAB_3_TEXT = 'Meine Tische';
export const NACHBARSCHAFTSTISCHE_TAB_4_TEXT = 'Nachbarschaftstisch anbieten';
export const NACHBARSCHAFTSTISCHE_TAB_5_TEXT = 'Meine Tische (archiv)';
export const NACHBARSCHAFTSTISCHE_OFFER_BTN_TEXT = 'Tisch anbieten';
export const DAY_TEXT = 'An welchem Tag?';
export const TIME_TEXT = 'Um wieviel Uhr?';
export const ADDRESS_TEXT = 'Welche Adresse?';
export const COST_TEXT = 'Wieviel kostet es?';
export const MEAL_TYPE_TEXT = 'Art der Mahlzeit';
export const MEAL_TYPE_TEXT_CLASSIC = 'Klassisch';
export const MEAL_TYPE_TEXT_VEGAN = 'Vegan';
export const MEAL_TYPE_TEXT_VEGETARIAN = 'Vegetarisch';
export const PARTICIPANTS_TEXT = 'Wieviele Teilnehmer?';
export const PRICE_TEXT = 'Wieviel kostet es?';
export const ACCESSIBILITY_TEXT = 'Barrierefreiheit vor Ort:';
export const REQUEST_BTN_TEXT = 'Teilnehmen';
export const SHARE_BTN_TEXT = 'Teilen';
export const REJECTION_BTN_TEXT = 'Teilnahme absagen';
export const REJECTION_CONFIRMATION_TITLE = 'Teilnahme absagen?';
export const EVENT_REJECTION_CONFIRMATION_TITLE =
  'Veranstaltung nicht mehr merken?';
export const REJECTION_CONFIRMATION_MESSAGE =
  'Möchten Sie Ihre Teilnahme wirklich absagen?';
export const EVENT_REJECTION_CONFIRMATION_MESSAGE =
  'Wollen Sie die Veranstaltung wirklich aus der Merkliste entfernen?';
export const REJECTION_SUCCESS_MESSAGE = 'Ihre Teilnahme wurde abgesagt';
export const EVENT_REJECTION_SUCCESS_MESSAGE =
  'Die Veranstaltung wird aus Ihrem Kalender entfernt';
export const PARTICIPATION_CONFIRMATION_TITLE = 'Teilnahme ?';
export const REQUEST_SUCCESS_DIALOG_TITLE = 'Anfrage verschickt';
export const REQUEST_SUCCESS_DIALOG_MESSAGE =
  'Ihre Anfrage wurde erfolgreich an den Veranstalter geschickt';
export const CONTACT_DIALOG_MSG_PARTICIPANTS_LABEL =
  'Geben Sie in das untenstehende Textfeld Ihre Frage oder Anmerkung an den Veranstalter ein:';
export const CONTACT_DIALOG_EMAIL_OR_PHONE_LABEL =
  'Geben Sie eine Telefonnummer oder eine E-Mail-Adresse an, unter der Sie kontaktiert werden können';
export const REQUEST_SUCCESS_DIALOG_NOTICE =
  'Sie erhalten eine Email sobald es Neuigkeiten gibt ...';
export const REQUEST_SUCCESS_DIALOG_BTN_TEXT = 'Alles klar';
export const CONTACT_ORGANIZER_ACTION_TEXT = 'senden';
export const PARTICIPATION_REQUEST_DIALOG_TITLE =
  'Offene Anfrage für Ihren Nachbarschaftstisch';
export const ADDRESS_MISSING_HEADING = 'Profil unvollständig';
export const NACHBARSCHAFTSTISCH_ADDRESS_MISSING_TEXT =
  'Um einen Nachbarschaftstisch anzubieten, müssen Sie Ihr Profil vervollständigen';
export const NAVIGATE_PROFILE_BTN_TEXT = 'Zum Profil';
export const REJECT_BTN_TEXT = 'Jetzt nicht';
export const CREATE_TABLE_NOT_AUTHENTICATED =
  'Sie müssen sich anmelden um einen Nachbarschaftstisch anbieten zu können';
export const TITLE_TEXT_INFO = 'darf nicht leer sein';
export const DESC_TEXT_INFO = 'muss mind. 10 Zeichen lang sein';
export const SHOW_MORE = 'Mehr erfahren';
export const LOGOUT_TEXT = 'Abmelden';
export const PARTICIPANT_BADGE_TEXT = 'Sie nehmen teil!';
export const ORGANIZER_BADGE_TEXT = 'Sie sind der Veranstalter!';
export const NO_PARTICIPANT_TEXT = 'keine Teilnehmer';
export const NACHBARSCHAFTSTISCH_NO_REGISTRATION =
  'Sie sind bei keinem Nachbarschaftstisch angemeldet';
export const NACHBARSCHAFTSTISCH_NO_PIN_HEADING = 'Hinweis zur Anmeldung';
export const NACHBARSCHAFTSTISCH_NO_PIN_TEXT =
  'Sobald Ihre Teilnahme für eine Veranstaltung bestätigt wurde, erscheint eine Pin-Nadel am Veranstaltungsort. Wenn Sie auf die Pin-Nadel klicken, erhalten Sie weitere Informationen und die genaue Adresse der Veranstaltung.';
export const CANCEL_TISCH_BTN_TEXT = 'Absagen';
export const CANCEL_SUCCESS_CLOSE_BTN_TXT = 'Okay';
export const NACHBARSCHAFTSTISCHE_TIME_WRONG =
  'Die Startzeit des Nachbarschaftstisches darf nicht später als die Endzeit sein. Bitte überprüfen Sie Ihre Eingaben!';
export const NACHBARSCHAFTSTISCHE_NEARBY_OFFERS_EMPTY_PLACEHOLDER =
  'Unter „Angebote in der Nähe“ sehen Sie eine Übersicht über alle Nachbarschaftstische, die zurzeit in Ihrem Suchradius angeboten werden. Wenn Sie keine Angebote angezeigt bekommen, dann werden momentan keine Nachbarschaftstische in den von Ihnen ausgewählten Orten angeboten.';
export const REPORT_BTN_TXT = 'Melden';
export const EVENT_TEXT = 'Veranstaltung';
export const NEIGBOURHOODTABLE_TEXT = 'Nachbarschaftstisch';
export const PINNWAND_TEXT = 'Eintrag';

// My Area Strings
export const MY_AREA_NOT_AUTHENTICATED =
  'Sie müssen sich anmelden um auf Ihren Bereich zugreifen zu können';
export const CONTACT_ORGANIZER_BTN_TEXT = 'Kontaktieren';
export const CONTACT_PARTIICPATNS_BTN_TEXT = 'Teilnehmerliste';
export const MY_AREA_MY_PROFILE = 'Mein Profil';
export const MY_AREA_MY_REGISTRATIONS = 'Meine Anmeldungen';
export const MY_AREA_MY_LOCATION = 'Meinen Suchradius festlegen';
export const MY_AREA_CREATE_NEIGHBOURHOOD_TABLE =
  'Nachbarschaftstisch anbieten';
export const MY_AREA_MY_OLD_TABLES = 'Meine Nachbarschaftstische (archiv)';
export const MY_AREA_CREATE_EVENT = 'Veranstaltung anbieten';
export const MY_AREA_MY_OLD_EVENTS = 'Meine Veranstaltungen (archiv)';
export const MY_AREA_CREATE_NOTICE = 'Pinnwand beitrag erstellen';
export const MY_AREA_DIRECTUS = 'Beitrag erstellen (CMS)';

// Pending Dialog strings
export const PENDING_DIALOG_TITLE_TEXT = 'Offene Anfrage';

// Confirmation Dialog Strings
export const CONFIRM_DIALOG_TITLE_TEXT = 'Alle Angaben korrekt?';
export const CONFIRM_DIALOG_MSG_TEXT =
  'Sind Sie sicher, alle Angaben korrekt eingegeben zu haben?';
export const CONFIRM_BTN_TEXT = 'Ja, Sicher';
export const CONFIRM_BTN_TEXT_SHORT = 'Ja';
export const BACK_BTN_TEXT = 'Zurück';
export const SEND_BTN_TEXT = 'Senden';

// Delete Confirmation Dialog Strings
export const DELETE_CONFIRM_BTN_TEXT = 'Ja, löschen';
export const CANCEL_BTN_TEXT = 'Abbrechen';

// Success Dialog Strings
export const SUCCESS_DIALOG_BTN_TEXT = 'Alles klar';

// Error Dialog String
export const ERROR_DIALOG_TITLE = 'Fehler';
export const ERROR_DIALOG_BTN_TEXT = 'Alles klar';
export const ERROR_DIALOG_MSG =
  'Das hat leider nicht geklappt. Bitte überprüfen Sie Ihre Eingaben';

// Settings Strings
export const HEADER_TITLE_SETTINGS = 'Einstellungen';
export const PROFILE_SETTINGS = 'Mein Profil bearbeiten';
export const NEARBY_ME_SETTINGS = 'Meinen Suchradius festlegen';
export const FONT_SIZE_SETTINGS = 'Schriftgröße';
export const ACCOUNT_DELETION_TEXT = 'Konto löschen';
export const ACCOUNT_DELETION_CONFIRM_TEXT =
  'Sind Sie sicher, dass Sie Ihr Konto löschen möchten?';
export const ACCOUNT_DELETION_REASON_TEXT =
  'Wieso möchten Sie Ihr Konto löschen?';
export const ACCOUNT_DELETION_SUCCESS_TEXT =
  'Ihr Antrag zur Löschung ihres Kontos wurde gesendet.';
export const TBD_SETTINGS = 'Tbd';

// Profile Strings
export const HEADER_TITLE_PROFILE = 'Profil';
export const FIRST_NAME = 'Vorname';
export const LAST_NAME = 'Nachname';
export const DISPLAY_NAME = 'Öffentlicher Name';
export const EMAIL = 'Email';
export const NOT_VISIBLE = 'nicht sichtbar';
export const SETTINGS_CHOOSE_LOCATION = 'Ort auswählen';
export const CITY_NOT_VISIBLE_INFO =
  'nicht sichtbar; diese Information hilft bei Veranstaltungsvorschlägen';
export const MANDATOY_FIELDS = 'Pflichtfelder';
export const STREET = 'Straße';
export const ZIPCODE = 'PLZ';
export const CITY = 'Ort';
export const GEOAREA_NOT_FOUND = 'Nichts gefunden';
export const VISIBLE_PARTICIPANTS_ONLY =
  'sichtbar nur für Teilnehmer derselben Veranstaltung';
export const TELEPHONE = 'Telefon';
export const ABOUT_ME = 'Über mich';
export const ABOUT_ME_INFO = 'diese Information ist für alle Benutzer sichtbar';
export const SAVE = 'Speichern';
export const PROFILE_NOT_AUTHENTICATED =
  'Sie müssen angemeldet sein um auf Ihr Profil zugreifen zu können';
export const PROFILE_UPDATE_SUCCESS =
  'Ihr Profil wurde erfolgreich aktualisiert';
export const PROFILE_MISSING_SETTINGS_TITLE = 'Suchradius festlegen';
export const PROFILE_MISSING_SETTINGS_MSG =
  'Ihre Einstellungen wurden gespeichert. Bitte legen Sie jetzt Ihren Suchradius fest.';
export const PROFILE_MISSING_SETTINGS_BTN_ACTION = 'Zum Suchradius';

export const OFFER_TABLE_HEADING_TEXT =
  'Hier können Sie Ihren eigenen Nachbarschaftstisch anbieten';
export const TABLE_NAME_TEXT = 'Wie soll Ihr Tisch heißen?';
export const TABLE_DESC_TEXT = 'Beschreiben Sie Ihren Nachbarschaftstisch';
export const TABLE_DESC_PLACEHOLDER =
  'Geben Sie hier Ihre persönliche Nachricht an die Interessenten ein, um ein erstes Gefühl von Ihrem Nachbarschaftstisch zu vermitteln!';
export const HAPPENING_ACCESSIBILITY_TEXT =
  'Wie ist die Barrierefreiheit vor Ort?';
export const FURTHER_BTN_TEXT = 'Weiter';
export const CONTACT_ALL_TEXT = 'Alle kontaktieren';
export const EDIT_BTN_TEXT = 'Bearbeiten';
export const DELETE_BTN_TEXT = 'Löschen';

// Information Strings
export const HEADER_TITLE_INFORMATION = 'Anlaufstellen, Vorsorge und mehr';
export const CATEGORY_PENDING_POSTS = 'Ausstehende Beiträge';
export const CATEGORY_NEWS = 'Neuigkeiten';
export const CATEGORY_FIT = 'Fit und gesund im Alter';
export const INFO_HOME_EXPLANATION =
  'Im Bereich „Anlaufstellen, Vorsorge und mehr” finden Sie regionalspezifische Informationen und Dienstleistungen zu verschiedenen Themen.';
export const INFO_HOME_HEADING = 'Überblick';
export const SEARCH_EXPLANATION =
  'Hier suchen Sie alles rund um die Rubrik „Anlaufstellen, Vorsorge und mehr”';
export const SEARCH_HEADING = 'Suche';

// Veranstaltungen Strings
export const HEADER_TITLE_VERANSTALTUNGEN = 'Veranstaltungen';
export const VERANSTALTUNGEN_TAB_1_TEXT = 'Veranstaltungen in der Nähe';
export const VERANSTALTUNGEN_TAB_2_TEXT = 'Meine gemerkten Veranstaltungen';
export const VERANSTALTUNGEN_TAB_3_TEXT = 'Meine Veranstaltungen';
export const VERANSTALTUNGEN_TAB_4_TEXT = 'Veranstaltungen anbieten';
export const VERANSTALTUNGEN_TAB_5_TEXT = 'Meine Veranstaltungen (archiv)';
export const VERANSTALTUNGEN_OFFER_BTN_TEXT = 'Veranstaltung anbieten';
export const CREATE_EVENT_NOT_AUTHENTICATED_TEXT =
  'Sie müssen sich anmelden um eine Veranstaltung anlegen zu können';
export const OFFER_EVENT_HEADING_TEXT =
  'Hier können Sie eine Veranstaltung erstellen';
export const EVENT_NAME_TEXT = 'Wie soll Ihre Veranstaltung heißen?';
export const EVENT_DESC_TEXT = 'Beschreiben Sie Ihre Veranstaltung';
export const EVENT_DESC_PLACEHOLDER =
  'Geben Sie hier Ihre persönliche Nachricht an die Interessenten ein, um ein erstes Gefühl von Ihrer Veranstaltung zu vermitteln!';
export const VERANSTALTUNGEN_TYPE_TEXT = 'Kategorie der Veranstaltung';
export const VERANSTALTUNGEN_ORGANISER_NAME = 'Wer ist der Veranstalter?';
export const VERANSTALTUNGEN_ORGANISER_NAME_PLACEHOLDER = 'Name oder Verein';
export const VERANSTALTUNGEN_ORGANISER_CONTACT = 'Kontakt vom Veranstalter';
export const VERANSTALTUNGEN_ORGANISER_CONTACT_PLACEHOLDER =
  'E-Mail Adresse oder Telefonnummer';
export const HAPPENING_TYPE_TEXT = 'Wie findet die Veranstaltung statt?';
export const VERANSTALTUNGEN_ADDRESS_MISSING_TEXT =
  'Um eine Veranstaltung anzubieten, müssen Sie Ihr Profil vervollständigen';
export const VERANSTALTUNGEN_TIME_WRONG =
  'Die Startzeit der Veranstaltung darf nicht später als die Endzeit sein. Bitte überprüfen Sie Ihre Eingaben!';
export const ADD_URL = 'Link einfügen ...';
export const VERANSTALTUNG_TYPE = 'Art der Veranstaltung';
export const JOIN_URL = 'Link zur Teilnahme';
export const VERANSTALTUNGEN_JOIN_TEXT = 'Veranstaltung merken';
export const VERANSTALTUNGEN_JOIN_SUCCESS_TITLE = 'Veranstaltung gemerkt!';
export const VERANSTALTUNGEN_JOIN_SUCCESS_MESSAGE =
  'Die Veranstaltung wurde erfolgreich zu Ihrem Kalender hinzugefügt.';
export const VERANSTALTUNGEN_NO_PIN_HEADING = 'Hinweis zur Anmeldung';
export const VERANSTALTUNGEN_NO_PIN_TEXT =
  'Sobald Sie sich eine Veranstaltung gemerket haben, erscheint eine Pin-Nadel am Veranstaltungsort. Wenn Sie auf die Pin-Nadel klicken, erhalten Sie weitere Informationen und die genaue Adresse der Veranstaltung.';
export const VERANSTALTUNGEN_DELETE_CONFIRMATION =
  'Möchten Sie diese Veranstaltung wirklich löschen?';
export const CONTACT_INTERESTED_BTN_TEXT = 'Interessenten';
export const VERANSTALTUNGEN_LEAVE_TEXT = 'Aus Kalender entfernen';
export const VERANSTALTUNGEN_NEARBY_OFFERS_EMPTY_PLACEHOLDER =
  'Unter „Veranstaltungen in der Nähe“ sehen Sie eine Übersicht über alle Veranstaltungen, die zurzeit in Ihrem Suchradius angeboten werden.';
export const INTERESTED_BADGE_TEXT = 'Sie sind interessiert!';
export const REGISTRATION_HINT_LABEL = 'Hinweise zur Anmeldung';
export const REGISTRATION_HINT_PLACEHOLDER = 'Kontaktdaten für die Anmeldung';
export const VERANSTALTUNGEN_TERMINE_PUBLISH_BTN_TEXT =
  'Im Termine Regional veröffentlichen';
export const TERMINE_PUBLISH_CONFIRMATION_TEXT =
  'Möchten Sie diese Veranstaltung wirklich veröffentlichen ?';
export const TERMINE_PUBLISH_SUCCESS_TEXT = 'Erfolgreich veröffentlicht';

// ERROR MESSAGES
export const CREATE_UPDATE_HAPPENING_ADDRESS_ERROR =
  'Bitte prüfen Sie die Adresse';
export const DEFAULT_ERROR =
  'Hoppla, etwas ist schief gelaufen. Kontaktieren Sie bitte den Support';
export const MAX_PARTICIPANT_REACHED_ERROR =
  'Maximale Anzahl an Teilnehmern erreicht';

// News Strings
export const HEADER_NEWS = 'Aktuelles';
export const HEADER_TITLE_NEWS = 'Neuigkeiten';
export const NEWS_DESC_TEXT =
  'Im Bereich „Neuigkeiten“ finden Sie aktuelle Informationen und Hinweise zu seniorenspezifischen Themen und Veranstaltungen in Ihrer Region';

// Fit Health Strings
export const HEADER_TITLE_FITHEALTH = 'Fit & gesund im Alter';
export const NO_SUBCATEGORY_FITHEALTH = 'Allgemein';

// NearbyCalendar Strings
export const CALENDAR_TODAYS_DATE = 'Heutiges Datum:';
export const CALENDAR_NACHBARSCHAFTSTISCH = 'Nachbarschaftstisch';
export const CALENDAR_VERANSTALTUNG = 'Veranstaltung';

// Footer Strings
export const LINK_PROFILE = 'Mein Bereich';
export const LINK_HELP = 'Hilfe';
export const LINK_HOME = 'Startseite';
export const LINK_IMPRINT = 'Impressum';
export const LINK_SETTING = 'Einstellungen';

// Upload Image Strings
export const UPLOAD_IMAGE_DIALOG_TITLE = 'Bild hochladen';
export const UPLOAD_IMAGE_DIALOG_BTN_UPLOAD = 'Bild hochladen';
export const UPLOAD_IMAGE_DIALOG_BTN_DELETE = 'Bild löschen';
export const UPLOAD_IMAGE_DIALOG_BTN_FINISHED = 'Fertig';
export const UPLOAD_IMAGE_DIALOG_BTN_CLOSE = 'Abbrechen';

// Update Image Strings
export const UPDATE_IMAGE_DIALOG_TITLE = 'Bild aktualisieren';

// Delete Image Strings
export const DELETE_IMAGE_DIALOG_TITLE = 'Möchten Sie das Bild löschen?';

// Alert String
export const ALERT_TITLE = 'Info';
export const ALERT_TEXT =
  'Bitte beachten Sie, dass der folgende Link eine Webseite öffnet, auf der andere Datenschutzbestimmung gelten.';
export const ALERT_BTN_CONFIRM = 'Ok';
export const ALERT_BTN_BACK = 'Zurück';

// NearbyCalendar Strings
export const HEADER_TITLE_KALENDER = 'Kalender';
export const NAVBAR_MEIN_KALENDER = 'Mein Kalender';

// MeineNahe Strings
export const MEINENAHE_DESCRIPTION =
  'Bitte wählen Sie alle Orte aus, für die Sie Nachbarschaftstische und Veranstaltungen angezeigt bekommen möchten. Wenn Sie Ihre Auswahl beendet haben, scrollen Sie nach unten und klicken auf „Speichern“. Sie können Ihre Auswahl jederzeit neu anpassen.';
export const PROFILE_AREA_UPDATE_SUCCESS = 'Änderungen gespeichert';

// Notice Strings
export const NOTICE_TAB_1_TEXT = 'Übersicht';
export const NOTICE_TAB_2_TEXT = 'Angebote';
export const NOTICE_TAB_3_TEXT = 'Gesuche';
export const NOTICE_TAB_4_TEXT = 'Suche';
export const NOTICE_TAB_5_TEXT = 'Erstellen';
export const NOTICE_TAB_6_TEXT = 'Meine Beiträge';
export const OFFER_OPTION_TEXT = 'Biete';
export const SEEK_OPTION_TEXT = 'Suche';
export const OFFER_TEXT = 'Angebot';
export const SEEKING_TEXT = 'Gesuch';
export const CREATE_NOTICE_NOT_AUTHENTICATED =
  'Sie müssen sich anmelden um einen Beitrag auf der Pinnwand erstellen zu können';
export const AUTHENTICATE_TO_COMMENT =
  'Sie müssen sich anmelden um kommentieren zu können';
export const CREATE_NOTICE_DESC_TEXT =
  'Hier können Sie ein eigenes Angebot oder Gesuch erstellen und auf der Pinnwand veröffentlichen. Machen Sie auf Ihr Angebot oder Gesuch aufmerksam, indem Sie ein passendes Bild hochladen und einen informativen Text dazu verfassen.';
export const NEARBY_NOTICE_HEADING_TEXT =
  'Erklärungstext oberhalb der aufgelisteten Einträge:';
export const NEARBY_NOTICE_DESC_TEXT =
  'Auf der Pinnwand können Sie kostenlos ehrenamtlichen Angebote/Gesuche einstellen oder suchen, z.B. um Gleichgesinnte für gemeinsame Interessen oder Vorhaben zu finden. Die Übersicht zeigt Ihnen alle Angebote und Gesuche sortiert nach Orten.';
export const NOTICE_TYPE_LABEL = 'Angebot oder Gesuch?';
export const NOTICE_CATEGORY_LABEL = 'In welchem Bereich?';
export const CREATE_NOTICE_TITLE_LABEL = 'Wie soll Ihr Angebot/Gesuch heißen?';
export const CREATE_NOTICE_TITLE_PLACEHOLDER =
  'Schreiben Sie hier den Titel zu Ihrem Beitrag!';
export const CREATE_NOTICE_TEXT_LABEL = 'Beschreiben Sie Ihren Beitrag';
export const CREATE_NOTICE_TEXT_PLACEHOLDER =
  'Schreiben Sie hier mehr Informationen zu Ihrem Beitrag!';
export const CREATE_NOTICE_BTN_TEXT = 'Erstellen';
export const UPDATE_NOTICE_BTN_TEXT = 'Aktualisieren';
export const SEARCH_NOTICE_HEADING_TEXT =
  'Nutzen Sie die Suchfunktion, um das für Sie passende Angebot oder Gesuch zu finden. Dafür können Sie aus verschiedenen Bereichen auswählen und sich alle Angebote oder Gesuche der ausgewählten Rubrik anzeigen lassen.';
export const SEARCH_NOTICE_BTN_TEXT = 'Suchen';
export const COMMENT_INPUT_PLACEHOLDER = 'Stellen Sie eine Frage...';
export const NO_COMMENTS_TEXT = 'Keine öffentliche Frage';
export const COMMENTS_TEXT = 'Öffentliche Fragen';
export const COMMENT_BTN_TEXT = 'Pinnen';
export const OPEN_COMMENTS_BTN_TEXT = 'Frage stellen';
export const NO_SEARCH_RESULTS = 'Kein passender Beitrag gefunden';
export const NOTICE_EDIT_BTN_TEXT = 'Bearbeiten';
export const NOTICE_DELETE_BTN_TEXT = 'Löschen';
export const NOTICE_DELETE_CONFIRMATION =
  'Möchten Sie diese Pinnwand wirklich löschen?';

// Share Strings
export const SHARE_DIALOG_TITLE_MESSAGE = 'Teile diesen Nachbarschaftstisch';
