import { Box, Card, CardContent, CardMedia } from '@mui/material';
import { CSSProperties, FC } from 'react';
import type { To } from 'react-router';
import ShowMoreTag from './ShowMoreTag';
import SimpleLink from './SimpleLink';

const useStyles = (): Record<string, CSSProperties> => ({
  xsCardStyle: {},
  cardStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative'
  },
  cardContentStyle: {
    flex: '1 1 80%',
    maxHeight: '10rem'
  },
  xsCardMediaContainerStyle: {},
  cardMediaContainerStyle: { position: 'relative', flex: '1 1 15%' },
  xsCardMediaStyle: { height: '10rem' },
  cardMediaStyle: {
    objectFit: 'contain',
    flex: '0 0 10rem',
    maxWidth: '12rem',
    minHeight: '10rem',
    maxHeight: '10rem'
  }
});

type OverviewCardProps = {
  image?: string;
  defaultImage: string;
  detailsLink: To;
  showOverviewTag?: boolean;
  tagTitle?: string;
  isLargeScreen?: boolean;
};

const OverviewCard: FC<OverviewCardProps> = ({
  children,
  image,
  defaultImage,
  detailsLink,
  isLargeScreen
}) => {
  const styles = useStyles();
  return (
    <SimpleLink to={detailsLink}>
      <Card
        sx={isLargeScreen ? styles.cardStyle : styles.xsCardStyle}
        variant="outlined"
      >
        {isLargeScreen && (
          <CardContent sx={styles.cardContentStyle}>{children}</CardContent>
        )}
        <Box
          sx={
            isLargeScreen
              ? styles.cardMediaContainerStyle
              : styles.xsCardMediaContainerStyle
          }
        >
          <CardMedia
            component="img"
            aria-hidden="true"
            sx={isLargeScreen ? styles.cardMediaStyle : styles.xsCardMediaStyle}
            src={image ?? defaultImage}
          />
          <ShowMoreTag />
        </Box>
        {!isLargeScreen && (
          <CardContent sx={styles.cardContentStyle}>{children}</CardContent>
        )}
      </Card>
    </SimpleLink>
  );
};

export default OverviewCard;
