import { FC } from 'react';
import { useOutletContext } from 'react-router-dom';
import PostDetail from '../../../components/PostDetail';
import { TFitHealthOutletContext } from '../FitHealthView';

const FitHealthPostView: FC = () => {
  const { posts } = useOutletContext<TFitHealthOutletContext>();

  return <PostDetail posts={posts} />;
};

export default FitHealthPostView;
