import { FC, Fragment, useMemo } from 'react';
import {
  useOutletContext,
  useParams,
  Link as ReactRouterLink
} from 'react-router-dom';
import { Stack, Typography, Breadcrumbs, Link } from '@mui/material';
import PostCardView from './PostCardView';
import { TPostOutletContext } from './PostView';
import { getThumbnailUrl } from '../../api/InformationAPI';
import { findById } from '../../utils/common';
import { HOME_TILE_INFOMATION } from '../../constants/strings';

type TParams = {
  categoryId: string;
  subCategoryId: string;
};

const PostListView: FC = () => {
  const { posts, categories, subCategories } =
    useOutletContext<TPostOutletContext>();
  const { categoryId, subCategoryId } = useParams<TParams>();

  const currentPosts = useMemo(() => {
    if (subCategoryId) {
      return posts.filter((p) => p.subcategory === subCategoryId);
    }
    return posts.filter(
      (p) => p.category === categoryId && p.subcategory === null
    );
  }, [categoryId, posts, subCategoryId]);

  const selectedCategory = useMemo(() => {
    if (!categoryId) {
      return;
    }
    return findById(categories, categoryId);
  }, [categories, categoryId]);

  const selectedSubCategory = useMemo(() => {
    if (!subCategoryId) {
      return;
    }
    return subCategories.find(
      (subCategoriesforReturn) => subCategoriesforReturn.id == subCategoryId
    );
  }, [subCategories, subCategoryId]);

  const selectedCategoryURL =
    '/information' + '/category/' + selectedCategory?.id;

  return (
    <Stack spacing={1}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          color="text.primary"
          variant="h3"
          to="/information"
          component={ReactRouterLink}
        >
          {HOME_TILE_INFOMATION}
        </Link>
        <Link
          underline="hover"
          color="text.primary"
          variant="h3"
          to={selectedCategoryURL}
          component={ReactRouterLink}
        >
          {selectedCategory?.name}
        </Link>
        {selectedSubCategory && (
          <Typography color="text.primary" variant="h3">
            {selectedSubCategory?.name}
          </Typography>
        )}
      </Breadcrumbs>
      {currentPosts.length === 0 ? (
        <Typography align="center">Keine Beiträge vorhanden</Typography>
      ) : (
        <Fragment>
          {currentPosts.map((p) => {
            const { id, title, abstract, text, dateCreated, featuredImage } = p;

            return (
              <PostCardView
                key={id}
                id={id}
                image={getThumbnailUrl(featuredImage)}
                title={title}
                abstract={abstract}
                text={text}
                date={dateCreated}
              />
            );
          })}
        </Fragment>
      )}
    </Stack>
  );
};
export default PostListView;
