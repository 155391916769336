import { copyDate } from '../../../utils/date';
import {
  TAction,
  TSetCost,
  TSetDate,
  TSetDescription,
  TSetEditableProperties,
  TSetEndTime,
  TSetDeleteImage,
  TSetMaxParticipants,
  TSetStartTime,
  TSetTitle
} from './actions';
import { initialState, TState } from './state';
import { EPresentType } from './types';

const setTitleReducer = (state: TState, action: TSetTitle): TState => {
  const { title } = action;
  const newState = {
    ...state,
    title
  };
  return newState;
};

const setDescriptionReducer = (
  state: TState,
  action: TSetDescription
): TState => {
  const { description } = action;
  const newState = {
    ...state,
    description
  };
  return newState;
};

const setDateReducer = (state: TState, action: TSetDate): TState => {
  const { startTime, endTime } = state;
  const { date } = action;

  const newStartTime = copyDate(startTime, date);
  const newEndTime = copyDate(endTime, date);

  return {
    ...state,
    date,
    startTime: newStartTime,
    endTime: newEndTime
  };
};

const setStartTimeReducer = (state: TState, action: TSetStartTime): TState => {
  const { date } = state;
  const { startTime } = action;

  const newStartTime = copyDate(startTime, date);

  const newState = {
    ...state,
    startTime: newStartTime
  };
  return newState;
};

const setEndTimeReducer = (state: TState, action: TSetEndTime): TState => {
  const { date } = state;
  const { endTime } = action;

  const newEndTime = copyDate(endTime, date);

  const newState = {
    ...state,
    endTime: newEndTime
  };
  return newState;
};

const setMaxParticipantsReducer = (
  state: TState,
  action: TSetMaxParticipants
): TState => {
  const { maxParticipants } = action;
  const newState = {
    ...state,
    maxParticipants: parseInt(maxParticipants.toString())
  };
  return newState;
};

const setCostReducer = (state: TState, action: TSetCost): TState => {
  const { cost } = action;
  const newState = {
    ...state,
    cost
  };
  return newState;
};

const setDeleteImageReducer = (
  state: TState,
  action: TSetDeleteImage
): TState => {
  const { deleteImage } = action;
  const newState = {
    ...state,
    deleteImage
  };
  return newState;
};

const setEditablePropertiesReducer = (
  state: TState,
  action: TSetEditableProperties
): TState => {
  const { editableProperties } = action;
  const {
    title,
    description,
    cost,
    image = null,
    maxParticipants,
    startTime,
    endTime,
    accessibility,
    location,
    organizerName,
    organizerContact,
    registrationNotice
  } = editableProperties;

  const newState = {
    ...state,
    title,
    description,
    cost,
    image,
    maxParticipants,
    date: new Date(startTime),
    startTime: new Date(startTime),
    endTime: new Date(endTime),
    accessible: {
      treppe: accessibility?.includes('treppe') ?? false,
      fahrstuhl: accessibility?.includes('fahrstuhl') ?? false,
      treppenlift: accessibility?.includes('treppenlift') ?? false,
      parkplätze: accessibility?.includes('parkplätze') ?? false,
      bushaltestelle: accessibility?.includes('bushaltestelle') ?? false
    },
    address: {
      street: location?.address?.split(',')[0].trim() ?? '',
      zipCode: location?.address?.split(',')[1].trim() ?? '',
      city: location?.address?.split(',')[2].trim() ?? '',
      fullAdress: location?.address ?? ''
    },
    organiser: {
      name: organizerName ?? '',
      contact: organizerContact ?? ''
    },
    registrationHint: registrationNotice ?? ''
  };
  return newState;
};

export const reducer = (state: TState, action: TAction): TState => {
  const name = action.name;
  switch (name) {
    case 'setTitle':
      return setTitleReducer(state, action);
    case 'setOrganiserName':
      return {
        ...state,
        organiser: {
          ...state.organiser,
          name: action.organiserName
        }
      };
    case 'setOrganiserContact':
      return {
        ...state,
        organiser: {
          ...state.organiser,
          contact: action.organiserContact
        }
      };
    case 'setDescription':
      return setDescriptionReducer(state, action);
    case 'setRegistrationHint':
      return {
        ...state,
        registrationHint: action.registrationHint
      };
    case 'setAddress':
      const { street, city, zipCode } = action;
      return {
        ...state,
        address: {
          street,
          zipCode,
          city,
          fullAdress: `${street}, ${zipCode} ${city}`
        }
      };
    case 'setAccessibleValue':
      return {
        ...state,
        accessible: {
          ...state.accessible,
          [action.key]: action.value
        }
      };
    case 'setDate':
      return setDateReducer(state, action);
    case 'setStartTime':
      return setStartTimeReducer(state, action);
    case 'setEndTime':
      return setEndTimeReducer(state, action);
    case 'setMaxParticipants':
      return setMaxParticipantsReducer(state, action);
    case 'setCost':
      return setCostReducer(state, action);
    case 'setDeleteImage':
      return setDeleteImageReducer(state, action);
    case 'setImageId':
      return {
        ...state,
        imageId: action.imageId
      };
    case 'setImage':
      return {
        ...state,
        image: action.image
      };
    case 'setPresentType':
      return {
        ...state,
        presentType: action.presentType,
        url: action.presentType === EPresentType.ON_SITE ? null : state.url
      };
    case 'setUrl':
      return {
        ...state,
        url: action.url
      };
    case 'setEditableProperties':
      return setEditablePropertiesReducer(state, action);
    case 'reset':
      return {
        ...initialState
      };
    default:
      throw new Error('Unknown action');
  }
};
