export const APP_LOCALE = 'de-DE';

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL as string;

export const DIRECTUS_URL = process.env.REACT_APP_DIRECTUS_URL as string;

export const LOGOUT_URL = process.env.REACT_APP_LOGOUT_URL as string;

export const POST_LOGIN_REDIRECT_URL = process.env
  .REACT_APP_POST_LOGIN_REDIRECT_URL as string;

export const NODE_ENV = process.env.NODE_ENV as string;

export const KEYCLOAK_BASE_URL = process.env
  .REACT_APP_KEYCLOAK_BASE_URL as string;

export const KEYCLOAK_REALM = process.env.REACT_APP_KEYCLOAK_REALM as string;

export const KEYCLOAK_CLIENT_ID = process.env
  .REACT_APP_KEYCLOAK_CLIENT_ID as string;

export const APP_VARIANT_IDENTIFIER = process.env
  .REACT_APP_PORTAL_VARIANT_IDENTIFIER as string;

export const TERMINE_REGIONAL_INTEGRATION =
  (process.env.REACT_APP_TERMINE_REGIONAL_INTEGRATION ?? '').toLowerCase() ===
  'true';

export const APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL as string;

export const APP_ROOT_AREA_ID = process.env
  .REACT_APP_PORTAL_VARIANT_ROOT_AREA_ID as string;
