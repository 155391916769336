"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientCommentCreateRequestToJSON = exports.ClientCommentCreateRequestFromJSONTyped = exports.ClientCommentCreateRequestFromJSON = void 0;
function ClientCommentCreateRequestFromJSON(json) {
    return ClientCommentCreateRequestFromJSONTyped(json, false);
}
exports.ClientCommentCreateRequestFromJSON = ClientCommentCreateRequestFromJSON;
function ClientCommentCreateRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'postId': json['postId'],
        'text': json['text'],
    };
}
exports.ClientCommentCreateRequestFromJSONTyped = ClientCommentCreateRequestFromJSONTyped;
function ClientCommentCreateRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'postId': value.postId,
        'text': value.text,
    };
}
exports.ClientCommentCreateRequestToJSON = ClientCommentCreateRequestToJSON;
