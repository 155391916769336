"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * REST API Documentation for digital-vital-backend
 * This is the digital.vital platform
 *
 * The version of the OpenAPI document: unknown
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SortToJSON = exports.SortFromJSONTyped = exports.SortFromJSON = void 0;
const runtime_1 = require("../runtime");
function SortFromJSON(json) {
    return SortFromJSONTyped(json, false);
}
exports.SortFromJSON = SortFromJSON;
function SortFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'empty': !runtime_1.exists(json, 'empty') ? undefined : json['empty'],
        'sorted': !runtime_1.exists(json, 'sorted') ? undefined : json['sorted'],
        'unsorted': !runtime_1.exists(json, 'unsorted') ? undefined : json['unsorted'],
    };
}
exports.SortFromJSONTyped = SortFromJSONTyped;
function SortToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'empty': value.empty,
        'sorted': value.sorted,
        'unsorted': value.unsorted,
    };
}
exports.SortToJSON = SortToJSON;
