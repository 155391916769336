import styled from '@emotion/styled';
import { FC } from 'react';
import LoadingSVG from '../assets/svgs/loader.svg';

// Styles to center the loading Image
const StyledImg = styled.img`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100px;
  z-index: 9999;
`;

// Component to display loading image
const Loader: FC = () => {
  return <StyledImg src={LoadingSVG} alt="Loading" />;
};

export default Loader;
