import CampaignIcon from '@mui/icons-material/Campaign';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import EventSharpIcon from '@mui/icons-material/EventSharp';
import FoodBankSharpIcon from '@mui/icons-material/FoodBankSharp';
import FormatListBulletedSharpIcon from '@mui/icons-material/FormatListBulletedSharp';
import GroupsIcon from '@mui/icons-material/Groups';
import HikingIcon from '@mui/icons-material/Hiking';
import {
  Box,
  Card,
  Container,
  DialogContentText,
  Grid,
  Typography
} from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import ComingSoon from '../../components/ComingSoon';
import AbstractDialog from '../../components/dialogs/AbstractDialog';
import LogoPromotion from '../../components/LogoPromotion';

import {
  HOME_PROFILE_INCOMPLETE_DIALOG_ACTION_BUTTON,
  HOME_PROFILE_INCOMPLETE_DIALOG_CLOSE_BUTTON,
  HOME_PROFILE_INCOMPLETE_DIALOG_TEXT,
  HOME_PROFILE_INCOMPLETE_DIALOG_TITLE,
  HOME_TILE_CALENDER,
  HOME_TILE_FIT,
  HOME_TILE_INFOMATION,
  HOME_TILE_NACHBARSCHAFTSTISCHE,
  HOME_TILE_NEUIGKEITEN,
  HOME_TILE_PINNWAND,
  HOME_TILE_VERANSTALTUNGEN
} from '../../constants/strings';
import useCurrentUser from '../../hooks/useCurrentUser';
import KeycloakService from '../../services/KeycloakService';
import {
  CALENDAR_PRIMARY,
  FITHEALTH_PRIMARY,
  INFORMATIONEN_SERVICE_PRIMARY,
  NACHBARSCHAFTSTISCHE_PRIMARY,
  NEUIGKEITEN_PRIMARY,
  PINNWAND_PRIMARY,
  VERANSTALTUNGEN_PRIMARY
} from '../../themes/colors/globalColors';

const primaryCardStyle = {
  position: 'relative',
  p: 1,
  minHeight: '10rem'
} as const;

const IconStyles = {
  color: 'common.white',
  fontSize: '4rem',
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)'
} as const;

const textStyle = {
  position: 'absolute',
  bottom: '8px',
  textTransform: 'uppercase',
  fontWeight: '500'
} as const;

const Home: FC = () => {
  const { currentUser } = useCurrentUser();
  const navigate = useNavigate();
  const [isProfileDialogOpen, setIsProfileDialogOpen] =
    useState<boolean>(false);

  const closeProfileDialog = useCallback(() => {
    KeycloakService.doLogout();
  }, []);

  const navigateToProfilePage = useCallback(() => {
    setIsProfileDialogOpen(false);
    navigate('/my-area/my-profile');
  }, [navigate]);

  useEffect(() => {
    if (currentUser === null) {
      return;
    }
    const { firstName, lastName, displayName, homeGeoArea } = currentUser;
    if (!firstName || !lastName || !displayName || !homeGeoArea) {
      setIsProfileDialogOpen(true);
    }
  }, [currentUser]);

  return (
    <>
      <Container>
        <LogoPromotion />
        <Grid container spacing={1}>
          <Grid item lg={12} xs={12}>
            <Grid container spacing={1}>
              <Grid item sm={6} xs={12}>
                <Link to="/nachbarschaftstische/nearbytisch">
                  <Card
                    sx={{
                      ...primaryCardStyle,
                      background: NACHBARSCHAFTSTISCHE_PRIMARY
                    }}
                  >
                    <FoodBankSharpIcon sx={IconStyles} />
                    <Typography
                      variant="subtitle1"
                      color="white"
                      sx={textStyle}
                    >
                      {HOME_TILE_NACHBARSCHAFTSTISCHE}
                    </Typography>
                  </Card>
                </Link>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Link to="/veranstaltungen/nearbyevents">
                  <Box sx={{ position: 'relative' }}>
                    <Card
                      sx={{
                        ...primaryCardStyle,
                        background: VERANSTALTUNGEN_PRIMARY
                      }}
                    >
                      <GroupsIcon sx={IconStyles} />
                      <Typography
                        variant="subtitle1"
                        color="white"
                        sx={textStyle}
                      >
                        {HOME_TILE_VERANSTALTUNGEN}
                      </Typography>
                      {/* <ComingSoon /> */}
                    </Card>
                  </Box>
                </Link>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Link to="/information">
                  <Box sx={{ position: 'relative' }}>
                    <Card
                      sx={{
                        ...primaryCardStyle,
                        background: INFORMATIONEN_SERVICE_PRIMARY
                      }}
                    >
                      <ContactPhoneIcon sx={IconStyles} />
                      <Typography
                        variant="subtitle1"
                        color="white"
                        sx={textStyle}
                      >
                        {HOME_TILE_INFOMATION}
                      </Typography>
                      {/* <ComingSoon /> */}
                    </Card>
                  </Box>
                </Link>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Link to="/kalender/nearbyevents">
                  <Box sx={{ position: 'relative' }}>
                    <Card
                      sx={{
                        ...primaryCardStyle,
                        background: CALENDAR_PRIMARY
                      }}
                    >
                      <EventSharpIcon sx={IconStyles} />
                      <Typography
                        variant="subtitle1"
                        color="white"
                        sx={textStyle}
                      >
                        {HOME_TILE_CALENDER}
                      </Typography>
                      {/* <ComingSoon /> */}
                    </Card>
                  </Box>
                </Link>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Link to="/aktuelles">
                  <Box sx={{ position: 'relative' }}>
                    <Card
                      sx={{
                        ...primaryCardStyle,
                        background: NEUIGKEITEN_PRIMARY
                      }}
                    >
                      <CampaignIcon sx={IconStyles} />
                      <Typography
                        variant="subtitle1"
                        color="white"
                        sx={textStyle}
                      >
                        {HOME_TILE_NEUIGKEITEN}
                      </Typography>
                      {/* <ComingSoon /> */}
                    </Card>
                  </Box>
                </Link>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Link to="/fit-gesund">
                  <Box sx={{ position: 'relative' }}>
                    <Card
                      sx={{
                        ...primaryCardStyle,
                        background: FITHEALTH_PRIMARY
                      }}
                    >
                      <HikingIcon sx={IconStyles} />
                      <Typography
                        variant="subtitle1"
                        color="white"
                        sx={textStyle}
                      >
                        {HOME_TILE_FIT}
                      </Typography>
                      {/* <ComingSoon /> */}
                    </Card>
                  </Box>
                </Link>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Link to="/pinnwand/nearbynotices">
                  <Box sx={{ position: 'relative' }}>
                    <Card
                      sx={{
                        ...primaryCardStyle,
                        background: PINNWAND_PRIMARY
                      }}
                    >
                      <FormatListBulletedSharpIcon sx={IconStyles} />
                      <Typography
                        variant="subtitle1"
                        color="white"
                        sx={textStyle}
                      >
                        {HOME_TILE_PINNWAND}
                      </Typography>
                      {/* <ComingSoon /> */}
                    </Card>
                  </Box>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <AbstractDialog
        title={HOME_PROFILE_INCOMPLETE_DIALOG_TITLE}
        disableModalCloseActions={true}
        open={isProfileDialogOpen}
        onClose={closeProfileDialog}
        onAction={navigateToProfilePage}
        btnCancelText={HOME_PROFILE_INCOMPLETE_DIALOG_CLOSE_BUTTON}
        btnActionText={HOME_PROFILE_INCOMPLETE_DIALOG_ACTION_BUTTON}
        maxWidth="420px"
      >
        <DialogContentText>
          {HOME_PROFILE_INCOMPLETE_DIALOG_TEXT}
        </DialogContentText>
      </AbstractDialog>
    </>
  );
};
export default Home;
