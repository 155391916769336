import { useEffect, useState } from 'react';
import BackendMediaItemAPI from '../api/MediaItemAPI';
import useAuthContext from './useAuthContext';

const useMediaItemApi = (): BackendMediaItemAPI => {
  const { keycloak } = useAuthContext();

  const [mediaItemApi, setMediaItemApi] = useState<BackendMediaItemAPI>(
    () => new BackendMediaItemAPI(keycloak.token)
  );

  useEffect(() => {
    if (mediaItemApi.accessToken !== keycloak.token) {
      setMediaItemApi(new BackendMediaItemAPI(keycloak.token));
    }
  }, [mediaItemApi.accessToken, keycloak.token]);

  return mediaItemApi;
};

export default useMediaItemApi;
