import { Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Theme } from '@mui/system/createTheme/createTheme';
import { CSSProperties, FC, PropsWithChildren, useMemo } from 'react';
import clip from 'text-clipper';
import Post from '../../assets/svgs/post.svg';
import OverviewCard from '../../components/OverviewCard';
import { isNil } from '../../utils/common';

const useStyles = (theme: Theme): Record<string, CSSProperties> => ({
  textPreviewStyle: {
    overflow: 'hidden',
    maxHeight: '6rem'
  },
  dividerStyle: {
    marginBottom: theme.spacing(1)
  }
});

type PostCardViewProps = {
  id: string;
  title: string;
  abstract: string;
  text: string;
  image?: string;
  date: Date;
};

const PostCardView: FC<PostCardViewProps> = (
  props: PropsWithChildren<PostCardViewProps>
) => {
  const { id, title, image, abstract, text } = props;
  const theme = useTheme();
  const styles = useStyles(theme);
  const smallScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const mediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const largeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const clippedText = useMemo(() => {
    const screenSize = largeScreen ? 280 : 200;
    const maxChars = mediumScreen ? screenSize : 130;
    if (isNil(abstract) || abstract.length < 1) {
      return clip(text, maxChars, {
        html: true,
        maxLines: 2,
        stripTags: ['img', 'svg', 'a', 'br', 'strong']
      });
    }
    return clip(abstract, maxChars, { maxLines: 2 });
  }, [abstract, largeScreen, mediumScreen, text]);

  return (
    <OverviewCard
      image={image}
      defaultImage={Post}
      detailsLink={`/information/post/${id}`}
      isLargeScreen={smallScreen}
    >
      <Typography gutterBottom variant="h3">
        {title}
      </Typography>
      <Divider flexItem={true} sx={styles.dividerStyle} />
      <Typography
        dangerouslySetInnerHTML={{
          __html: clippedText
        }}
        component="div"
        gutterBottom={true}
        style={styles.textPreviewStyle}
        variant="body2"
      />
    </OverviewCard>
  );
};

export default PostCardView;
