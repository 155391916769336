import { Box, Container, ThemeProvider } from '@mui/material';
import {
  FC,
  Reducer,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
  CSSProperties
} from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  fetchCategories,
  fetchPublishedPosts,
  fetchSubCategories
} from '../../api/InformationAPI';
import CategoryNavBar from '../../components/CategoryNavBar';
import Loader from '../../components/Loader';
import { NINE_UUID } from '../../constants/constants';
import informationTheme from '../../themes/informationTheme';
import { TCategory, TPost, TSubCategory } from '../../types/information';
import { findById } from '../../utils/common';
import {
  initialState,
  reducer,
  setCategories,
  setPosts,
  setSelectedCategory,
  setSelectedSubCategory,
  setSubCategories,
  TAction,
  TState
} from './PostReducer';
import { INFORMATIONEN_SERVICE_PRIMARY } from '../../themes/colors/globalColors';
export type TPostParams = {
  categoryId: string;
  subCategoryId: string;
  postId: string;
};

export type TPostOutletContext = {
  categories: Array<TCategory>;
  subCategories: Array<TSubCategory>;
  posts: Array<TPost>;
};

const useStyles = (): Record<string, CSSProperties> => ({
  fullWidth: {
    width: '100%'
  },
  navBarBox: {
    position: 'fixed',
    background: INFORMATIONEN_SERVICE_PRIMARY,
    width: '100%',
    zIndex: 1000
  }
});

const PostView: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const styles = useStyles();
  const navBarEl = useRef<null | HTMLDivElement>(null);
  const { categoryId, subCategoryId, postId } = useParams<TPostParams>();
  const [loading, setLoading] = useState<boolean>(true);
  const [state, dispatch] = useReducer<Reducer<TState, TAction>>(
    reducer,
    initialState
  );
  const {
    selectedCategoryId,
    selectedSubCategoryId,
    currentSubCategories,
    posts,
    categories,
    subCategories
  } = state;
  const [navBarHeight, setNavBarHeight] = useState<number>(72);

  const selectedCategory = useMemo(() => {
    if (!selectedCategoryId) {
      return;
    }
    return findById(categories, selectedCategoryId);
  }, [categories, selectedCategoryId]);

  const loadCategories = useCallback(() => {
    const loadCategoriesAsync = async () => {
      setLoading(true);
      try {
        const fetchedCategories = await fetchCategories();
        dispatch(setCategories(fetchedCategories));
      } finally {
        setLoading(false);
      }
    };
    loadCategoriesAsync();
  }, []);

  const loadSubCategories = useCallback(() => {
    const loadSubCategoriesAsync = async () => {
      setLoading(true);
      try {
        const fetchedSubCategories = await fetchSubCategories();
        dispatch(setSubCategories(fetchedSubCategories));
      } finally {
        setLoading(false);
      }
    };
    loadSubCategoriesAsync();
  }, []);

  const loadPublishedPosts = useCallback(() => {
    const loadAllPostsAsync = async () => {
      setLoading(true);
      try {
        const publishedPosts = await fetchPublishedPosts();
        dispatch(setPosts(publishedPosts));
      } finally {
        setLoading(false);
      }
    };
    loadAllPostsAsync();
  }, []);

  const changeCurrentCategory = useCallback(
    (newCategoryId: string) => {
      if (newCategoryId === NINE_UUID) {
        navigate('/information');
      } else {
        navigate(`category/${newCategoryId}`);
      }
    },
    [navigate]
  );

  const changeCurrentSubCategory = useCallback(
    (newSubCategoryId: string) => {
      const subCategory = findById(subCategories, newSubCategoryId);
      if (!subCategory) {
        return;
      }
      const newCategoryId = subCategory.categoryId;
      navigate(
        `/information/category/${newCategoryId}/sub-category/${newSubCategoryId}`
      );
    },
    [navigate, subCategories]
  );

  useEffect(() => {
    loadCategories();
  }, [loadCategories]);

  useEffect(() => {
    loadSubCategories();
  }, [loadSubCategories]);

  useEffect(() => {
    loadPublishedPosts();
  }, [loadPublishedPosts]);

  useEffect(() => {
    if (!postId) {
      return;
    }
    const currentPost = findById(posts, postId);
    if (!currentPost) {
      return;
    }
    if (selectedCategoryId !== currentPost.category) {
      dispatch(setSelectedCategory(currentPost.category));
    }
  }, [postId, posts, selectedCategoryId]);

  useEffect(() => {
    if (!categoryId) {
      switch (location.pathname) {
        case '/information': {
          if (selectedCategoryId !== NINE_UUID) {
            dispatch(setSelectedCategory(NINE_UUID));
          }
          break;
        }
        case '/information/search': {
          if (selectedCategoryId) {
            dispatch(setSelectedCategory(undefined));
          }
          break;
        }
        default:
          break;
      }
      return;
    }
    if (selectedCategoryId !== categoryId) {
      dispatch(setSelectedCategory(categoryId));
    }
  }, [categoryId, location.pathname, postId, selectedCategoryId]);

  useEffect(() => {
    if (!subCategoryId) {
      return;
    }
    if (selectedSubCategoryId !== subCategoryId) {
      dispatch(setSelectedSubCategory(subCategoryId));
    }
  }, [selectedSubCategoryId, subCategoryId]);

  useEffect(() => {
    if (categories.length === 0 || subCategories.length === 0) {
      return;
    }
    if (navBarEl.current && navBarEl.current.clientHeight !== navBarHeight) {
      setNavBarHeight(navBarEl.current.clientHeight);
    }
  }, [categories, navBarHeight, subCategories]);

  return (
    <ThemeProvider theme={informationTheme}>
      <Box sx={styles.fullWidth}>
        <Box sx={styles.navBarBox} ref={navBarEl}>
          <Container>
            <CategoryNavBar
              selectedCategory={selectedCategory}
              currentSubCategories={currentSubCategories}
              categories={categories}
              subCategories={subCategories}
              onCategoryChange={changeCurrentCategory}
              onSubCategoryChange={changeCurrentSubCategory}
            />
          </Container>
        </Box>
        <Box sx={{ height: `${navBarHeight}px` }} />
        {loading ? (
          <Loader />
        ) : (
          <Container>
            <Box
              sx={{ padding: { sm: '10px', xs: '5px' }, marginTop: '0.5rem' }}
            >
              <Outlet context={{ categories, subCategories, posts }} />
            </Box>
          </Container>
        )}
      </Box>
    </ThemeProvider>
  );
};
export default PostView;
