import { FC, Fragment, useCallback } from 'react';
import { Grid, Link, Typography, Avatar } from '@mui/material';
import { PARTICIPATION_REQUEST_DIALOG_TITLE } from '../../../constants/strings';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import { SETTINGS_PROFILE_PIC } from '../../../themes/colors/globalColors';
type PendingContentViewProps = {
  happeningTitle: string;
  participantDisplayName?: string;
  participantProfilePicture?: string;
  onParticipantClick: () => void;
};

const PendingContentView: FC<PendingContentViewProps> = (
  props: PendingContentViewProps
) => {
  const {
    happeningTitle,
    participantDisplayName,
    participantProfilePicture,
    onParticipantClick
  } = props;

  const handleParticipantClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      event.stopPropagation();
      onParticipantClick();
    },
    [onParticipantClick]
  );

  return (
    <Fragment>
      <Typography align="center" gutterBottom={true}>
        {PARTICIPATION_REQUEST_DIALOG_TITLE}
      </Typography>
      <Typography align="center" gutterBottom={true}>
        <strong>
          <q>{happeningTitle}</q>
        </strong>
      </Typography>
      <Grid
        container
        flexDirection="row"
        spacing={1}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Typography>Teilnehmer:</Typography>
        </Grid>
        <Grid item xs={4}>
          {participantProfilePicture ? (
            <Avatar
              src={participantProfilePicture}
              style={{
                border: `1px solid ${SETTINGS_PROFILE_PIC}`
              }}
            />
          ) : (
            <AccountCircleRoundedIcon sx={{ fontSize: '2.75rem' }} />
          )}
        </Grid>
        <Grid item xs={8}>
          <Link style={{ cursor: 'pointer' }} onClick={handleParticipantClick}>
            {participantDisplayName}
          </Link>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default PendingContentView;
