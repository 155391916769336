import EmailIcon from '@mui/icons-material/Email';
import { TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
  ClientHappeningExtended,
  ClientHappeningMessageRequest,
  ClientUser
} from 'digital-vital-backend-api';
import { FC, useCallback, useMemo, useState } from 'react';
import { CONTACT_ORGANIZER_ACTION_TEXT } from '../../../constants/strings';
import useHappeningApi from '../../../hooks/useHappeningApi';
import { isInRange } from '../../../utils/common';
import AbstractDialog from '../../dialogs/AbstractDialog';

type ContactParticipantDialogProps = {
  open: boolean;
  onClose: () => void;
  happening?: ClientHappeningExtended;
  participant?: ClientUser;
  isContactAllParticipants: boolean;
};

const ContactParticipantDialog: FC<ContactParticipantDialogProps> = ({
  open,
  onClose,
  happening,
  participant,
  isContactAllParticipants
}) => {
  const happeningApi = useHappeningApi();
  const [message, setMessage] = useState<string>('');

  const enableSend = useMemo(() => isInRange(10, 500, message), [message]);

  const handleClose = useCallback(() => {
    onClose();
    setMessage('');
  }, [onClose]);

  const handleMessageChange = useCallback(
    (e) => setMessage(e.target.value),
    [setMessage]
  );

  const handleSendMsgToParticipants = useCallback(() => {
    const SendMsgToParticipants = async () => {
      if (!happening) {
        return;
      }
      const msgRquest: ClientHappeningMessageRequest = {
        message
      };
      await happeningApi.contactParticipants(happening.id, msgRquest);
      handleClose();
    };
    const SendMsgToParticipant = async () => {
      if (!participant || !happening) {
        return;
      }
      const msgRquest: ClientHappeningMessageRequest = {
        message
      };

      await happeningApi.contactParticipant(
        happening.id,
        participant?.id,
        msgRquest
      );
      handleClose();
    };
    if (isContactAllParticipants) SendMsgToParticipants();
    else if (participant) SendMsgToParticipant();
  }, [
    happening,
    message,
    handleClose,
    participant,
    isContactAllParticipants,
    happeningApi
  ]);

  const label = useMemo(() => {
    if (isContactAllParticipants && !participant) return 'alle Teilnehmer';
    else return participant?.displayName;
  }, [isContactAllParticipants, participant]);

  return (
    <AbstractDialog
      icon={<EmailIcon color="primary" sx={{ fontSize: '64px' }} />}
      open={open}
      btnActionText={CONTACT_ORGANIZER_ACTION_TEXT}
      hideCancelBtn={false}
      onClose={handleClose}
      onAction={handleSendMsgToParticipants}
      maxWidth="none"
      isActionDisabled={!enableSend}
    >
      <Box sx={{ mb: 2 }}>
        <Typography gutterBottom={true}>
          {`Hier können sie eine Nachricht an ${
            !isContactAllParticipants ? 'den Teilnehmer ' : ''
          }`}
          <b>{label}</b>
          {' ihres Nachbarschaftstisches '}
          <b>"{happening?.title}"</b>
          {' senden:'}
        </Typography>
        <TextField
          multiline
          rows={4}
          variant="outlined"
          placeholder="Hier tippen…"
          label="Nachricht"
          fullWidth
          value={message}
          onChange={handleMessageChange}
          sx={{ mt: 2 }}
        />
      </Box>
    </AbstractDialog>
  );
};

export default ContactParticipantDialog;
