export const MuiTabComponent = (
  primaryColor: string,
  selectedColor: string,
  selectedBgColor: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  return {
    styleOverrides: {
      root: {
        color: primaryColor,
        borderRadius: '5px 5px 0 0',
        '&.Mui-selected': {
          color: selectedColor,
          backgroundColor: selectedBgColor
        }
      }
    }
  };
};
