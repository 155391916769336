import IosShareIcon from '@mui/icons-material/IosShare';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { CSSProperties, FC } from 'react';
import AbstractDialog from './AbstractDialog';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import { SHARE_DIALOG_TITLE_MESSAGE } from '../../constants/strings';
const useStyles = (): Record<string, CSSProperties> => ({
  buttonAreaStyle: {
    fontSize: '2.5rem'
  }
});
type ShareDialogProps = {
  title: string;
  open: boolean;
  onClose: () => void;
};

const ShareDialog: FC<ShareDialogProps> = ({ open, onClose, title }) => {
  const styles = useStyles();
  const currentURLLocation =
    document.location.origin + document.location.pathname;
  // For testing in Local Env
  // const currentURLLocation = 'google.com';

  return (
    <AbstractDialog
      icon={<IosShareIcon color="primary" sx={{ fontSize: '64px' }} />}
      open={open}
      hideCancelBtn={false}
      onClose={onClose}
      maxWidth="none"
      hideActionBtn={true}
    >
      <Box alignContent={'center'}>
        <Box sx={{ mb: 2 }}>
          <Typography gutterBottom={true}>
            {SHARE_DIALOG_TITLE_MESSAGE}
          </Typography>
        </Box>

        <Grid container>
          <Grid item xs={12}>
            <Grid container alignItems={'center'}>
              {/* For facebook share there is a known issue of quotes not showing up. Please check https://github.com/nygardk/react-share/issues/456 */}
              <FacebookShareButton url={currentURLLocation} quote={title}>
                <FacebookRoundedIcon
                  color="primary"
                  sx={styles.buttonAreaStyle}
                />{' '}
              </FacebookShareButton>
              <span style={{ marginLeft: '.5rem' }}>Facebook</span>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems={'center'}>
              <TwitterShareButton url={currentURLLocation} title={title}>
                <TwitterIcon color="primary" sx={styles.buttonAreaStyle} />{' '}
              </TwitterShareButton>
              <span style={{ marginLeft: '.5rem' }}>Twitter</span>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems={'center'}>
              <WhatsappShareButton url={currentURLLocation} title={title}>
                <WhatsAppIcon color="primary" sx={styles.buttonAreaStyle} />{' '}
              </WhatsappShareButton>
              <span style={{ marginLeft: '.5rem' }}>WhatsApp</span>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </AbstractDialog>
  );
};

export default ShareDialog;
